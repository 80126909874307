import { Page, View, Text } from '@react-pdf/renderer'
import React from 'react'
import Header from './Header'
import tw from '../../../Utils/tailwind-react-pdf'
import FooterInvoice from './FooterInvoice'
import WaterMarkSymbol from '../../../Utils/WaterMarkSymbol'

const PageWrapper = ({ children }) => {
    return (
        // <Page size={"A4"} style={[tw("p-1 text-xs border border-grey-600"),{fontFamily: "Times-Roman"}]} >
        <Page size={"A4"} wrap style={[tw("px-2 pb-[160px] text-sm flex flex-col h-full relative "), { fontFamily: "Times-Roman" }]} >
            <WaterMarkSymbol />
            <View fixed style={tw("mt-9")}>
                <Header />
            </View>
            <View>
                {children}
            </View>
            <View fixed style={tw("pr-2 pb-2 mt-3 absolute bottom-3")}>

                <FooterInvoice />

            </View>
        </Page>
    )
}

export default PageWrapper