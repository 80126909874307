import React, { useContext, useRef, } from "react";
import { TextInput, DropdownInput } from "../../../../Inputs";
import secureLocalStorage from "react-secure-storage";
import { dropDownListObject } from "../../../../Utils/contructObject";
import { FieldsContext } from '..';
import { useGetProductCategoryMasterQuery } from "../../../../redux/LabServices/ProductCategoryMasterServices";
import { useGetBrandMasterQuery } from "../../../../redux/LabServices/BrandMasterServices";
import { useGetWashMasterQuery } from "../../../../redux/LabServices/WashMasterServies";
import { useGetFinishMasterQuery } from "../../../../redux/LabServices/FinishMasterServices";
import { Loader } from "../../../../Basic/components";


export default function Form() {
    const fields = useContext(FieldsContext);

    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: productCategoryList, isLoading: isProductCategoryLoading, isFetching: isProductCategoryFetching } =
        useGetProductCategoryMasterQuery({ params });

    const { data: brandList, isLoading: isBrandLoading, isFetching: isBrandFetching } =
        useGetBrandMasterQuery({ params });

    const { data: washList, isLoading: isWashLoading, isFetching: isWashFetching } =
        useGetWashMasterQuery({ params });

    const { data: finishList, isLoading: isFinishLoading, isFetching: isFinishFetching } =
        useGetFinishMasterQuery({ params });

    return (
        <div>
            {isProductCategoryLoading || isProductCategoryFetching || isBrandLoading || isBrandFetching ||
                isWashLoading || isWashFetching || isFinishLoading || isFinishFetching
                ?
                <Loader />
                :
                <div>
                    <div className='grid grid-cols-3 my-2'>
                        <DropdownInput name="Test Stage" autoFocus options={dropDownListObject(fields.id ? productCategoryList.data : productCategoryList.data.filter(item => item.active), "name", "id")} value={fields.productCategoryId} setValue={(value) => { fields.setProductCategoryId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <DropdownInput name="Wash" options={dropDownListObject(fields.id ? washList.data : washList.data.filter(item => item.active), "name", "id")} value={fields.washId} setValue={(value) => { fields.setWashId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <DropdownInput name="Finish" options={dropDownListObject(fields.id ? finishList.data : finishList.data.filter(item => item.active), "name", "id")} value={fields.finishId} setValue={(value) => { fields.setFinishId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                    </div>
                    <div className='grid grid-cols-3 my-2'>
                        <TextInput name="Actual Fabric Dia" type="text" value={fields.actualFabricDia} setValue={fields.setActualFabricDia} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Age / Sizerange" type="text" value={fields.sizerange} setValue={fields.setSizerange} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                    </div>
                </div>
            }
        </div>
    )
}