import React, { useRef } from 'react'
import { Document, Page, Text, View, PDFViewer, StyleSheet, PDFRenderer } from '@react-pdf/renderer';
import Footer from '../../../Laboratory/Components/PrintFormat-InvoiceReport/Address';
import Header from './Header';
import FactoryAddress from '../PrintFormat-TestReport/FactoryAddress';
import FooterInvoice from './FooterInvoice';
import InvoiceContent from './InvoiceContent';
import tw from '../../../Utils/tailwind-react-pdf'

const InvoiceDocument = ({ data, testDetailsData }) => {
    const componentRef = useRef()
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#ffffff',
            padding: 20,
            fontFamily: 'Helvetica',
            fontSize: 12,
            paddingBottom: 160,
            border: "2px solid black",

        },
        content: {
            marginBottom: 160,

            fontSize: 9,
        },
        header: {
            textAlign: 'center',
            height: 50
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',

            padding: 10,
            height: 150,
        },

    });



    return (
        <>
            <Document  >

                {/* <Page size="A4" style={[styles.page,{fontFamily: "Times-Roman"}]}>
            <View fixed>
                    
                    <Header/> 
                  
               </View>
                <View style={styles.content} > */}

                <InvoiceContent data={data} testDetailsData={testDetailsData} />
                {/* </View>
                <View fixed style={tw(" pb-2 mt-3 absolute bottom-3")}>

                   <FooterInvoice/>
                   <View style={tw("text-right w-full pb-1 pt-1")}>

<Text render={({ pageNumber, totalPages }) => (
   `Page No :${pageNumber} / ${totalPages}`
)} fixed />
</View>
                </View>
            </Page> */}
            </Document>
        </>
    )
}

export default InvoiceDocument