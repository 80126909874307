import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetPartyQuery,
    useGetPartyByIdQuery,
    useAddPartyMutation,
    useUpdatePartyMutation,
    useDeletePartyMutation,
} from "../../../redux/ErpServices/PartyMasterServices";
import { useGetCityQuery } from "../../../redux/services/CityMasterService";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, DropdownInput, CheckBox, TextArea, MultiSelectDropdown, Modal } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListMergedObject, multiSelectOption } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, params } from "../../../Utils/helper";
import { useGetLabTestStandardMasterQuery } from "../../../redux/LabServices/LabTestStandardMasterServices";
import { Loader } from "../../../Basic/components";
import { faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BranchReport from "./BranchReport";
import NewBranch from "./NewBranch";
import PartyLogoUpload from "./PartyLogoUpload";
import ExecutiveSummary from "./ExecutiveSummary";
import TestDetails from "./TestDetails";

const MODEL = "Party Master";

export default function Form() {

    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);

    const [id, setId] = useState("");
    const [panNo, setPanNo] = useState("");
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [pincode, setPincode] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [partyCategoryList, setPartyCategoryList] = useState([])
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("")
    const [active, setActive] = useState(true);
    const [invoiceTo, setInvoiceTo] = useState(true);
    const [reportTo, setReportTo] = useState(true);
    const [isOutsideBill, setIsOutsideBill] = useState(false);
    const [isGstBill, setIsGstBill] = useState(true);
    const [executiveSummary, setExecutiveSummary] = useState([]);
    const [testDetails, setTestDetails] = useState([]);


    const [standardList, setStandardList] = useState([]);
    const [isSupplier, setIsSupplier] = useState(true);
    const [isBuyer, setIsBuyer] = useState(true);
    const [isAgent, setIsAgent] = useState(true);
    const [contactDetails, setContactDetails] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [branchForm, setBranchForm] = useState(false);
    const [partyBranch, setPartyBranch] = useState([]);
    const [currentSelectedBranchIndex, setCurrentSelectedBranchIndex] = useState("");
    const [isPartyLogoOpen, setIsPartyLogoOpen] = useState(false);
    const [logo, setLogo] = useState("")






    const childRecord = useRef(0);
    const dispatch = useDispatch()

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const { data: labTestStandardMasterList } =
        useGetLabTestStandardMasterQuery({ params: { companyId } })
    const userId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userId"
    )

    const { data: cityList } =
        useGetCityQuery({ params });

    const { data: allData, isLoading, isFetching } = useGetPartyQuery({ params, searchParams: searchValue });

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetPartyByIdQuery(id, { skip: !id });

    const [addData] = useAddPartyMutation();
    const [updateData] = useUpdatePartyMutation();
    const [removeData] = useDeletePartyMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        // setPartyCategoryList(data?.PartyTypeOnParty ? data.PartyTypeOnParty.map(item => {
        //     return { value: item.partyCategoryId, label: findFromList(item.partyCategoryId, labTestStandardMasterList ? labTestStandardMasterList.data : [], "name") }
        // }) : []);
        setStandardList(data?.PartyOnStandardItems ? data.PartyOnStandardItems.map(item => {
            return { value: item.labTestStandardId, label: findFromList(item.labTestStandardId, labTestStandardMasterList ? labTestStandardMasterList.data : [], "name") }
        }) : [])
        setPanNo(data?.panNo ? data?.panNo : "");
        setName(data?.name ? data?.name : "");
        setAddress(data?.address ? data?.address : "");
        setGstNo(data?.gstNo ? data?.gstNo : "");
        setCode(data?.code ? data?.code : "");
        setPincode(data?.pincode ? data?.pincode : "");
        setCity(data?.cityId ? data?.cityId : "");
        setActive(id ? (data?.active ? data.active : false) : true);
        setIsAgent((data?.isAgent ? data.isAgent : false));
        setIsBuyer((data?.isBuyer ? data.isBuyer : false));
        setIsSupplier((data?.isSupplier ? data.isSupplier : false));
        setIsGstBill(id ? (data?.isGstBill ? data.isGstBill : false) : true);
        setIsOutsideBill((data?.isOutsideBill ? data.isOutsideBill : false));
        setPartyBranch(data?.PartyBranch ? data?.PartyBranch : [])
        setInvoiceTo(data?.PartyBranch[0] ? data?.PartyBranch[0]["isInvoice"] : true);
        setReportTo(data?.PartyBranch[0] ? data?.PartyBranch[0]["isReport"] : true);
        setContactDetails(data?.PartyBranch[0] ? data?.PartyBranch[0]["contactDetails"] : [])
        setExecutiveSummary(data?.ExecutiveSummary ? data?.ExecutiveSummary : []);
        setLogo(data?.logo ? data?.logo : null);
        setTestDetails(data?.testDetails ? data?.testDetails : [])
    }, [id]);


    useEffect(() => {
        setPartyBranch(prev => {
            if (prev.length <= 1) {
                return [{ name, address, isHo: true, isInvoice: invoiceTo, isReport: reportTo, contactDetails }]
            } else {
                let newBranch = structuredClone(prev);
                newBranch[0] = { name, address, isHo: true, isInvoice: invoiceTo, isReport: reportTo, contactDetails }
                return newBranch
            }
        })
    }, [name, address, invoiceTo, reportTo, contactDetails])

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        name, code, address, cityId: city, pincode, panNo, email, isGstBill, isOutsideBill,
        contactPersonName, gstNo, active, companyId, id, userId, phone, isSupplier, isBuyer, isAgent,
        partyCategoryList: partyCategoryList ? partyCategoryList.map(item => item.value) : undefined,
        partyStandardList: standardList ? standardList.map(item => item.value) : undefined,
        partyBranch, logo, testDetails
    }

    const validateData = (data) => {
        return data.name && data.joiningDate && data.fatherName && data.dob && data.gender &&
            data.maritalStatus && data.bloodGroup && data.degree && data.specialization && data.partyCategoryList && data.currencyId &&
            data.contactDetails && data.localAddress && data.localCity && data.localPincode && data.panNo && data.email && data.mobile
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback({ id, body: data }).unwrap();
            } else {
                returnData = await callback(data).unwrap();
            }
            dispatch({
                type: `accessoryItemMaster/invalidateTags`,
                payload: ['AccessoryItemMaster'],
            });
            dispatch({
                type: `CityMaster/invalidateTags`,
                payload: ['City/State Name'],
            });
            dispatch({
                type: `CurrencyMaster/invalidateTags`,
                payload: ['Currency'],
            });
            // dispatch({
            //     type: `registration/invalidateTags`,
            //     payload: ['Registration'],
            // });
            setId("")
            syncFormWithDb(undefined);
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        // if (!validateData(data)) {
        //     toast.info("Please fill all required fields...!", { position: "top-center" })
        //     return
        // }
        // if (!validateEmail(data.email)) {
        //     toast.info("Please enter proper email id!", { position: "top-center" })
        //     return
        // }
        // if (!validateMobile(data.mobile)) {
        //     toast.info("Please enter proper mobile number...!", { position: "top-center" })
        //     return
        // }
        // if (!validatePan(data.panNo)) {
        //     toast.info("Please enter proper pan number...!", { position: "top-center" })
        //     return
        // }
        // if (!validatePincode(data.localPincode)) {
        //     toast.info("Please enter proper local Pincode...!", { position: "top-center" })
        //     return
        // }
        // if (data.permPincode && !validatePincode(data.permPincode)) {
        //     toast.info("Please enter proper perm. Pincode...!", { position: "top-center" })
        //     return
        // }

        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }


    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                dispatch({
                    type: `accessoryItemMaster/invalidateTags`,
                    payload: ['AccessoryItemMaster'],
                });
                setId("");
                dispatch({
                    type: `CityMaster/invalidateTags`,
                    payload: ['City/State Name'],
                });
                dispatch({
                    type: `CurrencyMaster/invalidateTags`,
                    payload: ['Currency'],
                });
                syncFormWithDb(undefined);
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setReadOnly(false);
        setForm(true);
        setSearchValue("");
        setId("");
        syncFormWithDb(undefined);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }
    const tableHeaders = ["Code", "Name",]
    const tableDataNames = ["dataObj.code", "dataObj.name"]


    function removeItem(index) {
        setContactDetails(contactDetails => {
            return contactDetails.filter((_, i) => parseInt(i) !== parseInt(index))
        });
    }

    if (!labTestStandardMasterList?.data) return <Loader />

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme">
            <Modal isOpen={branchForm} onClose={() => { setCurrentSelectedBranchIndex(""); setBranchForm(false) }} widthClass={"w-[900px] h-[600px] pt-10"}>
                <NewBranch setBranchForm={setBranchForm} setCurrentSelectedBranchIndex={setCurrentSelectedBranchIndex} currentSelectedBranchIndex={currentSelectedBranchIndex} branchDetails={partyBranch} setBranchDetails={setPartyBranch} setPartyBranch={setPartyBranch} partyBranch={partyBranch} />
            </Modal>
            <Modal isOpen={isPartyLogoOpen} onClose={() => setIsPartyLogoOpen(false)} widthClass={"p-8 overflow-auto"}>
                <PartyLogoUpload logo={logo} setLogo={setLogo} id={id} setIsPartyLogoOpen={setIsPartyLogoOpen} />
            </Modal>
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData} />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip ">
                    <div className=" col-span-3 border overflow-auto">
                        <div className='col-span-3 overflow-auto'>

                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1 '>
                                    <legend className='sub-heading'>Official Details</legend>

                                    {/* <MultiSelectDropdown name="Party Type"
                                            selected={partyCategoryList}
                                            setSelected={setPartyCategoryList}
                                            options={partyTypeList ? multiSelectOption(id ? partyTypeList.data : partyTypeList.data.filter(item => item.active), "name", "id") : []}
                                            // options={multiSelectOption(partyTypeList ? partyTypeList.data : [], "name", "id")} 
                                            required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} /> */}
                                    <div className="flex">
                                        <CheckBox name="Supplier" readOnly={readOnly} value={isSupplier} setValue={setIsSupplier} />
                                        <CheckBox name="Buyer" readOnly={readOnly} value={isBuyer} setValue={setIsBuyer} />
                                        <CheckBox name="Agent" readOnly={readOnly} value={isAgent} setValue={setIsAgent} />
                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                        <CheckBox name="Invoice To" readOnly={readOnly} value={invoiceTo} setValue={setInvoiceTo} />
                                        <CheckBox name="Report To" readOnly={readOnly} value={reportTo} setValue={setReportTo} />
                                        <CheckBox name="Gst.Bill" readOnly={readOnly} value={isGstBill} setValue={setIsGstBill} />
                                        <CheckBox name="Outside.Bill" readOnly={readOnly} value={isOutsideBill} setValue={setIsOutsideBill} />
                                        <button className='bg-blue-400 rounded-md p-0.5 text-xs ml-28' onClick={() => { setIsPartyLogoOpen(true) }} >Party Logo</button>

                                    </div>

                                    <div className='grid grid-cols-3 my-2'>

                                        <TextInput name="Party Code" type="text" value={code} setValue={setCode} readOnly={readOnly} required={true} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Party Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        {/* <TextInput name="Alias Name" type="text" value={aliasName} setValue={setAliasName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Display" type="text" value={displayName} setValue={setDisplayName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} /> */}

                                        <DropdownInput name="City/State Name" options={dropDownListMergedObject(id ? cityList.data : cityList.data.filter(item => item.active), "name", "id")} value={city} setValue={setCity} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Pan No" type="pan_no" value={panNo} setValue={setPanNo} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Pincode" type="number" value={pincode} setValue={setPincode} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="GST No" type="text" value={gstNo} setValue={setGstNo} readOnly={readOnly} />
                                        <TextArea name="Address" value={address} setValue={setAddress} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                    </div>
                                    <div className='grid grid-cols-2 my-2'>
                                        <MultiSelectDropdown name="Standard"
                                            selected={standardList}
                                            setSelected={setStandardList}
                                            options={labTestStandardMasterList ? multiSelectOption(id ? labTestStandardMasterList.data : labTestStandardMasterList.data.filter(item => item.active), "name", "id") : []}
                                            required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />

                                    </div>



                                </fieldset>
                                <div className='mr-1'>

                                    <fieldset className='frame my-1'>
                                        <legend className='sub-heading'>Contact Details</legend>
                                        <div className='grid grid-cols-1 gap-2 my-2'>
                                            <table className="border border-gray-500">
                                                <thead className="border border-gray-500">
                                                    <tr>
                                                        <th className="border border-gray-500 text-sm">S.no</th>
                                                        <th className="border border-gray-500 text-sm">Contact Person Name</th>
                                                        <th className="border border-gray-500 text-sm">Mobile.no</th>
                                                        <th className="border border-gray-500 text-sm">Tel.Phone</th>
                                                        <th className="border border-gray-500 text-sm" >Email Id</th>
                                                        <th>
                                                            <button type='button' className="text-green-700" onClick={() => {
                                                                let newContactDetails = [...contactDetails];
                                                                newContactDetails.push({});
                                                                setContactDetails(newContactDetails);
                                                            }}> {<FontAwesomeIcon icon={faUserPlus} />}

                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contactDetails?.map((item, index) =>
                                                        <tr className="text-center" key={index}>

                                                            <td className="border border-gray-500 text-xs">
                                                                {index + 1}
                                                            </td>
                                                            <td className="border border-gray-500 text-xs">
                                                                <input className="w-full p-1 capitalize"
                                                                    type="text" value={item.contactPersonName} onChange={(e) => {
                                                                        const newContactDetails = structuredClone(contactDetails)
                                                                        newContactDetails[index]["contactPersonName"] = e.target.value;
                                                                        setContactDetails(newContactDetails);
                                                                    }} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} caseSensitive />

                                                            </td>
                                                            <td className="border border-gray-500 text-xs">
                                                                <input className="w-full p-1" type="text" value={item.mobileNo} onChange={(e) => {
                                                                    const newContactDetails = structuredClone(contactDetails);
                                                                    newContactDetails[index]["mobileNo"] = e.target.value;
                                                                    setContactDetails(newContactDetails);
                                                                }} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />

                                                            </td>
                                                            <td className="border border-gray-500 text-xs">
                                                                <input className="w-full p-1" type="text" value={item.phone} onChange={(e) => {
                                                                    const newContactDetails = structuredClone(contactDetails);
                                                                    newContactDetails[index]["phone"] = e.target.value;
                                                                    setContactDetails(newContactDetails);
                                                                }} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />

                                                            </td>
                                                            <td className="border border-gray-500 text-xs">
                                                                <input className="w-full p-1" type="text" value={item.email} onChange={(e) => {
                                                                    const newContactDetails = structuredClone(contactDetails);
                                                                    newContactDetails[index]["email"] = e.target.value;
                                                                    setContactDetails(newContactDetails);
                                                                }} readOnly={readOnly} />

                                                            </td>
                                                            <td className="border border-gray-500 p-1 text-xs">
                                                                <button
                                                                    type='button'
                                                                    onClick={() => removeItem(index)}
                                                                    className='text-md text-red-600 ml-1'>{<FontAwesomeIcon icon={faTrashCan} />}</button>
                                                            </td>

                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>



                                        </div>
                                    </fieldset>
                                </div>
                                <div className='mr-1'>
                                    <fieldset className='frame my-1 overflow-auto'>
                                        <legend className='sub-heading'>Branch Details</legend>
                                        <BranchReport setBranchDetails={setPartyBranch} id={id} branchDetails={partyBranch} setBranchForm={setBranchForm} onNew={() => setBranchForm(true)} setCurrentSelectedBranchIndex={setCurrentSelectedBranchIndex} />
                                    </fieldset>
                                </div>
                                <div className='mr-1'>
                                    <fieldset className='frame my-1'>
                                        <legend className='sub-heading'>Test Details</legend>
                                        <TestDetails testDetails={testDetails} setTestDetails={setTestDetails} id={id} readOnly={readOnly} buyerId={id} />
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>


                </div>
            </div>

            {/* <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Party Info</legend>
                                    <div className='grid grid-cols-1 gap-2 my-2'>
                                        <TextInput name="Tin No" type="text" value={tinNo} setValue={setTinNo} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <DateInput name="CST Date" value={cstDate} setValue={setCstDate} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="CST No" type="text" value={cstNo} setValue={setCstNo} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Cin No" type="text" value={cinNo} setValue={setCinNo} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Fax No" type="text" value={faxNo} setValue={setFaxNo} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                    </div>
                                </fieldset> */}



        </div>
    );
}
