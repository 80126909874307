import { Page, Text, View, Image, Font } from '@react-pdf/renderer'
import React from 'react'
import Header from './Header'
import tw from '../../../Utils/tailwind-react-pdf'
import moment from 'moment'
import FactoryAddress from './Content/FactoryAddress'

import accreditationLogo from "../../../../src/assets/Ilac-MRA MONO LOGO.png"
import WaterMarkSymbol from '../../../Utils/WaterMarkSymbol'




const PageWrapper = ({ headingOptions, children, accredidationLogo = false, testHeading = false, reportData, sampleDetails = true, splitTest, testReportNo }) => {
    return (

        <Page size={"A4"} wrap style={[tw("px-3 pb-[170px] text-sm flex flex-col h-full relative "), { fontFamily: "Times-Roman" }]} >
            <WaterMarkSymbol />
            <View fixed>

                <Header data={reportData?.data} headingOptions={headingOptions} />
                {sampleDetails &&
                    <View style={tw('gap-y-3 grid grid-cols-1 place-items-start w-full place-content-center pt-1 pb-1 ml-9')}>
                        <View style={tw("flex flex-row gap-x-0.5")}>
                            <Text style={{ fontWeight: 900, fontFamily: "Times-Bold" }} > Test Report No :</Text>
                            <Text  > {testReportNo}</Text>
                        </View>
                        <Text>  {splitTest === "ACCREDITATION" && reportData?.data?.testDetailsSummary?.accredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"F"}` : splitTest === "NONACCREDITATION" ? "" : splitTest == "ALL" && reportData?.data?.testDetailsSummary?.nonAccredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"P"}` : reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"F"}` : "--"}</Text>
                        {/* <Text> Date Out :{moment.utc(reportData?.data?.dateOut).format("DD-MM-YYYY")}  </Text> */}
                        <View style={tw("flex flex-row gap-x-0.5")}>
                            <Text style={{ fontWeight: 900, fontFamily: "Times-Bold" }} >Date Out :</Text>
                            <Text  > {moment.utc(reportData?.data?.logoutDate).format("DD-MM-YYYY")} </Text>
                        </View>
                        {reportData?.data?.amendmentReason &&
                            <>
                                <View style={tw("flex flex-row gap-x-0.5")}>
                                    <Text style={{ fontWeight: 900, fontFamily: "Times-Bold" }} >Amendment  <Text>{reportData?.data?.testReportNo?.slice(-1)}</Text>  Date :</Text>
                                    <Text  > {moment.utc(reportData?.data?.amendmentLogOutDate).format("DD-MM-YYYY")} </Text>
                                </View>

                            </>
                        }
                    </View>
                }
                {
                    accredidationLogo &&
                    (splitTest == "ACCREDITATION" || splitTest == "ALL") && (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.passTestResults)?.length > 0 || reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.failTestResults).length > 0) &&

                    <View style={tw("-mt-24 flex flex-row-reverse pr-9  w-full")}>
                        <Image style={tw("bg-gray-400 rounded-xl text-end w-36")} alt="nabl logo" src={accreditationLogo} />
                    </View>

                }
                {
                    testHeading &&
                    <View style={tw('text-xs  flex flex-row w-full text-center mt-5 mb-3')}>
                        <Text style={[tw(' w-[40%] p-1 text-center text-lg'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Test Name </Text>
                        <Text style={[tw(' w-[40%] p-1 text-center -ml-9 text-lg'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Test Result</Text>
                        <Text style={[tw(' w-[20%] p-1 text-center text-lg'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Requirement</Text>

                    </View>

                }
            </View>
            <View >
                {children}
            </View>
            <View fixed style={tw("pr-2 pb-2 mt-3 absolute bottom-3")}>
                <FactoryAddress />
                <View style={tw("text-right w-full pb-1 pt-1")}>
                    <Text render={({ pageNumber, totalPages }) => (
                        `Page No :${pageNumber} / ${totalPages}`
                    )} fixed />
                </View>
            </View>

        </Page>
    )
}

export default PageWrapper