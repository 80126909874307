import React from 'react'
import { useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices'
import { GenerateButton } from '../../../Buttons'
import { getCommonParams } from '../../../Utils/helper'

const GenerateInvoiceNumber = ({ registrationId }) => {
    const [update] = useUpdateRegistrationMutation()
    const { finYearId } = getCommonParams()
    async function handleGenerate() {
        await update({ id: registrationId, isGenerateInvoiceNumber: true, finYearId })
    }
    return (
        <GenerateButton onClick={handleGenerate} />
    )
}

export default GenerateInvoiceNumber