import React, { useEffect } from 'react';
import { useGetFiberContentMasterByIdQuery } from '../../../redux/ErpServices/FiberContentMasterServices';
import { useGetBlendMasterQuery } from '../../../redux/ErpServices/BlendMasterServices';
import secureLocalStorage from 'react-secure-storage';
import { findFromList } from '../../../Utils/helper';
import BlendResultInput from './BlendResultInput';

const ResultInput = ({ setResultDetails, parameter, handleChangeBlend, fiberContentId, singleParameter, parameters, results, labTestRequirementDetailsId, resultDetails, readOnly, childRecord, testItem, requirementList, isEditable, isResult, matchingSampleDetails }) => {
  const datas = resultDetails ? resultDetails.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
  const parameterName = (datas?.parameterName)?.toLowerCase();
  let testParameters = parameters.flatMap(item => item.labTestAnalyteDetailsParameters)
  const findDecimalValue = (testParameters?.find(item => item?.name.toLowerCase() == parameterName))?.decimalValue
  const decimalValue = findDecimalValue ? findDecimalValue : 0;
  const resultData = datas?.resultValue
  function getRegex(formula, subHeading, labTestRequirementDetailsId) {



    if (!formula) return formula
    let input = formula;
    const words = formula.match(/\{(.*?)\}/g)
    if (!words) return formula
    words.forEach(element => {
      input = input.replace(element, getFormula(element.slice(1, -1), subHeading, labTestRequirementDetailsId))
    });
    return getRegex(input, subHeading, labTestRequirementDetailsId)
  }



  function getFormula(parameter, subHeading, labTestRequirementDetailsId) {

    let resultTest = resultDetails.filter(res => parseInt(res.testId) === parseInt(testItem.testId));



    resultTest = resultTest.find(i => i.parameterName.toLowerCase() === parameter.toLowerCase())

    if (!resultTest) return parameter
    resultTest = resultTest.resultValue.find(i => i.subHeading.toLowerCase() === subHeading.toLowerCase())
    if (!resultTest) return parameter

    return resultTest?.value
  }

  function getEval(value) {
    try {
      return eval(value)
    } catch (err) {
      return 0
    }
  }


  function handleOnChange(newValue, labTestRequirementDetailsId, resultIndex, field, isResult, subHeading) {

    const index = resultDetails.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));

    setResultDetails(prev => {
      let newReq = structuredClone(prev);
      newReq[index]["resultValue"][resultIndex][field] = newValue;

      return newReq
    })
  }


  // useEffect(()=> {


  //   setResultDetails(prev => {
  //     let newReq = structuredClone(prev);
  //     const index = resultDetails.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));
  //   const findHeading =resultDetails.find(val=>val.labTestRequirementDetailsId==labTestRequirementDetailsId).resultValue

  //   const  resultIndex = resultData?.findIndex(item=>findHeading?.filter(val=>val.subHeading==item.subHeading))
  //   const  subHeading = resultData?.find(item=>findHeading?.filter(val=>val.subHeading==item.subHeading))?.subHeading
  //    newReq[index]["resultValue"][resultIndex]["reportValue"]=getEval(getRegex(newReq[index]["resultValue"][resultIndex]["value"],subHeading, labTestRequirementDetailsId))

  //       return newReq
  //   })
  // }, [isResult])




  return (
    <>{console.log(singleParameter, "singleParametersingleParameter")}
      {
        singleParameter?.selectedOption === "CLAIMEDFIBERCONTENT" ?

          <tr >
            {
              resultData?.map((result, resultIndex) =>
                <BlendResultInput singleParameter={singleParameter} result={result} resultIndex={resultIndex} handleChangeBlend={handleChangeBlend} labTestRequirementDetailsId={labTestRequirementDetailsId}
                  isEditable={isEditable} childRecord={childRecord} />
              )}
          </tr>


          :
          resultData?.map((result, resultIndex) =>
            <td className='border border-gray-500  p-1 ' key={resultIndex}>
              <input type="text" className="ml-4 text-center w-52 focus:outline-none "
                value={isEditable ? result?.value ? result?.value : "" : parseFloat(getEval(getRegex(result["value"], result.subHeading, labTestRequirementDetailsId))).toFixed(decimalValue || 2)}
                // onBlur={isEditable ? result?.id && decimalValue > 0 && requirementList?.isDecimalAdd ? parseFloat(result?.value).toFixed(decimalValue) : result?.value ? parseFloat(result.value).toFixed(2) : "" : parseFloat(getEval(getRegex(result["value"], result.subHeading, labTestRequirementDetailsId))).toFixed(decimalValue ? decimalValue : 2)}
                onChange={(e) => handleOnChange(e.target.value, labTestRequirementDetailsId, resultIndex, "value", isResult, result?.subHeading)}
                readOnly={!isEditable} disabled={(childRecord.current > 0)} />
            </td>
          )
      }

    </>
  );
}



export default ResultInput;
