import React from 'react'
import SampleDetailsPrint from './SampleDetailsPrint'
import { getDateFromDateTimeForDisplay,formatAMPM } from '../../../Utils/helper'
import WashCareInstruction from './WashCareInstruction'
import { TICK_ICON } from '../../../icons';
import SampleTestParameters from './SampleTestParameters';
import WashCareInstructionPrint from '../PrintFormat-TestScanningSheet/WashCareInstructionPrint';
import SampleTestParametersPrint from './SampleTestParametersPrint';

const PrintSheet = ({data,washCareData,testList, methodList}) => {
  return (
    <>
     <div className='font-times grid grid-cols-2 place-items-start w-full place-content-center p-1 gap-y-3'>
            <h1>Lab Report No : {data?.docId}</h1>
            <h1>Log-In-Date And Time : {data?.createdAt ? getDateFromDateTimeForDisplay(data.createdAt) + "  " + formatAMPM(data.createdAt) : ""} </h1>
           
            <h1>Due Date : {data?.dueDate ? getDateFromDateTimeForDisplay(data.dueDate) : ""}</h1>
            <h1>Service :{data?.ServiceLevel?.name}</h1>
            
            <h1>Buyer Name :{data?.Buyer?.name} </h1>
           
          </div>
          <SampleDetailsPrint data={data} />
         
         <div className="mt-3 mb-3">
         <table className="w-full">
              <tbody>
                <tr className="">
                <td className='border border-gray-500 w-44 text-xs py-4'>Submitted Washcare Instruction </td>
                  <td className='border border-gray-500  text-xs '>{data.washCareDetails ? <WashCareInstructionPrint washCareData={washCareData} washCareDetails={data.washCareDetails}/> : ""} </td>
                </tr>
              </tbody>
            </table>
         </div>
           
           
         
          <div>
            <SampleTestParametersPrint testList={testList} methodList={methodList}  testDetails={data?.testDetails ? data?.testDetails : []} packageName={data?.Package ? data?.Package.name : ""} />
          </div>

          <div className="mt-3 ">
            <h1>(Standard Note)</h1>
            <div className="mt-3">
           <h1> {TICK_ICON} You are requested to update your GSTN details in our records. Please send your GST number(s) and Address to <a className="text-blue-500" href="#"> account.pro@srgapparels.com</a> </h1> 
           <h1> {TICK_ICON} Please quote Lab Report number for any correspondence</h1> 
           <h1> {TICK_ICON}  Delay in receiving any requested mandatory information or additional sample may affect in report due date.</h1> 
           <h1> {TICK_ICON} Test assignment are made based on the specification in the submitted TRF by customer</h1> 
           <h1> {TICK_ICON} Hard copy of test report will be issued based on the request.</h1> 
           <h1> {TICK_ICON} Please check with accounts for outstanding payment and clear the payment to avoid the delay for further testing / reports process.</h1> 
          <h1> {TICK_ICON} Please feel free Contact For further enquiry : <strong>Technical: N. Sethuraman – 9952785239 / Customer Service: Ms. Manju -9677706626</strong></h1> 
           </div>
          </div>
    </>
  )
}

export default PrintSheet