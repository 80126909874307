import React from 'react'
import { useGetWashCareMasterByIdQuery } from '../../../redux/LabServices/WashCareMasterServices'
import { Loader } from '../../../Basic/components'
import { getImageUrlPath, viewBase64String } from "../../../Utils/helper";

const WashCareData = ({ value }) => {
  const { data, isLoading, isFetching } = useGetWashCareMasterByIdQuery(value.washCareId, { skip: !(value.washCareId) })
  if (isLoading || isFetching) return <Loader />
  return (
    <div>
      <div className='flex gap-2'>
        <h1 className=' p-2 text-center'> <img className='w-20' src={getImageUrlPath(data.data.image)} alt="" />  </h1>
        <h1 className='grid place-content-center '>{value.samples.length===1?"":value.samples + ""}</h1>
      </div>
    </div>
  )
}

export default WashCareData