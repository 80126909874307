import React, { useEffect, useState, useRef, useCallback } from "react";
import SampleDetails from "./SampleDetails";
import SampleTestParameters from "./SampleTestParameters";
import logo from "../../../assets/SRG.png"
import { formatAMPM, getDateFromDateTime, getDateFromDateTimeForDisplay, getTimeFromDateTime } from "../../../Utils/helper";
import { PrintButtonOnly } from "../../../Buttons";
import { useReactToPrint } from "react-to-print";
import { TICK_ICON } from '../../../icons';
import WashCareInstruction from "./WashCareInstruction";
import Header from "./Header";
import Document from "./Document";
import { useGetWashCareGroupMasterQuery } from "../../../redux/LabServices/WashCareGroupMasterServices";
import { findFromList, params } from "../../../Utils/helper"
import { Loader } from "../../../Basic/components";
import { useGetLabTestNameMasterQuery } from "../../../redux/LabServices/LabTestNameMasterServices";
import { useGetLabTestMethodMasterQuery } from "../../../redux/LabServices/LabTestMethodMasterServices";

const MODEL = "Sample Receipt Confirmation";

export default function PrintSampleReceiptConfirmation({ data }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { data: testList } = useGetLabTestNameMasterQuery({ params })
  const { data: methodList } = useGetLabTestMethodMasterQuery({ params })
  

  const { data: washCareData, isLoading: isWashCareGroupLoading, isFetching: isWashCareGroupFetching } =
  useGetWashCareGroupMasterQuery({ params: { ...params, includeWashCare: true } })
  if (isWashCareGroupLoading || isWashCareGroupFetching || !testList?.data || !methodList?.data) return <Loader />
 

 
  return (
    <div className="h-full overflow-auto font-times">
      <div className="flex w-full justify-end ">
        <PrintButtonOnly onClick={handlePrint}/>
      </div>
      <div className='hidden font-times'>
            <Document testList={testList} methodList={methodList}  data={data} componentRef={componentRef} washCareData={washCareData}/>
        </div>
      <div className="max-h-full p-4 text-xs bg-white  page-border"  >
        <div className=' mt-2  '>
         <Header/>
          <div className=' grid grid-cols-2 place-items-start w-full place-content-center p-1 gap-y-3'>
            <h1>Lab Report No : {data?.docId}</h1>
            <h1>Log-In-Date And Time : {data?.createdAt ? getDateFromDateTimeForDisplay(data.createdAt) + "  " + formatAMPM(data.createdAt) : ""} </h1>
           
            <h1>Due Date : {data?.dueDate ? getDateFromDateTimeForDisplay(data.dueDate) : ""}</h1>
            <h1>Service :{data?.ServiceLevel?.name}</h1>
            
            <h1>Buyer Name :{data?.Buyer?.name} </h1>
           
          </div>
          <SampleDetails data={data} />
         
         <div className="mt-3 mb-3">
         <table className="w-full">
              <tbody>
                <tr className="">
                <td className='border border-gray-500 w-44 text-xs py-4'>Submitted Washcare Instruction </td>
                  <td className='border border-gray-500  text-xs '>{data.washCareDetails ? <WashCareInstruction washCareDetails={data.washCareDetails}/> : ""} </td>
                </tr>
              </tbody>
            </table>
         </div>
           
           
         
          <div>
            <SampleTestParameters testDetails={data?.testDetails ? data?.testDetails : []} packageName={data?.Package ? data?.Package.name : ""} />
          </div>

          <div className="mt-3 ">
            <h1>(Standard Note)</h1>
            <div className="mt-3">
           <h1> {TICK_ICON} You are requested to update your GSTN details in our records. Please send your GST number(s) and Address to <a className="text-blue-500" href="#"> account.pro@srgapparels.com</a> </h1> 
           <h1> {TICK_ICON} Please quote Lab Report number for any correspondence</h1> 
           <h1> {TICK_ICON}  Delay in receiving any requested mandatory information or additional sample may affect in report due date.</h1> 
           <h1> {TICK_ICON} Test assignment are made based on the specification in the submitted TRF by customer</h1> 
           <h1> {TICK_ICON} Hard copy of test report will be issued based on the request.</h1> 
           <h1> {TICK_ICON} Please check with accounts for outstanding payment and clear the payment to avoid the delay for further testing / reports process.</h1> 
          <h1> {TICK_ICON} Please feel free Contact For further enquiry : <strong>Technical: N. Sethuraman – 9952785239 / Customer Service: Ms. Manju -9677706626</strong></h1> 
           </div>
          </div>

          
        
        </div>
       
      </div>
      
    </div>
  );
}
