import React, { Fragment } from "react";
import secureLocalStorage from "react-secure-storage";
import { useGetLabTestGroupMasterQuery } from "../../../redux/LabServices/LabTestGroupMasterServices";
import { Loader } from "../../../Basic/components";
import { useGetPackageMasterByIdQuery } from "../../../redux/LabServices/PackageMasterServices";
import { TICK_ICON } from "../../../icons";


export default function TestSelectionPanel({ sampleDetails, setSampleDetails, currentActiveTab, packageId, removeTest }) {


    let index = sampleDetails.findIndex(item => item.sampleId === currentActiveTab)

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };
    const { data: packageDetails, isLoading: isPackageDetailsLoading, isFetching: isPackageDetailsFetching } = useGetPackageMasterByIdQuery(packageId, { skip: !packageId })

    const { data: labTestGroupList, isLoading: labGroupLoading, isFetching: labGroupFetching } =
        useGetLabTestGroupMasterQuery({ params: { ...params, LabTest: true } });

    function isTestInSelectedPackage(testId) {
        return packageDetails.data.PackageTemplateDetails.find(packageTest => parseInt(packageTest.testId) === parseInt(testId)) ? true : false
    }

    function addTest(testId) {
        setSampleDetails(prevSampleDetails => {
            const newSampleDetails = structuredClone(prevSampleDetails);
            newSampleDetails[index]["SampleTestDetails"] = [...newSampleDetails[index]["SampleTestDetails"],
            { testId, methodId: "", conditionId: "", isPackage: isTestInSelectedPackage(testId) }]
            return newSampleDetails
        })
    }

    function handleChangeOnTest(testId) {
        if (isTestAdded(testId)) {
            removeTest(testId)
        } else {
            addTest(testId)
        }
    }

    function isTestAdded(testId) {
        if (!sampleDetails[index]) return false
        if (!sampleDetails[index]["SampleTestDetails"]) return false
        if (sampleDetails[index]["SampleTestDetails"].find(item => parseInt(item.testId) === parseInt(testId))) return true
        return false
    }

    function handleSelectAllChange(value) {
        // if(!labTestNameList) return
        if (value) {
            // labTestNameList.data.forEach(item => addItem(item.id))
        } else {
            // labTestNameList.data.forEach(item => removeItem(item.id))
        }
    }

    function getSelectAll() {
        // return labTestNameList.data.every(item => isTestAdded(item.id))
    }
    if (!labTestGroupList) return <Loader />

    let count = 1;

    return (
        <div>
            <table className="text-xs w-full">
                <thead>
                    <tr>
                        <th className='border border-black'>
                            {/* <label>Select all</label> */}
                            {/* <input type='checkbox' onChange={(e) => handleSelectAllChange(!getSelectAll())}
                                checked={getSelectAll()}
                            /> */}
                            Select
                        </th>
                        <th className="w-96 h-8 border border-black">Number of Tests</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {JSON.stringify(labTestGroupList.data)} */}
                    {labTestGroupList.data.map((testGroup, index) => (
                        <Fragment key={testGroup.id}>
                            {/* <tr key={index}>
                                <td className='border border-black w-12 text-center'>
                                    <input type='checkbox' checked={isTestAdded(testGroup.id)}
                                        onChange={() => {
                                            // if (isTestAdded(testGroup.id)) {
                                            //     removeItem(test.id)
                                            // } else {
                                            //     addItem(test.id)
                                            // }
                                        }} />
                                </td>
                                <td className="border border-black text-center">Group</td>
                                <td className="w-96 border border-black py-2 ">
                                    {testGroup.name}
                                </td>
                            </tr> */}
                            {testGroup.LabTestSubGroup.map(labTestSubGroup =>
                                <Fragment key={labTestSubGroup.id} >
                                    {labTestSubGroup.LabTest.length > 0 &&
                                        <tr key={labTestSubGroup.id} className="bg-blue-300 text-center">
                                            <td className='border border-black w-12 text-center '>
                                                {/* <input type='checkbox' checked={false}
                                                    onChange={() => {
                                                        // if (isTestAdded(testGroup.id)) {
                                                        //     removeItem(test.id)
                                                        // } else {
                                                        //     addItem(test.id)
                                                        // }
                                                    }} /> */}
                                            </td>
                                            <td className="w-96 border border-black py-2 ">
                                                {`${testGroup.name} - ${labTestSubGroup.name}`}
                                            </td>
                                        </tr>
                                    }
                                    {labTestSubGroup.LabTest.map(labTest =>
                                        <tr key={labTest.id}>
                                            <td className='border border-black w-12 text-center' onClick={() => {
                                                handleChangeOnTest(labTest.id)
                                            }}>
                                                {isTestAdded(labTest.id) ? TICK_ICON : ""}
                                            </td>
                                            <td className="w-96 border border-black py-2 text-xs">
                                                {labTest.name}
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
