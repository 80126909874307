import React from 'react'
import { formatAMPM, getDateFromDateTimeForDisplay } from "../../../Utils/helper";
import logo from "../../../assets/SRG.png"
import SampleDetailsPrint from './SampleDetailsPrint'
import SampleTestParametersPrint from './SampleTestParametersPrint'
import WashCareInstructionPrint from './WashCareInstructionPrint';

const PrintSheet = ({washCareData,testList,methodList,conditionList,data,groupName,groupTestDetails,sampleItem,sampleWashCareDetails}) => {
  return (
    <>
 <div className="max-h-full p-4 text-xs bg-white font-times">
        <div className=' mt-2 '>
         
          <div className='gap-y-3 grid grid-cols-2 place-items-start w-full place-content-center p-1'> 
            <h1>Report No : {sampleItem?.sampleId}</h1>
            <h1>Date And Time : {data?.createdAt ? getDateFromDateTimeForDisplay(data.createdAt) + "  " + formatAMPM(data.createdAt) : ""} </h1>
         
            <h1>Due Date :{data?.dueDate?getDateFromDateTimeForDisplay(data?.dueDate):""}</h1>
            <h1>Service :{data?.ServiceLevel?.name}</h1>
            <h1>Log In By:{data?.logIn}</h1>
            <h1>Buyer Name :{data?.Buyer?.name} </h1>
          </div>
          <SampleDetailsPrint data={data} sampleItem={sampleItem}/>
          <div className="mt-2 mb-2 p-2">
          <table className="w-full">
              <tbody>
                <tr className="">
                <td className='border border-gray-500 w-1/4 text-xs py-2'>Submitted Washcare Instruction :</td>
                  <td className='border border-gray-500 w-3/4 text-xs p-2'>{sampleWashCareDetails ? <WashCareInstructionPrint washCareData={washCareData} washCareDetails={sampleWashCareDetails} sampleItem={sampleItem}/> : ""}  </td>
                 
                
                </tr>
               
              </tbody>
            </table>
           
          </div>
          <SampleTestParametersPrint testList={testList} methodList={methodList} conditionList={conditionList} groupName={groupName} testDetails={groupTestDetails ? groupTestDetails : []} packageName={data?.Package ? data?.Package.name : ""} />
         
        </div>
      </div>

    </>
  )
}

export default PrintSheet