import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTime, isBetweenRange, substract } from '../../../Utils/helper';
import {
    useGetPoQuery,
} from "../../../redux/ErpServices/PoServices";
import { DELETE } from '../../../icons';
import { toast } from 'react-toastify';


const FabricInwardItems = ({ finalInwardItems, setFinalInwardItems, readOnly, removeItem }) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        setFinalInwardItems(newBlend);
    };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )

    const { data: poList, isLoading: poListLoading, isFetching: poListFetching } = useGetPoQuery({ branchId });

    const { data: fabricList } =
        useGetFabricMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    const { data: designList } =
        useGetdesignQuery({ params: { companyId } });

    const { data: gaugeList } =
        useGetGaugeQuery({ params: { companyId } });

    const { data: loopLengthList } =
        useGetLoopLengthQuery({ params: { companyId } });

    const { data: gsmList } =
        useGetgsmQuery({ params: { companyId } });

    const { data: diaList } =
        useGetDiaQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });


    if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList || !poList) return <Loader />
    return (
        <>
            <div className={`relative w-full overflow-y-auto py-1`}>
                <table className="border border-gray-600 text-xs table-auto w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className='h-8'>
                            <th className='text-center w-8 border border-r-black border-b-black border-l-black'>
                                S.no
                            </th>
                            <th className='text-center w-24 border border-r-black border-b-black'>
                                Po.no
                            </th>
                            <th className='text-center w-24 border border-r-black border-b-black'>
                                Po.Date
                            </th>
                            <th className='text-center w-24 border border-r-black border-b-black'>
                                Due Date
                            </th>
                            <th className='w-24 border border-r-black border-b-black'>
                                Fabric Name
                            </th>
                            <th className='w-16 border border-r-black border-b-black'>
                                Color
                            </th>
                            <th className='w-16 border border-r-black border-b-black'>
                                Design
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Gauge
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                LL
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Gsm
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                K-Dia
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                F-Dia
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Uom
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Po qty
                            </th>
                            <th className='border border-r-black border-b-black'>
                                Already Inwarded qty
                            </th>
                            <th className='border border-r-black border-b-black'>
                                Balance
                            </th>
                            <th className='border border-r-black border-b-black'>
                                Inward
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Price
                            </th>
                            <th className='w-16 border border-b-black'>
                                Gross
                            </th>
                            {!readOnly &&
                                <th className='table-data border border-gray-600 w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
                        {finalInwardItems.map((item, index) => (
                            <tr key={index} className="w-full">
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {index + 1}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {item.poNo}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {getDateFromDateTime(item.poDate)}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {getDateFromDateTime(item.dueDate)}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.fabricId, fabricList.data, "aliasName")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.colorId, colorList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.designId, designList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.gaugeId, gaugeList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.loopLengthId, loopLengthList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.gsmId, gsmList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.kDiaId, diaList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.fDiaId, diaList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.uomId, uomList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {substract(item.qty, item.alreadyCancelQty).toFixed(3)}
                                </td>
                                <td className='border border-black text-right'>
                                    {(parseFloat(item.alreadyInwardedQty) + parseFloat(item.alreadyReturnedQty)).toFixed(3)}
                                </td>
                                <td className='border border-black text-right'>
                                    {substract(substract(item.qty, item.alreadyCancelQty), (parseFloat(item.alreadyInwardedQty) + parseFloat(item.alreadyReturnedQty))).toFixed(3)}
                                </td>
                                <td className='border-black border-2'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1  px-1 w-full"
                                        value={item.inwardQty}
                                        disabled={readOnly}
                                        onChange={(event) => {
                                            if(!event.target.value){
                                                handleInputChange(0, index, "inwardQty");
                                                return
                                            }
                                            if (isBetweenRange(0, substract(substract(item.qty, item.alreadyCancelQty), (parseFloat(item.alreadyInwardedQty) + parseFloat(item.alreadyReturnedQty))), event.target.value)) {
                                                handleInputChange(event.target.value.replace(/^0+/, ''), index, "inwardQty")
                                            } else {
                                                toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                                            }
                                        }}
                                        onBlur={(e) =>
                                            {
                                                if(!e.target.value){
                                                    handleInputChange(0.000, index, "inwardQty");
                                                    return
                                                }
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "inwardQty")
                                        }}
                                    />
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {item.price}
                                </td>
                                <td className='text-center  border border-b-black shadow-xl'>
                                    {(parseFloat(item.price) * parseFloat(item.inwardQty)).toFixed(2)}
                                </td>
                                {!readOnly &&
                                    <td className='border border-gray-600 w-12'>
                                        <div tabIndex={-1} onClick={() => removeItem(item.poNo, item.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FabricInwardItems