import React, { useEffect, useState } from 'react'
import { useGetFabricMasterQuery } from "../../../redux/ErpServices/FabricMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import { useGetdesignQuery } from "../../../redux/ErpServices/DesignMasterServices";
import { useGetGaugeQuery } from "../../../redux/ErpServices/GaugeMasterServices";
import { useGetLoopLengthQuery } from "../../../redux/ErpServices/LoopLengthMasterServices";
import { useGetgsmQuery } from "../../../redux/ErpServices/GsmMasterServices";
import { useGetDiaQuery } from "../../../redux/ErpServices/DiaMasterServices";
import secureLocalStorage from 'react-secure-storage';
import { Loader } from '../../../Basic/components';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { findFromList, getDateFromDateTime } from '../../../Utils/helper';



const FabricPoItemSelection = ({ poItems,transtype }) => {
    const [localItems, setLocalItems] = useState(poItems);

    const [searchFilter, setSearchFilter] = useState([]);

    // useEffect(() => {
    //     let fullList = []
    //     poItems.forEach((poItem) => {
    //         poItem.poItems.forEach((item) => {
    //             fullList.push({ ...item, poNo: poItem.id, poDate: poItem.createdAt, dueDate: poItem.dueDate, poItemId: item.poItemId })
    //         })
    //     })
    //     setLocalItems(fullList)
    // }, [poItems, setLocalItems, searchFilter])

    function filterRecords(localItems) {
        let newLocalItems = [...localItems]
        newLocalItems = newLocalItems.filter(item => {
            for (let filter of searchFilter) {
                let fieldName = filter.fieldName
                let searchValue = filter.searchValue
                let objTransformFunc = filter.objTransformFunc
                let itemObj = objTransformFunc(item[fieldName])
                if (!(`${itemObj}`.toLowerCase().includes(searchValue.toLowerCase()))) return false
            }
            return true
        })
        return newLocalItems
    }

    useEffect(() => {
        setLocalItems(prev => filterRecords(prev))
    }, [setLocalItems, searchFilter, poItems])


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const { data: fabricList, isLoading: fabricListLoading, isFetching: fabricListFetching } = useGetFabricMasterQuery({ companyId })
    const { data: uomList, isLoading: uomLoading, isFetching: uomFetching } = useGetUnitOfMeasurementMasterQuery({ companyId })
    const { data: colorList, isLoading: colorLoading, isFetching: colorFetching } = useGetColorMasterQuery({ companyId })
    const { data: designList, isLoading: designLoading, isFetching: designFetching } = useGetdesignQuery({ companyId })
    const { data: gaugeList, isLoading: gaugeLoading, isFetching: gaugeFetching } = useGetGaugeQuery({ companyId })
    const { data: loopList, isLoading: loopLoading, isFetching: loopFetching } = useGetLoopLengthQuery({ companyId })
    const { data: gsmList, isLoading: gsmLoading, isFetching: gsmFetching } = useGetgsmQuery({ companyId })
    const { data: diaList, isLoading: diaLoading, isFetching: diaFetching } = useGetDiaQuery({ companyId })

    if (fabricListLoading || fabricListFetching || uomFetching || uomLoading || colorFetching || colorLoading || designLoading || designFetching ||
        gaugeLoading || gaugeFetching || loopLoading || loopFetching || gsmLoading || gsmFetching || diaLoading || diaFetching) {
        return <tr>
            <td>
                <Loader />
            </td>
        </tr>
    }

    function changeSearchFilter(field, value, objTransformFunc) {
        setSearchFilter(prev => {
            if (!value) {
                return prev.filter(item => item.fieldName !== field)
            }
            let newSearchFilter = [...prev]
            let index = newSearchFilter.findIndex(item => item.fieldName === field)
            if (index !== -1) {
                newSearchFilter[index]["searchValue"] = value
            } else {
                newSearchFilter.push({ fieldName: field, searchValue: value, objTransformFunc });
            }
            return newSearchFilter
        })
    }

    let count = 1;
    return (
        <div className='border border-r-black border-t-black border-l-black bg-gray-200'>
            <table className='w-full text-xs'>
                <thead>
                    <tr className='bg-blue-200 border border-b-black sticky top-10'>
                       
                        <th className='text-center w-8 border border-r-black border-b-black border-l-black'>
                            S.no
                        </th>
                        <th className='text-center w-24 border border-r-black border-b-black'>
                            Po.no
                        </th>
                        <th className='w-24 border border-r-black border-b-black'>
                            Item Type
                        </th>

                        <th className='w-24 border border-r-black border-b-black'>
                            Fabric Name
                        </th>
                        <th className='w-16 border border-r-black border-b-black'>
                            Color
                        </th>
                        <th className='w-16 border border-r-black border-b-black'>
                            Design
                        </th>
                        <th className='w-12 border border-r-black border-b-black'>
                            Gauge
                        </th>
                        <th className='w-12 border border-r-black border-b-black'>
                            LL
                        </th>
                        <th className='w-12 border border-r-black border-b-black'>
                            Gsm
                        </th>
                        <th className='w-12 border border-r-black border-b-black'>
                            K-Dia
                        </th>
                        <th className='w-12 border border-r-black border-b-black'>
                            F-Dia
                        </th>
                        <th className='w-12 border border-r-black border-b-black'>
                            Uom
                        </th>
                        <th className='w-12 border border-r-black border-b-black'>
                            Po qty
                        </th>
                       
                        <th className='w-12 border border-r-black border-b-black'>
                            Price
                        </th>
                      
                    </tr>
                </thead>
                <tbody>
                    <tr className='sticky top-20 bg-blue-200'>
                       
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("poNo", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>

                        
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("fabricId", e.target.value, (item) => findFromList(item, fabricList.data, "aliasName"))
                                }} />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>
                       
                        {/* <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("colorId", e.target.value, (item) => findFromList(item, colorList.data, "name"))
                                }}
                                disabled={transtype.toLowerCase().includes("grey")}
                            />
                        </td> */}
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("designId", e.target.value, (item) => findFromList(item, designList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("gaugeId", e.target.value, (item) => findFromList(item, gaugeList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("loopLengthId", e.target.value, (item) => findFromList(item, loopList.data, "name"))
                                }}
                            />
                        </td>

                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("gsmId", e.target.value, (item) => findFromList(item, gsmList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("kDiaId", e.target.value, (item) => findFromList(item, diaList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("fDiaId", e.target.value, (item) => findFromList(item, diaList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("uomId", e.target.value, (item) => findFromList(item, uomList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>
                       
                    </tr>
                    {localItems.map((item, index) =>
                        <tr key={index} className='py-2'>{console.log(item)}
                           
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {count++}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {item.id}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {transtype}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.fabricId, fabricList.data, "aliasName")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.colorId, colorList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.designId, designList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.gaugeId, gaugeList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.loopLengthId, loopList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.gsmId, gsmList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.kDiaId, diaList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.fDiaId, diaList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.uomId, uomList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {item.qty}
                            </td>
                          
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {item.price}
                            </td>
                           
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>

    )
}

export default FabricPoItemSelection