import React from 'react'
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices'
import { findFromList, params } from "../../../Utils/helper"
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices'
import { Loader } from '../../../Basic/components'
const SampleTestParametersPrint = ({testList, methodList, testDetails, packageName }) => {

 
  return (
    <div className=''>
      {/* <div className=" border border-gray-600 p-2">
        <h1 className='font-bold'>Package : {packageName}</h1>
      </div> */}
      <table className=' border border-gray-500 w-full'>
        <thead className='border border-gray-500 '>
          <tr className=''>
            <th className='border border-gray-500 p-2 w-6'>
              S.NO
            </th>
            <th className='border border-gray-500 p-2 '>
             List Of Test Parameter
            </th>
            <th className='border border-gray-500 p-2 '>
             Samples
            </th>
            {/* <th className='border border-gray-500 p-2 w-2/4'>
              Methods
            </th> */}
          </tr>
        </thead>
        <tbody>
          
          {testDetails.map((item, index) =>
            <tr key={item.id}>
              <td className='border border-gray-500 text-xs text-center'>
                {index + 1}
              </td>
              <td className='border border-gray-500 text-xs'>
                {findFromList(item.testId, testList.data, "name")}
              </td>
              <td className='border border-gray-500 text-xs'>
               {item.samples.length===1?"":item.samples+" "}   
              </td>
            </tr>
          )}
          
        </tbody>
      </table>
      {/* <div className=" border border-gray-600 p-2 font-bold">
        <h1>Remarks :</h1>
      </div> */}
      {/* <div className='text-end mr-32'>
        <h1>Authorised Signatory :</h1>
      </div> */}
    </div>
  )
}

export default SampleTestParametersPrint