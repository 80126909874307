import React from 'react'
import WashCareDataPrint from '../PrintFormat-TestScanningSheet/WashCareDataPrint'
import WashCareData from './WashCareData'


const WashCareInstruction = ({washCareDetails}) => {
  return (
    <div className='grid grid-flow-col auto-cols-max gap-x-2 w-full'>
            {washCareDetails.map((value,index)=> <WashCareData value={value}  key={index} index={index}/> )}      
    </div>
  )
}

export default WashCareInstruction