import React from 'react'
import TestRequirement from './TestRequirement'
import { Text, View } from '@react-pdf/renderer'
import tw from '../../../../Utils/tailwind-react-pdf'

const SampleTestParameters = ({ item, reportData }) => {

  return (
    <View style={tw('ml-9 w-full text-xs capitalize')} wrap={true}>
      <View style={tw('text-xs mt-5 w-full')}>
        <Text style={[tw('font-bold underline capitalize'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
          {item?.testName?.toLowerCase() || ""}
        </Text>
        <View style={tw('w-3/4 mt-3  gap-y-2 capitalize text-xs')}>
          <Text style={tw("capitalize")}>{item?.methodName}</Text>
          <Text style={tw('capitalize')}>{item?.conditionName?.toLowerCase() || ""}</Text>
        </View>
      </View>
      <View style={tw('mr-10 text-xs ')}>
        {item?.requirement?.map((req, reqIndex) => {
          return <TestRequirement key={reqIndex} item={item} req={req} requirementLength={item?.requirement?.length} reportData={reportData} />
        })}
      </View>
      {
        item?.remarks ?
          <View style={tw('text-xs mt-3 flex flex-row gap-x-3 w-full text-xs P-0.5')}>
            <Text style={[tw('p-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>#Remark:</Text>
            <Text style={tw('p-1 w-3/4')}> {item?.remarks}</Text>
          </View>
          :
          <></>
      }


    </View>
  )
}

export default SampleTestParameters