import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PRODUCT_CATEGORY_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const ProductCategoryMasterApi = createApi({
  reducerPath: "productCategoryMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["ProductCategoryMaster"],
  endpoints: (builder) => ({
    getProductCategoryMaster: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: PRODUCT_CATEGORY_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PRODUCT_CATEGORY_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["ProductCategoryMaster"],
    }),
    getProductCategoryMasterById: builder.query({
      query: (id) => {
        return {
          url: `${PRODUCT_CATEGORY_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["ProductCategoryMaster"],
    }),
    addProductCategoryMaster: builder.mutation({
      query: (payload) => ({
        url: PRODUCT_CATEGORY_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["ProductCategoryMaster"],
    }),
    updateProductCategoryMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${PRODUCT_CATEGORY_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ProductCategoryMaster"],
    }),
    deleteProductCategoryMaster: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_CATEGORY_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCategoryMaster"],
    }),
  }),
});

export const {
  useGetProductCategoryMasterQuery,
  useGetProductCategoryMasterByIdQuery,
  useAddProductCategoryMasterMutation,
  useUpdateProductCategoryMasterMutation,
  useDeleteProductCategoryMasterMutation,
} = ProductCategoryMasterApi;

export default ProductCategoryMasterApi;
