import React from 'react'
import tw from '../../../../Utils/tailwind-react-pdf'
import { Text, View } from '@react-pdf/renderer'
import { CLOSE_ICON_PDF, TICK_ICON_PDF } from '../../../../icons'

const OverAllResult = ({ splitTest, data }) => {

    function checkResult(accre, nonAccre, field) {
        if (field == "pass") {
            if ((accre?.pass || accre?.data) && (nonAccre?.pass || nonAccre?.data)) {
                return true
            }
            else {
                return false
            }
        }
        if (field == "fail") {
            if ((accre?.fail || !accre?.data) && (nonAccre?.fail || !nonAccre?.data)) {
                return true
            }
            else {
                return false
            }
        }

        if (field == "data") {
            if ((accre?.data) && (nonAccre?.data)) {
                return true
            }
            else {
                return false
            }
        }

    }

    // function findOverAllResult(array, field) {
    //     if (array?.length === 0) return false

    //     if (field == "pass") {
    //         if (array.every(val => val.data)) return false
    //         let data = array.filter(val => val?.pass || val?.data)
    //         if (data?.length == array?.length) {
    //             return true
    //         }
    //         return false
    //     }
    //     if (field == "fail") {
    //         let data = array.filter(val => val?.fail)
    //         if (data?.length > 0) {
    //             return true
    //         }
    //         return false
    //     }
    //     if (field == "data") {
    //         let result = array.every(val => val.data) ? true : false
    //         return result
    //     }

    // }


    function findOverAllResult(array, field) {
        if (array?.length === 0) return false

        if (field == "pass") {
            if (array.every(val => val.data)) return false
            let data = array.filter(val => val?.pass || val?.data)
            if (data?.length == array?.length) {
                return true
            }
            return false
        }
        if (field == "fail") {
            let data = array.filter(val => val?.fail)
            if (data?.length > 0) {
                return true
            }
            return false
        }
        if (field == "data") {
            let result = array.every(val => val.data) ? true : false
            return result
        }

    }



    let pass, fail, dataResult;
    if (splitTest === "ACCREDITATION") {
        pass = findOverAllResult(data?.testDetailsSummary?.accredidatedResults, "pass") ? CLOSE_ICON_PDF : "";
        fail = findOverAllResult(data?.testDetailsSummary?.accredidatedResults, "fail") ? CLOSE_ICON_PDF : "";
        dataResult = findOverAllResult(data?.testDetailsSummary?.accredidatedResults, "data") ? CLOSE_ICON_PDF : "";
    } else if (splitTest === "NONACCREDITATION") {
        pass = findOverAllResult(data?.testDetailsSummary?.nonAccredidatedResults, "pass") ? CLOSE_ICON_PDF : "";
        fail = findOverAllResult(data?.testDetailsSummary?.nonAccredidatedResults, "fail") ? CLOSE_ICON_PDF : "";
        dataResult = findOverAllResult(data?.testDetailsSummary?.nonAccredidatedResults, "data") ? CLOSE_ICON_PDF : "";
    } else {
        pass = findOverAllResult(data?.testDetailsSummary?.allResults, "pass") ? CLOSE_ICON_PDF : "";
        fail = findOverAllResult(data?.testDetailsSummary?.allResults, "fail") ? CLOSE_ICON_PDF : "";
        dataResult = findOverAllResult(data?.testDetailsSummary?.allResults, "data") ? CLOSE_ICON_PDF : "";
        // pass = data?.overAllResult?.all?.pass ? CLOSE_ICON_PDF : "";
        // fail = data?.overAllResult?.all?.fail ? CLOSE_ICON_PDF : "";
        // dataResult = data?.overAllResult?.all?.data ? CLOSE_ICON_PDF : "";
    }
    return (
        <View style={tw(' border-2 border-gray-900 h-32 w-52 ')}>
            <Text style={tw('font-bold p-1 text-sm underline uppercase')}>OverAll Rating :</Text>
            <View style={tw('py-2 px-2 flex flex-col gap-5')}>
                <View style={tw('flex flex-row')}><Text>Pass : </Text> <View style={tw("font-bold text-xl ml-16")}>{pass}  </View></View>
                <View style={tw('flex flex-row text-xs')}><Text>Fail :</Text> <View style={tw("font-bold text-xl ml-16")}>{fail}</View>  </View>
                <View style={tw('flex flex-row text-xs')}><Text>Data :</Text> <View style={tw("font-bold text-xl ml-16")}>{dataResult}</View>  </View>
                {/* <View style={tw('flex flex-row')}>  <Text>Data : </Text> <Text> {dataResult} </Text> </View> */}
                {/* <View style={tw('flex flex-row')}><Text>Pass : </Text> <View style={tw("font-bold text-xl ml-16")}>{pass} </View></View>
                <View style={tw('flex flex-row text-xs')}><Text>Fail :</Text> <View style={tw("font-bold text-xl ml-16")}> {fail}</View>  </View>
                <View style={tw('flex flex-row')}>  <Text>Data : </Text> <Text> {dataResult}</Text> </View> */}
            </View>
        </View>
    )
}

export default OverAllResult