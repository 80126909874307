import React, { useEffect, useState, useRef, useCallback, Fragment } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetLabTestNameMasterQuery,

} from "../../../redux/LabServices/LabTestNameMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { CheckBox, DropdownInput, MultiSelectDropdown, LongDropdownInput, Modal } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject, multiSelectOption } from "../../../Utils/contructObject";
import { findFromList } from "../../../Utils/helper";
import { Loader } from "../../../Basic/components";
import { useGetLabTestMethodMasterQuery } from "../../../redux/LabServices/LabTestMethodMasterServices";
import {
    useAddLabTestRequirementMasterMutation,
    useDeleteLabTestRequirementMasterMutation,
    useGetLabTestRequirementMasterByIdQuery,
    useGetLabTestRequirementMasterQuery,
    useUpdateLabTestRequirementMasterMutation
} from "../../../redux/LabServices/LabTestRequirementMasterServices";
import { useGetLabTestConditionMasterQuery } from "../../../redux/LabServices/LabTestConditionMasterServices";
import { useGetLabTestAnalyteMasterByIdQuery, useGetLabTestAnalyteMasterQuery } from "../../../redux/LabServices/LabTestAnalyteMasterServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import { useGetFormulaMasterQuery } from "../../../redux/LabServices/FormulaMasterServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useGetSampleDescriptionTwoMasterQuery } from "../../../redux/LabServices/SampleDescriptionTwoMasterServices";
import { useGetSampleDescriptionThreeMasterQuery } from "../../../redux/LabServices/SampleDescriptionThreeMasterServices";
import { useGetSampleDescriptionFourMasterQuery } from "../../../redux/LabServices/SampleDescriptionFourMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import { useGetSampleDescriptionOneMasterQuery } from "../../../redux/LabServices/SampleDescriptionOneMasterServices";
import LabTestRequirementFormReport from "./LabTestRequirementFormReport";
import { toast } from "react-toastify";

const MODEL = "Requirement Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [active, setActive] = useState(true);
    const [testId, setTestId] = useState("");
    const [unit, setUnit] = useState("")
    const [methodList, setMethodList] = useState([]);
    const [isClone, setIsClone] = useState(false);

    const [formReport, setFormReport] = useState(false);
    const [category, setCategory] = useState("");
    const [testConditionId, setTestConditionId] = useState("");

    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);
    const [partyId, setPartyId] = useState("");
    const [sampleTypeThreeId, setSampleTypeThreeId] = useState("");
    const [sampleTypeFourId, setSampleTypeFourId] = useState("");
    const [sampleTypeOneId, setSampleTypeOneId] = useState("");
    const [sampleTypeTwoId, setSampleTypeTwoId] = useState("");
    const [requirementDetails, setRequirementDetails] = useState([]);
    const [labTestParameterDatas, setLabTestParameterDatas] = useState([]);
    const [isDecimalAdd, setIsDecimalAdd] = useState(false)
    const [count, setCount] = useState(2);
    const [unitId, setUnitId] = useState("");
    const [reason, setReason] = useState("")


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        )
    };

    function handleOnChange(index, reqIndex, formulaIndex, value, field) {

        setRequirementDetails(prev => {
            let newReq = structuredClone(prev);
            newReq[index]["requirement"][reqIndex]["formula"][formulaIndex][field] = value;
            return newReq
        })

    }
    function handleChange(index, reqIndex, value, field) {

        setRequirementDetails(prev => {
            let newReq = structuredClone(prev);
            newReq[index]["requirement"][reqIndex][field] = value;
            return newReq
        })

    }
    function handleInputChange(index, reqIndex, value, field) {

        for (let i = 0; i <= (requirementDetails.length - 1); i++) {
            setRequirementDetails(prev => {
                let newReq = structuredClone(prev);
                newReq[i]["requirement"][reqIndex][field] = value;
                return newReq
            })

        }


    }
    const { data: labTestNameList } =
        useGetLabTestNameMasterQuery({ params });

    const { data: labTestMethodList } =
        useGetLabTestMethodMasterQuery({ params });

    const { data: labTestParameterList } =
        useGetLabTestAnalyteMasterQuery({ params });
    const { data: labTestConditionList } =
        useGetLabTestConditionMasterQuery({ params });



    const { data: sampleTypeFourList } =
        useGetSampleDescriptionFourMasterQuery({ params });

    const { data: sampleTypeThreeList } =
        useGetSampleDescriptionThreeMasterQuery({ params });

    const { data: buyerList, isLoading: buyerLoading, isFetching: buyerFetching } =
        useGetPartyQuery({ params });

    const { data: formulaList, isFormulaListLoading, isFormulaListFetching } = useGetFormulaMasterQuery({ params });
    const { data: uomList } = useGetUnitOfMeasurementMasterQuery({ params });

    const { data: sampleTypeTwoList } =
        useGetSampleDescriptionTwoMasterQuery({ params });

    const { data: sampleTypeOneList } =
        useGetSampleDescriptionOneMasterQuery({ params });

    const { data: sampleTwoList } =
        useGetSampleDescriptionTwoMasterQuery({ params });

    const { data: allData, isLoading, isFetching } = useGetLabTestRequirementMasterQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetLabTestRequirementMasterByIdQuery(id, { skip: !id });



    const gridRecordOneParameterId = singleData?.data?.LabTestRequirementDetails[0]?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.labTestAnalyteId;



    const parameterList = (labTestParameterList?.data || []).filter(i => id ? (parseInt(i.id) === parseInt(gridRecordOneParameterId)) : i.active).find((item) => ((parseInt(item.testId) === parseInt(testId)) && (parseInt(item.SampleDescriptionOne.id) === parseInt(sampleTypeOneId))))



    const parameterListId = parameterList?.id ? parameterList.id : ""
    const { data: singleLabTestParameterList, isLoading: isLabParameterLoading, isFetching: isLabParameterFetching } =
        useGetLabTestAnalyteMasterByIdQuery(parameterListId, { skip: !parameterListId });




    const [addData] = useAddLabTestRequirementMasterMutation();
    const [updateData] = useUpdateLabTestRequirementMasterMutation();
    const [removeData] = useDeleteLabTestRequirementMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            else setReadOnly(false)
            setUnit(data?.unit ? data.unit : "");
            setReason(data?.reason ? data?.reason : "")
            setCategory(data?.category ? data.category : "");
            setTestConditionId(data?.testConditionId ? data.testConditionId : "")
            setMethodList(data?.methodList ? data.methodList : [])
            setTestId(data?.testId ? data.testId : "")
            setActive(id ? (data?.active ? data.active : false) : true);
            setPartyId(data?.partyId ? data.partyId : "");
            setRequirementDetails(data?.LabTestRequirementDetails ? data.LabTestRequirementDetails : [])
            setSampleTypeThreeId(data?.sampleTypeThreeId ? data.sampleTypeThreeId : "")
            setSampleTypeTwoId(data?.sampleTypeTwoId ? data.sampleTypeTwoId : "")
            setSampleTypeOneId(data?.sampleTypeOneId ? data.sampleTypeOneId : "")
            setSampleTypeFourId(data?.sampleTypeFourId ? data.sampleTypeFourId : "")
            setUnitId(data?.unitId ? data.unitId : "");
            setIsDecimalAdd(id ? data?.isDecimalAdd ? data?.isDecimalAdd : false : true)
            childRecord.current = data?.childRecord || 0;
        },
        [id]
    );


    const childRecordExists = false;


    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);


    useEffect(() => {
        setRequirementDetails(prev => {
            if (!singleLabTestParameterList?.data) return prev

            if (prev.length !== 0) return prev
            let newRequirements = [];

            singleLabTestParameterList.data.LabTestAnalyteDetails.map(heading => {
                heading?.labTestAnalyteDetailsParameters.forEach((parameter, parameterIndex) => {

                    newRequirements.push({
                        parameterId: parameter.id,
                        aliasName: parameter.name,
                        headingId: heading.id ? heading.id : "",

                        requirement: [
                            {
                                formulaAliasName: "",
                                subHeading: "BASE",
                                formula: [

                                ]
                            }
                        ]
                    })

                })
            })
            return newRequirements
        })

    }, [testId, singleLabTestParameterList?.data, sampleTypeOneId, isLabParameterFetching, isLabParameterLoading, setRequirementDetails])



    useEffect(() => {
        let filteredHeadings = singleLabTestParameterList?.data?.LabTestAnalyteDetails.filter(item => requirementDetails.find(val => parseInt(val.headingId) == parseInt(item.id)))
        setLabTestParameterDatas(prev => {
            if (!singleLabTestParameterList?.data) return prev
            let parameterDatas = structuredClone(prev)
            parameterDatas = filteredHeadings
            return parameterDatas
        })
    }, [singleLabTestParameterList, isLabParameterLoading, isLabParameterFetching, setLabTestParameterDatas, setRequirementDetails, requirementDetails])

    const data = {
        id, reason, unitId, isDecimalAdd, unit, labTestRequirementDetails: requirementDetails.filter(val => labTestParameterDatas?.find(item => parseInt(item.id) === parseInt(val.headingId))), category, partyId, sampleTypeThreeId, sampleTypeOneId, sampleTypeFourId, sampleTypeTwoId, testConditionId, testId, methodList, active,
        companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId",), ...params
    }

    const validateData = (data) => {
        if (data.testConditionId && data.testId && data.partyId && data.methodList) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            if (returnData?.statusCode === 1) {
                return toast.error(returnData?.message);
            } else {
                setId("")
                syncFormWithDb(undefined);
                onNew();
                toast.success(text + "Successfully");
            }
        } catch (error) {
            console.log("handle");
        }
    };



    const saveData = () => {
        if (!validateData(data)) {

            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (isClone) {
            handleSubmitCustom(addData, data, "Added");
        }
        else if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        }
        else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                let returnData = await removeData(id).unwrap();
                if (returnData?.statusCode === 1) {
                    return toast.error(returnData?.message);
                } else {
                    setId("")
                    syncFormWithDb(undefined);
                    toast.success("Deleted Successfully");
                }
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setForm(true);
        setSearchValue("");
        setId("");
        syncFormWithDb(undefined);
        setLabTestParameterDatas([])
        setReadOnly(false);
        setIsClone(false);
    };

    function onDataClick(id) {
        setId(id);


        setForm(true);
    }

    function handleAliasNameChange(index, value, field) {
        setRequirementDetails(prev => {
            let newReq = structuredClone(prev);
            newReq[index][field] = value;
            return newReq
        })
    }



    function refreshRequirementDetails() {

        setRequirementDetails(prev => {
            if (!singleLabTestParameterList?.data) return prev


            let newRequirements = [...prev];


            singleLabTestParameterList.data.LabTestAnalyteDetails.map(heading => {
                heading?.labTestAnalyteDetailsParameters.forEach((parameter, parameterIndex) => {

                    let parameterid = prev.find(i => i.parameterId == parameter.id)?.parameterId

                    if (parseInt(parameterid) !== parseInt(parameter.id)) {
                        newRequirements.push({
                            parameterId: parameter.id,
                            aliasName: parameter.name,
                            headingId: heading.id ? heading.id : "",

                            requirement: [
                                {

                                    subHeading: "BASE",
                                    formulaAliasName: "",
                                    formula: [

                                    ]
                                }
                            ]
                        })
                    }

                })
            })
            return newRequirements
        })
    }




    const tableHeaders = [
        "Buyer", "Sample Type", "Test Name", "Status"
    ]
    const tableDataNames = ["dataObj.Party?.name", "dataObj.SampleDescriptionOne?.name", "dataObj.LabTest?.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (isFormulaListLoading || isFormulaListFetching || !formulaList || buyerLoading || buyerFetching || !labTestNameList ||
        !labTestMethodList || !labTestConditionList || !labTestParameterList || !sampleTypeTwoList ||
        !sampleTypeThreeList || !sampleTypeFourList || !uomList || !sampleTypeOneList || !sampleTwoList) return <Loader />


    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />

        );

    const span = requirementDetails?.flatMap(item => item.requirement).length;
    let parameters = singleLabTestParameterList?.data?.LabTestAnalyteDetails.flatMap(item => item?.labTestAnalyteDetailsParameters)
    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid overflow-auto bg-theme"
        >
            <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
                <LabTestRequirementFormReport
                    onClick={(id) => { setId(id); setFormReport(false) }} />
            </Modal>
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onClone={() => { setIsClone(true); toast.success("Clone Successfull") }}
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    openReport={() => { setFormReport(true) }}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />

                <div className="flex-1 grid gap-x-2">
                    <div className="col-span-3 grid overflow-auto">
                        < div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                            <div className={`${formReport ? "col-span-7" : "col-span-1"}`}>
                                <fieldset className='frame'>
                                    <legend className='sub-heading'>Test Requirement Info</legend>
                                    <div className="grid grid-cols-5">
                                        <DropdownInput name="SampleType 1" options={dropDownListObject(id ? sampleTypeOneList.data : sampleTypeOneList.data.filter(item => item.active), "name", "id")} value={sampleTypeOneId} setValue={setSampleTypeOneId} required={true} disabled={(childRecord.current > 0)} readOnly={(isClone ? false : id) || readOnly || childRecordExists} />

                                        <DropdownInput name="SampleType 3" options={dropDownListObject(id ? sampleTypeThreeList.data : sampleTypeThreeList.data.filter(item => item.active), "name", "id")} value={sampleTypeThreeId} setValue={setSampleTypeThreeId} required={true} disabled={(childRecord.current > 0)} readOnly={(isClone ? false : id) || readOnly || childRecordExists} />

                                        <DropdownInput name="SampleType 4" options={dropDownListObject(id ? sampleTypeFourList.data : sampleTypeFourList.data.filter(item => item.active), "name", "id")} value={sampleTypeFourId} setValue={setSampleTypeFourId} required={true} disabled={(childRecord.current > 0)} readOnly={(isClone ? false : id) || readOnly || childRecordExists} />
                                        <DropdownInput name="Unit" options={dropDownListObject(id ? uomList.data : uomList.data.filter(item => item.active), "name", "id")} value={unitId} setValue={setUnitId} required={true} disabled={(childRecord.current > 0)} readOnly={(isClone ? false : id) || readOnly || childRecordExists} />
                                        <DropdownInput name="Buyer Name" options={dropDownListObject(id ? buyerList.data : buyerList.data.filter(item => item.active), "name", "id")} value={partyId} setValue={setPartyId} required={true} disabled={(childRecord.current > 0)} readOnly={(isClone ? false : id) || readOnly || childRecordExists} />

                                    </div>


                                    <div className="grid grid-cols-5">

                                        <DropdownInput name="Test Name" options={dropDownListObject(id ? labTestNameList.data : labTestNameList.data.filter(item => item.active), "name", "id")} value={testId} setValue={setTestId} required={true} readOnly={(isClone ? false : id) || readOnly || childRecordExists} disabled={(childRecord.current > 0)} />
                                        <LongDropdownInput className={"w-full"} name="Test Condition"
                                            options={dropDownListObject(id ? labTestConditionList.data : labTestConditionList.data.filter(val => val.LabTestMethod?.labTestId == testId).filter(item => item.active), "name", "id")} value={testConditionId} setValue={setTestConditionId} required={true} readOnly={(isClone ? false : id) || readOnly || childRecordExists} disabled={(childRecord.current > 0)} />
                                        <CheckBox name="isDecimalAdd" readOnly={(isClone ? false : id) || readOnly || childRecordExists} value={isDecimalAdd} setValue={setIsDecimalAdd} />


                                    </div>
                                    <div className="grid grid-cols-4">
                                        <MultiSelectDropdown name="Test Method"
                                            selected={methodList}
                                            setSelected={setMethodList}
                                            options={labTestMethodList.data ? multiSelectOption((id ? labTestMethodList.data : labTestMethodList.data.filter(item => item.labTestId == testId).filter(item => item.active)), "name", "id") : []}
                                            required={true} readOnly={(isClone ? false : id) || readOnly || childRecordExists} disabled={(childRecord.current > 0)} />

                                    </div>

                                    <div className='flex gap-x-5 w-full mt-3'>
                                        <CheckBox name="Active" value={active} setValue={setActive} readOnly={readOnly} />
                                        {!active && (
                                            <div className='flex items-center justify-center text-xs md:-mt-6'>
                                                <label className='w-1/4'>Reason</label>
                                                <textarea
                                                    readOnly={readOnly}
                                                    className='border border-gray-400 ml-2 w-[400px] md:-mt-2'
                                                    value={reason}
                                                    onChange={(e) => setReason(e.target.value)}
                                                ></textarea>
                                            </div>

                                        )}
                                        <div className=" grid justify-end ">
                                            <button
                                                type='button' className={`text-white bg-green-700 rounded-md p-1 w-24 mr-5 mb-5`}
                                                onClick={() => {
                                                    refreshRequirementDetails()

                                                }}
                                                disabled={childRecordExists}
                                            > Refresh
                                            </button>
                                        </div>
                                    </div>



                                    {/* <RadioButton label={"isDecimalAdd"} value={isDecimalAdd} onChange={(e)=>setIsDecimalAdd(e.target.value)} name={"isDecimalAdd"}/> */}

                                    {/* <div className="flex justify-end relative top-0 right-0">
                                        <button className="text-xs bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1" onClick={() => setFormReport(prev => !prev)}>
                                            {formReport ? "Close Report >" : "Open Report <"}
                                        </button>
                                    </div> */}
                                </fieldset>


                                <div className={`${formReport ? "col-span-9" : "col-span-1"} ${childRecordExists ? "pointer-events-none" : ""}`}>

                                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-5 w-full flex min-h-[350px] overflow-auto border border-gray-600'>

                                        <legend className='sub-heading'>Sub Test Info</legend>
                                        <div className='grid my-2 w-full overflow-auto'>


                                            <table className="text-xs table-auto" >
                                                <thead>
                                                    <tr className="border border-black">

                                                        <th className='border w-10 border-black h-5'>
                                                            S.no
                                                        </th>
                                                        <th className="w-32 border border-black  h-5">Parameter Type</th>

                                                        <th className=" border w-36 border-black  h-5">Parameter</th>
                                                        <th className=" border w-36 border-black  h-5">AliasName</th>
                                                        <th className=" border w-36 border-black  h-5">Sub Heading</th>
                                                        <th className=" border w-36 border-black  h-5">FormulaName</th>


                                                        <th className=" " colSpan={count * 2} >Requirement</th>
                                                        <th className="  h-5"></th>
                                                        <th className="  h-5"></th>

                                                        <th className=" W-7 text-xs text-right pr-2"  >
                                                            <button
                                                                type='button' className={`text-green-700`}
                                                                onClick={() => {

                                                                    setRequirementDetails(prev => {

                                                                        let newPrev = structuredClone(prev);
                                                                        return newPrev.map((item) => {
                                                                            return {
                                                                                ...item,
                                                                                requirement: [...item?.requirement, {
                                                                                    formulaAliasName: "",
                                                                                    subHeading: "",
                                                                                    formula: [
                                                                                        {
                                                                                            formulaId: "",
                                                                                            value: ""
                                                                                        }
                                                                                    ]

                                                                                }]

                                                                            }

                                                                        })


                                                                    })
                                                                    setCount(prev => prev + 3)

                                                                }}
                                                            > {<FontAwesomeIcon icon={faUserPlus} />}
                                                            </button>

                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {labTestParameterDatas?.map((heading, itemIndex) =>
                                                        <Fragment key={heading.id} >
                                                            {Number.isInteger(heading?.id) &&
                                                                <tr key={heading?.id} className="bg-gray-400 text-center border border-black">
                                                                    <td className='border border-black w-12 text-center '>
                                                                    </td>
                                                                    <td className=" uppercase" colSpan={span * 4} >

                                                                        {`${heading?.heading}`}

                                                                    </td>
                                                                    <td className="" >
                                                                    </td>
                                                                    <td
                                                                        type='button' tabIndex={-1}
                                                                        onClick={() => {
                                                                            setLabTestParameterDatas(prev => {
                                                                                let newPrev = structuredClone(prev);
                                                                                newPrev = newPrev.filter((val, i) => val.id !== heading.id)
                                                                                return newPrev
                                                                            })
                                                                        }}

                                                                        className='text-md text-red-600 ml-1 mt-2'>{<FontAwesomeIcon icon={faTrashCan} />}
                                                                    </td>

                                                                </tr>

                                                            }
                                                            {requirementDetails.map((req, index) => {

                                                                let newReq = structuredClone(req)
                                                                newReq["currentIndex"] = index
                                                                return newReq
                                                            }).filter(i => Number.isInteger(i.headingId) ? (parseInt(i.headingId) === parseInt(heading.id)) : true).
                                                                map((req, index) =>
                                                                    <tr key={index + 1} className="border border-black ">

                                                                        <td className='border border-black w-12 text-center '>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td className='border border-black w-12 text-center '>
                                                                            {findFromList(req.parameterId, parameters, "SampleDescriptionTwo").name}
                                                                        </td>
                                                                        <td className="w-16 border border-black  text-xs  uppercase">
                                                                            {findFromList(req.parameterId, parameters, "name")}
                                                                        </td>
                                                                        <td className="w-16 border border-black  text-xs  capitalize">
                                                                            <input type="text"
                                                                                readOnly={readOnly}
                                                                                className="border border-black text-center w-full h-full p-2 capitalize"
                                                                                value={req.aliasName} onChange={(e) => handleAliasNameChange(req.currentIndex, e.target.value, "aliasName")}
                                                                                onKeyDown={e => { if (e.key === "Enter") { handleAliasNameChange(req.currentIndex, e.target.value, "aliasName") } }}
                                                                                disabled={childRecordExists}

                                                                            />

                                                                        </td>

                                                                        {req?.requirement?.map((requirement, reqIndex) =>

                                                                            <>


                                                                                <td className="w-16 border border-black  text-xs  capitalize">
                                                                                    <input type="text"
                                                                                        readOnly={readOnly}
                                                                                        className="border border-black text-center w-full h-full p-2 capitalize"
                                                                                        value={requirement?.subHeading} onChange={(e) => handleChange(req.currentIndex, reqIndex, e.target.value, "subHeading")}
                                                                                        onKeyDown={e => { if (e.key === "Enter") { handleInputChange(req.currentIndex, reqIndex, e.target.value, "subHeading") } }}
                                                                                        disabled={childRecordExists}


                                                                                    />

                                                                                </td>
                                                                                <td className="w-16 border border-black  text-xs  capitalize">
                                                                                    <input type="text"
                                                                                        readOnly={readOnly}
                                                                                        className="border border-black text-center w-full h-full p-2 capitalize"
                                                                                        value={requirement?.formulaAliasName} onChange={(e) => handleChange(req.currentIndex, reqIndex, e.target.value, "formulaAliasName")}
                                                                                        onKeyDown={e => { if (e.key === "Enter") { handleInputChange(req.currentIndex, reqIndex, e.target.value, "formulaAliasName") } }}
                                                                                        disabled={childRecordExists}

                                                                                    />

                                                                                </td>
                                                                                <td className=" text-xs flex h-9" colSpan={count * 4} >
                                                                                    {requirement?.formula?.map((formula, fIndex) =>
                                                                                        <>
                                                                                            <div key={fIndex + 1} className="flex ml-1 mt-1.0">
                                                                                                <select name="" value={formula.formulaId} className="border border-black py-1" onChange={(e) => handleOnChange(req.currentIndex, reqIndex, fIndex, e.target.value, "formulaId")}
                                                                                                    disabled={childRecordExists || readOnly}
                                                                                                >
                                                                                                    <option value="">Select</option>
                                                                                                    {(formulaList?.data ? formulaList?.data : []).map(f =>
                                                                                                        <option value={f.id}> {f.name} </option>
                                                                                                    )}
                                                                                                </select>
                                                                                                <input type="text" readOnly={readOnly} className="border border-black text-center w-20" value={formula.value} onChange={(e) => handleOnChange(req.currentIndex, reqIndex, fIndex, e.target.value, "value")}
                                                                                                    disabled={childRecordExists}
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                type='button' tabIndex={-1}
                                                                                                onClick={() => {
                                                                                                    setRequirementDetails(prev => {
                                                                                                        let newPrev = structuredClone(prev);
                                                                                                        newPrev[req.currentIndex]["requirement"][reqIndex]["formula"] = newPrev[req.currentIndex]["requirement"][reqIndex]["formula"].filter((_, i) => i !== fIndex)
                                                                                                        return newPrev
                                                                                                    })
                                                                                                }}
                                                                                                disabled={childRecordExists}

                                                                                                className='text-md text-red-600 ml-1 mt-3'>{<FontAwesomeIcon icon={faTrashCan} />}
                                                                                            </div>

                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td className="w-9 border border-black  text-xs text-center h-9 "  >

                                                                                    <button
                                                                                        type='button' className={`text-green-700 text-center`}
                                                                                        onClick={() => {
                                                                                            setRequirementDetails(prev => {
                                                                                                let newPrev = structuredClone(prev);
                                                                                                newPrev[req.currentIndex]["requirement"][reqIndex]["formula"] = [...newPrev[req.currentIndex]["requirement"][reqIndex]["formula"],
                                                                                                { formulaId: "", value: "" }]
                                                                                                return newPrev
                                                                                            })
                                                                                        }}
                                                                                        disabled={childRecordExists}
                                                                                    > {<FontAwesomeIcon icon={faUserPlus} />}
                                                                                    </button>


                                                                                </td>
                                                                                <td className="w-9 border border-black  text-xs text-center h-9 "  >
                                                                                    <button
                                                                                        type='button' tabIndex={-1}
                                                                                        onClick={() => {
                                                                                            setRequirementDetails(prev => {
                                                                                                let newPrev = structuredClone(prev);
                                                                                                newPrev = newPrev.filter((_, i) => i !== req.currentIndex)
                                                                                                return newPrev
                                                                                            })
                                                                                        }}
                                                                                        disabled={childRecordExists}
                                                                                        className='text-md text-red-600 ml-1 mt-3'>{<FontAwesomeIcon icon={faTrashCan} />}
                                                                                    </button>
                                                                                </td>

                                                                            </>

                                                                        )}

                                                                    </tr>

                                                                )}

                                                        </Fragment>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            {/* <div className={`frame h-full overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-5`}>
                                <FormReport
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    setId={setId}
                                    tableHeaders={tableHeaders}
                                    tableDataNames={tableDataNames}
                                    data={allData?.data}
                                    loading={
                                        isLoading || isFetching
                                    }
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}