import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FINISH_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const FinishMasterApi = createApi({
    reducerPath: "finishMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["FinishMaster"],
    endpoints: (builder) => ({
        getFinishMaster: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: FINISH_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: FINISH_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["FinishMaster"],
        }),
        getFinishMasterById: builder.query({
            query: (id) => {
                return {
                    url: `${FINISH_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["FinishMaster"],
        }),
        addFinishMaster: builder.mutation({
            query: (payload) => ({
                url: FINISH_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["FinishMaster"],
        }),
        updateFinishMaster: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${FINISH_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["FinishMaster"],
        }),
        deleteFinishMaster: builder.mutation({
            query: (id) => ({
                url: `${FINISH_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["FinishMaster"],
        }),
    }),
});

export const {
    useGetFinishMasterQuery,
    useGetFinishMasterByIdQuery,
    useAddFinishMasterMutation,
    useUpdateFinishMasterMutation,
    useDeleteFinishMasterMutation,
} = FinishMasterApi;

export default FinishMasterApi;
