import React, { useEffect, useState } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetAccessoryMasterQuery } from '../../../redux/ErpServices/AccessoryMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';
import { toast } from "react-toastify"
import { Loader } from '../../../Basic/components';
import { VIEW } from '../../../icons';
import TaxDetailsFullTemplate from '../TaxDetailsCompleteTemplate';

import Modal from '../../../UiComponents/Modal';

const AccessoryDirectItems = ({ poItems, setPoItems, readOnly, params, isSupplierOutside, taxTypeId }) => {

    const [currentSelectedIndex, setCurrentSelectedIndex] = useState("")

    const handleInputChange = (value, index, field) => {
       
            const newBlend = structuredClone(poItems);
            newBlend[index][field] = value;
            if (field === "accessoryId") {
                newBlend[index]["taxPercent"] = findYarnTax(value)
            }
            setPoItems(newBlend);
        

    };


    const validateFunction=(value,e)=>
    { 
    if(!parseFloat(value)){
        toast.info("Required Field", {position: "top-center"})
        e.target.focus();
    }
    }


    useEffect(() => {
        if (readOnly) return
        else {
            if (poItems.length === 0) {
                setPoItems(Array.from({ length: 5 }, i => {
                    return { accessoryId: "", qty: "", colorId: "", sizeId: "", uomId: "", qty: "", price: "", discountType: "Percentage" }
                }
                )
                )
            }
        }
    }, [poItems, readOnly])

    const addRow = () => {
        if (poItems.length >= accessoryList.data.length) {

            toast.info("No More Items", { position: 'top-center' })
            return
        }
        const newRow = { accessoryId: "", qty: "", colorId: "", sizeId: "", uomId: "", qty: "", price: "" };
        setPoItems([...poItems, newRow]);
    };
    const handleDeleteRow = id => {
        setPoItems(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
    };


    const { data: accessoryList } =
        useGetAccessoryMasterQuery({ params: { ...params, active: true } });


    const { data: colorList } =
        useGetColorMasterQuery({ params: { ...params, active: true } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { ...params, active: true } });

    const { data: sizeList } =
        useGetSizeMasterQuery({ params: { ...params, active: true } });

    function findAccessoryItemName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.name : null
    }

    function findAccessoryGroupName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.AccessoryGroup.name : null
    }

    function findYarnTax(id) {
        if (!accessoryList) return 0
        let yarnItem = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return yarnItem ? yarnItem.taxPercent : 0
    }



    if (!accessoryList || !colorList || !uomList || !sizeList) return <Loader />

    return (
        <>
            {
                poItems.length !== 0 ?
                    <>
                        <Modal isOpen={Number.isInteger(currentSelectedIndex)} onClose={() => setCurrentSelectedIndex("")}>
                            <TaxDetailsFullTemplate taxTypeId={taxTypeId} currentIndex={currentSelectedIndex} poItems={poItems} handleInputChange={handleInputChange} isSupplierOutside={isSupplierOutside} />
                        </Modal>
                        <div className={` relative w-full overflow-y-auto p-3`}>
                            <table className=" border border-gray-600 text-xs table-auto w-full">
                                <thead className='bg-blue-200 top-0'>
                                    <tr>
                                        <th className="table-data border border-gray-600 w-2 text-center">S.no</th>
                                        <th className="table-data border border-gray-600">Accessory Name<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-36">Accessory Item</th>
                                        <th className="table-data border border-gray-600 w-36">Accessory Group</th>
                                        <th className="table-data border border-gray-600">Colors<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">Size<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">UOM<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-16">Quantity<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-16">Price<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-16">Gross</th>
                                        <th className="table-data border border-gray-600 w-16">View Tax</th>
                                        <th>
                                            {readOnly ?
                                                "" :
                                                <div onClick={addRow}
                                                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                                    {PLUS}
                                                </div>
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
                                    {poItems.map((row, index) => (
                                        <tr key={index} className="w-full">
                                            <td className="table-data border border-gray-600 w-2 text-center">
                                                {index + 1}
                                            </td>
                                            <td className='border border-gray-600'>
                                                <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.accessoryId} onChange={(e) => handleInputChange(e.target.value, index, "accessoryId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {accessoryList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.aliasName}
                                                        </option>
                                                    )}
                                                </select>
                                            </td>
                                            <td className='border border-gray-600'>
                                                <input
                                                    type="text"
                                                    className="text-center rounded w-36 py-1"
                                                    value={findAccessoryItemName(row.accessoryId)}
                                                    disabled={true}

                                                />
                                            </td>
                                            <td className='border border-gray-600'>
                                                <input
                                                    type="text"
                                                    className="text-center rounded w-36 py-1"
                                                    value={findAccessoryGroupName(row.accessoryId)}
                                                    disabled={true}
                                                />
                                            </td>
                                            <td className='border border-gray-600'>
                                                <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.colorId} onChange={(e) => handleInputChange(e.target.value, index, "colorId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {colorList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </td>
                                            <td className='border border-gray-600'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.sizeId} onChange={(e) => handleInputChange(e.target.value, index, "sizeId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {sizeList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </td>
                                            <td className='border border-gray-600'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.uomId} onChange={(e) => handleInputChange(e.target.value, index, "uomId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {uomList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </td>
                                            <td className='border border-gray-600'>
                                                <input
                                                    type="text"
                                                    className="text-right rounded py-1 px-1 w-16"
                                                    value={(!row.qty) ? 0 : row.qty}
                                                    disabled={readOnly}
                                                    onChange={(e) =>
                                                        handleInputChange(e.target.value, index, "qty")
                                                    }
                                                    onBlur={(e) =>{
                                                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty");
                                                          validateFunction(e.target.value,e);
                    
                                                    }
                                                    }
                                                   
                                                />
                                                
                                            </td>
                                            <td className='border border-gray-600'>
                                            <input
                                                    type="number"
                                                    className="text-right rounded py-1 px-1 w-16"
                                                    value={(!row.price) ? 0 : row.price}
                                                    disabled={readOnly}
                                                    onChange={(e) =>
                                                        handleInputChange(e.target.value, index, "price")
                                                    }
                                                    onBlur={(e) =>
                                                        {
                                                        handleInputChange(parseFloat(e.target.value).toFixed(2), index, "price");
                                                        validateFunction(e.target.value,e);
                                                    }
                                                }
                                                /> 
                                            </td>
                                            <td className='border border-gray-600'>
                                                <input
                                                    type="number"
                                                    className="text-right rounded py-1 px-1 w-16"
                                                    value={(!row.qty || !row.price) ? 0 : (parseFloat(row.qty) * parseFloat(row.price))}
                                                    disabled={true}
                                                />
                                            </td>
                                            <td className='border border-gray-600 w-16'>
                                                <button
                                                    className="text-center rounded py-1 w-full"
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setCurrentSelectedIndex(index);
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        if (!taxTypeId) return toast.info("Please select Tax Type", { position: "top-center" });
                                                        setCurrentSelectedIndex(index)
                                                    }
                                                    }
                                                >
                                                    {VIEW}
                                                </button>
                                            </td>
                                            <td className='border border-gray-600'>
                                                {readOnly
                                                    ?
                                                    ""
                                                    :
                                                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                                        {DELETE}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                    :
                    <div></div>
            }
        </>
    )
}

export default AccessoryDirectItems