import React, { useEffect, useState } from 'react'
import moment from 'moment'
import WashCareInstructions from './WashCareInstructions'
import { TICK_ICON } from '../../../icons';
import { CLOSE_ICON } from '../../../icons';
import { useGetWashCareMasterByIdQuery, useGetWashCareMasterQuery } from '../../../redux/LabServices/WashCareMasterServices';
import { Loader } from '../../../Basic/components';
import { getImageUrlPath } from '../../../Utils/helper';
import { useUpdateLabTestResultEntryMutation } from '../../../redux/LabServices/LabTestResultEntryServices';
import { toast } from 'react-toastify';
import { useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { useDispatch } from 'react-redux';

const SampleDetails = ({ washCareData, data, splitTest, activeRegistrationId }) => {

  const [updateData] = useUpdateRegistrationMutation();
  const [conditioning, setConditioning] = useState("");
  const [serviceRemarks, setServiceRemarks] = useState("");


  useEffect(() => {
    setConditioning(data?.conditioning || "");
    setServiceRemarks(data?.serviceRemarks || "")
  }, [data, setServiceRemarks, setConditioning])


  const dispatch = useDispatch()


  const handleSubmitCustom = async (callback, conditioningData, text, isDataClear = false) => {
    try {
      await callback(conditioningData).unwrap();
      toast.success(text + "Successfully");
      dispatch({
        type: "labTestResultEntry/invalidateTags",
        payload: ["LabTestResultEntry"]
      })
    } catch (error) {
      console.log("handle");
    }
  };
  let washcare = data?.washCareDetails?.flatMap(val => val.washCareDetails)

  const saveData = (isDataClear = false) => {

    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }
    handleSubmitCustom(updateData, { registrationId: activeRegistrationId, conditioningUpdate: true, conditioning, serviceRemarks }, "Updated", isDataClear);
  };

  let companyName = (data?.companyName.toLowercase || "");

  function findDays(workingDays, serviceDays) {
    if (parseInt(serviceDays) >= parseInt(workingDays)) {
      return workingDays
    }
    else {
      return "#"
    }
  }

  function checkResult(accre, nonAccre, field) {
    if (field == "pass") {
      if ((accre?.pass || accre?.data) && (nonAccre?.pass || nonAccre?.data)) {
        return true
      }
      else {
        return false
      }
    }
    if (field == "fail") {
      if ((accre?.fail || !accre?.data) && (nonAccre?.fail || !nonAccre?.data)) {
        return true
      }
      else {
        return false
      }
    }

    if (field == "data") {
      if ((accre?.data) && (nonAccre?.data)) {
        return true
      }
      else {
        return false
      }
    }

  }

  function findOverAllResult(array, field) {
    if (array?.length === 0) return false

    if (field == "pass") {
      if (array.every(val => val.data)) return false
      let data = array.filter(val => val?.pass || val?.data)
      if (data?.length == array?.length) {
        return true
      }
      return false
    }
    if (field == "fail") {
      let data = array.filter(val => val?.fail)
      if (data?.length > 0) {
        return true
      }
      return false
    }
    if (field == "data") {
      let result = array.every(val => val.data) ? true : false
      return result
    }

  }

  function readyMadeArray(motherArray) {
    let claimedArray = [];

    motherArray.map(val => {
      val.claimedDetails?.forEach(item => {
        claimedArray.push({
          sample: val?.sampleId?.slice(-1),
          sampleId: val?.sampleId,
          subHeading: item?.subHeading,
          fiberContentId: item?.fiberContentId,
          name: item?.FiberContent?.aliasName

        })
      })
    })



    return claimedArray




  }

  function claimedContentArray(motherArray) {
    let claimedArray = [];

    motherArray.map(val => {
      val.claimedDetails?.forEach(item => {
        claimedArray.push({
          sample: val?.sampleId?.slice(-1),
          sampleId: val?.sampleId,
          subHeading: item?.subHeading,
          value: item?.value,
          name: item?.UnitOfMeasurement?.name,
          unitId: item?.UnitOfMeasurement?.id,

        })
      })
    })


    return claimedArray

  }


  function getData(array) {



    const uniqueIds = new Set();
    let newArray = array.filter((item) => {
      if (!uniqueIds.has(item.fiberContentId)) {
        uniqueIds.add(item.fiberContentId);
        return true;
      }
      return false;
    });
    return newArray

  }

  function getArrayData(array) {

    const uniqueIds = new Set();
    let newArray = array.filter((item) => {
      if (!uniqueIds.has(item.value) && !uniqueIds.has(item.subHeading) && !uniqueIds.has(item.name)) {
        uniqueIds.add(item.value, item.subHeading, item.name);
        return true;
      }
      return false;
    });

    return newArray

  }



  function getSamples(fiberId, array) {

    let samples = array?.filter(item => parseInt(item?.fiberContentId) === parseInt(fiberId))
    return samples

  }


  function getSamplesClaimed(id, value, array) {
    let samples = array?.filter(item => parseInt(item?.unitId) === parseInt(id) && item?.value == value)
    return samples

  }



  return (
    <div className='ml-5 page capitalize'>{console.log(getData(readyMadeArray(data?.claimedFiberContent)), "getdataaaa")}

      <div className=' grid grid-cols-2 gap-x-9 ml-12 w-full'>

        <div>

          {
            splitTest == "ACCREDITATION" ? (
              <div className=' text-xs grid grid-cols-2'>
                <div className=' text-sm font-bold'>Test Report No : </div>
                <div className='text-sm pl-8 ml-4'>{data?.testReportNo} - A   </div>
              </div>
            )
              :
              splitTest == "NONACCREDITATION" ? (
                <div className=' text-xs grid grid-cols-2'>
                  <div className='text-sm font-bold'>Test Report No : </div>
                  <div className=' text-sm pl-8 ml-4'>{data?.testReportNo} - B </div>
                </div>
              )

                :
                splitTest == "ALL" ? (
                  <div className=' text-xs grid grid-cols-2'>
                    <div className='text-sm font-bold'>Test Report No : </div>
                    <div className=' text-sm pl-8 ml-4'>{data?.testReportNo} </div>


                  </div>
                ) : ""
          }


          <div className='text-xs '>

            <div className='p-1'> {splitTest == "ACCREDITATION" && data?.testDetailsSummary?.accredidatedResults.length > 0 && data?.ulrNo ? `${data?.ulrNo}${"F"}` : splitTest == "NONACCREDITATION" ? "" : splitTest == "ALL" && data?.testDetailsSummary?.nonAccredidatedResults.length > 0 && data?.ulrNo ? `${data?.ulrNo}${"P"}` : data?.ulrNo ? `${data?.ulrNo}${"F"}` : "--"}</div>
          </div>


          <div className=' text-xs grid grid-cols-2 mt-3'>
            <div className='text-sm  p-1 font-bold'>Sample.LogIn.Date: </div>
            <div className='pl-8 text-sm p-1 ml-4'>{moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")}</div>


          </div>

          <div className=' text-xs grid grid-cols-2'>
            <div className='text-sm  p-1 font-bold'>Date.of.TestPeriod:</div>
            <div className=' text-smp-1 pl-8 ml-4'> {`${moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")} - ${data?.logoutDate ? moment.utc(data?.logoutDate).format("DD-MM-YYYY") : ""}`}</div>
          </div>


          {/* <div className='text-xs grid grid-cols-2  ml-12 w-full'>
        <div className='p-1 '> <strong>Sample.LogIn.Date: </strong><span className='pl-12'>{moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")}</span>  </div>
        <div>
          <strong>Date.of.TestPeriod:</strong> {`${moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")} - ${moment.utc(data?.dateOut).format("DD-MM-YYYY")}`}
        </div>
      </div> */}



          <div className='text-xs grid grid-cols-2'>
            <div className='text-sm  p-1 font-bold'>Date Out :</div>
            <div className='text-sm pl-8 p-1 ml-4'> {data?.logoutDate ? moment.utc(data?.logoutDate).format("DD-MM-YYYY") : ""}</div>
          </div>



          {data?.amendmentReason &&
            <>
              <div className='text-xs grid grid-cols-2'>
                <div className='text-sm  p-1 font-bold'>Amendment  <span>{data?.testReportNo?.slice(-1)}</span>  Date :</div>
                <div className='text-sm pl-8 p-1 ml-4'> {data?.amendmentLogOutDate ? moment.utc(data?.amendmentLogOutDate).format("DD-MM-YYYY") : ""}</div>
              </div>
            </>
          }


          <div className='text-sm grid grid-cols-2'>
            <div className='  p-1 font-bold'>No Of WorkingDays:</div>
            <div className='pl-8  p-1 ml-4'> {findDays(data?.noOfWorkingDays, data?.serviceDays)}</div>
          </div>

        </div>



        <div>
          {
            splitTest == "ACCREDITATION" ?

              <div className=' mr-40 font-bold'>

                <div className=' border border-gray-900 h-32 pl-2 w-60 '>
                  <h1 className='font-bold p-1 uppercase underline'>OverAll Rating :</h1>
                  <div className='py-0.5 px-2  gap-y-3 grid text-sm '>
                    <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>pass : </h1> <h1>{findOverAllResult(data?.testDetailsSummary?.accredidatedResults, "pass") ? CLOSE_ICON : ""} </h1></div>
                    <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>fail :</h1> <h1> {findOverAllResult(data?.testDetailsSummary?.accredidatedResults, "fail") ? CLOSE_ICON : ""}</h1>  </div>
                    <div className='grid grid-cols-3 capitalize'>  <h1 className='capitalize'>data : </h1> <h1> {findOverAllResult(data?.testDetailsSummary?.accredidatedResults, "data") ? CLOSE_ICON : ""}</h1> </div>
                    {/* <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>pass : </h1> <h1>{data?.overAllResult?.accredidated?.pass ? CLOSE_ICON : ""} </h1></div>
                    <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>fail :</h1> <h1> {data?.overAllResult?.accredidated?.fail ? CLOSE_ICON : ""}</h1>  </div>
                    <div className='grid grid-cols-3 capitalize'>  <h1 className='capitalize'>data : </h1> <h1> {data?.overAllResult?.accredidated?.data ? CLOSE_ICON : ""}</h1> </div> */}
                  </div>
                </div>
              </div>
              :
              splitTest == "NONACCREDITATION" ?
                <div className=' mr-40 font-bold'>

                  <div className=' border border-gray-900  h-32  pl-2 w-60 '>
                    <h1 className='font-bold p-1 uppercase underline'>OverAll Rating :</h1>
                    <div className='py-0.5 px-2  gap-y-3 grid text-sm'>
                      <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>pass : </h1> <h1>{findOverAllResult(data?.testDetailsSummary?.nonAccredidatedResults, "pass") ? CLOSE_ICON : ""} </h1></div>
                      <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>fail :</h1> <h1> {findOverAllResult(data?.testDetailsSummary?.nonAccredidatedResults, "fail") ? CLOSE_ICON : ""}</h1>  </div>
                      <div className='grid grid-cols-3 capitalize'>  <h1 className='capitalize'>data : </h1> <h1> {findOverAllResult(data?.testDetailsSummary?.nonAccredidatedResults, "data") ? CLOSE_ICON : ""}</h1> </div>
                      {/* <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>pass : </h1> <h1>{data?.overAllResult?.nonAccredidated?.pass ? CLOSE_ICON : ""} </h1></div>
                      <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>fail :</h1> <h1> {data?.overAllResult?.nonAccredidated?.fail ? CLOSE_ICON : ""}</h1>  </div>
                      <div className='grid grid-cols-3 capitalize'>  <h1 className='capitalize'>data : </h1> <h1> {data?.overAllResult?.nonAccredidated?.data ? CLOSE_ICON : ""}</h1> </div> */}
                    </div>
                  </div>
                </div>
                :
                <div className='mr-40 font-bold'>

                  <div className=' border border-gray-900  h-32  pl-2 w-60 '>
                    <h1 className='font-bold p-1 uppercase text-sm underline'>OverAll Rating :</h1>
                    <div className='py-0.5 px-2  gap-y-3 grid text-sm'>
                      <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>pass : </h1> <h1>{findOverAllResult(data?.testDetailsSummary?.allResults, "pass") ? CLOSE_ICON : ""} </h1></div>
                      <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>fail :</h1> <h1> {findOverAllResult(data?.testDetailsSummary?.allResults, "fail") ? CLOSE_ICON : ""}</h1>  </div>
                      <div className='grid grid-cols-3 capitalize'>  <h1 className='capitalize'>data : </h1> <h1> {findOverAllResult(data?.testDetailsSummary?.allResults, "data") ? CLOSE_ICON : ""}</h1> </div>
                      {/* <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>pass : </h1> <h1>{checkResult(data?.overAllResult?.accredidated, data?.overAllResult?.nonAccredidated, "pass") ? CLOSE_ICON : ""} </h1></div>
                      <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>fail :</h1> <h1> {checkResult(data?.overAllResult?.accredidated, data?.overAllResult?.nonAccredidated, "fail") ? CLOSE_ICON : ""} </h1>  </div>
                      <div className='grid grid-cols-3 capitalize'>  <h1 className='capitalize'>data : </h1> <h1> {checkResult(data?.overAllResult?.accredidated, data?.overAllResult?.nonAccredidated, "data") ? CLOSE_ICON : ""} </h1> </div> */}


                      {/* <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>pass : </h1> <h1>{data?.overAllResult?.all?.pass ? CLOSE_ICON : ""} </h1></div>
                      <div className='grid grid-cols-3 capitalize'><h1 className='capitalize'>fail :</h1> <h1> {data?.overAllResult?.all?.fail ? CLOSE_ICON : ""}</h1>  </div>
                      <div className='grid grid-cols-3 capitalize'>  <h1 className='capitalize'>data : </h1> <h1> {data?.overAllResult?.all?.data ? CLOSE_ICON : ""}</h1> </div> */}
                    </div>
                  </div>
                </div>

          }
        </div>


      </div>




      <div className='md:ml-12 w-full mt-5 capitalize'>
        <table>
          <tbody>
            <tr className='text-sm capitalize'>
              <td className=' p-1  font-bold'>Company Name & Address: </td>
              <td className='py-1 md:pl-44 capitalize'>{`${data?.companyName?.toLowerCase() || ""} & ${data?.companyAddress?.toLowerCase() || ""}`}</td>
            </tr>

            <tr className='text-sm '>
              <td className=' p-1 font-bold'>Contact Name : </td>
              {/* <td className='  pl-32 '>{(data?.companyContact ? data?.companyContact : []).map(name => name.contactPersonName).join("/")}</td> */}
              <td className='  md:pl-44 capitalize'>{data?.submittedBy.toLowerCase() || ""}</td>
            </tr>
          </tbody>
        </table>
      </div>



      <div className='mt-4 ml-12 w-full capitalize'>
        <table className='w-full'>
          <tbody>

            <tr className='text-sm '>
              <td className='  p-1 '>Sample Description :</td>
              <td className=' p-1 pl-24 capitalize'>{data?.sampleDescription ? data?.sampleDescription.toLowerCase() : ""}</td>

            </tr>

            <tr className='text-sm'>
              <td className=' p-1 '>Style No :</td>
              <td className=' p-1 pl-24 capitalize'>{data?.styleNo ? data.styleNo : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1 '>Order No :</td>
              <td className=' p-1 pl-24 capitalize'>{data?.orderNo ? data.orderNo : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1 '>Dept No :</td>
              <td className=' p-1 pl-24 capitalize'>{data?.departmentNo ? data.departmentNo : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className='  p-1'>Batch/Lot No :</td>
              <td className=' pl-24 p-1 capitalize'>{data?.batchNo ? data.batchNo : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Color Name : :</td>
              <td className='pl-24  p-1 capitalize'>{data?.colorName ? data.colorName.toLowerCase() : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Season:</td>
              <td className='pl-24 p-1'>{data?.season ? data.season.toLowerCase() : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Phase:</td>
              <td className='pl-24 p-1 capitalize'>{data?.phase ? data.phase.toLowerCase() : ""}</td>
            </tr>


            <tr className='text-sm'>
              <td className=' p-1  '>Fabric Supplier / Mill Name : </td>
              <td className='pl-24  p-1 capitalize'>{data?.fabricSupplierMillName ? data?.fabricSupplierMillName.toLowerCase() : ""}</td>

            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Age Range:</td>
              <td className='pl-24 p-1 capitalize'>{data?.ageSizeRange ? data.ageSizeRange : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Submitted Size:</td>
              <td className='pl-24 p-1 capitalize'>{data?.submittedSize ? data.submittedSize : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className='  p-1'>Country Of Origin:</td>
              <td className='pl-24  p-1 capitalize'>{data?.countryOfOrigin ? data.countryOfOrigin.toLowerCase() : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Country Of Destination:</td>
              <td className='pl-24  p-1 capitalize'>{data?.countryOfDestination ? data.countryOfDestination?.map(val => `    ${val}     `).join("&") : ""}</td>
            </tr>
            <tr className='text-sm'>
              <td className=' p-1'>End Use:</td>
              <td className='pl-24  p-1 capitalize'>{data?.endUse ? data.endUse?.map(val => `    ${val}     `).join("&") : ""}</td>
            </tr>




            <tr className='text-sm'>
              <td className='  p-1'>Claimed Fabric Weight:</td>
              {/* <td className=' pl-24 p-1 capitalize'>{(getArrayData(claimedContentArray(data?.claimedFabricWeight)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedFabricWeight))?.map(j => data?.samples?.length > 1 ? j.sample ${"&"} : "")} ${data?.samples?.length > 1 ? "--" : ""} ${val?.value} ${val?.name} ,   `)}</td> */}
              <td className=' pl-24 p-1 capitalize'>{data?.samples.length > 1 ? (getArrayData(claimedContentArray(data?.claimedFabricWeight)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedFabricWeight))?.map(j => j.sample)?.join(" & ")}  ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `) : (getArrayData(claimedContentArray(data?.claimedFabricWeight)))?.map(val => `  ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"} ${val?.value} ${val?.name},   `)}</td>

              {/* <td className='pl-24  p-1 capitalize'>{data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""}{`${(data?.claimedFabricWeight ? data?.claimedFabricWeight : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `     ${item.subHeading.toLowerCase() || ""} - ${item.value}  ${item.UnitOfMeasurement.name.toLowerCase() || ""}  `) : "--").join("&")} `}</td> */}
            </tr>

            <tr className='text-sm'>
              <td className='  p-1'>Claimed Yarn Count:</td>
              <td className=' pl-24 p-1 capitalize'>{data?.samples.length > 1 ? (getArrayData(claimedContentArray(data?.claimedYarnCount)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedYarnCount))?.map(j => j.sample)?.join(" & ")}  ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `) : (getArrayData(claimedContentArray(data?.claimedYarnCount)))?.map(val => `  ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"} ${val?.value} ${val?.name},   `)}</td>

              {/* <td className=' pl-24 p-1 capitalize'>{(getArrayData(claimedContentArray(data?.claimedYarnCount)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedYarnCount))?.map(j => j.sample)?.join(" & ")}     ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `)}</td> */}

              {/* <td className='pl-24  p-1 capitalize'>{data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""}{` ${(data?.claimedYarnCount ? data?.claimedYarnCount : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `     ${item.subHeading.toLowerCase() || ""} -  ${item.value} ${item.UnitOfMeasurement.name.toLowerCase() || ""}  `) : "--").join("&")} `}</td> */}
            </tr>

            <tr className='text-sm'>
              <td className='  p-1'>Claimed Fabric Count:</td>
              <td className=' pl-24 p-1 capitalize'>{data?.samples.length > 1 ? (getArrayData(claimedContentArray(data?.claimedFabricCount)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedFabricCount))?.map(j => j.sample)?.join(" & ")}  ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `) : (getArrayData(claimedContentArray(data?.claimedFabricCount)))?.map(val => `  ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"} ${val?.value} ${val?.name},   `)}</td>

              {/* <td className=' pl-24 p-1 capitalize'>{(getArrayData(claimedContentArray(data?.claimedFabricCount)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedFabricCount))?.map(j => j.sample)?.join(" & ")}     ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `)}</td> */}

              {/* <td className='pl-24  p-1 capitalize'>{data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""}{` ${(data?.claimedFabricCount ? data?.claimedFabricCount : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `       ${item.subHeading.toLowerCase() || ""} -  ${item.value}  ${item.UnitOfMeasurement.name.toLowerCase() || ""}   `) : "--").join("&")} `}</td> */}
            </tr>

            <tr className='text-sm'>
              <td className='p-1'>Claimed Fiber Content:</td>


              <td className=' pl-24 p-1 capitalize'>{data?.samples.length > 1 ? (getData(readyMadeArray(data?.claimedFiberContent)))?.map(val => `${getSamples(val?.fiberContentId, readyMadeArray(data?.claimedFiberContent))?.map(j => j.sample)?.join(" & ")}        ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.name},   `) : (getData(readyMadeArray(data?.claimedFiberContent)))?.map(val => `       ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"}    ${val?.name},   `)}</td>

              {/* <td className=' pl-24 p-1 capitalize'>{(getData(readyMadeArray(data?.claimedFiberContent)))?.map(val => `${getSamples(val?.fiberContentId, readyMadeArray(data?.claimedFiberContent))?.map(j => j.sample)?.join(" & ")}        ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.name},   `)}</td> */}
              {/* <td className=' pl-24 p-1 capitalize'>{data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""}--{`${(data?.claimedFiberContent ? data?.claimedFiberContent : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? (sampleItem.claimedDetails).map(item => `${item.subHeading.toLowerCase() || ""} -${item?.FiberContent?.aliasName.toLowerCase() || ""}    `) : "--").join("&")}`}</td> */}

            </tr>

            <tr className='text-sm'>
              <td className='  p-1'>Actual Fiber Content:</td>
              <td className='pl-24  p-1 capitalize'>/</td>
            </tr>


            <tr className='text-sm'>
              <td className=' p-1'>Suggested Fiber Content:</td>
              <td className='pl-24  p-1'>/</td>
            </tr>

            <tr className='text-sm'>
              <td className='  p-1'>Product Category:</td>
              <td className='pl-24  p-1 capitalize'>{data?.productCategory ? data?.productCategory.toLowerCase() : ""}</td>

            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Test Stage (Package):</td>
              <td className='pl-24 p-1 capitalize'>{data?.testStage ? data.testStage.toLowerCase() : ""}</td>
            </tr>

            <tr className='text-sm'>
              <td className=' p-1'>Previous Report NO:</td>
              <td className='pl-24 p-1 capitalize'>{data?.prevReportNo ? data.prevReportNo : ""}</td>
            </tr>

            <tr className=" text-sm ">
              <td className=' p-1 '>Submitted Washcare Instruction :</td>
              <td className='pl-24 p-1'>

                <>
                  {data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""}
                </>
                <div className='flex flex-wrap gap-y-5 gap-x-7'>
                  {(data?.groupedWashCareImages ? data?.groupedWashCareImages : []).map((val) =>
                    <img alt='washcare' key={val.washCareId} src={getImageUrlPath(val?.image ? val?.image : "")} style={{ height: "50px", width: "50px", objectFit: 'fit' }} />
                  )}
                </div>
              </td>
            </tr>
            <tr className=" text-sm">
              <td className='font-bold p-1  capitalize'></td>
              <td className='pl-24 grid grid-cols-1 p-1'> {data?.groupedSpecialInstructions.length > 1 ? (data?.groupedSpecialInstructions ? data?.groupedSpecialInstructions : []).flatMap(val => `${val.samples.map(i => i)} --  ${val.specialInstruction} `).join("/") : data?.groupedSpecialInstructions.map(val => val.specialInstruction)}  </td>
            </tr>



            <tr className=" text-sm w-full">
              <td className='text-xs  w-full' colspan={2}>
                <div className='p-1 flex gap-x-5'>

                  <h1 className='mt-2 font-bold'>
                    conditioning:
                  </h1>

                  <input className="h-full w-3/4  p-2  text-left capitalize border border-gray-500" name="conditioning"
                    type="text"
                    value={conditioning}
                    onChange={(e) => setConditioning(e.target.value)}
                  />
                  <button
                    className='bg-green-400 rounded-lg border border-black text-xs  p-1 w-12 ' onClick={() => { saveData() }}>Save
                  </button>
                </div>
              </td>
            </tr>

            <tr className=" text-sm w-full">
              <td className='text-xs  w-full' colspan={2}>
                <div className='p-1 flex gap-x-5'>

                  <h1 className='mt-2 font-bold'>
                    Remarks:
                  </h1>

                  <input className="h-full w-3/4  p-2 ml-5 text-left capitalize border border-gray-500" name="serviceRemarks"
                    type="text"
                    value={serviceRemarks}
                    onChange={(e) => setServiceRemarks(e.target.value)}
                  />
                  <button
                    className='bg-green-400 rounded-lg border border-black text-xs  p-1 w-12 ' onClick={() => { saveData() }}>Save
                  </button>
                </div>
              </td>
            </tr>
            {data?.amendmentReason &&
              <>
                <tr className=" text-sm w-full">
                  <td className='text-xs  w-full' colspan={2}>
                    <div className='p-1 flex gap-x-5'>


                      <h1 className='w-3/4'><strong>Amendment  <span>{data?.testReportNo?.slice(-1)}</span>  Reason :</strong><span className='h-full w-full  p-2  text-left capitalize'>{`${data?.amendmentReason} ${""}`} </span> </h1>
                      {/* <input className="h-full w-3/4  p-2 ml-5 text-left capitalize" name="serviceRemarks"
                    type="text"
                    value={`${data?.amendmentReason} ${"thrhjyjhyjywrjymwtymwt5tyjmtyhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhnkmoljmmjmhfrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr"}`}
                  // onChange={(e) => setServiceRemarks(e.target.value)}
                  /> */}

                    </div>
                  </td>
                </tr>
              </>
            }


          </tbody>
        </table>
      </div>
    </div>

  )
}

export default SampleDetails