import React, { useRef } from 'react'
import { Document, Page, Text, View, PDFViewer, StyleSheet,PDFRenderer } from '@react-pdf/renderer';


import Content from './Content';
import { groupBy } from '../../../Utils/helper';

const ScanningSheetReport = ({registrationData, conditionList ,testList, methodList,  data,  washCareData}) => {
   
   
    return (
        <Document >

               
                  {registrationData?.data.RegistrationSampleDetails.map(sampleItem => {
                    let sampleItemTests = sampleItem.SampleTestDetails.map(item => {
                      let newItem = structuredClone(item)
                      newItem["group"] = item.LabTest.LabTestSubGroup.LabTestGroup.name
                      return newItem
                    })

                    let sampleWashCareDetails = sampleItem.SampleWashCareDetails.map(item => { return { washCareId: item.washCareId } })
                    let groupByLabGroup = groupBy(sampleItemTests, "group")
                    return Object.keys(groupByLabGroup).map((item, itemIndex) =>
                <Content key={itemIndex} washCareData={washCareData} testList={testList} methodList={methodList} conditionList={conditionList} data={data} groupName={item} groupTestDetails={groupByLabGroup[item]} sampleItem={sampleItem} sampleWashCareDetails={sampleWashCareDetails} />      
                  
                  
                    )
                  }
                  )}
                
    </Document>
   
    )
}

export default ScanningSheetReport