import React from 'react'
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import TestParametersLists from './TestParametersLists';
import { findFromList } from '../../../Utils/helper';
import { useGetEmployeeByIdQuery, useGetEmployeeQuery } from '../../../redux/services/EmployeeMasterService';
import secureLocalStorage from 'react-secure-storage';

const TestStatusDoneBy = ({ val, valIndex, dataObj }) => {

    const params = {
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        )
    }
    const { data: employeeData } = useGetEmployeeQuery({ params });
    return (
        <>
            <tr className='w-full '>
                <td className=' py-1 text-left gridAlignLeft capitalize '>

                    {val?.User?.Employee?.name || ""}
                </td>
            </tr>


        </>
    )
}

export default TestStatusDoneBy