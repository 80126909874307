import React, { useRef } from 'react'
import { Document, Page, Text, View, PDFViewer, StyleSheet,PDFRenderer } from '@react-pdf/renderer';


import Content from './Content';

const ConfirmationDocument = ({reportData,testList,methodList,data,washCareData}) => {
   
   
    return (
        <Document >
         <Content reportData={reportData}  testList={testList} methodList={methodList}  data={data}  washCareData={washCareData}/>
    </Document>
   
    )
}

export default ConfirmationDocument