import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BRAND_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const BrandMasterApi = createApi({
    reducerPath: "brandMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["BrandMaster"],
    endpoints: (builder) => ({
        getBrandMaster: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: BRAND_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: BRAND_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["BrandMaster"],
        }),
        getBrandMasterById: builder.query({
            query: (id) => {
                return {
                    url: `${BRAND_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["BrandMaster"],
        }),
        addBrandMaster: builder.mutation({
            query: (payload) => ({
                url: BRAND_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["BrandMaster"],
        }),
        updateBrandMaster: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${BRAND_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["BrandMaster"],
        }),
        deleteBrandMaster: builder.mutation({
            query: (id) => ({
                url: `${BRAND_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["BrandMaster"],
        }),
    }),
});

export const {
    useGetBrandMasterQuery,
    useGetBrandMasterByIdQuery,
    useAddBrandMasterMutation,
    useUpdateBrandMasterMutation,
    useDeleteBrandMasterMutation,
} = BrandMasterApi;

export default BrandMasterApi;
