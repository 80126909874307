import React from 'react'

const ReqValue = ({ testIndex, test }) => {
  return (

    <>
      {test?.formulaAliasName ? test?.formulaAliasName.toLowerCase() : ""}
    </>
  )
}


export default ReqValue