import React, { useEffect } from 'react'
import { useGetBlendMasterQuery } from '../../../redux/ErpServices/BlendMasterServices';
import secureLocalStorage from 'react-secure-storage';
import { findFromList } from '../../../Utils/helper';
import { getClaimedValue } from '../../../Utils/helper'

const BlendResultInput = ({getRegex, parameter, loopsubHeading, matchingSampleDetails,decimalValue, getEval,getResult,isResult,setResultDetailsWithResult,resultDetailsWithResult,resultDetails, singleParameter,result, resultIndex, item, itemIndex, handleChangeBlend, labTestRequirementDetailsId, isEditable, childRecord }) => {
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),

    };

    console.log(resultDetails,"resultdetails",resultDetailsWithResult,"setResultDetailsWithResult")



    useEffect(() => {   
     
        const reqIndex = resultDetails.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));
        const result = resultDetails[reqIndex]['resultValue'].find((_, i) => i === resultIndex)
        setResultDetailsWithResult(prev => {
            const reqIndex = prev.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));
           
            if (reqIndex === -1) return prev;
            let newReq = structuredClone(prev);
     

            const subHeadingIndex = newReq[reqIndex]["resultValue"].findIndex(i => i.subHeading.toLowerCase() == result.subHeading.toLowerCase())
          
            if (subHeadingIndex === -1) return prev;
         
            newReq[reqIndex]["resultValue"][subHeadingIndex]["value"] = result?.value ? result?.value : '0';
            if (isResult) {
                newReq[reqIndex]["resultValue"][subHeadingIndex]["result"] = getResult(parameter?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.LabTestAnalyte?.selectedOption, matchingSampleDetails, parameter, result, loopsubHeading, parameter?.labTestAnalyteDetailsParameters?.walesCourse);
                // newReq[reqIndex]["resultValue"][subHeadingIndex]["finalClaimedValue"] = getClaimedValue(parameter?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.LabTestAnalyte?.selectedOption, matchingSampleDetails, loopsubHeading, parameter?.labTestAnalyteDetailsParameters?.walesCourse)
                newReq[reqIndex]["resultValue"][subHeadingIndex]["reportValue"] = newReq[reqIndex]["resultValue"][subHeadingIndex]["value"] ? getEval(getRegex(newReq[reqIndex]["resultValue"][subHeadingIndex]["value"], result.subHeading)) : ""
                newReq[reqIndex]["resultValue"][subHeadingIndex]["requirementValue"] = (parameter?.requirement?.find(item => item.subHeading == loopsubHeading).formula.map(i => `${i.Formula?.name}${i.value}`).join(" "))
            } 
            // else {
            //     newReq[reqIndex]["resultValue"][subHeadingIndex]["result"] = true
            // }

           
            return newReq
        })
    }, [resultDetails, resultIndex])


    const { data: blendDatas } = useGetBlendMasterQuery({ params });
    return (
        <>
        
            <td key={resultIndex}>
                <table>
                    <thead>
                        <tr>
                            <th className='border border-gray-500 text-xs'>
                                Blend
                            </th>
                            <th className='border border-gray-500 text-xs '>
                                Claimed
                            </th>
                            <th className='border border-gray-500 text-xs '>
                                Percentage
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {(result?.claimedValue? result.claimedValue :[]).map((item, itemIndex) =>
                            <tr key={resultIndex}>
                                <td className='border border-gray-500 p-0.5 text-xs ' key={resultIndex}>
                                    {findFromList(item?.blendId, blendDatas?.data, "name")}
                                </td>
                                <td className='border border-gray-500 p-0.5 text-xs ' key={resultIndex}>
                                    {item.claimedPercentage}
                                </td>
                                <td className='border border-gray-500  p-1 ' key={resultIndex}>
                                    <input type="text" className="ml-4 text-center w-20 focus:outline-none " value={item?.percentage}
                                        onChange={(e) => handleChangeBlend(e.target.value,result.subHeading, labTestRequirementDetailsId, resultIndex, itemIndex, "percentage")}
                                        readOnly={!isEditable} disabled={(childRecord.current > 0)} />
                                </td>

                            </tr>
                        )}
                    </tbody>
                </table>
            </td>
      
        </>
    )
}

export default BlendResultInput
