import React, { useEffect, useState } from 'react'
import BrowseSingleImage from '../../../Basic/components/BrowseSingleImage'
import { useSampleImageUploadMutation } from '../../../redux/LabServices/RegistrationServices';
import { toast } from 'react-toastify';
import { useIsMount } from "../../../CustomHooks/useIsMount";

const SampleImageUpload = ({ currentActiveTab, sampleDetails }) => {
    const isMount = useIsMount()
    let currentSample = sampleDetails.find(item => item.sampleId === currentActiveTab)

    const [image, setImage] = useState(currentSample?.image ? currentSample.image : "");
    const [upload] = useSampleImageUploadMutation();

    const handleUpload = async (text = "Uploaded") => {
        try {
            const formData = new FormData()
            if (image instanceof File) {
                formData.append("image", image);
            } else if (!image) {
                formData.append("isDeleteImage", true);
            }

            await upload({ id: currentSample.id, body: formData }).unwrap();
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };
    useEffect(() => {
        if (isMount) return
        if (!image) {
            handleUpload("Deleted")
        }
    }, [image, isMount])

    return (
        <div className='grid'>
            <BrowseSingleImage picture={image} setPicture={setImage} />
            <button className='w-full bg-green-400 text-gray-100 rounded-md' onClick={() => handleUpload()}>Upload</button>
        </div>
    )
}

export default SampleImageUpload
