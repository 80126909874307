import React, { useEffect } from 'react'
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices'
import { params } from "../../../Utils/helper"
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices'
import { Loader } from '../../../Basic/components'
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices'
import TestRequirement from './TestRequirement'
import { useUpdateLabTestResultEntryMutation } from '../../../redux/LabServices/LabTestResultEntryServices'
import { toast } from 'react-toastify';
import { useState } from 'react'

const SampleTestParameters = ({ activeRegistrationId, passFail, item, index, reportData }) => {
  const [remarks, setRemarks] = useState(item?.remarks ? item?.remarks : "");
  useEffect(() => {
    setRemarks(item?.remarks ? item?.remarks : "")
  }, [item])
  const [updateData] = useUpdateLabTestResultEntryMutation();
  const handleSubmitCustom = async (callback, remarksData, text, isDataClear = false) => {
    try {
      await callback(remarksData).unwrap();
      toast.success(text + "Successfully");
      setRemarks("")
    } catch (error) {
      console.log("handle");
    }
  };
  const saveData = (testId, methodId, remarks, isDataClear = false) => {

    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }
    handleSubmitCustom(updateData, { testId, methodId, remarks: remarks, registrationId: activeRegistrationId, remarksUpdate: true }, "Updated", isDataClear);
  };

  const { data: testList } = useGetLabTestNameMasterQuery({ params })
  const { data: methodList } = useGetLabTestMethodMasterQuery({ params })
  const { data: conditionList } = useGetLabTestConditionMasterQuery({ params })
  if (!testList?.data || !methodList?.data || !conditionList?.data) return <Loader />
  return (
    <div className='ml-5'>
      <div className=' mt-5 w-full '>
        <div className='font-bold underline  textCapital'>
          {item?.testName.toLowerCase() || ""}
        </div>
        {/* <div className='flex mr-9 capitalize'> */}
        <h1>{item?.methodName}</h1>
        <h1 className='capitalize'>{item?.conditionName}</h1>
        {/* </div> */}
      </div>
      <div className=' mr-10 capitalize'>
        {item?.requirement?.map((req, reqIndex) => {
          return <TestRequirement key={reqIndex} item={item} req={req} reqIndex={reqIndex} requirementLength={item?.requirement?.length} reportData={reportData} />
        })}
      </div>
      <div className='text-xs flex gap-x-5  w-full'>
        <div className='p-1 mt-5 font-bold'>Remarks:</div>
        <input className="h-full w-3/4 mt-5 p-2  text-left capitalize border border-gray-500" name="remarks"
          type="text"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
        <button
          className='bg-green-400 rounded-lg border border-black text-xs  p-1 mt-5' onClick={() => { saveData(item?.testId, item?.methodId, remarks ? remarks : "") }}>Save
        </button>
      </div>
    </div>
  )
}

export default SampleTestParameters