import React from 'react';

const Invoice = ({ }) => {
    return (
        <div className=''>

            <div >
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>


            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>
            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>

            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>
            <div className=''>
                <h1>Invoicedata</h1>
                <p>{"dataaaa"}</p>
            </div>




        </div>
    );
};

export default Invoice;
