import React from 'react'
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices'
import { findFromList, params } from "../../../Utils/helper"
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices'
import { Loader } from '../../../Basic/components'
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices'
const SampleTestParameters = ({ testDetails, packageName, groupName }) => {

  const { data: testList } = useGetLabTestNameMasterQuery({ params })
  const { data: methodList } = useGetLabTestMethodMasterQuery({ params })
  const { data: conditionList } = useGetLabTestConditionMasterQuery({ params })
  if (!testList?.data || !methodList?.data || !conditionList?.data) return <Loader />
  return (
    <div className=''>
      <div className="text-center p-1 page">
        {/* <h1 className='font-bold'>Package : {packageName}</h1> */}
        <h1 className='font-bold p-1'>Group : {groupName}</h1>
      </div>
      <table className=' border border-gray-500 w-full'>
        <thead className='border border-gray-500 '>
          <tr className=''>
            <th className='text-xs border border-gray-500 p-2 w-2'>
              S.NO
            </th>
            <th className=' text-xs border border-gray-500 p-2 w-2/6'>
              Test Parameter
            </th>
            <th className=' text-xs border border-gray-500 p-2 w-1/6'>
              Methods
            </th>
            <th className=' text-xs border border-gray-500 p-2 w-2/6'>
              Condition
            </th>
            <th className='text-xs border border-gray-500 p-2 w-2/6 '>
              Result
            </th>
          </tr>
        </thead>
        <tbody>
          {testDetails.map((item, index) =>
            <tr key={item.id}>
              <td className='border  border-gray-500 text-xs p-1 whitespace-normal break-words'>
                {index + 1}
              </td>
              <td className='border border-gray-500 text-xs p-1 whitespace-normal break-words'>
                {findFromList(item.testId, testList.data, "name")}
              </td>
              <td className='border border-gray-500 text-xs p-1 whitespace-normal break-words'>
                {findFromList(item.methodId, methodList.data, "name")}
              </td>
              <td className='border border-gray-500 text-xs p-1 whitespace-normal break-words'>
                {findFromList(item.conditionId, conditionList.data, "name")}
              </td>
              <td className='border border-gray-500 text-xs p-1 whitespace-normal break-words'>
               
              </td>
            </tr>
          )}
           
          
          
        </tbody>
      </table>
      {/* <div className=' grid grid-cols-2 '>
        <div className='ml-10 mt-5'>
        <h1 className='font-bold'>Sample Preparation By:</h1>
        <h1 className='mt-5 font-bold'>Test Performed by:</h1>
        <h1 className='mt-5 font-bold'>Verification By (First Review):</h1>


        </div>
        <div className='mt-5'>
        <h1 className='font-bold'>Sign & Date :</h1>
        <h1 className='mt-5 font-bold'>Sign & Date :</h1>
        <h1 className='mt-5 font-bold'>Verification By (Final Review) :</h1>
       

        </div>
      </div> */}
    </div>
  )
}

export default SampleTestParameters