import React from 'react'
import TestHeading from './TestHeading'
import { Text, View } from '@react-pdf/renderer'
import tw from '../../../../Utils/tailwind-react-pdf'

const TestRequirement = ({ item, req, requirementLength, reportData }) => {
  return (
    <View style={tw('w-full ')}>
      <View style={tw('ml-16 font-bold')}>
        <Text style={tw('upperCase')}>{requirementLength > 1 ? req?.requirementGroup : ""}</Text>
      </View>
      <View style={tw("w-full")}>
        {req?.headingsResult?.map((heading, headingIndex) => {
          return <TestHeading key={headingIndex} item={item} heading={heading} headingIndex={headingIndex} reportData={reportData} />
        })}
      </View>
    </View>
  )
}

export default TestRequirement