import React, { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { PreviewButtonOnly, PrintButtonOnly } from '../../../Buttons'
import PrintProformaInvoice from '../PrintFormat-ProfomaInvoice'

const ProfomaInvoice = ({ id }) => {

    const[profomaInvoiceDocument,setProfomaInvoiceDocument]=useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div className='w-full bg-white p-3'>
            <div className="flex w-full justify-end ">
                {/* <PrintButtonOnly onClick={handlePrint} /> */}

                <PreviewButtonOnly onClick={()=>setProfomaInvoiceDocument(true)} />

            </div>
            <div  className='overflow-auto flex flex-col w-full '>
                <PrintProformaInvoice id={id} componentRef={componentRef} profomaInvoiceDocument={profomaInvoiceDocument}  setProfomaInvoiceDocument={setProfomaInvoiceDocument}
                />
            </div>
        
        </div>
    )
}

export default ProfomaInvoice
