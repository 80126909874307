import React from "react";
import SampleDetails from "./SampleDetails";
import SampleTestParameters from "./SampleTestParameters";
import logo from "../../../assets/SRG.png"
import { formatAMPM, getDateFromDateTimeForDisplay } from "../../../Utils/helper";
import WashCareInstruction from "../PrintFormat-TestScanningSheet/WashCareInstruction";
import Document from "./Document";
import { useGetLabTestNameMasterQuery } from "../../../redux/LabServices/LabTestNameMasterServices";
import { useGetLabTestMethodMasterQuery } from "../../../redux/LabServices/LabTestMethodMasterServices";
import { useGetLabTestConditionMasterQuery } from "../../../redux/LabServices/LabTestConditionMasterServices";
import { findFromList, params } from "../../../Utils/helper"
import Header from "./Header";
import Footer from "./Footer";
import { Loader } from "../../../Basic/components";

import { useGetWashCareGroupMasterQuery } from "../../../redux/LabServices/WashCareGroupMasterServices";

const MODEL = "Test Scanning Sheet";

export default function PrintTestScanningSheet({ componentRef, data, sampleItem, groupName, groupTestDetails, sampleWashCareDetails }) {
  const { data: testList } = useGetLabTestNameMasterQuery({ params })
  const { data: methodList } = useGetLabTestMethodMasterQuery({ params })
  const { data: conditionList } = useGetLabTestConditionMasterQuery({ params })
  const { data: washCareData, isLoading: isWashCareGroupLoading, isFetching: isWashCareGroupFetching } =
    useGetWashCareGroupMasterQuery({ params: { ...params, includeWashCare: true } })
  if (isWashCareGroupLoading || isWashCareGroupFetching) return <Loader />
  // const { data, isLoading, isFetching } = useGetWashCareMasterByIdQuery(value.washCareId, { skip: !(value.washCareId) })
  // if (isLoading || isFetching) return <Loader />


  let docIdSplit = data?.amendmentReason ? data?.docId?.split("-") : ""

  return (
    <div className="font-times p-1 w-full">
      <div className='hidden' >
        <Document washCareData={washCareData} testList={testList} methodList={methodList} conditionList={conditionList} data={data} groupName={groupName} groupTestDetails={groupTestDetails} sampleItem={sampleItem} sampleWashCareDetails={sampleWashCareDetails} componentRef={componentRef} />
      </div>
      <div className="max-h-full p-4 text-xs bg-white">
        <div className=' mt-2 '>
          <Header />
          <div className='gap-y-3 grid grid-cols-2 place-items-start w-full place-content-center p-1'>
            <h1><span className="font-bold">Report No :</span> {sampleItem?.sampleId}{docIdSplit?.length > 0 ? "-" : ""}{docIdSplit?.length > 0 ? docIdSplit[1] : ""}</h1>
            <h1><span className="font-bold">Date And Time :</span> {data?.createdAt ? getDateFromDateTimeForDisplay(data.createdAt) + "  " + formatAMPM(data.createdAt) : ""} </h1>

            <h1> <span className="font-bold">Due Date :</span> {data?.dueDate ? getDateFromDateTimeForDisplay(data?.dueDate) : ""}</h1>
            <h1><span className="font-bold">Service :</span>{data?.ServiceLevel?.name}</h1>
            <h1><span className="font-bold">Log In By:</span>{data?.logIn}</h1>
            <h1><span className="font-bold">Buyer Name :</span>{data?.Buyer?.name} </h1>
          </div>
          <SampleDetails data={data} sampleItem={sampleItem} />
          <div className="mt-3 mb-2 p-2">
            <table className="w-full">
              <tbody>
                <tr className="">
                  <td className='border border-gray-500 w-1/4 text-xs py-2'>Submitted Washcare Instruction :</td>
                  <td className='border border-gray-500 w-3/4 text-xs p-2'>{sampleWashCareDetails ? <WashCareInstruction washCareDetails={sampleWashCareDetails} sampleItem={sampleItem} /> : ""}  </td>


                </tr>

              </tbody>
            </table>

          </div>
          <SampleTestParameters groupName={groupName} testDetails={groupTestDetails ? groupTestDetails : []} packageName={data?.Package ? data?.Package.name : ""} />
          <Footer />
        </div>
      </div>

    </div>
  );
}
