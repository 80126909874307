import React, { useEffect, useState } from 'react'
import { useUpdateLabTestResultEntryMutation } from '../../../redux/LabServices/LabTestResultEntryServices';
import { toast } from 'react-toastify';
import { useGetRegistrationByIdQuery } from '../../../redux/LabServices/RegistrationServices';
import { useGetUserByIdQuery } from '../../../redux/services/UsersMasterService';
import secureLocalStorage from 'react-secure-storage';

const TestReportRevert = ({ activeRegistrationId, setRevert }) => {

  const [revertReasons, setRevertReasons] = useState("")

  const {
    data: singleRegistrationData,
    isFetching: isSingleRegistrationDataFetching,
    isLoading: isSingleRegistrationDataLoading,
  } = useGetRegistrationByIdQuery({ id: activeRegistrationId }, { skip: !activeRegistrationId });

  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )

  useEffect(() => {
    setRevertReasons(singleRegistrationData?.data?.RegistrationRevertReasons ? singleRegistrationData?.data?.RegistrationRevertReasons.map(val => (val.reasons)) : "")
  }, [singleRegistrationData, isSingleRegistrationDataFetching, isSingleRegistrationDataLoading, setRevertReasons])

  const [updateData] = useUpdateLabTestResultEntryMutation();
  const handleSubmitCustom = async (callback, reasons, text, isDataClear = false) => {
    try {
      await callback(reasons).unwrap();
      toast.success(text + "Successfully");
      setRevertReasons("")
      setRevert(false)

    } catch (error) {
      console.log("handle");
    }
  };
  const saveData = (reasons, isDataClear = false) => {

    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }
    handleSubmitCustom(updateData, { reasons, registrationId: activeRegistrationId, isAdminRevertReason: true, userId }, "Updated", isDataClear);
  };





  return (
    <div className='w-full'>
      <div className='mt-3 font-bold text-center w-full'>Reasons To Revert :</div>
      <div className='flex gap-x-3 mt-3 h-44 w-full ml-20'>


        <textarea className=" w-3/4 focus:outline-none border border-gray-500 rounded p-2"
          value={revertReasons} onChange={(e) => setRevertReasons(e.target.value)} >

        </textarea>





        <button

          className='bg-green-400 rounded-lg border border-black text-xs w-16 h-9 p-1 mt-16'
          onClick={() => { saveData(revertReasons ? revertReasons : "") }}
        >Save

        </button>

      </div>
    </div>
  )
}

export default TestReportRevert