import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useAddPoMutation,
  useDeletePoMutation,
  useUpdatePoMutation,
  useGetPoQuery,
  useGetPoByIdQuery
} from "../../../redux/ErpServices/PoServices";
import { useGetPartyQuery, useGetPartyByIdQuery } from "../../../redux/ErpServices/PartyMasterServices";
import { useGetPaytermMasterQuery } from "../../../redux/ErpServices/PayTermMasterServices";
import { useGetTaxTemplateQuery } from '../../../redux/ErpServices/TaxTemplateServices';
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { LongDropdownInput, LongDisabledInput, LongDateInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { poTypes, } from '../../../Utils/DropdownData';
import YarnPoItems from "./YarnPoItems";
import FabricPoItems from "./FabricPoItems";
import AccessoryPoItems from "./AccessoryPoItems"

import moment from "moment";
import PoSummary from "./PoSummary";
import Modal from "../../../UiComponents/Modal";

const MODEL = "Purchase Order";


export default function Form() {
  const today = new Date()

  const [summary, setSummary] = useState(false);

  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [poItems, setPoItems] = useState([]);
  const [id, setId] = useState("");
  const [poNo, setPoNo] = useState("");
  const [date, setDate] = useState(moment.utc(today).format('YYYY-MM-DD'));
  const [taxTemplateId, setTaxTemplateId] = useState("");
  const [payTermId, setPayTermId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [transType, setTransType] = useState("GreyYarn");
  const [supplierId, setSupplierId] = useState("");

  const [totalDiscount, setTotalDiscount] = useState({ type: "Percentage", value: "" })

  const [active, setActive] = useState(true)

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )
  const params = {
    branchId, companyId
  };

  const { data: supplierList } =
    useGetPartyQuery({ params: { ...params, active: true } });

  const { data: taxTypeList } =
    useGetTaxTemplateQuery({ params: { ...params, active: true } });

  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  const { data: payTermList } =
    useGetPaytermMasterQuery({ params: { ...params, active: true } });

  const { data: allData, isLoading, isFetching } = useGetPoQuery({ params, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetPoByIdQuery(id, { skip: !id });

  const [addData] = useAddPoMutation();
  const [updateData] = useUpdatePoMutation();
  const [removeData] = useDeletePoMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setTransType(data?.transType ? data.transType : "GreyYarn");
    setPoNo(data?.id ? `00${data?.id}` : "");
    setPoItems(data?.poItems ? structuredClone(data.poItems) : []);
    if (data?.date) setDate(data?.date);
    setTaxTemplateId(data?.taxTemplateId ? data?.taxTemplateId : "");
    setPayTermId(data?.payTermId ? data?.payTermId : "");
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setDueDate(data?.dueDate ? moment.utc(data?.dueDate).format("YYYY-MM-DD") : "");
    setActive(id ? (data?.active ? data.active : false) : true);
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    transType, supplierId, dueDate, taxTemplateId, payTermId,
    branchId, id, userId,
    poItems: poItems.filter(po => po.yarnId || po.fabricId || po.accessoryId)
  }

  function isSupplierOutside() {
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMIL NADU"
    }
    return false
  }

  const validateData = (data) => {
    return data.transType && data.supplierId && data.dueDate && data.taxTemplateId && data.payTermId
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      if (text === "Updated") {
        await callback({ id, body: data });
      } else {
        await callback(data)
      }
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
  };

  function onDataClick(id) {
    setId(id);
    setForm(true);
  }

  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']
  useEffect(() => {
    if (id) return
    setPoItems([])
  }, [transType])


  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLoading || isFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  const allSuppliers = supplierList ? supplierList.data : []

  function filterSupplier() {
    let finalSupplier = []
    if (transType.toLowerCase().includes("yarn")) {
      finalSupplier = allSuppliers.filter(s => s.yarn)
    } else if (transType.toLowerCase().includes("fabric")) {
      finalSupplier = allSuppliers.filter(s => s.fabric)
    } else {
      finalSupplier = allSuppliers.filter(s => s.PartyOnAccessoryItems.length > 0)
    }
    return finalSupplier
  }
  let supplierListBasedOnSupply = filterSupplier()




  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
    >{console.log(poItems, "poItems")}
      <Modal isOpen={summary} onClose={() => setSummary(false)} widthClass={"p-10"}>
        <PoSummary totalDiscount={totalDiscount} setTotalDiscount={setTotalDiscount} poItems={poItems} taxTypeId={taxTemplateId} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          onClose={() => {
            setForm(false);
            setSearchValue("");
          }}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                  <div className={`${formReport ? "col-span-10" : "col-span-1"}`}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 h-[27%] px-3 overflow-auto'>
                      <legend className='sub-heading'>Purchase Info</legend>
                      <div className="flex justify-end relative top-0 right-0">
                        <button className="text-xs bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1" onClick={() => setFormReport(prev => !prev)}>
                          {formReport ? "Close Report >" : "Open Report <"}
                        </button>
                      </div>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="flex flex-wrap ">
                          <div className="flex flex-col">
                            <LongDisabledInput name="Po no." className={`${id ? "block" : "hidden"}`} value={poNo} required={true} readOnly={readOnly} />
                            <div className='flex gap-20 items-center justify-start md:my-1 px-1 data'>
                              <label>Po Type</label>
                              <select id='dd' autoFocus name="name" className='h-6 w-40 border border-gray-500 rounded' value={transType} onChange={(e) => setTransType(e.target.value)}>
                                {poTypes.map((option, index) => <option key={index} value={option.value} >
                                  {option.show}
                                </option>)}
                              </select>
                            </div>
                            <LongDropdownInput className={"w-40"} name="Pay Terms" options={dropDownListObject(payTermList ? payTermList.data : [], "name", "id")} value={payTermId} setValue={(value) => { setPayTermId(value); }} required={true} readOnly={readOnly} />
                          </div>
                          <div className="flex flex-col">
                            <LongDisabledInput name="Po Date" value={date} type={"date"} required={true} readOnly={readOnly} />
                            <LongDropdownInput className={"w-40"} name="Tax Type" options={dropDownListObject(taxTypeList ? taxTypeList.data : [], "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={readOnly} />
                          </div>
                          <div className="flex flex-col">
                            <LongDropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={readOnly} />
                            <LongDateInput name="Due Date" value={dueDate} setValue={setDueDate} required={true} readOnly={readOnly} />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex h-[360px] overflow-auto overflow-x-hidden'>
                      <legend className='sub-heading'>Purchase Details</legend>
                      {transType.toLowerCase().includes("yarn")
                        ?
                        <YarnPoItems id={id} transType={transType} taxTypeId={taxTemplateId} params={params} poItems={poItems} setPoItems={setPoItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                        :
                        (
                          transType.toLowerCase().includes("fabric")
                            ?
                            <FabricPoItems id={id} transType={transType} taxTypeId={taxTemplateId} params={params} poItems={poItems} setPoItems={setPoItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                            :
                            <AccessoryPoItems id={id} transType={transType} taxTypeId={taxTemplateId} params={params} poItems={poItems} setPoItems={setPoItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                        )
                      }
                    </fieldset>
                    <button className="text-sm bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition p-1 ml-5 rounded"
                      onClick={() => {
                        if (!taxTemplateId) {
                          toast.info("Please Select Tax Template !", { position: "top-center" })
                          return
                        }
                        setSummary(true)
                      }}>
                      View Po Summary
                    </button>
                  </div>
                  <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-2`}>
                    <FormReport
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      setId={setId}
                      tableHeaders={tableHeaders}
                      tableDataNames={tableDataNames}
                      data={allData?.data}
                      loading={
                        isLoading || isFetching
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}