import React from 'react'

const ColourFastnessLegend = ({testResults}) => {
  return (
    <>
 <div className="ml-9 mt-10">
        {

          testResults?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults).length > 0 ||
            testResults?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults).length > 0 ||
            testResults?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults).length > 0 ||
            testResults?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults).length > 0 ?
            <table className='border border-gray-600 w-2/4'>
              <tbody>
                <tr className='border border-gray-500 text-xs text-center'>
                  <td className='border border-gray-500 text-xs p-0.5' colSpan={2}>
                    COLOUR CHANGE / STAINING RATING
                  </td>

                </tr>
                <tr>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    Grade 5
                  </td>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    NO CHANGE
                  </td>
                </tr>

                <tr>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    Grade 4
                  </td>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    SLIGHT COLOUR CHANGE / STAINING
                  </td>
                </tr>

                <tr>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    Grade 3
                  </td>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    NOTICEABLE COLOUR CHANGE / STAINING
                  </td>
                </tr>
                <tr>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    Grade 2
                  </td>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    CONSIDERABLE COLOUR CHANGE / STAINING
                  </td>
                </tr>
                <tr>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    Grade 1
                  </td>
                  <td className='border border-gray-500 text-xs p-0.5'>
                    SEVERE COLOUR CHANGE / STAINING
                  </td>
                </tr>
              </tbody>

            </table> : ""

        }
      </div>

    </>
  )
}

export default ColourFastnessLegend