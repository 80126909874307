import React from 'react'
import { findFromList, getImageUrlPath, viewBase64String } from "../../../../Utils/helper";
import { Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import tw from '../../../../Utils/tailwind-react-pdf';

const WashCareDataPrint = ({washCareData,value,sampleItem }) => {

  let washCareImageArray=washCareData?.data.flatMap(val=>val.WashCare)
 
 
  return (
    <View>

      <View className=' '>
        <View className=' p-2 text-center'>
           {/* <img src={viewBase64String(data?.data?.imageBase64)} alt="" />  */}
           <Image style={tw("w-12")} src={getImageUrlPath(findFromList(value.washCareId,washCareImageArray,"image"))}/>
            </View>

       
      </View>
    </View>
  )
}

export default WashCareDataPrint