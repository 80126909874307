import React from 'react'
import TestedImages from './TestedImages'

const SamplewiseTestedImages = ({ testImg, testImgIndex, passFail, splitTest }) => {
  let TestImages
  if (splitTest == "ACCREDITATION") {
    TestImages = testImg?.images?.filter(val => val.isAccreditationTestedImage)
  }
  else if (splitTest == "NONACCREDITATION") {
    TestImages = testImg?.images?.filter(val => !val.isAccreditationTestedImage)
  }
  else {
    TestImages = testImg?.images
  }

  return (
    <>
      {
        TestImages.length > 0 ?
          <>
            <div className=' mt-1 uppercase font-bold'>

              {testImg?.sampleId.slice(-1) >= 0 ? "" : testImg?.sampleId.slice(-1)}
            </div>

            <div className='flex flex-row flex-wrap justify-center items-center  gap-x-3'>
              {TestImages?.map((img, imgIndex) => {
                return <TestedImages key={imgIndex} img={img} imgIndex={imgIndex} />
              })}
            </div>
          </>
          : <></>
      }

    </>
  )
}

export default SamplewiseTestedImages