import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import tw from '../../../../Utils/tailwind-react-pdf'

const ResultValue = ({ item, testIndex, test, reportData }) => {

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function checkIsData(testId, allResults) {
    let findData = allResults?.find(i => parseInt(i.testId) === parseInt(testId))?.data

    return findData
  }

  return (
    <>

      {
        item?.isRemovalForce ? (
          <View style={tw('w-full flex flex-row gap-x-3 overflow-auto ')}>
            <Text style={tw('capitalize w-[25%]')}>
              {test?.typeOfComponent}
            </Text>
            <Text style={tw('capitalize w-[25%]')}>
              {test?.areaOfComponent}
            </Text>
            <Text style={tw('capitalize w-[25%]')}>
              {test?.reportValue}{(!(test?.result && test?.isResult)) ? "*" : ""}

            </Text>
            <Text style={tw('capitalize w-[25%]')}>

              {test?.modeOfFailure}
            </Text>
          </View>
        ) :
          <Text style={tw(`${(!(test?.result) && (test?.isResult) && (!checkIsData(item?.testId, reportData?.testDetailsSummary?.allResults))) ? "font-bold" : ""} capitalize`)} >
            {(test?.decimalValue && isNumeric(test?.reportValue)) ? parseFloat(test?.reportValue).toFixed(test?.decimalValue) : test?.reportValue}
            {test?.isResult ? ((!(test?.result) && (!checkIsData(item?.testId, reportData?.testDetailsSummary?.allResults))) ? "*" : "") : ""}
          </Text>
      }
    </>
  )
}

export default ResultValue