
import React from 'react'
import GenerateInvoiceNumber from '../PrintFormat-InvoiceReport/GenerateInvoiceNumber'
import moment from 'moment'
import tw from '../../../Utils/tailwind-react-pdf'
import empty from "../../../assets/empty.png"
import { getImageUrlPath } from '../../../Utils/helper'


import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PageWrapper from './PageWrapper'


const InvoiceContent = ({ data, testDetailsData }) => {

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
      fontFamily: 'Helvetica',
    },
    title: {
      fontSize: 9,

      textAlign: 'center',
      marginBottom: 20,

    },
    subtitle: {
      fontSize: 9,
      textAlign: 'center',
      marginBottom: 10,
    },
    grid: {
      flexDirection: 'row',
      justifyContent: "space-between",
      paddingLeft: 30,
      paddingRight: 40,
      paddingVertical: 3,
      flexWrap: "wrap",
      flexGrow: 1,
    },
    label: {
      fontWeight: 'bold',
      marginRight: 5,
    },
    value: {
      marginLeft: 5,
    },
    section: {
      flexDirection: 'column',
      justifyContent: "space-between",
      paddingLeft: 29,
      marginBottom: 2,
      marginTop: 2,

    },
    box: {

      padding: 2

    },
    toAddress: {
      marginBottom: 6,
      marginTop: 10,
      paddingLeft: 30,
    },
    table: {
      border: 0.5,
      borderColor: '#000',
      width: "100%",
      marginBottom: 10,
      marginTop: 10,

    },
    row: {
      alignItems: 'center',

      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%"
    },
    cell: {
      height: 30,
      padding: 5,
      fontSize: 8,
      fontWeight: 15,
      borderRight: 0.5,
      borderBottom: 0.5,
      borderColor: '#000',
      flexGrow: 1,
      width: 25,
      textAlign: "center",
      alignItems: 'flex-middle',
      justifyContent: 'flex-middle',

    },

    container: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      marginRight: 32,
      marginTop: 2,
      fontSize: 8,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 5,
    },
    analysisCharge: {
      marginBottom: 10,
    },
    mb2: {
      marginBottom: 2,
      marginTop: 2,
    },



    list: {
      fontSize: 10,
      marginBottom: 5,
    },
    bold: {
      fontWeight: 'bold',
    },
    details: {
      marginBottom: 10,
    },


  });

  const imageWidth = "100px"
  const imageHeight = "50px";

  const image = data?.data?.authorizedSignature ? data?.data?.authorizedSignature : ""


  return (
    <>
      <PageWrapper>

        <View style={tw("px-9")}>

          <Text style={[styles.title, { fontSize: 12, fontWeight: 35, marginTop: 5 }]}>{(data?.data?.partyIsGst || data?.data?.partyIsOutside) ? "TAX INVOICE" : "INVOICE"}</Text>
          <View style={tw("w-full px-9")}>





            <View style={tw("flex flex-row  justify-start w-full ml-11")} >

              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Invoice No:</Text>
                <Text style={tw("text-xs")}>{data?.data.invoiceNo}</Text>
              </View>
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Invoice Date:</Text>
                <Text style={tw("text-xs")}>{moment.utc(data?.data?.invoiceDate).format("DD-MM-YYYY")} </Text>
              </View>
            </View>






            <View style={tw("flex flex-row  justify-start w-full ml-11 mt-1")} >

              <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>STATE CODE:</Text>
                <Text style={tw("text-xs")}>33</Text>
              </View>
              <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>HSN CODE:</Text>
                <Text style={tw("text-xs")}>998346 </Text>
              </View>
            </View>


            <View style={tw("flex flex-row flex-wrap  w-1/2 ml-11 mt-2")}>
              <Text style={[tw("text-xs font-bold")]}>To :</Text>
              <Text style={tw("text-xs")}>{data?.data?.toCompany?.name} ---{data?.data?.toCompany?.address}</Text>
            </View>

            <View style={tw("flex flex-row flex-wrap  w-1/2 ml-11 mt-2")}>
              <Text style={[tw("text-xs font-bold")]}>ContactName : </Text>
              <Text style={tw("text-xs")}>{data?.data?.toCompany?.contactDetails?.map(val => val.contactPersonName).join("/")}</Text>
            </View>

            <View style={tw("flex flex-row flex-wrap  w-1/2 ml-11 mt-1")}>
              <Text style={[tw("text-xs font-bold")]}>Email.Id : </Text>
              <Text style={tw("text-xs")}>{data?.data?.toCompany?.contactDetails?.map(val => val.email).join("/")}</Text>
            </View>









            <View style={tw("flex gap-y-1 w-full ml-11 mt-2 ")}>

              <View style={tw("flex flex-row flex-wrap  justify-start w-full ")} >

                <View style={tw("flex flex-row flex-wrap  w-1/2 ")}>
                  <Text style={[tw("text-xs font-bold ")]}>Buyer Name :</Text>
                  <Text style={tw("text-xs")}>{data?.data?.buyerName ? data?.data?.buyerName : ""}</Text>
                </View>
                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Test Report No:</Text>
                  <Text style={tw("text-xs")}>{data?.data?.testReportNo ? data?.data?.testReportNo : ""} </Text>
                </View>
              </View>

              <View style={tw("flex flex-row flex-wrap justify-start w-full  ")} >

                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Color:</Text>
                  <Text style={tw("text-xs")}>{data?.data?.color ? data?.data?.color : ""}</Text>
                </View>
                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Reg.Date:</Text>
                  <Text style={tw("text-xs")}> {moment.utc(data?.data?.regDate ? data?.data?.regDate : "").format("DD-MM-YYYY")} </Text>
                </View>
              </View>



              <View style={tw("flex flex-row  flex-wrap justify-start w-full  ")} >

                <View style={tw("flex flex-row flex-wrap  w-1/2 ")}>
                  <Text style={[tw("text-xs font-bold")]}>Service:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.service ? data?.data?.service : ""}  </Text>
                </View>
                <View style={tw("flex flex-row flex-wrap w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Description:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.sampleDescription ? data?.data?.sampleDescription : ""} </Text>
                </View>
              </View>


              <View style={tw("flex flex-row flex-wrap justify-start w-full ")} >

                <View style={tw("flex flex-row flex-wrap w-1/2 ")}>
                  <Text style={[tw("text-xs font-bold")]}>Style. No:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.styleNo ? data?.data?.styleNo : ""}</Text>
                </View>
                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Batch. No:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.batchNo ? data?.data?.batchNo : ""}</Text>
                </View>
              </View>

            </View>

          </View>

          {
            data?.data?.typeOfInvoice == "PACKAGE" ?

              <View style={styles.table}>

                <View style={[styles.row, {
                  borderBottomWidth: 2,
                }]}>
                  <Text style={[{
                    padding: 5, textAlign: "center",

                    fontSize: 8,
                    borderRight: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 8, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>S.No</Text>
                  <Text style={[{
                    padding: 5, textAlign: "center",
                    fontSize: 8,
                    borderRight: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 70, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Package Name</Text>
                  <Text style={[{
                    padding: 5, textAlign: "center",

                    fontSize: 8,
                    borderRight: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Quantity</Text>
                  <Text style={[styles.cell, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Price</Text>
                  <Text style={[styles.cell, { fontWeight: 900, fontFamily: "Times-Bold" }]}>SurCharge Price</Text>
                  <Text style={[styles.cell, , { fontWeight: 900, fontFamily: "Times-Bold" }]}>Discount</Text>
                  <Text style={[styles.cell, , { fontWeight: 900, fontFamily: "Times-Bold" }]}>Amount</Text>


                </View>


                {(data?.data?.packageDetails ? data?.data?.packageDetails : []).map((item, index) =>
                  <View key={index} style={styles.row}>
                    <Text style={[{
                      padding: 5, textAlign: "center",

                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 8, height: 30,
                    }]}>{index + 1}</Text>
                    <Text style={[{
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 70, height: 30,
                    }]}>{item.packageName}</Text>
                    <Text style={[{
                      padding: 5, textAlign: "right",
                      borderBottom: 0.5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item?.qty}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{item.price}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{item.surChargePrice}
                    </Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{item.discount}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{(!item.qty || !item.price) ? 0 : (((parseFloat(item.qty) * parseFloat(item.price)) + parseFloat(item?.surChargePrice || 0)) - parseFloat(item.discount)).toFixed(2)}</Text>

                  </View>
                )}


                {
                  data?.data?.amendment &&
                  <View style={styles.row}>
                    <Text style={[{
                      padding: 5, textAlign: "center",

                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 8, height: 30,
                    }]}>1</Text>
                    <Text style={[{
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 70, height: 30,
                    }]}>Amendment Charge</Text>
                    <Text style={[{
                      padding: 5, textAlign: "right",
                      borderBottom: 0.5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{data?.data?.amendmentQty || 0}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{data?.data?.amendmentPrice || 0}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}></Text>

                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{data?.data?.amendmentDiscount}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{(!data?.data?.amendmentQty || !data?.data?.amendmentPrice) ? 0 : ((parseFloat(data?.data?.amendmentQty) * parseFloat(data?.data?.amendmentPrice)) - (data?.data?.amendmentDiscount ? data?.data?.amendmentDiscount : 0)).toFixed(2)}</Text>

                  </View>


                }


              </View>
              : ""

          }

          {
            (testDetailsData ? testDetailsData : []).length > 0 ? (

              <View style={styles.table}>
                <View style={[styles.row, { borderBottomWidth: 2 }]}>
                  <Text style={[{
                    padding: 5,
                    fontSize: 8,
                    fontWeight: "bold",
                    textAlign: "center",
                    borderRight: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 8, height: 30,

                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>S.No</Text>
                  <Text style={[{
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: 5,
                    fontSize: 8,
                    borderRight: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 70, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Test</Text>
                  <Text style={[{
                    textAlign: "center",
                    padding: 5,
                    fontWeight: "bold",
                    fontSize: 8,
                    borderRight: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Quantity</Text>
                  <Text style={[styles.cell, {
                    textAlign: "center",
                    fontWeight: "bold",
                  }]}>Rate</Text>
                  <Text style={[styles.cell, {
                    textAlign: "center",
                    fontWeight: "bold",
                  }]}>SurchargePrice</Text>
                  <Text style={[styles.cell, {
                    textAlign: "center",
                    fontWeight: "bold",
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Discount</Text>
                  <Text style={[styles.cell, {
                    textAlign: "center",
                    fontWeight: "bold",
                  }]}>Amount</Text>
                </View>


                {testDetailsData?.filter(val => val.qty != 0)?.map((item, index) => (
                  <View key={index} style={styles.row}>
                    <Text style={[{
                      textAlign: "center",
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 8, height: 30,
                    }]}>{index + 1}</Text>
                    <Text style={[{
                      padding: 4,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 70, height: 30,
                    }]}>{item.testName}</Text>
                    <Text style={[{
                      textAlign: "right",
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item?.qty}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{item.price}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{item?.surChargePrice || 0}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",
                    }]}>{item.discount}</Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",
                    }]}>{(!item.qty || !item.price) ? 0 : (((parseFloat(item.qty) * parseFloat(item.price)) + parseFloat(item?.surChargePrice || 0)) - parseFloat(item.discount)).toFixed(2)}</Text>
                  </View>
                ))}





                {
                  (data?.data?.amendment && data?.data?.amendmentQty > 0) &&
                  <View style={styles.row}>
                    <Text style={[{
                      padding: 5, textAlign: "center",

                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 8, height: 30,
                    }]}>1
                    </Text>
                    <Text style={[{
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 70, height: 30,
                    }]}>Amendment Charge
                    </Text>
                    <Text style={[{
                      padding: 5, textAlign: "right",
                      borderBottom: 0.5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{data?.data?.amendmentQty || 0}
                    </Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{data?.data?.amendmentPrice || 0}
                    </Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>
                    </Text>

                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{data?.data?.amendmentDiscount}
                    </Text>
                    <Text style={[styles.cell, {
                      textAlign: "right",

                    }]}>{(!data?.data?.amendmentQty || !data?.data?.amendmentPrice) ? 0 : ((parseFloat(data?.data?.amendmentQty) * parseFloat(data?.data?.amendmentPrice)) - (data?.data?.amendmentDiscount ? data?.data?.amendmentDiscount : 0)).toFixed(2)}</Text>

                  </View>


                }

              </View>
            )
              : ""
          }


          <View style={tw("mt-1")}>
            <View style={styles.container}>
              <View style={styles.gridContainer}>
                <Text style={styles.analysisCharge}>Analysis Charge:</Text>
                {data?.data?.taxDetails?.map((val, index) => (
                  <Text key={index}>
                    {`${val?.taxName} @ ${val.taxPercent} % & ${"TDS"} @ ${val?.tdsPercent} % : ${parseFloat(val?.taxAmount).toFixed(2)}`}
                  </Text>
                ))}
                <Text style={styles.mb2}>
                  RoundOff: {data?.data?.roundOff ? parseFloat(data?.data?.roundOff).toFixed(2) : 0.00}
                </Text>


                <Text style={{ marginTop: 3 }}>NetCharge: {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}</Text>
                <Text style={{ marginTop: 2 }}>Grand Total: {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}</Text>
              </View>
            </View>
          </View>







          <View>
            <Text style={{ fontSize: 10, fontWeight: 35, marginBottom: 3, marginTop: 15, color: "black", textAlign: "left", fontWeight: 900, fontFamily: "Times-Bold" }}>TERMS AND CONDITIONS : </Text>
            <View style={styles.details}>
              <Text style={styles.list}>
                1. 2% interest per month will be charged for any invoice remained unpaid for more than a month from the date of issue of the Invoice
              </Text>
              <Text style={styles.list}>
                2. No claim against this invoice will be entertained unless brought to our notice in writing within 7 days of receipt of this invoice
              </Text>
              <Text style={styles.list}>
                3. Our report for which this invoice is issued includes all the tests requested by you and the results thereof. You shall have thirty days from receipt of report to request
                additional testing of the samples or to notify us of any errors or omissions relating to our report provided, however, such notice shall be in writing and shall
                specifically address the issue you wish to raise. A failure to raise such issue within the prescribed time shall constitute your unqualified acceptance of the
                completeness of report, the tests conducted and the correctness of the report contents
              </Text>
              <Text style={styles.list}>
                4. Prepayment may be enforced when the account is overdue.
              </Text>
              <Text style={styles.list}>
                5. Any dispute arising out of this transaction / Contract will be referred to institutional arbitration council of Tirupur as per the rules and regulations of Arbitration
                Council of Tirupur and the award passed will be binding on us.
              </Text>
              <Text style={styles.list}>
                6. The test report is as per the sample provided by you / your representation.
              </Text>
            </View>
          </View>






          <View style={tw("ml-5")}>

            <View style={tw("flex flex-row gap-x-2 w-1/2 ")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>OUR BANK DETAILS : </Text>
              <Text style={tw("text-xs")}></Text>
            </View>

            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-2")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>ACCOUNT NAME:</Text>
              <Text style={tw("text-xs")}> {data?.data?.bankDetails?.accountName ? data?.data?.bankDetails?.accountName : ""}  </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>ACCOUNT NO:</Text>
              <Text style={tw("text-xs")}>    {data?.data?.bankDetails?.accountNo ? data?.data?.bankDetails?.accountNo : ""} </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>IFSC.CODE:</Text>
              <Text style={tw("text-xs")}>   {data?.data?.bankDetails?.ifsc ? data?.data?.bankDetails?.ifsc : ""} </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>BRANCH:</Text>
              <Text style={tw("text-xs")}>   {data?.data?.bankDetails?.branch ? data?.data?.bankDetails?.branch : ""} </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}> GST NO :</Text>
              <Text style={tw("text-xs")}> 33AADCS8162K1Z4 </Text>
            </View>





          </View>





          <View style={tw("-mt-5")}>


            <View style={tw("flex flex-row justify-end mt-2 mr-3")}>


              {Boolean(image) ?
                <Image style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
                  src={getImageUrlPath(image)}
                />
                :
                <Image src={empty} style={tw("w-24 text-center")} />
              }

            </View>

            <View style={tw("flex flex-row justify-end mt-1")}>



              <Text style={styles.label}>Verified By & Authorized By : </Text>

            </View>
          </View>


        </View>
      </PageWrapper>

    </>
  )
}

export default InvoiceContent




