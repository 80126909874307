import React from 'react'
import ReqValue from './ReqValue'

const ResultReq = ({ req, reqIndex }) => {
  return (
    <>
      <td className='p-1 text-right pl-16'>

        ({
          req?.result?.map((test, testIndex) => {
            return <ReqValue key={testIndex} test={test} testIndex={testIndex} />
          }
          )
        })
      </td>
    </>
  )
}

export default ResultReq