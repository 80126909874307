import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react'
import { Delete } from '../../../Buttons';
import { useTestedImageDeleteMutation, useTestedImageUploadMutation } from '../../../redux/LabServices/RegistrationServices';
import { toast } from 'react-toastify';
import { getImageUrlPath } from '../../../Utils/helper';
import empty from "../../../assets/empty.png"
import { CheckBox } from '../../../Inputs';


const TestedImageUpload = ({ matchingSampleDetails, sampleId, testedImages, setTestedImages, setIsTestedImageOpen, }) => {
    const [upload] = useTestedImageUploadMutation();
    const [removeData] = useTestedImageDeleteMutation();

    // useEffect(() => {
    //     setTestedImages(prev => {
    //         let newObj = structuredClone(prev)
    //         newObj = matchingSampleDetails?.TestedImage ? matchingSampleDetails.TestedImage : []
    //         return newObj
    //     })

    // }, [matchingSampleDetails, setTestedImages])

    useEffect(() => {
        setTestedImages(prev => {
            let newObj = structuredClone(prev)
            newObj = matchingSampleDetails?.TestedImage ? matchingSampleDetails.TestedImage : []
            return newObj
        })

    }, [matchingSampleDetails, setTestedImages])


    console.log(matchingSampleDetails, "matchingSampleDetails")

    function handleOnChangeImage(file, index, field) {

        setTestedImages(prev => {
            const newObj = structuredClone(prev)
            newObj[index][field] = file
            return newObj
        })
    }

    const handleUpload = async (text, picture, comment, isAccreditation = false) => {

        try {
            const formData = new FormData()
            if (picture instanceof File) {
                formData.append("image", picture);
            }
            formData.append("comment", comment);
            formData.append("isAccreditationTestedImage", isAccreditation);
            let returnData = await upload({ id: sampleId, body: formData }).unwrap();

            toast.success(text + " " + "Successfully");
        } catch (error) {
            console.log("handle");
        }

    };

    const deleteData = async (id) => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)

                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };


    const imageFormatter = (picture) => {
        if (picture) {
            if ((picture) instanceof File) {
                return URL.createObjectURL(picture)
            } else {
                return getImageUrlPath(picture)
            }
        }
        return null
    }

    return (
        <div>
            <div className='grid grid-cols-1  w-full mt-9 justify-items-center'>
                <table className='table-auto w-full mt-3'>
                    <thead>
                        <tr className="border border-black bg-blue-200">
                            <th className="text-xs w-5 border border-black capitalize p-2">
                                s.no
                            </th>
                            <th className="W-28 text-xs  border border-black capitalize p-2">
                                IsAccreditation
                            </th>
                            <th className="W-28 text-xs  border border-black capitalize p-2">
                                comment
                            </th>
                            <th className=" W-40 text-xs  border border-black capitalize p-2">
                                Image
                            </th>
                            <th className=" W-16 text-xs  border border-black capitalize p-2"  >
                                <button
                                    type='button' className={`text-green-700`}
                                    onClick={() => {

                                        setTestedImages(prev => {

                                            let newPrev = structuredClone(prev);
                                            newPrev?.push({

                                                comment: "",
                                                picture: "",
                                                isAccreditationTestedImage: false,
                                                isAccreditation: ""


                                            })
                                            return newPrev

                                        })
                                    }}
                                > {<FontAwesomeIcon icon={faUserPlus} />}
                                </button>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(testedImages ? testedImages : []).map((item, index) =>
                            <tr className="border border-black " key={index}>
                                <td className='text-xs text-center  border border-black'>
                                    {index + 1}
                                </td>
                                <td className='text-xs text-center  border border-black'>

                                    <input type="checkbox" className='mx-2 py-2' checked={item?.isAccreditationTestedImage} onChange={(e) => handleOnChangeImage(e.target.checked, index, "isAccreditationTestedImage")} />


                                </td>

                                <td className='text-xs h-12 border border-black'>
                                    <input className="w-full  h-full p-1 uppercase" name="" type="text" value={item.comment}
                                        onChange={(e) =>
                                            handleOnChangeImage(e.target.value, index, "comment")
                                        }
                                        onBlur={(e) => {
                                            handleOnChangeImage(e.target.value, index, "comment");
                                        }
                                        }
                                    />
                                </td>
                                <td className='text-xs text-center  border border-black p-1'>
                                    {(item?.picture)
                                        ?
                                        <img className='w-12 h-12 ml-24 '
                                            src={imageFormatter(item?.picture)} />
                                        :
                                        <img src={empty} className='w-36' />
                                    }
                                </td>
                                <td className='text-xs text-center border border-black'>
                                    {item.picture ?
                                        <>
                                            {((item.picture) instanceof File)
                                                ?
                                                <div>
                                                    <div>
                                                        <button className='w-full bg-green-200 border border-gray-700 text-black p-2  rounded-md' onClick={() => handleUpload("Uploaded", item?.picture, item?.comment, item?.isAccreditationTestedImage)}>Upload</button>

                                                    </div>
                                                    OR
                                                    <div>
                                                        <div>
                                                            <div className='flex items-center border border-gray-700 bg-green-200 hover:border-lime-500 rounded-md h-8 px-1'>
                                                                <input type="file" id={`profileImage${index}`} className='hidden' onChange={(e) => {
                                                                    handleOnChangeImage(e.target.files[0], index, "picture")
                                                                }} />
                                                                <label htmlFor={`profileImage${index}`} className="text-xs w-full"> Browse</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <Delete
                                                    onClick={() => {
                                                        handleOnChangeImage(null, index, "picture")
                                                        deleteData(item?.id)
                                                    }}
                                                />
                                            }
                                        </>
                                        :
                                        <div>
                                            <div className='flex items-center border border-gray-700 hover:border-lime-500 rounded-md h-8 px-1'>
                                                <input type="file" id={`profileImage${index}`} className='hidden' onChange={(e) => {
                                                    handleOnChangeImage(e.target.files[0], index, "picture")
                                                }} accept='image/png' />
                                                <label htmlFor={`profileImage${index}`} className="text-xs w-full"> Browse</label>
                                            </div>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TestedImageUpload