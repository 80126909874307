import React from 'react'
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices'
import { findFromList, params } from "../../../Utils/helper"
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices'
import { Loader } from '../../../Basic/components'
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices'
const SampleTestParametersPrint = ({testList,methodList,conditionList, testDetails, packageName, groupName }) => {

 
  if (!testList?.data || !methodList?.data || !conditionList?.data) return <Loader />
  return (
    <div className=''>
      <div className="text-center p-1">
        {/* <h1 className='font-bold'>Package : {packageName}</h1> */}
        <h1 className='font-bold p-1'>Group : {groupName}</h1>
      </div>
      <table className=' border border-gray-500 w-full'>
        <thead className='border border-gray-500 '>
          <tr className=''>
            <th className='border border-gray-500 p-1 w-2'>
              S.NO
            </th>
            <th className='border border-gray-500 p-1 w-2/6'>
              Test Parameter
            </th>
            <th className='border border-gray-500 p-1 w-1/6'>
              Methods
            </th>
            <th className='border border-gray-500 p-1 w-2/6'>
              Condition
            </th>
            <th className='border border-gray-500 p-1 w-2/6'>
              Result
            </th>
          </tr>
        </thead>
        <tbody>
          {testDetails.map((item, index) =>
            <tr key={item.id}>
              <td className='border border-gray-500'>
                {index + 1}
              </td>
              <td className='border border-gray-500 text-xs'>
                {findFromList(item.testId, testList.data, "name")}
              </td>
              <td className='border border-gray-500 text-xs'>
                {findFromList(item.methodId, methodList.data, "name")}
              </td>
              <td className='border border-gray-500 text-xs'>
                {findFromList(item.conditionId, conditionList.data, "name")}
              </td>
              <td className='border border-gray-500 text-xs'>
               
              </td>
            </tr>
          )}
           
          
          
        </tbody>
      </table>
     
    </div>
  )
}

export default SampleTestParametersPrint