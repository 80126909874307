import React, { useEffect, useState } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { toast } from "react-toastify"
import { Loader } from '../../../Basic/components';
import { VIEW } from '../../../icons';
import secureLocalStorage from 'react-secure-storage';

const FabricPoItems = ({ id, transType, programDetails, setProgramDetails, readOnly, setRawMaterial}) => {
  const [greyObj, setGreyObj] = useState({});
  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };
  const handleInputChange = (value, index, field) => {
    console.log(field, index, value);
    const newBlend = structuredClone(programDetails);
    newBlend[index][field] = value;
    if (field === "fabricId") {
      newBlend[index]["taxPercent"] = findYarnTax(value)
    }
    setProgramDetails(newBlend);
  };

  useEffect(() => {
    if (id || programDetails.length !== 0) return
    setProgramDetails(Array.from({ length: 5 }, i => {
      return { yarnId: "", qty: "0.000", inwardQty: "0.000", colorId: greyObj ? greyObj.id : "", uomId: "", price: "0.00", discountType: "Percentage", discountValue: "0.00" }
    }))
  }, [greyObj, transType, setProgramDetails, programDetails])

  useEffect(() => {
    setProgramDetails(prev => prev.map(item => { item["colorId"] = greyObj ? greyObj.id : ""; return item }))
  }, [greyObj, setProgramDetails])

  const addRow = () => {
    if (programDetails.length >= fabricList.data.length) {
      toast.info("No More Items", { position: 'top-center' })
      return
    }
    const newRow = { fabricId: "", qty: "", colorId: greyObj ? greyObj.id : "", uomId: "", gaugeId: "", designId: "", gsmId: "", loopLengthId: "", kDiaId: "", fDiaId: "", qty: "", price: "" };
    setProgramDetails([...programDetails, newRow]);
  };
  const handleDeleteRow = id => {
    setProgramDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
  };

  const { data: fabricList } =
    useGetFabricMasterQuery({ params });

  const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
    useGetColorMasterQuery({ params });

  const { data: uomList } =
    useGetUnitOfMeasurementMasterQuery({ params });

  const { data: gaugeList } =
    useGetGaugeQuery({ params });

  const { data: designList } =
    useGetdesignQuery({ params });

  const { data: gsmList } =
    useGetgsmQuery({ params });

  const { data: loopLengthList } =
    useGetgsmQuery({ params });

  const { data: diaList } =
    useGetDiaQuery({ params });

  function findIdInYarnBlend(id) {
    return programDetails ? programDetails.find(blend => parseInt(blend.fabricId) === parseInt(id)) : false
  }
  function findYarnTax(id) {
    if (!fabricList) return 0
    let yarnItem = fabricList.data.find(item => parseInt(item.id) === parseInt(id))
    return yarnItem ? yarnItem.taxPercent : 0
  }

  if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList) return <Loader />
  return (
    <>
      <div className={`w-full p-2`}>
        <table className="border border-gray-600 text-xs table-fixed w-full">
          <thead className='bg-blue-200 top-0'>
            <tr>
              <th className="table-data border border-gray-600 text-center">S.no</th>
              <th className="table-data border border-gray-600">Items<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600">Design<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">Gauge<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">LL<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">GSM<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">K Dia<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">F Dia<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">UOM<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">Quantity<span className="text-red-500">*</span></th>
              <th className="table-data border border-gray-600 ">View Delivery Details</th>
              {readOnly &&
                <th>
                  <div onClick={addRow}
                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                    {PLUS}
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
            {programDetails.map((row, index) => (
              <tr key={index} className="w-full">
                <td className="table-data border border-gray-600 w-2 text-center">
                  {index + 1}
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.fabricId} onChange={(e) => handleInputChange(e.target.value, index, "fabricId")}>
                    <option hidden>
                      Select
                    </option>
                    {fabricList.data.map((blend) =>
                      <option value={blend.id} key={blend.id} >
                        {blend.aliasName}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.designId} onChange={(e) => handleInputChange(e.target.value, index, "designId")}>
                    <option hidden>
                      Select
                    </option>
                    {designList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.gaugeId} onChange={(e) => handleInputChange(e.target.value, index, "gaugeId")}>
                    <option hidden>
                      Select
                    </option>
                    {gaugeList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.loopLengthId} onChange={(e) => handleInputChange(e.target.value, index, "loopLengthId")}>
                    <option hidden>
                      Select
                    </option>
                    {loopLengthList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left  rounded py-1' value={row.gsmId} onChange={(e) => handleInputChange(e.target.value, index, "gsmId")}>
                    <option hidden>
                      Select
                    </option>
                    {gsmList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.kDiaId} onChange={(e) => handleInputChange(e.target.value, index, "kDiaId")}>
                    <option hidden>
                      Select
                    </option>
                    {diaList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.fDiaId} onChange={(e) => handleInputChange(e.target.value, index, "fDiaId")}>
                    <option hidden>
                      Select
                    </option>
                    {diaList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.uomId} onChange={(e) => handleInputChange(e.target.value, index, "uomId")}>
                    <option hidden>
                      Select
                    </option>
                    {uomList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select>
                </td>
                <td className='border border-black'>
                  <input
                    type="text"
                    className="text-right rounded py-1 px-1 w-full"
                    value={(!row.qty) ? 0 : row.qty}
                    disabled={readOnly}
                    onChange={(e) =>
                      handleInputChange(e.target.value, index, "qty")
                    }
                    onBlur={(e) =>
                      handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                    }
                  />
                </td>
                <td className='border border-black '>
                  <button
                    className="text-center rounded py-1 w-full"
                    onClick={()=> setRawMaterial(true)}
                  >
                    {VIEW}
                  </button>
                </td>
                {readOnly
                  &&
                  <td className='border border-gray-600'>
                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                      {DELETE}
                    </div>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>

  )
}

export default FabricPoItems