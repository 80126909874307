import React, { useContext, useRef } from "react";

import secureLocalStorage from "react-secure-storage";
import { DropdownInput } from "../../../Inputs";
import { dropDownListObject } from "../../../Utils/contructObject";
import { useGetSampleDescriptionOneMasterQuery } from "../../../redux/LabServices/SampleDescriptionOneMasterServices";
import { useGetSampleDescriptionTwoMasterQuery } from "../../../redux/LabServices/SampleDescriptionTwoMasterServices";
import { useGetSampleDescriptionThreeMasterQuery } from "../../../redux/LabServices/SampleDescriptionThreeMasterServices";
import { useGetSampleDescriptionFourMasterQuery } from "../../../redux/LabServices/SampleDescriptionFourMasterServices";
import { Loader } from "../../../Basic/components";

const SampleTypeSelectionSampleWise = ({ id, readOnly, sampleDetails, setSampleDetails, currentActiveTab, setIsSampleTypeOpen, isResultEntryStarted }) => {


    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    let index = sampleDetails.findIndex(item => item.sampleId == currentActiveTab)
    console.log(index, "index")

    const { data: sampleOneList, isLoading: isSampleOneLoading, isFetching: isSampleOneFetching } =
        useGetSampleDescriptionOneMasterQuery({ params });

    const { data: sampleTwoList, isLoading: isSampleTwoLoading, isFetching: isSampleTwoFetching } =
        useGetSampleDescriptionTwoMasterQuery({ params });

    const { data: sampleThreeList, isLoading: isSampleThreeLoading, isFetching: isSampleThreeFetching } =
        useGetSampleDescriptionThreeMasterQuery({ params });

    const { data: sampleFourList, isLoading: isSampleFourLoading, isFetching: isSampleFourFetching } =
        useGetSampleDescriptionFourMasterQuery({ params });

    return (
        <div>
            {isSampleOneLoading || isSampleOneFetching || isSampleTwoLoading || isSampleTwoFetching ||
                isSampleThreeLoading || isSampleThreeFetching || isSampleFourLoading || isSampleFourFetching
                ?
                <Loader />
                :
                <div>
                    <div className='grid grid-cols-2 my-2 gap-9'>

                        <DropdownInput name="SampleType 1"
                            options={dropDownListObject(sampleOneList?.data ? sampleOneList.data.filter(i => i.active) : [], "name", "id")}
                            value={sampleDetails[index]["sampleOneId"] ? sampleDetails[index]["sampleOneId"] : ""}
                            setValue={(value) => {
                                setSampleDetails(sampleDetails => {
                                    let newSampleDetails = structuredClone(sampleDetails)
                                    newSampleDetails[index]["sampleOneId"] = value
                                    return newSampleDetails
                                })

                            }}
                            required={true} readOnly={readOnly || isResultEntryStarted} />


                        <DropdownInput name="SampleType 2"
                            options={dropDownListObject(sampleTwoList?.data ? sampleTwoList.data.filter(i => i.active) : [], "name", "id")}
                            value={sampleDetails[index]["sampleTwoId"] ? sampleDetails[index]["sampleTwoId"] : ""}
                            setValue={(value) => {
                                setSampleDetails(sampleDetails => {
                                    let newSampleDetails = structuredClone(sampleDetails)
                                    newSampleDetails[index]["sampleTwoId"] = value
                                    return newSampleDetails
                                })

                            }}
                            required={true} readOnly={readOnly || isResultEntryStarted} />



                        <DropdownInput name="SampleType 3"
                            options={dropDownListObject(sampleThreeList?.data ? sampleThreeList.data.filter(i => i.active) : [], "name", "id")}
                            value={sampleDetails[index]["sampleThreeId"] ? sampleDetails[index]["sampleThreeId"] : ""}
                            setValue={(value) => {
                                setSampleDetails(sampleDetails => {
                                    let newSampleDetails = structuredClone(sampleDetails)
                                    newSampleDetails[index]["sampleThreeId"] = value
                                    return newSampleDetails
                                })

                            }}
                            required={true} readOnly={readOnly || isResultEntryStarted} />


                        <DropdownInput name="SampleType 4"
                            options={dropDownListObject(sampleFourList?.data ? sampleFourList.data.filter(i => i.active) : [], "name", "id")}

                            value={sampleDetails[index]["sampleFourId"] ? sampleDetails[index]["sampleFourId"] : ""}
                            setValue={(value) => {
                                setSampleDetails(sampleDetails => {
                                    let newSampleDetails = structuredClone(sampleDetails)
                                    newSampleDetails[index]["sampleFourId"] = value
                                    return newSampleDetails
                                })

                            }}
                            required={true} readOnly={readOnly || isResultEntryStarted} />






                    </div>
                    <div className=" grid justify-end">
                        <button
                            onKeyDown={e => {
                                if (e.code === "Enter") {
                                    setIsSampleTypeOpen(false)
                                }

                            }}
                            className='bg-green-400 rounded-lg p-1 w-32' onClick={() => setIsSampleTypeOpen(false)}>Done
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default SampleTypeSelectionSampleWise