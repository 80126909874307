import React from 'react'
import PageWrapper from "../PageWrapper";
import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";
import tw from "../../../../Utils/tailwind-react-pdf";

import { formatAMPM, getDateFromDateTimeForDisplay } from '../../../../Utils/helper';
import moment from 'moment';

import { TICK_ICON_PDF } from '../../../../icons';
import WashCareInstructionPrint from './WashCareInstructionPrint';

import SampleDetailsPrint from "./SampleDetailsPrint"
import SampleTestParametersPrint from './SampleTestParametersPrint';



const Content = ({ washCareData, testList, methodList, conditionList, data, groupName, groupTestDetails, sampleItem, sampleWashCareDetails }) => {
  let docIdSplit = data?.amendmentReason ? data?.docId?.split("-") : ""
  return (
    <>
      <PageWrapper>
        <View style={tw(" px-5 pt-2 pb-2 w-full")}>

          <View style={tw("flex gap-y-3")}>

            <View style={tw("flex flex-row justify-start w-full  ")} >
              <View style={tw("flex flex-row w-1/2 gap-x-2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}> Lab Report No :</Text>
                <Text style={tw("text-xs")}>  {sampleItem?.sampleId}{docIdSplit?.length > 0 ? "-" : ""}{docIdSplit?.length > 0 ? docIdSplit[1] : ""} </Text>
              </View>
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Date And Time :</Text>
                <Text style={tw("text-xs")}>{data?.createdAt ? moment(data.createdAt).format("DD-MM-YYYY") + "    " + formatAMPM(data.createdAt) : ""}</Text>
              </View>
            </View>


            <View style={tw("flex flex-row  justify-start w-full ")} >

              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Due Date : </Text>
                <Text style={tw("text-xs")}>{data?.dueDate ? moment(data.dueDate).format("DD-MM-YYYY") : ""}</Text>
              </View>
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold "), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Service :</Text>
                <Text style={tw("text-xs")}>{data?.ServiceLevel?.name}</Text>
              </View>
            </View>
            <View style={tw("flex flex-row  justify-start w-full  ")} >
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Log In By:</Text>
                <Text style={tw("text-xs")}>{data?.logIn} </Text>
              </View>


              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold "), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Buyer Name :</Text>
                <Text style={tw("text-xs")}>{data?.Buyer?.name} </Text>
              </View>

            </View>




          </View>


          <View style={tw('mt-5 ')}>
            <SampleDetailsPrint data={data} sampleItem={sampleItem} />
          </View>
          <View style={tw("mt-4 mb-2")}>
            <View style={tw("w-full")}>
              <View style={tw("border border-gray-500 p-1")}>
                <Text style={tw("text-xs p-1 underline")}>Submitted Washcare Instruction :</Text>
                <View style={tw("flex flex-row gap-x-3 ")} >
                  {sampleWashCareDetails ? <WashCareInstructionPrint washCareData={washCareData} washCareDetails={sampleWashCareDetails} sampleItem={sampleItem} /> : ""}

                </View>
                <View style={tw('w-full flex flex-row  place-content-start ml-5')}>
                  <Text>{sampleItem?.specialInstruction ? sampleItem?.specialInstruction + "" : ""}</Text>
                </View>

              </View>
            </View>
          </View>
          <View>
            <SampleTestParametersPrint testList={testList} methodList={methodList} conditionList={conditionList} groupName={groupName} testDetails={groupTestDetails ? groupTestDetails : []} packageName={data?.Package ? data?.Package.name : ""} />

          </View>


        </View>
      </PageWrapper>
      {/* <PageWrapper>
     
      </PageWrapper> */}
    </>
  )
}

export default Content