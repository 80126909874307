import React, { useEffect, useState } from 'react';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from "react-secure-storage";
import { useGetPartyQuery } from '../../../redux/ErpServices/PartyMasterServices';
import { useGetPoQuery } from '../../../redux/ErpServices/PoServices';
import { useGetStockQuery } from '../../../redux/ErpServices/StockServices';
import { findFromList } from '../../../Utils/helper';
import YarnPoItemSelection from './YarnPoItemSelection';
import FabricPoItemSelection from './FabricPoItemSelection';
import AccessoryPoItemSelection from './AccessoryPoItemSelection';
import { poTypes } from '../../../Utils/DropdownData';
import { LongDropdownInput } from '../../../Inputs';
import { dropDownListObject } from '../../../Utils/contructObject';
import { EMPTY_ICON } from "../../../icons";

const PoItemsSelection = () => {
  const [poType, setPoType] = useState("GreyYarn")
  const [supplierId, setSupplierId] = useState("")

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const { data: poList, isLoading: isPoLoading, isFetching: isPoFetching } = useGetStockQuery({ params: { branchId, active: true } })

  const { data: supplierList, isLoading: supplierLoading, isFetching: supplierFetching } =
    useGetPartyQuery({ params: { companyId, active: true } });
  

  let 
   filteredPoList = poList ? poList.data.filter(p => {
    return (p.transType === poType) 
  }) : []

  
  // if (supplierFetching || supplierLoading || isPoLoading || isPoFetching) return <Loader />

  // function filterSupplier() {
  //   let finalSupplier = []
  //   if (poType.toLowerCase().includes("yarn")) {
  //     finalSupplier = supplierList.data.filter(s => s.yarn)
  //   } else if (poType.toLowerCase().includes("fabric")) {
  //     finalSupplier = supplierList.data.filter(s => s.fabric)
  //   } else {
  //     finalSupplier = supplierList.data.filter(s => s.PartyOnAccessoryItems.length > 0)
  //   }
  //   return finalSupplier
  // }
  // let supplierListBasedOnSupply = filterSupplier()
  return (
    <div className=''>
      <div className='w-full h-full  p-2'>
      <h1 className='md:flex md:items-center md:justify-between page-heading p-2 font-bold'>Stock Registry</h1>
        <div className='flex justify-between text-center bg-blue-200 rounded-b-md mb-7 sticky top-0 '>
          <div className='p-2 rounded-lg flex items-center gap-5'>
            <div className='flex gap-10 items-center justify-center md:my-1 px-1 data'>
              <label>Po Type</label>
              <select id='dd' autoFocus name="name" className='h-6 w-40 border border-gray-500 rounded' value={poType} onChange={(e) => setPoType(e.target.value)}>
                {poTypes.map((option, index) => <option key={index} value={option.value} >
                  {option.show}
                </option>)}
              </select>
            </div>
          </div>
          {/* <div className='p-2 rounded-lg flex items-center gap-5'>
            <LongDropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} />
          </div> */}
        </div>
        <div>
          {
            filteredPoList.length !== 0 ?
              <>
                {poType.includes("Yarn") ?
                  <YarnPoItemSelection transtype={poType} poItems={filteredPoList} />
                  :
                  <>
                    {poType.includes("Fabric") ?

                      <FabricPoItemSelection transtype={poType} poItems={filteredPoList} />
                      :
                      <AccessoryPoItemSelection transtype={poType} poItems={filteredPoList} />
                    }
                  </>
                }
              </>
              :
              // <div className="text-center"> {EMPTY_ICON}No Items Found</div>
              <div className="flex justify-center items-center text-blue-900  text-3xl sm:mt-52">
              <p>{EMPTY_ICON} No Data Found...! </p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PoItemsSelection