import React, { useEffect } from 'react'
import { useState } from 'react'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";

const AccordionCustomUI = ({ stateData, BodyComponent, HeaderComponent }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(stateData)
    }, [stateData])
    function handleOpenClose(index) {
        setData(prev => {
            let newPrev = structuredClone(prev);
            newPrev[index]['isOpen'] = !newPrev[index]['isOpen'];
            return newPrev
        })
    }
    function isOpen(index) {
        return data[index]['isOpen']
    }
    return (
        <div>
            {data.map((item, index) =>
                <Accordion key={index} open={isOpen(index)}>
                    <AccordionHeader onClick={() => handleOpenClose(index)} className="bg-white">
                        <HeaderComponent item={item} />
                    </AccordionHeader>
                    <AccordionBody>
                        <div className={`${isOpen(index) ? "block" : 'hidden'}`}>
                            <BodyComponent item={item} />
                        </div>
                    </AccordionBody>
                </Accordion>
            )}
        </div>
    )
}

export default AccordionCustomUI
