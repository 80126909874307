import React, { useEffect, useState } from 'react'
import { discountTypes } from '../../../Utils/DropdownData';
import { useGetTaxTemplateByIdQuery } from '../../../redux/ErpServices/TaxTemplateServices';
import { useGetTaxTermMasterQuery } from '../../../redux/ErpServices/TaxTermMasterServices';
import { Loader } from '../../../Basic/components';
import { numToWords, titleCase } from "../../../Utils/helper";

const PoSummary = ({ poItems, readOnly, taxTypeId, isSupplierOutside, totalDiscount, setTotalDiscount }) => {
    const [formulas, setFormulas] = useState([])

    const { data, isLoading, isFetching } = useGetTaxTemplateByIdQuery(taxTypeId, { skip: !taxTypeId })

    const { data: taxTermMaster, isLoading: isTemplateTermLoading, isFetching: isTemplateTermFetching } = useGetTaxTermMasterQuery(taxTypeId, { skip: !taxTypeId })


    function getRegex(formula) {
        if (!formula) return formula
        let input = formula;
        const words = formula.match(/\{(.*?)\}/g)
        if (!words) return formula
        words.forEach(element => {
            input = input.replace(element, getFormula(element.slice(1, -1)))
        });
        return getRegex(input)
    }


    function getFormula(constant) {
        const split = constant.split("_");
        let name = split[0];
        let value = split[1];
        let formula = formulas.find(f => f.name === name)
        return formula ? formula[value.toLowerCase()] : ""
    }
    useEffect(() => {
        if (data) {
            setFormulas(data.data.TaxTemplateDetails.map(f => { return { name: (getName(f.taxTermId)), isPowise: getIsPoItem(f?.taxTermId), displayName: f.displayName, value: f.value, amount: f.amount } }))
        }

    }, [isLoading, isFetching, isTemplateTermFetching, isTemplateTermLoading, taxTypeId])

    function getName(id) {
        if (!taxTermMaster) return ""
        let data = taxTermMaster.data.find(t => parseInt(t.id) === parseInt(id))
        if (!data) return ""
        return data.name
    }

    function getFormulaByName(formulaName) {
        let formula = formulas.find(f => f.name === formulaName)
        return formula ? formula : ''
    }

    function getIsPoItem(id) {
        if (!taxTermMaster) return false
        let data = taxTermMaster.data.find(t => parseInt(t.id) === parseInt(id))
        if (!data) return false
        return data.isPoWise
    }

    function getTotalQuantity(taxTerm, valueOrAmount) {
        let calculateItems = poItems.filter(item => item.yarnId || item.fabricId || item.accessoryId)
        let formula = getRegex(getFormulaByName(taxTerm)[valueOrAmount])
        const total = calculateItems.reduce((accumulator, currentItem) => {
            let price = isNaN(parseFloat(currentItem["price"])) ? 0 : parseFloat(currentItem["price"])
            let qty = isNaN(parseFloat(currentItem["qty"])) ? 0 : parseFloat(currentItem["qty"])
            let discountType = currentItem["discountType"];
            let discountValue = isNaN(parseFloat(currentItem["discountValue"])) ? 0 : parseFloat(currentItem["discountValue"]);
            let taxPercent = isNaN(parseFloat(currentItem["taxPercent"])) ? 0 : parseFloat(currentItem["taxPercent"])
            return accumulator + eval(formula)
        }, 0)
        return total 
    }

    function handleInputChange(e) {
        setTotalDiscount(prev => {
            let newState = { ...prev }
            newState[e.target.name] = e.target.value;
            return newState
        })
    }
    if (!formulas || isFetching || isLoading) {
        return <Loader />
    }
    let discountType = totalDiscount.type
    let discountValue = totalDiscount.value ? totalDiscount.value : 0;
    let grossAmount = getTotalQuantity("GROSS", "amount")
    let netAmount = getTotalQuantity("NET", "amount")
   
    let discountAmount = totalDiscount.type === "Flat" ? discountValue : (netAmount / 100 * discountValue).toFixed(2)
    let poValue = (netAmount - discountAmount)
    let roundOff = Math.ceil(poValue)
    return (
        <div className={`bg-gray-200 rounded z-50`}>
            <table className="border border-gray-500 w-full text-xs text-start">
                <thead className="border border-gray-500">
                    <tr>
                        <th className="w-52 border border-gray-500">Tax Name</th>
                        <th className="w-28 border border-gray-500">Value</th>
                        <th className="w-28 border border-gray-500">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border border-gray-500">Gross Amount</td>
                        <td className="border border-gray-500 text-right" colSpan={2}
                        >
                            {grossAmount.toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray-500">Net Amount</td>
                        <td className="border border-gray-500 text-right" colSpan={2}
                        >
                            {netAmount.toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <td className="border border-gray-500">Discount Type</td>
                        <td className="border border-gray-500" colSpan={2}
                        >
                            <select autoFocus name='type' disabled={readOnly} className='text-left w-full rounded h-8'
                                value={totalDiscount.type}
                                onChange={(e) => { handleInputChange(e) }}
                            >
                                <option hidden>
                                    Select
                                </option>
                                {discountTypes.map((option, index) => <option key={index} value={option.value} >
                                    {option.show}
                                </option>)}
                            </select>
                        </td>
                    </tr>
                    <tr className='h-7'>
                        <td className="border border-gray-500">Discount</td>
                        <td className="border border-gray-500"
                        >
                            <input type="text" name='value' disabled={readOnly} className='h-7 w-full' value={discountValue}
                                onChange={(e) => { handleInputChange(e) }}
                            />
                        </td>
                        <td className="border border-gray-500"
                        >
                            <input disabled type="text" name='value' className='h-7 w-full text-right' value={discountAmount}
                            />
                        </td>
                    </tr>
                    <tr className='h-7'>
                        <td className="border border-gray-500">Po Value</td>
                        <td className="border border-gray-500" colSpan={2}
                        >
                            <input disabled type="text" name='value' className='h-7 w-full text-right'
                                value={
                                    poValue.toFixed(2)
                                }
                            />
                        </td>
                    </tr>
                    <tr className='h-7'>
                        <td className="border border-gray-500">Round Off</td>
                        <td className="border border-gray-500" colSpan={2}
                        >
                            <input disabled type="text" name='value' className='h-7 w-full text-right'
                                value={
                                    roundOff.toFixed(2)
                                }
                            />
                        </td>
                    </tr>
                    <tr className='h-7'>
                        <td className="border border-gray-500">Amount in Words</td>
                        <td className="border border-gray-500" colSpan={2}
                        >
                            <input disabled type="text" name='value' className='h-7 w-full text-right'
                                value={
                                    titleCase(numToWords(roundOff)) + "Only"
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PoSummary;