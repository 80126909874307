import React from 'react'
import { getImageUrlPath } from '../../../../Utils/helper'
import { Image, Text, View } from '@react-pdf/renderer';
import tw from '../../../../Utils/tailwind-react-pdf';

const FailureImages = ({ item, img }) => {

  const imageWidth = "150px"
  const imageHeight = "150px";

  const image = img?.picture
  return (
    <View style={tw("text-center")}>

      <View style={tw("flex flex-col gap-y-1 max-w-md")}>
        <Text>
          {img?.comment}
        </Text>
        <View style={tw(' mb-2')}>
          <Text>
            {img?.sampleid.slice(-1) >= 0 ? "" : img?.sampleid.slice(-1)}
          </Text>
          <View style={tw("border border-gray-700 p-2")}>
            <Image alt='failure' style={{ height: imageHeight, width: imageWidth, objectFit: 'cover' }}
              src={getImageUrlPath(image)}
            />
          </View>

        </View>
      </View>

    </View>
  )
}

export default FailureImages