import React, { useState } from "react";

import RequirementExcel from "./RequirementExcel";



export default function ParameterRequirementSummary() {
    return <RequirementExcel
        dataClick={(sampleId, registrationId) => {

        }}
    />
}