import React from 'react'
import TestParametersLists from './TestRequirementLists'



const TestMethods = ({ val, valIndex, dataObj }) => {

    return (
        <>
            <tr className='w-full'>
                <td className=' py-1 text-left gridAlignLeft capitalize'>
                    *{val?.label.toLowerCase() || ""}
                </td>
            </tr>

        </>
    )
}

export default TestMethods