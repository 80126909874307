import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LAB_TEST_RESULT_ENTRY_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const LabTestResultEntryApi = createApi({
  reducerPath: "labTestResultEntry",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["LabTestResultEntry"],
  endpoints: (builder) => ({
    getLabTestResultEntry: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: LAB_TEST_RESULT_ENTRY_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LAB_TEST_RESULT_ENTRY_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["LabTestResultEntry"],
    }),
    // getByRegisterLabTestResultEntry: builder.query({
    //   query: ({params, searchParams}) => {
    //     if(searchParams){
    //       return {
    //         url: LAB_TEST_RESULT_ENTRY_API +"/search/"+searchParams,
    //         method: "GET",
    //         headers: {
    //           "Content-type": "application/json; charset=UTF-8",
    //         },
    //         params
    //       };
    //     }
    //     return {
    //       url: LAB_TEST_RESULT_ENTRY_API,
    //       method: "GET",
    //       headers: {
    //         "Content-type": "application/json; charset=UTF-8",
    //       },
    //       params
    //     };
    //   },
    //   providesTags: ["LabTestResultEntry"],
    // }),
    getLabTestResultEntryById: builder.query({
      query: (id) => {
        return {
          url: `${LAB_TEST_RESULT_ENTRY_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["LabTestResultEntry"],
    }),
    addLabTestResultEntry: builder.mutation({
      query: (payload) => ({
        url: LAB_TEST_RESULT_ENTRY_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["LabTestResultEntry"],
    }),
    updateLabTestResultEntry: builder.mutation({
      query: (payload) => {
        const { ...body } = payload;
        return {
          url: `${LAB_TEST_RESULT_ENTRY_API}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["LabTestResultEntry"],
    }),
    deleteLabTestResultEntry: builder.mutation({
      query: ({ registrationSampleId, testId }) => ({
        url: `${LAB_TEST_RESULT_ENTRY_API}/${registrationSampleId}`,
        method: "DELETE",
        params: { testId }
      }),
      invalidatesTags: ["LabTestResultEntry"],
    }),
  }),
});

export const {
  useGetLabTestResultEntryQuery,
  useGetLabTestResultEntryByIdQuery,
  useAddLabTestResultEntryMutation,
  useUpdateLabTestResultEntryMutation,
  useDeleteLabTestResultEntryMutation,
} = LabTestResultEntryApi;

export default LabTestResultEntryApi;
