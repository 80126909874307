import React from 'react'
import { getImageUrlPath } from "../../../../Utils/helper";
import { Text, View, Image } from '@react-pdf/renderer';
import tw from '../../../../Utils/tailwind-react-pdf'

const WashCareData = ({ value }) => {
  
  return (
    <View style={tw("mt-3")}>
     <View >
     
     <Image style={tw("h-12 w-12")} src={getImageUrlPath(value?.image)} alt="alter" />
   </View>
    <Text style={tw("mt-2")}>{value?.samples?.length > 1 ? value.samples.map(i=>i).join("&"):""}</Text>
    </View>
   
  )
}

export default WashCareData