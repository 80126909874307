import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetPackageMasterQuery,
    useGetPackageMasterByIdQuery,
    useAddPackageMasterMutation,
    useUpdatePackageMasterMutation,
    useDeletePackageMasterMutation,
} from "../../../redux//LabServices/PackageMasterServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox, DropdownInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject } from "../../../Utils/contructObject";
import PackageGridTemplate from "./PackageGridTemplate";
import { Loader } from "../../../Basic/components";
import { faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MODEL = "Package Name Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [buyerNameId, setBuyerNameId] = useState("")
    const [name, setName] = useState("");
    const [packagePrice, setPackagePrice] = useState();
    const [active, setActive] = useState(true);

    const defaultData = [
        { isSelected: false, type: "BASE", days: "" },
        { isSelected: false, type: "BULK", days: "" },
        { isSelected: false, type: "GPT", days: "" },
    ]


    const [reportValidatePeriod, setReportValidatePeriod] = useState(defaultData)
    const [packageTemplateDetails, setPackageTemplateDetails] = useState([]);
    const [formReport, setFormReport] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: buyersList } =
        useGetPartyQuery({ params });

    const { data: allData, isLoading, isFetching } = useGetPackageMasterQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetPackageMasterByIdQuery(id, { skip: !id });


    const [addData] = useAddPackageMasterMutation();
    const [updateData] = useUpdatePackageMasterMutation();
    const [removeData] = useDeletePackageMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            else setReadOnly(false)
            setName(data?.name ? data.name : "");
            setBuyerNameId(data?.buyerNameId ? data.buyerNameId : "");
            setPackagePrice(data?.packagePrice ? data.packagePrice : 0);
            setPackageTemplateDetails(data?.PackageTemplateDetails ? data?.PackageTemplateDetails : []);
            setActive(id ? (data?.active ? data.active : false) : true);
            setReportValidatePeriod(data?.reportValidatePeriod?.length > 0 ? data?.reportValidatePeriod : [
                { isSelected: false, type: "BASE", days: "" },
                { isSelected: false, type: "BULK", days: "" },
                { isSelected: false, type: "GPT", days: "" },
            ])
        },
        [id]
    );

    console.log(singleData, "single")

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, name, packagePrice, buyerNameId, packageTemplateDetails, active, companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"),
        reportValidatePeriod
    }

    const validateData = (data) => {
        if (data.name && data.buyerNameId) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined);
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                let response = await removeData(id).unwrap()
                if (response.statusCode === 1) {
                    toast.info(response.message)
                    return
                }
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    function removeItem(index) {
        setReportValidatePeriod((reportValidate) => {
            return reportValidate.filter((_, i) => parseInt(i) !== parseInt(index));
        });
    }



    function handleInputChange(value, index, field) {
        const newBlend = structuredClone(reportValidatePeriod);
        if (field == "isSelected") {
            if (newBlend[index][field] == false) {
                newBlend[index][field] = true;
            }
            else {
                newBlend[index][field] = false;
            }

        }
        else {
            if (field == "type") {
                newBlend[index][field] = value.toUpperCase();
            }
            else {
                newBlend[index][field] = value;
            }

        }

        setReportValidatePeriod(newBlend);
    }



    const tableHeaders = [
        "Name", "Status"
    ]
    const tableDataNames = ["dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!buyersList?.data) return <Loader />

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid bg-theme"
        >{console.log(reportValidatePeriod, "reportValidatePeriod")}
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid gap-x-2">
                    <div className="col-span-3 grid overflow-auto">
                        <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                            <div className={`${formReport ? "col-span-10" : "col-span-1"}`}>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading mx-2'>Package Info</legend>
                                    <div className='grid grid-cols-4 my-2 gap-y-4'>
                                        <DropdownInput name="Buyer Name" options={dropDownListObject(id ? buyersList.data : buyersList.data.filter(item => item.active), "name", "id")} value={buyerNameId} setValue={(value) => { setBuyerNameId(value); }} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Package Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />




                                        <TextInput name="PackagePrice" type="number" value={packagePrice} setValue={setPackagePrice} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />

                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />

                                        <div className="flex justify-left ml-3 relative top-0 right-0">
                                            <button className="text-xs bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1" onClick={() => setFormReport(prev => !prev)}>
                                                {formReport ? "Close Report >" : "Open Report <"}
                                            </button>
                                        </div>

                                    </div>
                                </fieldset>



                                <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-5 w-2/4 flex h-[250px] overflow-auto border border-gray-600'>
                                    <legend className='sub-heading mx-2'>Report Validate Details</legend>
                                    <div className="mt-4 w-full p-5 border border-gray-500 rounded-lg">
                                        <table className="w-full border border-gray-500">
                                            <thead>
                                                <tr className="bg-gray-100">
                                                    <th className="border border-gray-500 text-sm p-1">
                                                        Select
                                                    </th>
                                                    <th className="border border-gray-500 text-sm p-1">
                                                        Type
                                                    </th>
                                                    <th className="border border-gray-500 text-sm p-1">
                                                        Days
                                                    </th>


                                                    <th className="border border-gray-500 text-sm p-1">
                                                        <button
                                                            type="button"
                                                            className="text-green-700 p-1"
                                                            onClick={() => {
                                                                let newValidateItems = [...reportValidatePeriod];
                                                                newValidateItems.push({ isSelected: false, type: "", days: "" });
                                                                setReportValidatePeriod(newValidateItems);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faUserPlus} />
                                                        </button>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportValidatePeriod?.map((item, index) => (
                                                    <tr key={index} className="text-center">
                                                        <td className="border border-gray-500 text-xs p-1">
                                                            <input type="radio" name="reportValidate"
                                                                disabled={readOnly}
                                                                checked={item.isSelected}
                                                                value={item.isSelected}
                                                                onClick={(e) => handleInputChange(e.target.value, index, "isSelected")}
                                                                className="text-xs" />
                                                        </td>


                                                        <td className="border border-gray-500 text-xs p-1">
                                                            <input
                                                                className="w-full p-1 capitalize"
                                                                type="text"
                                                                value={item.type}
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        e.target.value,
                                                                        index,
                                                                        "type"
                                                                    )
                                                                }

                                                                readOnly={readOnly}
                                                                disabled={childRecord.current > 0}
                                                            />
                                                        </td>
                                                        <td className="border border-gray-500 text-xs p-1">
                                                            <input
                                                                className="w-full p-1 capitalize"
                                                                type="text"
                                                                value={item.days}
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        e.target.value,
                                                                        index,
                                                                        "days"
                                                                    )
                                                                }

                                                                readOnly={readOnly}
                                                                disabled={childRecord.current > 0}
                                                            />
                                                        </td>

                                                        <td className="border border-gray-500 text-xs p-1">
                                                            <button
                                                                type="button"
                                                                onClick={() => removeItem(index)}
                                                                className="text-red-600"
                                                            >
                                                                <FontAwesomeIcon icon={faTrashCan} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </fieldset>


                                <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-5 w-full flex h-[400px] overflow-auto border border-gray-600'>
                                    <legend className='sub-heading mx-2'>Package Template Details</legend>
                                    <PackageGridTemplate
                                        buyerId={buyerNameId}
                                        id={id}
                                        packageTemplateDetails={packageTemplateDetails}
                                        setPackageTemplateDetails={setPackageTemplateDetails} readOnly={readOnly} />
                                </fieldset>
                            </div>
                            <div className={`frame h-full overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-2`}>
                                <FormReport
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    setId={setId}
                                    tableHeaders={tableHeaders}
                                    tableDataNames={tableDataNames}
                                    data={allData?.data}
                                    loading={
                                        isLoading || isFetching
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
