import React from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices"
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetRegistrationQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { useGetPackageMasterQuery } from '../../../redux/LabServices/PackageMasterServices';
import { useGetLabTestResultEntryQuery } from '../../../redux/LabServices/LabTestResultEntryServices';
import { DateInput, Modal } from '../../../Inputs';
import TestReportContainerPreview from '../DashBoard/TestReportContainerPreview';
import { BACKWARD_ICON_PDF, VIEW } from '../../../icons';
import { SIGNOUT_ICON_PDF } from '../../../icons';
import { toast } from 'react-toastify';
import TestReportDocument from '../DashBoard/TestReportContainerPreviewReactPdf';
import TestReportRevert from '../AdminResultEntry/TestReportRevert';
import { useGetUserByIdQuery } from '../../../redux/services/UsersMasterService';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

const RegistrationLoginExcel = ({
    heading = "Sample Registration Summary Report",
    dataClick
}) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocId, setSearchDocId] = useState("");
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [searchDueDate, setSearchDueDate] = useState("");
    const [searchSupplierName, setSearchSupplierName] = useState("");
    const [searchBuyerName, setSearchBuyerName] = useState("");
    const [searchPackageName, setSearchPackageName] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [searchSampleLoginDate, setSearchSampleLoginDate] = useState()


    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = { searchDocId, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName, searchSampleLoginDate }

    useEffect(() => { setCurrentPageNumber(1) }, [dataPerPage, searchDocId, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName])

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const params = {
        branchId, companyId
    };

    const userId = {
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        ),
    };

    const {
        data: singleUserData,

    } = useGetUserByIdQuery(userId.userId, { skip: !userId.userId });

    const { data: allData, isLoading, isFetching } = useGetRegistrationQuery({ params: { branchId, ...searchFields, excelReport: true, fromDate: startDate, toDate: endDate, } }, { skip: !(endDate && startDate) });
    const { data: packageList, isLoading: isPackageLoading, isFetching: isPackageFetching } = useGetPackageMasterQuery({ params })
    const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
        useGetPartyQuery({ params: { ...params } });



    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])


    useEffect(() => {
        let registrationFormReportComponent = document.getElementById("registrationFormReport");
        registrationFormReportComponent.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = document.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            }
        });
        return () => {
            registrationFormReportComponent.removeEventListener('keydown', () => { });
        };
    }, []);

    const isLoadingIndicator = isLoading || isFetching || isSupplierFetching || isSupplierLoading || isPackageFetching || isPackageLoading


    return (
        <>

            <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto">{console.log(allData, "allregistration")}
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10">{heading}</div>
                    <div className=" sub-heading justify-center md:justify-start items-center">
                        <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
                        <select value={dataPerPage}
                            onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                            {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
                        </select>
                    </div>
                </div>
                <>
                    <div
                        className="h-full overflow-auto"
                    >
                        <div className='flex  p-2 w-full'>
                            <DateInput inputHead={"font-bold text-sm"} name={"From :"} value={startDate} setValue={setStartDate} />
                            <DateInput inputHead={"font-bold text-sm"} name={"To :"} value={endDate} setValue={setEndDate} />
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button text-white bg-blue-400 p-2 text-xs rounded-xl"
                                table="registration-login-summary"
                                filename="tablexls"
                                sheet="tablexls"
                                buttonText="GoToExcel"

                            >
                            </ReactHTMLTableToExcel>
                        </div>




                        <table id="registration-login-summary" style={{ display: 'none' }} className="table-auto text-center w-full border border-gray-400">
                            <thead className="border-2  table-Excel-header">
                                <tr className='h-2'>
                                    <th
                                        className="border-2  top-0 stick-bg w-5 textExcel"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg  textExcel w-28"
                                    >
                                        <div>ReportNo</div>
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >LogIn.Date

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >Due Date

                                    </th>
                                    <th

                                        className="border-2  top-0 stick-bg  textExcel w-28"
                                    >
                                        <div>Supplier</div>
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-28"
                                    >
                                        <div>Buyer</div>
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>SampleDes</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg w-12 textExcel"
                                    >
                                        <div>StyleNo</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg w-16 textExcel"
                                    >
                                        <div>OrderNo</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Dept</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >
                                        <div>Color.N</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >
                                        <div>Sesn</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Phase</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel "
                                    >
                                        <div>E.Use</div>

                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>T.Stage</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Service</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Sub.Type</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Cncl.Rsn</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>On.H.Rsn</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg w-12 textExcel"
                                    >
                                        <div>Remarks</div>

                                    </th>



                                </tr>
                            </thead>

                            <tbody className="border-2">
                                {(allData?.data || []).map((dataObj, index) => (
                                    <tr
                                        key={dataObj.id}
                                        className="border-2  table-Excel-row  cursor-pointer textExcel"                    >
                                        <td className='py-1 textExcel'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className='py-1  textExcel'> {dataObj.docId}</td>

                                        <td className='py-1 textExcel'>{getDateFromDateTimeToDisplay(dataObj.createdAt)} </td>{console.log(dataObj.dueDate, "dataObj.dueDate")}

                                        <td className='py-1 textExcel'>{`${getDateFromDateTimeToDisplay(dataObj.dueDate)}${moment(dataObj?.dueTime).format("hh-mm-ss a")}`}</td>
                                        <td className='py-1 textExcel capitalize'>{findFromList(dataObj.supplierId, supplierList.data, "name").toLowerCase() || ""}</td>
                                        <td className='py-1 textExcel capitalize'>{findFromList(dataObj?.buyerId, supplierList.data, "name").toLowerCase() || ""}     </td>
                                        <td className='py-1 textExcel text-left capitalize'> {dataObj?.sampleDescription || ""}</td>
                                        <td className='py-1 textExcel'> {dataObj?.styleNo || ""}</td>
                                        <td className='py-1 textExcel'> {dataObj?.OrderNo || ""}</td>
                                        <td className='py-1 textExcel'> {dataObj?.departmentNo || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.color || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.season || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.phase || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.RegistrationEndUseDetails?.map(val => val?.EndUse?.name.toLowerCase()) || ""}</td>
                                        <td className='py-1 textExcel text-left capitalize'> {dataObj?.testStage || ""}</td>
                                        <td className='py-1 textExcel text-left capitalize'> {dataObj?.ServiceLevel?.name || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.SubmissionType?.name || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.cancelReason || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.OnHoldReason?.name || ""}</td>
                                        <td className='py-1 textExcel'> </td>





                                    </tr>
                                ))}
                            </tbody>

                        </table>




                        <table id="original-table" className="table-auto text-center w-full border border-gray-400">
                            <thead className="border-2  table-Excel-header">
                                <tr className='h-2'>
                                    <th
                                        className="border-2  top-0 stick-bg w-5 textExcel"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg  textExcel w-28"
                                    >
                                        <div>ReportNo</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchDocId}
                                            onChange={(e) => {
                                                setSearchDocId(e.target.value);
                                            }}
                                        />
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >LogIn.Date

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >Due Date

                                    </th>
                                    <th

                                        className="border-2  top-0 stick-bg  textExcel w-28"
                                    >
                                        <div>Supplier</div><input
                                            type="text"
                                            className="text-black  h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchSupplierName}
                                            onChange={(e) => {
                                                setSearchSupplierName(e.target.value);
                                            }}
                                        />
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-28"
                                    >
                                        <div>Buyer</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchBuyerName}
                                            onChange={(e) => {
                                                setSearchBuyerName(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>SampleDes</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg w-12 textExcel"
                                    >
                                        <div>StyleNo</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg w-16 textExcel"
                                    >
                                        <div>OrderNo</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Dept</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >
                                        <div>Color.N</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel w-16"
                                    >
                                        <div>Sesn</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Phase</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel "
                                    >
                                        <div>E.Use</div>

                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>T.Stage</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Service</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Sub.Type</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Cncl.Rsn</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>On.H.Rsn</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg w-12 textExcel"
                                    >
                                        <div>Remarks</div>

                                    </th>



                                </tr>
                            </thead>

                            <tbody className="border-2">
                                {(allData?.data || []).map((dataObj, index) => (
                                    <tr
                                        key={dataObj.id}
                                        className="border-2  table-Excel-row  cursor-pointer textExcel"                    >
                                        <td className='py-1 textExcel'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className='py-1  textExcel'> {dataObj.docId}</td>

                                        <td className='py-1 textExcel'>{getDateFromDateTimeToDisplay(dataObj.createdAt)} </td>{console.log(dataObj.dueDate, "dataObj.dueDate")}

                                        <td className='py-1 textExcel'>{`${getDateFromDateTimeToDisplay(dataObj.dueDate)}${moment(dataObj?.dueTime).format("hh-mm-ss a")}`}</td>
                                        <td className='py-1 textExcel capitalize'>{findFromList(dataObj.supplierId, supplierList.data, "name").toLowerCase() || ""}</td>
                                        <td className='py-1 textExcel capitalize'>{findFromList(dataObj?.buyerId, supplierList.data, "name").toLowerCase() || ""}     </td>
                                        <td className='py-1 textExcel text-left capitalize'> {dataObj?.sampleDescription || ""}</td>
                                        <td className='py-1 textExcel'> {dataObj?.styleNo || ""}</td>
                                        <td className='py-1 textExcel'> {dataObj?.OrderNo || ""}</td>
                                        <td className='py-1 textExcel'> {dataObj?.departmentNo || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.color || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.season || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.phase || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.RegistrationEndUseDetails?.map(val => val?.EndUse?.name.toLowerCase()) || ""}</td>
                                        <td className='py-1 textExcel text-left capitalize'> {dataObj?.testStage || ""}</td>
                                        <td className='py-1 textExcel text-left capitalize'> {dataObj?.ServiceLevel?.name || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.SubmissionType?.name || ""}</td>
                                        <td className='py-1 textExcel capitalize'> {dataObj?.cancelReason || ""}</td>

                                        <td className='py-1 textExcel capitalize'> {dataObj?.OnHoldReason?.name || ""}</td>
                                        <td className='py-1 textExcel'> </td>





                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </>
                {/* <ReactPaginate
                    previousdiv={"<"}
                    nextdiv={">"}
                    breakdiv={"..."}
                    breakClassName={"break-me"}
                    forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                    pageCount={Math.ceil(totalCount / dataPerPage)}
                    marginPagesDisplayed={1}
                    onPageChange={handleOnclick}
                    containerClassName={"flex justify-center m-2 gap-5 items-center"}
                    pageClassName={"border custom-circle text-center"}
                    disabledClassName={"p-1 bg-gray-200"}
                    previousLinkClassName={"border p-1 text-center"}
                    nextLinkClassName={"border p-1"}
                    activeClassName={"bg-blue-900 text-white px-2"} /> */}


            </div>
        </>
    )
}

export default RegistrationLoginExcel