import React from 'react'
import TestRequirementLists from './TestRequirementLists'

const TestRequirements = ({ val }) => {
    return (
        <>
            {
                val?.requirement?.map((item, itemIndex) =>
                    <TestRequirementLists key={itemIndex} item={item} val={val} />
                )
            }
        </>
    )
}

export default TestRequirements