import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getCommonParams } from '../../../Utils/helper';
import BrowseSingleLogo from '../../../ERP/Components/PartyMaster/BrowseSingleLogo';
import { useGetBranchByIdQuery, useUploadAuthorizedBranchSignatureMutation } from '../../../redux/services/BranchMasterService';

const UploadSignature = () => {
    const [logo, setLogo] = useState("")

    const { branchId } = getCommonParams()
    const { data, isLoading, isFetching } = useGetBranchByIdQuery(branchId);
    const [upload] = useUploadAuthorizedBranchSignatureMutation()
    useEffect(() => {
        if (!data?.data) return
        setLogo(data?.data?.signature)
    }, [data, isLoading, isFetching])
    const handleUpload = async (text = "uploaded") => {
        try {
            const formData = new FormData()
            if (logo instanceof File) {
                formData.append("image", logo);
            } else {
                formData.append("isDelete", true);
            }
            await upload({ id: branchId, body: formData });
            toast.success(text + " Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    return (
        <div className='grid w-1/4'>
            <BrowseSingleLogo picture={logo} setPicture={setLogo} />
            <button className='w-full bg-green-400 text-gray-100 rounded-md' onClick={() => handleUpload()}>Upload</button>
        </div>
    )
}

export default UploadSignature
