import React from 'react'
import WashCareData from './WashCareData';
import WashCareDataPrint from './WashCareDataPrint';

const WashCareInstructionPrint = ({washCareData,washCareDetails,sampleItem}) => {
   
  return (
    <div >
      <div className='grid grid-flow-col auto-cols-max gap-x-5 w-full'>
      {washCareDetails.map((value,index)=> <WashCareDataPrint washCareData={washCareData} value={value}  key={value.washCareId} index={index}  sampleItem={sampleItem}/> )} 
      </div>
           
            <div className='w-full grid place-content-start ml-5'>
            <h1 className='  '>{sampleItem?.specialInstruction?sampleItem?.specialInstruction + "":""}</h1>
            </div>       

    </div>
  )
}

export default WashCareInstructionPrint