import React, { useState } from "react";

import ResultEntryExcel from "./ResultEntryExcel";


export default function ResultEntrySummary() {



    return <ResultEntryExcel
        dataClick={(sampleId, registrationId) => {

        }}
    />
}