
import React from 'react'
import GenerateInvoiceNumber from '../PrintFormat-InvoiceReport/GenerateInvoiceNumber'
import moment from 'moment'
import tw from '../../../Utils/tailwind-react-pdf'
import empty from "../../../assets/empty.png"
import { getImageUrlPath } from '../../../Utils/helper'

import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PageWrapper from './PageWrapper'


const InvoiceContent = ({ data, testDetailsData }) => {

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
      fontFamily: 'Helvetica',
    },
    title: {
      fontSize: 9,

      textAlign: 'center',
      marginBottom: 20,

    },
    subtitle: {
      fontSize: 9,
      textAlign: 'center',
      marginBottom: 10,
    },
    grid: {
      flexDirection: 'row',
      justifyContent: "space-between",
      paddingLeft: 30,
      paddingRight: 40,
      paddingVertical: 3,
      flexWrap: "wrap",
      flexGrow: 1,
      marginTop: 20
    },
    label: {
      fontWeight: 'bold',
      marginRight: 5,
    },
    value: {
      marginLeft: 5,
    },
    section: {
      flexDirection: 'column',
      justifyContent: "space-between",
      paddingLeft: 29,
      marginBottom: 2,
      marginTop: 2,

    },
    box: {

      padding: 2

    },
    toAddress: {
      marginBottom: 6,
      marginTop: 10,
      paddingLeft: 30,
    },
    table: {
      // border: 0.5,
      // borderColor: '#000',
      width: "100%",
      marginBottom: 10,
      marginTop: 10,

    },
    row: {
      alignItems: 'center',
      height: 30,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",

    },
    cell: {
      height: 30,
      padding: 5,
      fontSize: 8,
      fontWeight: 15,
      borderRight: 0.5,
      borderBottom: 0.5,
      borderColor: '#000',
      flexGrow: 1,
      width: 25,
      textAlign: "center",
      alignItems: 'flex-middle',
      justifyContent: 'flex-middle',

    },

    container: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      marginRight: 32,
      marginTop: 5,
      fontSize: 8,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 5,
    },
    analysisCharge: {
      marginBottom: 10,
    },
    mb2: {
      marginBottom: 2,
      marginTop: 2,
    },



    list: {
      fontSize: 10,
      marginBottom: 5,
    },
    bold: {
      fontWeight: 'bold',
    },
    details: {
      marginBottom: 10,
    },


  });

  const imageWidth = "100px"
  const imageHeight = "50px";

  const image = data?.data?.authorizedSignature ? data?.data?.authorizedSignature : ""


  return (
    <>
      <PageWrapper >
        <View style={tw("px-9")}>

          <Text style={[styles.title, { fontSize: 12, fontWeight: 35, marginTop: 5 }]}>PROFORMA INVOICE</Text>
          <View style={tw("w-full px-9")}>





            <View style={tw("flex flex-row  justify-start w-full ml-11 mt-1")} >

              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Invoice No:</Text>
                <Text style={tw("text-xs")}>{data?.data.profomaInvoiceNo}</Text>
              </View>
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Invoice Date:</Text>
                <Text style={tw("text-xs")}>{moment.utc(data?.data?.invoiceDate).format("DD-MM-YYYY")} </Text>
              </View>
            </View>






            <View style={tw("flex flex-row  justify-start w-full ml-11 mt-1")} >

              <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>STATE CODE:</Text>
                <Text style={tw("text-xs")}>33</Text>
              </View>
              <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>HSN CODE:</Text>
                <Text style={tw("text-xs")}>998346 </Text>
              </View>
            </View>


            <View style={tw("flex flex-row flex-wrap  w-1/2 ml-11 mt-2")}>
              <Text style={[tw("text-xs font-bold")]}>To :</Text>
              <Text style={tw("text-xs")}>{data?.data?.toCompany?.name} ---{data?.data?.toCompany?.address}</Text>
            </View>

            <View style={tw("flex flex-row flex-wrap  w-1/2 ml-11 mt-2")}>
              <Text style={[tw("text-xs font-bold")]}>ContactName : </Text>
              <Text style={tw("text-xs")}>{data?.data?.toCompany?.contactDetails?.map(val => val.contactPersonName).join("/")}</Text>
            </View>

            <View style={tw("flex flex-row flex-wrap  w-1/2 ml-11 mt-1")}>
              <Text style={[tw("text-xs font-bold")]}>Email.Id : </Text>
              <Text style={tw("text-xs")}>{data?.data?.toCompany?.contactDetails?.map(val => val.email).join("/")}</Text>
            </View>









            <View style={tw("flex gap-y-1 w-full ml-11 mt-2 ")}>

              <View style={tw("flex flex-row flex-wrap  justify-start w-full ")} >

                <View style={tw("flex flex-row flex-wrap  w-1/2 ")}>
                  <Text style={[tw("text-xs font-bold ")]}>Buyer Name :</Text>
                  <Text style={tw("text-xs")}>{data?.data?.buyerName ? data?.data?.buyerName : ""}</Text>
                </View>
                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Test Report No:</Text>
                  <Text style={tw("text-xs")}>{data?.data?.testReportNo ? data?.data?.testReportNo : ""} </Text>
                </View>
              </View>

              <View style={tw("flex flex-row flex-wrap justify-start w-full  ")} >

                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Color:</Text>
                  <Text style={tw("text-xs")}>{data?.data?.color ? data?.data?.color : ""}</Text>
                </View>
                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Reg.Date:</Text>
                  <Text style={tw("text-xs")}> {moment.utc(data?.data?.regDate ? data?.data?.regDate : "").format("DD-MM-YYYY")} </Text>
                </View>
              </View>



              <View style={tw("flex flex-row  flex-wrap justify-start w-full  ")} >

                <View style={tw("flex flex-row flex-wrap  w-1/2 ")}>
                  <Text style={[tw("text-xs font-bold")]}>Service:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.service ? data?.data?.service : ""}  </Text>
                </View>
                <View style={tw("flex flex-row flex-wrap w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Description:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.sampleDescription ? data?.data?.sampleDescription : ""} </Text>
                </View>
              </View>


              <View style={tw("flex flex-row flex-wrap justify-start w-full ")} >

                <View style={tw("flex flex-row flex-wrap w-1/2 ")}>
                  <Text style={[tw("text-xs font-bold")]}>Style. No:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.styleNo ? data?.data?.styleNo : ""}</Text>
                </View>
                <View style={tw("flex flex-row flex-wrap  w-1/2")}>
                  <Text style={[tw("text-xs font-bold ")]}>Batch. No:</Text>
                  <Text style={tw("text-xs")}> {data?.data?.batchNo ? data?.data?.batchNo : ""}</Text>
                </View>
              </View>

            </View>




          </View>






          {
            data?.data?.typeOfInvoice == "PACKAGE" ?

              <View style={styles.table}>

                <View style={[styles.row, {
                  borderBottomWidth: 1,
                }]}>
                  <Text style={[{
                    padding: 5, textAlign: "center",

                    fontSize: 8,
                    borderRight: 0.5,
                    borderLeft: 0.5,
                    borderTop: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 8, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>S.No</Text>
                  <Text style={[{
                    padding: 5, textAlign: "center",
                    fontSize: 8,
                    borderRight: 0.5,
                    borderTop: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 70, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Package Name</Text>
                  <Text style={[{
                    padding: 5, textAlign: "center",
                    borderTop: 0.5,
                    fontSize: 8,
                    borderRight: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Quantity</Text>
                  <Text style={[{
                    padding: 5, textAlign: "center",
                    borderTop: 0.5,
                    fontSize: 8,
                    borderRight: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Price</Text>
                  <Text style={[{
                    padding: 5, textAlign: "center",
                    borderTop: 0.5,
                    fontSize: 8,
                    borderRight: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Discount</Text>
                  <Text style={[{
                    padding: 5, textAlign: "center",
                    borderTop: 0.5,
                    fontSize: 8,
                    borderRight: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Amount</Text>


                </View>


                {(data?.data?.packageDetails ? data?.data?.packageDetails : []).map((item, index) =>
                  <View key={index} style={styles.row}>
                    <Text style={[{
                      padding: 5, textAlign: "center",

                      fontSize: 8,
                      borderRight: 0.5,
                      borderLeft: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 8, height: 30,
                    }]}>{index + 1}</Text>
                    <Text style={[{
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 70, height: 30,
                    }]}>{item.packageName}</Text>
                    <Text style={[{
                      padding: 5, textAlign: "right",
                      borderBottom: 0.5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item?.qty}</Text>
                    <Text style={[{
                      padding: 5, textAlign: "right",
                      borderBottom: 0.5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item.price}</Text>
                    <Text style={[{
                      padding: 5, textAlign: "right",
                      borderBottom: 0.5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item.discount}</Text>
                    <Text style={[{
                      padding: 5, textAlign: "right",
                      borderBottom: 0.5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{(!item.qty || !item.price) ? 0 : ((parseFloat(item.qty) * parseFloat(item.price)) - parseFloat(item.discount)).toFixed(2)}</Text>

                  </View>
                )}

              </View>
              : ""

          }

          {
            (testDetailsData ? testDetailsData : []).length > 0 ? (

              <View style={styles.table}>
                <View style={[styles.row, { borderBottomWidth: 1 }]}>
                  <Text style={[{
                    padding: 5,
                    fontSize: 8,
                    fontWeight: "bold",
                    textAlign: "center",
                    borderRight: 0.5,
                    borderLeft: 0.5,
                    borderTop: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 8, height: 30,

                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>S.No</Text>
                  <Text style={[{
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: 5,
                    fontSize: 8,
                    borderRight: 0.5,
                    borderTop: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 70, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Test</Text>
                  <Text style={[{
                    textAlign: "center",
                    padding: 5,
                    fontWeight: "bold",
                    fontSize: 8,
                    borderRight: 0.5,
                    borderTop: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Quantity</Text>
                  <Text style={[{
                    textAlign: "center",
                    padding: 5,
                    fontWeight: "bold",
                    fontSize: 8,
                    borderRight: 0.5,
                    borderTop: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Rate</Text>
                  <Text style={[{
                    textAlign: "center",
                    padding: 5,
                    fontWeight: "bold",
                    fontSize: 8,
                    borderRight: 0.5,
                    borderTop: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Discount</Text>
                  <Text style={[{
                    textAlign: "center",
                    padding: 5,
                    fontWeight: "bold",
                    fontSize: 8,
                    borderRight: 0.5,
                    borderTop: 0.5,
                    borderBottom: 0.5,
                    borderColor: '#000',
                    flexGrow: 1, width: 15, height: 30,
                  }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>Amount</Text>
                </View>


                {testDetailsData?.filter(val => val.qty != 0)?.map((item, index) => (
                  <View key={index} style={styles.row}>
                    <Text style={[{
                      textAlign: "center",
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderLeft: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 8, height: 30,
                    }]}>{index + 1}</Text>
                    <Text style={[{
                      padding: 4,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 70, height: 30,
                    }]}>{item.testName}</Text>
                    <Text style={[{
                      textAlign: "right",
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item?.qty}</Text>
                    <Text style={[{
                      textAlign: "right",
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item.price}</Text>
                    <Text style={[{
                      textAlign: "right",
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{item.discount}</Text>
                    <Text style={[{
                      textAlign: "right",
                      padding: 5,
                      fontSize: 8,
                      borderRight: 0.5,
                      borderBottom: 0.5,
                      borderColor: '#000',
                      flexGrow: 1, width: 15, height: 30,
                    }]}>{(!item.qty || !item.price) ? 0 : ((parseFloat(item.qty) * parseFloat(item.price)) - parseFloat(item.discount)).toFixed(2)}</Text>
                  </View>
                ))}

              </View>
            )
              : ""
          }


          <View style={tw("mt-3")}>
            <View style={styles.container}>
              <View style={styles.gridContainer}>
                <Text style={styles.analysisCharge}>Analysis Charge:</Text>
                {data?.data?.taxDetails?.map((val, index) => (
                  <Text key={index}>
                    {`${val?.taxName} @ ${val.taxPercent} % & ${"TDS"} @ ${val?.tdsPercent} % : ${parseFloat(val?.taxAmount).toFixed(2)}`}
                  </Text>
                ))}
                <Text style={styles.mb2}>
                  RoundOff: {data?.data?.totalTaxAmount ? parseFloat(data?.data?.totalTaxAmount).toFixed(2) : 0.00}
                </Text>


                <Text style={{ marginTop: 3 }}>NetCharge: {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}</Text>
                <Text style={{ marginTop: 2 }}>Grand Total: {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}</Text>
              </View>
            </View>
          </View>







          <View style={tw("h-44")}>
            <Text style={[{ fontSize: 10, fontWeight: 35, marginBottom: 5, marginTop: 15, color: "black", textAlign: "left" }, { fontWeight: 900, fontFamily: "Times-Bold" }]}>TERMS AND CONDITIONS : </Text>
            <View style={tw("")}>
              <Text style={tw("mt-1")}>
                1. Attached is the Proforma -Invoice for your approval. Kindly note, that this not a final invoice and the value could change depending on the nature of rendered job.
              </Text>
              <Text style={tw("mt-1")}>
                2. Our Services will be subject to TDS @ 2% under section 194J of the Income Tax Act 1961
              </Text>
              <Text style={tw("mt-1")}>
                3. Validity: 15 working days after the date of this Proforma invoice issued.
              </Text>
              <Text style={tw("mt-1")}>
                4. Any dispute arising out of this transaction / Contract will be referred to institutional arbitration council of Tirupur as per the rules and regulations of Arbitration
                Council of Tirupur and the award passed will be binding on us.
              </Text>


            </View>
          </View>






          <View style={tw("ml-5 mt-3")}>

            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-2")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>OUR BANK DETAILS : </Text>
              <Text style={tw("text-xs")}></Text>
            </View>

            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-2")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>ACCOUNT NAME:</Text>
              <Text style={tw("text-xs")}> {data?.data?.bankDetails?.accountName ? data?.data?.bankDetails?.accountName : ""}  </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>ACCOUNT NO:</Text>
              <Text style={tw("text-xs")}>    {data?.data?.bankDetails?.accountNo ? data?.data?.bankDetails?.accountNo : ""} </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>IFSC.CODE:</Text>
              <Text style={tw("text-xs")}>   {data?.data?.bankDetails?.ifsc ? data?.data?.bankDetails?.ifsc : ""} </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>BRANCH:</Text>
              <Text style={tw("text-xs")}>   {data?.data?.bankDetails?.branch ? data?.data?.bankDetails?.branch : ""} </Text>
            </View>
            <View style={tw("flex flex-row gap-x-2 w-1/2 mt-1")}>
              <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}> GST NO :</Text>
              <Text style={tw("text-xs")}> 33AADCS8162K1Z4 </Text>
            </View>





          </View>






          <View style={tw("-mt-5")}>



            <View style={tw("flex flex-row justify-end  mr-3")}>


              {Boolean(image) ?
                <Image style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
                  src={getImageUrlPath(image)}
                />
                :
                <Image src={empty} style={tw("w-24 text-center")} />
              }

            </View>

            <View style={tw("flex flex-row justify-end mt-1")}>



              <Text style={styles.label}>Verified By & Authorized By : </Text>

            </View>
          </View>

        </View>
      </PageWrapper>
    </>
  )
}

export default InvoiceContent




