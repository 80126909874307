import React from 'react'
import { PDFViewer,  BlobProvider } from '@react-pdf/renderer';
import { findFromList, handleMailSend, params } from "../../../Utils/helper"

import ScanningSheetReport from '../Document-ScanningSheetReport';

const ScanningSheetDoc = ({testList,methodList,conditionList,washCareData,registrationData,data}) => {
    return (
        <div >
            {/* <div className='mt-5'>
            <BlobProvider document={  <ScanningSheetReport registrationData={registrationData} conditionList={conditionList} testList={testList} methodList={methodList}  data={data}  washCareData={washCareData}/>} fileName="my_pdf3.pdf">
                    {({ blob }) => {
                   
                        // setPdfBlob(blob);
                        return <button className='bg-green-400 p-1 rounded-md mb-4' onClick={()=>handleMailSend(blob,companyEmail,"sample Acknowledgement",registrationData?.data?.docId)}>Send Mail</button>;
                    }}
                </BlobProvider>
            </div> */}
              
                <PDFViewer className='w-full h-screen p-9'>
                    <ScanningSheetReport registrationData={registrationData} conditionList={conditionList} testList={testList} methodList={methodList}  data={data}  washCareData={washCareData}/>
                </PDFViewer>
        </div>
      )
}

export default ScanningSheetDoc