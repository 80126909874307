import React, { useEffect, useState } from 'react'
import { useGetYarnMasterQuery } from "../../../redux/ErpServices/YarnMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import secureLocalStorage from 'react-secure-storage';
import { Loader } from '../../../Basic/components';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { findFromList, getDateFromDateTime } from '../../../Utils/helper';



const YarnPoItemSelection = ({ poItems, transtype }) => {
    const [localItems, setLocalItems] = useState(poItems);

    const [searchFilter, setSearchFilter] = useState([]);

    function filterRecords(localItems) {
        let newLocalItems = [...localItems]
        console.log(localItems, "items")
        newLocalItems = newLocalItems.filter(item => {
            for (let filter of searchFilter) {
                let fieldName = filter.fieldName
                let searchValue = filter.searchValue
                let objTransformFunc = filter.objTransformFunc
                let itemObj = objTransformFunc(item[fieldName])
                if (!(`${itemObj}`.toLowerCase().includes(searchValue.toLowerCase()))) return false
            }
            return true
        })
        return newLocalItems
    }

    useEffect(() => {
        console.log("searchfilter called")
        setLocalItems(prev => filterRecords(prev))
    }, [setLocalItems, searchFilter, poItems])


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const { data: yarnList, isLoading: yarnListLoading, isFetching: yarnListFetching } = useGetYarnMasterQuery({ companyId })
    const { data: uomList, isLoading: uomLoading, isFetching: uomFetching } = useGetUnitOfMeasurementMasterQuery({ companyId })
    const { data: colorList, isLoading: colorLoading, isFetching: colorFetching } = useGetColorMasterQuery({ companyId })

    if (yarnListLoading || yarnListFetching || uomFetching || uomLoading || colorFetching || colorLoading) {
        return <tr>
            <td>
                <Loader />
            </td>
        </tr>
    }

    function changeSearchFilter(field, value, objTransformFunc) {
        setSearchFilter(prev => {
            if (!value) {
                return prev.filter(item => item.fieldName !== field)
            }
            let newSearchFilter = [...prev]
            let index = newSearchFilter.findIndex(item => item.fieldName === field)
            if (index !== -1) {
                newSearchFilter[index]["searchValue"] = value
            } else {
                newSearchFilter.push({ fieldName: field, searchValue: value, objTransformFunc });
            }
            return newSearchFilter
        })
    }
    let count = 1;
    return (
        <div className='border border-r-black border-t-black border-l-black bg-gray-200'>
            <table className='table-fixed w-full text-xs'>
                <thead>
                    <tr className='bg-blue-200 border border-b-black sticky top-10 '>
                        <th className='text-center border border-r-black border-b-black border-l-black'>
                            S.no
                        </th>
                        <th className='border border-r-black border-b-black'>
                            Item Type
                        </th>
                        <th className='border border-r-black border-b-black'>
                            Uom
                        </th>
                        <th className='border border-r-black border-b-black'>
                            Yarn Name
                        </th>
                        <th className='border border-r-black border-b-black'>
                            Color
                        </th>
                        <th className='border border-r-black border-b-black'>
                            Po qty
                        </th>
                        <th className='border border-r-black border-b-black'>
                            Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='sticky top-20 bg-blue-200'>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text" placeholder='Search'
                                onChange={(e) => {
                                    changeSearchFilter("transtype", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text" placeholder='Search'
                                onChange={(e) => {
                                    changeSearchFilter("yarnId", e.target.value, (item) => findFromList(item, yarnList.data, "aliasName"))
                                }} />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("colorId", e.target.value, (item) => findFromList(item, colorList.data, "name"))
                                }}
                                disabled={transtype.toLowerCase().includes("grey")}
                            />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("uomId", e.target.value, (item) => findFromList(item, uomList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text" placeholder='Search'
                                onChange={(e) => {
                                    changeSearchFilter("poDate", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>
                        <td className='text-center border border-b-black shadow-xl'>
                        </td>
                        <td className='text-center border border-b-black shadow-xl'>
                        </td>
                    </tr>
                    {localItems.map((item, index) =>
                        <tr key={index} className=''>{console.log(item, "#########")}
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {count++}
                            </td>
                           
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {transtype}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.uomId, uomList.data, "name")}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.yarnId, yarnList.data, "aliasName")}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.colorId, colorList.data, "name")}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {item.qty}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {item.price}
                            </td>

                            {/* <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {item.price}
                            </td> */}








                            {/* <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.yarnId, yarnList.data, "aliasName")}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.colorId, colorList.data, "name")}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.uomId, uomList.data, "name")}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {item.qty}
                            </td>
                         
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {(parseFloat(item.qty) - parseFloat(item.alreadyInwardedQty)).toFixed(3)}
                            </td>
                            <td className='text-center border border-r-black border-b-black shadow-xl'>
                                {item.price}
                            </td>
                            <td className='text-center border border-b-black shadow-xl'>
                                {(parseFloat(item.price) * parseFloat(item.qty)).toFixed(2)}
                            </td> */}
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>

    )
}

export default YarnPoItemSelection