import React from 'react'
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import TestParametersLists from './TestParametersLists';

const TestStatus = ({ val, valIndex, dataObj }) => {

    return (
        <>
            <tr className='w-full'>
                <td className=' py-1 text-left gridAlignLeft capitalize'>

                    {val?.isResultEntryFinished ? "Done" : "InProgress"}
                </td>
            </tr>


        </>
    )
}

export default TestStatus