import React from 'react'
import { AddNewButton } from '../../../Buttons';
import 'react-toastify/dist/ReactToastify.css';

import { Loader } from "../../../Basic/components";
import { useGetCityQuery } from '../../../redux/services/CityMasterService';
import { DELETE, VIEW } from '../../../icons';


export default function BranchReport({ id, onNew, branchDetails, setCurrentSelectedBranchIndex, setBranchForm, setBranchDetails }) {
    const { data: cityList, isLoading: cityLoading, isFetching: cityFetching } =
        useGetCityQuery({});
    const columns = ["S.no", "Branch Name", "Address", "View"]
    return (
        <div className='flex flex-col md:justify-items-center'>

            {!cityList || cityLoading || cityFetching
                ?
                <Loader />
                :
                <table className='table-auto text-center m-1'>
                    <thead className='border-2 table-header'>
                        <tr>
                            {(columns ? columns:[]).map((head, index) => <th key={index} className='border-2 sticky top-0 stick-bg'>{head}</th>)}
                            <th className='w-32'>  <AddNewButton onClick={onNew} /></th>
                        </tr>
                    </thead>
                    <tbody className='border-2'>
                        {(branchDetails? branchDetails:[]).map((branch, index) =>
                            <tr className='border-2 table-row hover:bg-inherit'>
                                <td className='table-data'>{index + 1}</td>
                                <td className='table-data'>{branch?.name}</td>
                                <td className='table-data'>{branch?.address}</td>
                                {branch?.isHo
                                    ?
                                    <td> (HO) </td>
                                    :
                                    <td className='table-data' onClick={() => { setCurrentSelectedBranchIndex(index); setBranchForm(true) }}>
                                        {VIEW}
                                    </td>
                                }
                                {branch?.isHo
                                    ?
                                    <td></td>
                                    :
                                    <td className='table-data' onClick={() => { setBranchDetails(prev => prev.filter((_, i) => i !== index)) }}>
                                        {DELETE}
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </div>
    )
}