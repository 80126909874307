import { configureStore } from "@reduxjs/toolkit";
import { openTabs } from "./features";
import {
  countryMasterApi, pageMasterApi, stateMasterApi,
  cityMasterApi, departmentMasterApi, employeeCategoryMasterApi,
  finYearMasterApi, rolesMasterApi, employeeMasterApi, userMasterApi,
  branchMasterApi, companyMasterApi, pageGroupMasterApi
} from "./services"

import {
  partyCategoryMasterApi, currencyMasterApi, partyMasterApi, ContentMasterApi,
  YarnTypeMasterApi, CountsMasterApi, HsnMasterApi, YarnMasterApi,
  FabricTypeMasterApi, FabricMasterApi, AccessoryGroupMasterApi, AccessoryItemMasterApi,
  AccessoryMasterApi, ColorMasterApi, poApi, UnitOfMeasurementApi,
  PaytermMasterApi, TaxTermMasterApi, TaxTemplateApi, LocationMasterApi, GaugeMasterApi,
  DesignMasterApi, GsmMasterApi, LoopLengthMasterApi, DiaMasterApi, SizeMasterApi,
  PurchaseInwardOrReturnApi, stockApi, StyleMasterApi, ProcessMasterApi, BlendMasterApi, FiberContentMasterApi
} from "./ErpServices";

import {
  LabTestGroupMasterApi, LabTestNameMasterApi, LabTestSubGroupMasterApi, LabTestStandardMasterApi,
  LabTestConditionMasterApi, WashCareMasterApi, SampleDescriptionOneMasterApi, SampleDescriptionTwoMasterApi,
  SampleDescriptionThreeMasterApi, ProductCategoryMasterApi, BrandMasterApi, WashMasterApi, FinishMasterApi, RegistrationApi,
  ServiceLevelMasterApi, SubmissionTypeMasterApi, OnHoldReasonMasterApi, EndUseMasterApi, MillOrFactoryMasterApi,
  PackageMasterApi, PackageTemplateApi, LabTestMethodMasterApi, WashCareGroupMasterApi,LabCodeMasterApi, HolidaysCalenderMasterApi, LabTestAnalyteMasterApi, LabTestRequirementMasterApi, FormulaMasterApi, SampleDescriptionFourMasterApi, LabTestResultEntryApi,
} from "./LabServices";

import designApi from "./ErpServices/DesignMasterServices";

const commonReducers = {
  openTabs,
  countryMaster: countryMasterApi.reducer,
  pageMaster: pageMasterApi.reducer,
  stateMaster: stateMasterApi.reducer,
  cityMaster: cityMasterApi.reducer,
  departmentMaster: departmentMasterApi.reducer,
  employeeCategoryMaster: employeeCategoryMasterApi.reducer,
  finYearMaster: finYearMasterApi.reducer,
  roleMaster: rolesMasterApi.reducer,
  userMaster: userMasterApi.reducer,
  employeeMaster: employeeMasterApi.reducer,
  branchMaster: branchMasterApi.reducer,
  companyMaster: companyMasterApi.reducer,
  pageGroupMaster: pageGroupMasterApi.reducer,
}
const commonMiddleware = [countryMasterApi.middleware,
pageMasterApi.middleware,
stateMasterApi.middleware,
cityMasterApi.middleware,
departmentMasterApi.middleware,
employeeCategoryMasterApi.middleware,
finYearMasterApi.middleware,
rolesMasterApi.middleware,
userMasterApi.middleware,
employeeMasterApi.middleware,
branchMasterApi.middleware,
companyMasterApi.middleware,
pageGroupMasterApi.middleware,
];


const erpReducers = {
  partyCategoryMaster: partyCategoryMasterApi.reducer,
  currencyMaster: currencyMasterApi.reducer,
  partyMaster: partyMasterApi.reducer,
  contentMaster: ContentMasterApi.reducer,
  yarnTypeMaster: YarnTypeMasterApi.reducer,
  countsMaster: CountsMasterApi.reducer,
  hsnMaster: HsnMasterApi.reducer,
  blendMaster:BlendMasterApi.reducer,
  yarnMaster: YarnMasterApi.reducer,
  fabricTypeMaster: FabricTypeMasterApi.reducer,
  fabricMaster: FabricMasterApi.reducer,
  accessoryGroupMaster: AccessoryGroupMasterApi.reducer,
  accessoryItemMaster: AccessoryItemMasterApi.reducer,
  accessoryMaster: AccessoryMasterApi.reducer,
  colorMaster: ColorMasterApi.reducer,
  po: poApi.reducer,
  unitOfMeasurementMaster: UnitOfMeasurementApi.reducer,
  paytermMaster: PaytermMasterApi.reducer,
  taxTermMaster: TaxTermMasterApi.reducer,
  taxTemplate: TaxTemplateApi.reducer,
  locationMaster: LocationMasterApi.reducer,
  Gauge: GaugeMasterApi.reducer,
  gsm: GsmMasterApi.reducer,
  design: designApi.reducer,
  Dia: DiaMasterApi.reducer,
  LoopLength: LoopLengthMasterApi.reducer,
  sizeMaster: SizeMasterApi.reducer,
  PurchaseInwardOrReturn: PurchaseInwardOrReturnApi.reducer,
  stock: stockApi.reducer,
  styleMaster: StyleMasterApi.reducer,
  Process: ProcessMasterApi.reducer,
  [FiberContentMasterApi.reducerPath]: FiberContentMasterApi.reducer,
}
const erpMiddleware = [
  partyCategoryMasterApi.middleware,
  currencyMasterApi.middleware,
  partyMasterApi.middleware,
  ContentMasterApi.middleware,
  YarnTypeMasterApi.middleware,
  CountsMasterApi.middleware,
  HsnMasterApi.middleware,
  BlendMasterApi.middleware,
  YarnMasterApi.middleware,
  FabricTypeMasterApi.middleware,
  FabricMasterApi.middleware,
  AccessoryGroupMasterApi.middleware,
  AccessoryItemMasterApi.middleware,
  AccessoryMasterApi.middleware,
  ColorMasterApi.middleware,
  poApi.middleware,
  UnitOfMeasurementApi.middleware,
  PaytermMasterApi.middleware,
  TaxTermMasterApi.middleware,
  TaxTemplateApi.middleware,
  LocationMasterApi.middleware,
  DiaMasterApi.middleware,
  GsmMasterApi.middleware,
  GaugeMasterApi.middleware,
  LoopLengthMasterApi.middleware,
  DesignMasterApi.middleware,
  SizeMasterApi.middleware,
  PurchaseInwardOrReturnApi.middleware,
  stockApi.middleware,
  StyleMasterApi.middleware,
  ProcessMasterApi.middleware,
  FiberContentMasterApi.middleware,
]

const labReducers = {
  labTestGroupMaster: LabTestGroupMasterApi.reducer,
  labTestNameMaster: LabTestNameMasterApi.reducer,
  labTestSubGroupMaster: LabTestSubGroupMasterApi.reducer,
  labTestStandardMaster: LabTestStandardMasterApi.reducer,
  labTestConditionMaster: LabTestConditionMasterApi.reducer,
  washCareMaster: WashCareMasterApi.reducer,
  sampleDescriptionOneMaster: SampleDescriptionOneMasterApi.reducer,
  sampleDescriptionTwoMaster: SampleDescriptionTwoMasterApi.reducer,
  sampleDescriptionThreeMaster: SampleDescriptionThreeMasterApi.reducer,
  productCategoryMaster : ProductCategoryMasterApi.reducer,
  brandMaster: BrandMasterApi.reducer,
  washMaster: WashMasterApi.reducer,
  finishMaster: FinishMasterApi.reducer,
  registration: RegistrationApi.reducer,
  serviceLevelMaster: ServiceLevelMasterApi.reducer,
  submissionTypeMaster: SubmissionTypeMasterApi.reducer,
  onHoldReasonMaster: OnHoldReasonMasterApi.reducer,
  endUseMaster: EndUseMasterApi.reducer,
  millOrFactoryMaster: MillOrFactoryMasterApi.reducer,
  packageMaster: PackageMasterApi.reducer,
  packageTemplate: PackageTemplateApi.reducer,
  labTestMethodMaster: LabTestMethodMasterApi.reducer,
  labCodeMaster: LabCodeMasterApi.reducer,
  holidaysCalenderMaster: HolidaysCalenderMasterApi.reducer,
  labTestAnalyteMaster: LabTestAnalyteMasterApi.reducer,
  labTestRequirementMaster: LabTestRequirementMasterApi.reducer,
  formulaMaster: FormulaMasterApi.reducer,
  sampleDescriptionFourMaster: SampleDescriptionFourMasterApi.reducer,
  labTestResultEntry: LabTestResultEntryApi.reducer,



 [WashCareGroupMasterApi.reducerPath] : WashCareGroupMasterApi.reducer
}

const labMiddleware = [
  LabTestGroupMasterApi.middleware,
  LabTestNameMasterApi.middleware,
  LabTestSubGroupMasterApi.middleware,
  LabTestStandardMasterApi.middleware,
  LabTestConditionMasterApi.middleware,
  WashCareMasterApi.middleware,
  SampleDescriptionOneMasterApi.middleware,
  SampleDescriptionTwoMasterApi.middleware,
  SampleDescriptionThreeMasterApi.middleware,
  ProductCategoryMasterApi.middleware,
  BrandMasterApi.middleware,
  WashMasterApi.middleware,
  FinishMasterApi.middleware,
  RegistrationApi.middleware,
  ServiceLevelMasterApi.middleware,
  SubmissionTypeMasterApi.middleware,
  OnHoldReasonMasterApi.middleware,
  EndUseMasterApi.middleware,
  MillOrFactoryMasterApi.middleware,
  PackageMasterApi.middleware,
  PackageTemplateApi.middleware,
  LabTestMethodMasterApi.middleware,
  WashCareGroupMasterApi.middleware,
  LabCodeMasterApi.middleware,
  HolidaysCalenderMasterApi.middleware,
  LabTestAnalyteMasterApi.middleware,
  LabTestRequirementMasterApi.middleware,
  FormulaMasterApi.middleware,
  SampleDescriptionFourMasterApi.middleware,
 LabTestResultEntryApi.middleware,



]

const store = configureStore({
  reducer: {
    ...commonReducers, ...erpReducers, ...labReducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(commonMiddleware).concat(erpMiddleware).concat(labMiddleware)
});

export default store;