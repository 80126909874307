import React from 'react';
import ResultValue from './ResultValue';


const Result = ({ item, result, resultIndex, reportData }) => {


  return (
    <>
      {
        item?.isRemovalForce ? (
          <>
            <div className='text-left'>
              {reportData?.samples?.length > 1 ? result?.sampleId.slice(-1) : ""}
              {
                result?.result?.filter((test, testIndex) => test.isReport).map((test, testIndex) => {
                  return <ResultValue key={testIndex} item={item} test={test} testIndex={testIndex} reportData={reportData} />
                }
                )
              }
            </div>
          </>) :
          <td className='text-left'>
            {
              result?.result?.filter((test, testIndex) => test.isReport).map((test, testIndex) => {
                return <ResultValue key={testIndex} item={item} reportData={reportData} test={test} testIndex={testIndex} />
              }
              )
            }
          </td>
      }





    </>
  );
}

export default Result;
