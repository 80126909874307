import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import tw from '../../../Utils/tailwind-react-pdf'

const RegistrationDetail = ({ heading, value }) => {
    return (
        <View style={tw(' flex flex-row gap-x-1 pt-0.5 ')}>
            <Text style={tw(' font-bold break-keep pt-1 w-2/6 ')}>{heading}</Text>
            <Text style={tw(' w-4/6 pt-1 -ml-10 capitalize')}>{value ? value?.toLowerCase() : "/"}</Text>
        </View>
    )
}

export default RegistrationDetail