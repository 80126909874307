import React, { useRef, useState } from 'react'
import { useGetRegistrationByIdQuery } from '../../../redux/LabServices/RegistrationServices'
import { useGetBranchQuery } from '../../../redux/services/BranchMasterService'
import { useReactToPrint } from 'react-to-print'
import { PreviewButtonOnly, PrintButtonOnly } from '../../../Buttons'
import PrintTestReportSheet from '../PrintFormat-TestReport'
import { passFailOption, splitTestOption } from '../../../Utils/DropdownData'
import { useGetLabTestResultEntryQuery } from '../../../redux/LabServices/LabTestResultEntryServices'
import secureLocalStorage from 'react-secure-storage'
import PrintInvoiceReport from '../PrintFormat-InvoiceReport'

const InvoiceReportContainer = ({ activeRegistrationId }) => {

    const [showSaveButton, setShowSaveButton] = useState(true)
    const [invoiceDocument, setInvoiceDocument] = useState(false)
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),

    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    });

    return (
        <div className='bg-white p-2'>
            <div className="flex w-full justify-end ">
                {/* <PrintButtonOnly onClick={()=>{handlePrint()}} /> */}
                <PreviewButtonOnly onClick={() => setInvoiceDocument(true)} />


            </div>

            <div className='overflow-auto flex flex-col'>
                <PrintInvoiceReport invoiceDocument={invoiceDocument} setInvoiceDocument={setInvoiceDocument} componentRef={componentRef} id={activeRegistrationId} setShowSaveButton={setShowSaveButton} showSaveButton={showSaveButton}
                />
            </div>
        </div>
    )
}

export default InvoiceReportContainer
