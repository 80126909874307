import React, { useContext, useEffect, useRef, } from "react";
import { TextInput, DropdownInput, DateInput } from "../../../../Inputs";
import secureLocalStorage from "react-secure-storage";
import { dropDownListObject } from "../../../../Utils/contructObject";
import { FieldsContext } from '..';
import { useGetServiceLevelMasterQuery } from "../../../../redux/LabServices/ServiceLevelMasterServices";
import { useGetSubmissionTypeMasterQuery } from "../../../../redux/LabServices/SubmissionTypeMasterServices";
import { useGetOnHoldReasonMasterQuery } from "../../../../redux/LabServices/OnHoldReasonMasterServices";
import { Loader } from "../../../../Basic/components";
import { findFromList } from "../../../../Utils/helper";
import moment from "moment";
import { endDateLoopFinder } from "../../helper";
import { useGetHolidaysCalenderQuery } from '../../../../redux/LabServices/HolidaysServices';
import { useState } from "react";

export default function Form({ serviceLevelIdFromApi, date, id }) {
    const fields = useContext(FieldsContext);
    const [holidays, setHolidays] = useState([]);

    const { data: allData, isLoading, isFetching } = useGetHolidaysCalenderQuery({});

    useEffect(() => {
        if (!allData?.data) return
        setHolidays(allData.data)
    }, [allData, isLoading, isFetching]);

    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: serviceLevelList, isLoading: isServiceLoading, isFetching: isServiceFetching } =
        useGetServiceLevelMasterQuery({ params });

    const { data: submissionTypeList, isLoading: isSubmissionLoading, isFetching: isSubmissionFetching } =
        useGetSubmissionTypeMasterQuery({ params });

    const { data: onHoldList, isLoading: isOnHoldLoading, isFetching: isOnHoldFetching } =
        useGetOnHoldReasonMasterQuery({ params });

    const dueDate = (days) => {
        let date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }

    useEffect(() => {
        if (serviceLevelIdFromApi) return
        let days = findFromList(fields.serviceLevelId, serviceLevelList?.data ? serviceLevelList.data : [], "days")
        let startDate = new Date(date);
        let endDate = dueDate(days)
        let finalEndDate = endDateLoopFinder(startDate, endDate, holidays)
        fields.setDueDate(finalEndDate);
    }, [fields, serviceLevelList, holidays, serviceLevelIdFromApi, date]);

    const toISTTimeOnly = (date) => {
        const options = {
            timeZone: 'Asia/Kolkata',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Use 24-hour format; set to true if you prefer 12-hour format
        };

        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    useEffect(() => {
        // if (serviceLevelIdFromApi) return
        // let serviceDueTime = (moment.utc(new Date()).format("hh-mm-ss a"))
        const serviceDueTime = new Date()
        const istDate = toISTTimeOnly(serviceDueTime)

        fields.setDueTime(istDate)
    }, [fields, serviceLevelIdFromApi, date])

    return (
        <div>
            {isServiceLoading || isServiceFetching || isSubmissionLoading || isSubmissionFetching || isOnHoldLoading || isOnHoldFetching
                ?
                <Loader />
                :
                <div>
                    <div className='grid grid-cols-5 my-2 gap-3'>
                        <DropdownInput autoFocus name="Service Level" options={dropDownListObject(fields.id ? (serviceLevelList?.data || []) : (serviceLevelList?.data.filter(item => item.active) || []), "name", "id")} value={fields.serviceLevelId} setValue={(value) => { fields.setServiceLevelId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <DropdownInput name="Submission Type" options={dropDownListObject(fields.id ? submissionTypeList.data : submissionTypeList.data.filter(item => item.active), "name", "id")} value={fields.submissionTypeId} setValue={fields.setSubmissionTypeId} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <DropdownInput name="On-Hold Reason" options={dropDownListObject(fields.id ? onHoldList.data.filter(item => parseInt(fields.submissionTypeId) === parseInt(item.submissionTypeId)) : onHoldList.data.filter(item => parseInt(fields.submissionTypeId) === parseInt(item.submissionTypeId)).filter(item => item.active), "name", "id")} value={fields.onHoldId} setValue={fields.setOnHoldId} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <DateInput name="Due Date" value={moment.utc(fields.dueDate).format("YYYY-MM-DD")} setValue={fields.setDueDate} required={true} readOnly={fields.readOnly} />
                        <TextInput name="Due Time" value={fields.dueTime} setValue={fields.setDueTime} required={true} readOnly={fields.readOnly} />

                        <div className='flex gap-x-5 w-full'>

                            {findFromList(fields.submissionTypeId, submissionTypeList.data, "name") == "CANCEL" && (
                                <div className='flex items-center justify-center text-xs'>
                                    <label className=''>Reason</label>
                                    <textarea

                                        className='border border-gray-400 w-[300px] ml-1 '
                                        value={fields.cancelReason}
                                        onChange={(e) => fields.setCancelReason(e.target.value)}
                                    ></textarea>
                                </div>

                            )}
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}