import React, { useRef } from 'react'
import { Document, Page, Text, View, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import Footer from '../../Laboratory/Components/PrintFormat-TestScanningSheet/Footer';

const SampleDocument = () => {
    const componentRef = useRef()
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#ffffff',
            padding: 20,
            fontFamily: 'Helvetica',
        },
        content: {
            marginBottom: 60, // Adjust to leave space for the footer
        },
        header: {
            textAlign: 'center',
            marginBottom: 180,
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            backgroundColor: '#eeeeee',
            padding: 10,
            height: 150, // Set the height of the footer
        },

    });

    return (

        <Document ref={componentRef}>
            <Page size="A4" style={styles.page}>





                <View fixed >
                    <Text style={{ backgroundColor: "red" }}>
                        You can add both a header and a footer to the exported pdf via PageTemplate, you will only need to position the passed elements differently.

                        For example, you can have a similar setup:

                        https://stackblitz.com/edit/react-kd4nyh-7zpxjj?file=index.html
                        Regards,
                        Vessy
                        Progress Telerik
                    </Text>
                </View>




                <View style={styles.content}>
                    <Text >
                        Vessy
                        answered on 22 Nov 2023, 06:02 PM
                        Hi, Tejas,

                        You can add both a header and a footer to the exported pdf via PageTemplate, you will only need to position the passed elements differently.

                        For example, you can have a similar setup:

                        https://stackblitz.com/edit/react-kd4nyh-7zpxjj?file=index.html
                        Regards,
                        Vessy
                        Progress Telerik

                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!


                        sesMedia CoverageCareersOffices
                        Copyright © 2024 Progress Software Corporation and/or its subsidiaries or affiliates. All Rights Reserved.

                        Progress, Telerik, Ipswitch, Chef, Kemp, Flowmon, MarkLogic, Semaphore and certain product names used herein are trademarks or registered trademarks of Progress Software Corporation and/or one of its subsidiaries or affiliates in the U.S. and/or other countries. See Trademarks for appropriate markings.

                        Terms of UseSite FeedbackPrivacy CenterSecurity Center
                        DO NOT SELL OR SHARE MY PERSONAL INFORMATION
                        Vessy
                        answered on 22 Nov 2023, 06:02 PM
                        Hi, Tejas,

                        You can add both a header and a footer to the exported pdf via PageTemplate, you will only need to position the passed elements differently.

                        For example, you can have a similar setup:

                        https://stackblitz.com/edit/react-kd4nyh-7zpxjj?file=index.html
                        Regards,
                        Vessy
                        Progress Telerik

                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!

                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM
                        Hi, Tejas,

                        Currently, this is blocked on the browser level, and we have no direct option to do it:

                        https://stackoverflow.com/questions/48420833/open-automatically-a-downloaded-file
                        Tejas commented on 27 Nov 2023, 04:35 PM | edited
                        Any solution based on this PDF can be sent as a backend and used in the API.
                        Tejas commented on 28 Nov 2023, 12:11 PM
                        It is possible to direct print option this Pdf
                        Tejas commented on 28 Nov 2023, 01:19 PM
                        How to convet this Pdf as Base64
                        Konstantin Dikov commented on 29 Nov 2023, 03:22 AM
                        Hi Tejas,
                        Vessy
                        answered on 22 Nov 2023, 06:02 PM
                        Hi, Tejas,

                        You can add both a header and a footer to the exported pdf via PageTemplate, you will only need to position the passed elements differently.

                        For example, you can have a similar setup:

                        https://stackblitz.com/edit/react-kd4nyh-7zpxjj?file=index.html
                        Regards,
                        Vessy
                        Progress Telerik

                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!

                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM
                        Hi, Tejas,

                        Currently, this is blocked on the browser level, and we have no direct option to do it:

                        https://stackoverflow.com/questions/48420833/open-automatically-a-downloaded-file
                        Tejas commented on 27 Nov 2023, 04:35 PM | edited
                        Any solution based on this PDF can be sent as a backend and used in the API.
                        Tejas commented on 28 Nov 2023, 12:11 PM
                        It is possible to direct print option this Pdf
                        Tejas commented on 28 Nov 2023, 01:19 PM
                        How to convet this Pdf as Base64
                        Konstantin Dikov commented on 29 Nov 2023, 03:22 AM
                        Hi Tejas,
                        Vessy
                        answered on 22 Nov 2023, 06:02 PM
                        Hi, Tejas,

                        You can add both a header and a footer to the exported pdf via PageTemplate, you will only need to position the passed elements differently.

                        For example, you can have a similar setup:

                        https://stackblitz.com/edit/react-kd4nyh-7zpxjj?file=index.html
                        Regards,
                        Vessy
                        Progress Telerik

                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!

                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM
                        Hi, Tejas,

                        Currently, this is blocked on the browser level, and we have no direct option to do it:

                        https://stackoverflow.com/questions/48420833/open-automatically-a-downloaded-file
                        Tejas commented on 27 Nov 2023, 04:35 PM | edited
                        Any solution based on this PDF can be sent as a backend and used in the API.
                        Tejas commented on 28 Nov 2023, 12:11 PM
                        It is possible to direct print option this Pdf
                        Tejas commented on 28 Nov 2023, 01:19 PM
                        How to convet this Pdf as Base64
                        Konstantin Dikov commented on 29 Nov 2023, 03:22 AM
                        Hi Tejas,
                        Vessy
                        answered on 22 Nov 2023, 06:02 PM
                        Hi, Tejas,

                        You can add both a header and a footer to the exported pdf via PageTemplate, you will only need to position the passed elements differently.

                        For example, you can have a similar setup:

                        https://stackblitz.com/edit/react-kd4nyh-7zpxjj?file=index.html
                        Regards,
                        Vessy
                        Progress Telerik

                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!

                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM
                        Hi, Tejas,

                        Currently, this is blocked on the browser level, and we have no direct option to do it:

                        https://stackoverflow.com/questions/48420833/open-automatically-a-downloaded-file
                        Tejas commented on 27 Nov 2023, 04:35 PM | edited
                        Any solution based on this PDF can be sent as a backend and used in the API.
                        Tejas commented on 28 Nov 2023, 12:11 PM
                        It is possible to direct print option this Pdf
                        Tejas commented on 28 Nov 2023, 01:19 PM
                        How to convet this Pdf as Base64
                        Konstantin Dikov commented on 29 Nov 2023, 03:22 AM
                        Hi Tejas,
                        Vessy
                        answered on 22 Nov 2023, 06:02 PM
                        Hi, Tejas,

                        You can add both a header and a footer to the exported pdf via PageTemplate, you will only need to position the passed elements differently.

                        For example, you can have a similar setup:

                        https://stackblitz.com/edit/react-kd4nyh-7zpxjj?file=index.html
                        Regards,
                        Vessy
                        Progress Telerik

                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!

                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM
                        Hi, Tejas,

                        Currently, this is blocked on the browser level, and we have no direct option to do it:

                        https://stackoverflow.com/questions/48420833/open-automatically-a-downloaded-file
                        Tejas commented on 27 Nov 2023, 04:35 PM | edited
                        Any solution based on this PDF can be sent as a backend and used in the API.
                        Tejas commented on 28 Nov 2023, 12:11 PM
                        It is possible to direct print option this Pdf
                        Tejas commented on 28 Nov 2023, 01:19 PM
                        How to convet this Pdf as Base64
                        Konstantin Dikov commented on 29 Nov 2023, 03:22 AM
                        Hi Tejas,

                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!

                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM
                        Hi, Tejas,

                        Currently, this is blocked on the browser level, and we have no direct option to do it:

                        https://stackoverflow.com/questions/48420833/open-automatically-a-downloaded-file
                        Tejas commented on 27 Nov 2023, 04:35 PM | edited
                        Any solution based on this PDF can be sent as a backend and used in the API.
                        Tejas commented on 28 Nov 2023, 12:11 PM
                        It is possible to direct print option this Pdf
                        Tejas commented on 28 Nov 2023, 01:19 PM
                        How to convet this Pdf as Base64
                        Konstantin Dikov commented on 29 Nov 2023, 03:22 AM
                        Hi Tejas,
                        Stay tuned by visiting our public roadmap and feedback portal pages! Or perhaps, if you are new to our Kendo family, check out our getting started resources!

                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM
                        Hi, Tejas,

                        Currently, this is blocked on the browser level, and we have no direct option to do it:

                        https://stackoverflow.com/questions/48420833/open-automatically-a-downloaded-file
                        Tejas commented on 27 Nov 2023, 04:35 PM | edited
                        Any solution based on this PDF can be sent as a backend and used in the API.
                        Tejas commented on 28 Nov 2023, 12:11 PM
                        It is possible to direct print option this Pdf
                        Tejas commented on 28 Nov 2023, 01:19 PM
                        How to convet this Pdf as Base64
                        Konstantin Dikov commented on 29 Nov 2023, 03:22 AM
                        Hi Tejas,


                    </Text>
                </View>

                <View fixed style={styles.footer}>
                    <Text>
                        Tejas commented on 23 Nov 2023, 11:17 AM
                        Wow, it's great. is working perfectly. But I have one question on my mind. It is possible to click the button; it will generate a pdf and show the same browser.
                        Vessy commented on 24 Nov 2023, 11:19 PM

                    </Text>
                </View>





            </Page>



        </Document>
    )
}

export default SampleDocument