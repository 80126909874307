import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { REGISTRATION_API } from "../../Api";
import { getCommonParams } from "../../Utils/helper";

const BASE_URL = process.env.REACT_APP_SERVER_URL;
const { finYearId } = getCommonParams()
const RegistrationApi = createApi({
  reducerPath: "registration",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["Registration"],
  endpoints: (builder) => ({
    getRegistration: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: REGISTRATION_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: REGISTRATION_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params: { ...params, finYearId }
        };
      },
      providesTags: ["Registration"],
    }),
    getRegistrationById: builder.query({
      query: ({ id, params }) => {
        return {
          url: `${REGISTRATION_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Registration"],
    }),
    addRegistration: builder.mutation({
      query: (payload) => ({
        url: REGISTRATION_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Registration"],
    }),
    sampleImageUpload: builder.mutation({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `${REGISTRATION_API}/sampleImageUpload/${id}`,
          method: "PATCH",
          body,
        };

      },
      invalidatesTags: ["Registration"],
    }),
    testedImageUpload: builder.mutation({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `${REGISTRATION_API}/testedImageUpload/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Registration"],
    }),
    testedImageDelete: builder.mutation({
      query: (id) => ({
        url: `${REGISTRATION_API}/removeTestedImage/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Registration"],
    }),

    failureImageUpload: builder.mutation({
      query: (payload) => {
        const { id, body } = payload;
        return {
          url: `${REGISTRATION_API}/failureImageUpload/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Registration"],
    }),
    failureImageDelete: builder.mutation({
      query: (id) => ({
        url: `${REGISTRATION_API}/removeFailureImage/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Registration"],
    }),

    updateRegistration: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${REGISTRATION_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Registration"],
    }),
    amendRegistration: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${REGISTRATION_API}/amendment/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Registration"],
    }),
    deleteRegistration: builder.mutation({
      query: (id) => ({
        url: `${REGISTRATION_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Registration"],
    }),
  }),
});

export const {
  useGetRegistrationQuery,
  useGetRegistrationByIdQuery,
  useAddRegistrationMutation,
  useTestedImageUploadMutation,
  useTestedImageDeleteMutation,
  useFailureImageUploadMutation,
  useFailureImageDeleteMutation,
  useUpdateRegistrationMutation,
  useDeleteRegistrationMutation,
  useSampleImageUploadMutation,
  useAmendRegistrationMutation
} = RegistrationApi;

export default RegistrationApi;
