import React from 'react'

import { Loader } from '../../../Basic/components'
import { findFromList, params } from "../../../Utils/helper"

const SampleDetails = ({ data, sampleItem }) => {


  return (
    <div className='text-xs p-3 '>

      <div className='overflow-x-auto p-2'>
        <table className='w-full mt-1 border border-gray-500 table-fixed p-2'>
          <tbody>
            <tr className=' '>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>Supplier Name : </td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words  '>{data?.Supplier ? data?.Supplier.name : ""}</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>Sample Description :</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words '>{data?.sampleDescription ? data?.sampleDescription : ""}</td>
            </tr>
            <tr className='  '>

              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>Color Name : :</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words  '>{data?.color ? data.color : ""}</td>
              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words'>Style No :</td>
              <td className=' text-xs border border-gray-600  p-1 whitespace-normal break-words '>{data?.styleNo ? data.styleNo : ""}</td>
            </tr>
            <tr className='   '>

              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words '>Batch/Lot No :</td>
              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words '>{data?.batchNo ? data.batchNo : ""}</td>
              <td className='text-xs  border border-gray-600 p-1 whitespace-normal break-words '>Order No :</td>
              <td className='text-xs border border-gray-600  p-1  whitespace-normal break-words'>{data?.orderNo ? data.orderNo : ""}</td>
            </tr>

            <tr className='   '>

              <td className='text-xs  border border-gray-600 p-1 whitespace-normal break-words'>Claimed Fabric Weight:</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words  '>{`${(sampleItem?.RegistrationSampleDetailsClaimedFabricWeight ? sampleItem?.RegistrationSampleDetailsClaimedFabricWeight : []).map(item => `   ${item.subHeading} - ${item.value}  ${item.UnitOfMeasurement.name}`).join("&")}`}</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words '>End Use:</td>
              <td className='text-xs border border-gray-600  p-1  whitespace-normal break-words'>{data?.RegistrationEndUseDetails ? data.RegistrationEndUseDetails.map(item => item.EndUse.name).toString() : ""}</td>
            </tr>

            <tr className=' '>

              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words'>Claimed Fiber Content:</td>
              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words '>{`${(sampleItem?.RegistrationSampleDetailsClaimedFiberContent ? sampleItem?.RegistrationSampleDetailsClaimedFiberContent : []).map(item => `   ${item.subHeading} - ${item?.FiberContent?.aliasName} `).join("&")}`}</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words '>Season:</td>
              <td className='text-xs border border-gray-600   p-1 whitespace-normal break-words '>{data?.season ? data.season : ""}</td>
            </tr>

            <tr className=' '>

              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words'>Claimed Yarn Count:</td>
              <td className=' text-xs border border-gray-600 p-1 whitespace-normal break-words  '>{`${(sampleItem?.RegistrationSampleDetailsClaimedYarnCount ? sampleItem?.RegistrationSampleDetailsClaimedYarnCount : []).map(item => `    ${item.subHeading} - ${item.value}  ${item.UnitOfMeasurement.name}`).join("&")}`}</td>
              <td className='text-xs  border border-gray-600 p-1 whitespace-normal break-words'>Phase:</td>
              <td className='text-xs border border-gray-600  p-1  whitespace-normal break-words'>{data?.phase ? data.phase : ""}</td>
            </tr>

            <tr className='   '>

              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>Claimed Fabric Count:</td>
              <td className=' text-xsborder border-gray-600 p-1 whitespace-normal break-words'>{`${(sampleItem?.RegistrationSampleDetailsClaimedFabricCount ? sampleItem?.RegistrationSampleDetailsClaimedFabricCount : []).map(item => `   ${item.subHeading} - ${item.value}  ${item.UnitOfMeasurement.name}`).join("&")}`}</td>
              <td className='text-xs  border border-gray-600 p-1  whitespace-normal break-words'>Age Range:</td>
              <td className='text-xs border border-gray-600  p-1  whitespace-normal break-words'>{data?.sizerange ? data.sizerange : ""}</td>
            </tr>

            <tr className=' '>

              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words'>Country Of Origin:</td>
              <td className=' text-xs border border-gray-600 p-1 whitespace-normal break-words '>{data?.CountryOfOrigin ? data.CountryOfOrigin.name : ""}</td>
              <td className='text-xs  border border-gray-600 p-1 whitespace-normal break-words'>Submitted Size:</td>
              <td className='text-xs border border-gray-600  p-1  whitespace-normal break-words'>{data?.submittedSize ? data.submittedSize : ""}</td>
            </tr>

            <tr className='  '>

              <td className='text-xs  border border-gray-600 p-1  whitespace-normal break-words'>Country Of Destination:</td>
              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words'>{`${(data?.CountryOfDestinationIds ? data?.CountryOfDestinationIds : []).map(item => `   ${item.Country?.name} `).join("/")}`}</td>

              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>Test Stage (Package):</td>
              <td className=' text-xs border border-gray-600  p-1 whitespace-normal break-words'>{data?.testStage ? data.testStage : ""}</td>
            </tr>

            <tr className=''>

              <td className='text-xs  border border-gray-600 p-1 whitespace-normal break-words '>Retest:</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words '>{data?.retest ? "YES" : "NO"}</td>
              <td className='text-xs border border-gray-600 p-1  whitespace-normal break-words  '>Previous Report NO:</td>
              <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>{data?.prevReportNo ? data.prevReportNo : ""}</td>


            </tr>


          </tbody>
        </table>
      </div>

    </div>

  )
}

export default SampleDetails