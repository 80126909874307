
import React, { useContext, useRef, } from "react";
import { DropdownInput, TextInput } from "../../../Inputs";

import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import { Loader } from "../../../Basic/components";
import secureLocalStorage from "react-secure-storage";
import { useGetFiberContentMasterQuery } from "../../../redux/ErpServices/FiberContentMasterServices";
import { dropDownListObject } from "../../../Utils/contructObject";
import { FieldsContext } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { walesCourseList } from "../../../Utils/DropdownData";

export default function ClaimedDetailsSampleWise({id, readOnly, sampleDetails, setSampleDetails, currentActiveTab,setIsClaimedDetailsOpen}) {
    const fields = useContext(FieldsContext);

    const childRecord = useRef(0);
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        )
    };

    const { data: unitList, isLoading: unitLoading, isFetching: unitFetching } =
    useGetUnitOfMeasurementMasterQuery({ params });

    const { data: fiberContentList, isLoading: fiberContentLoading, isFetching: fiberContentFetching } =
    useGetFiberContentMasterQuery({ params });

    let mainIndex = sampleDetails.findIndex(item => item.sampleId === currentActiveTab)
   

    function handleOnChange(value,field,index,item,valueIndex){
        
        setSampleDetails(sampleDetails => {
            let newSampleDetails = structuredClone(sampleDetails)
            newSampleDetails[mainIndex][item][index][field] = value
            return newSampleDetails
        })

    }

    if (unitLoading || unitFetching || fiberContentLoading || fiberContentFetching) return <Loader />

    return (
        <div>

<div className="grid grid-cols-2 gap-x-1 gap-y-3">{console.log(sampleDetails,"samppp",fields.claimedFabricWeight)}

          <div className=''>
    <div className="text-start text-xs">Claimed Fabric Weight   </div>
    <table className="border border-gray-500 ">
        <thead className="border border-gray-500">
            <tr>
                <th className="border border-gray-500 w-24 text-xs p-0.5">Sub Heading </th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">Value</th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">Unit </th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">

                    <button type='button' className="text-green-700 text-xs " onClick={() => {

                      setSampleDetails(prev=>{
                        let newPrev =structuredClone(prev)
                        newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricWeight"]=[...newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricWeight"],{  subHeading :"",
                                        value :"",
                                        unitId:"",}]
                        return newPrev
                      })


                    }}> {<FontAwesomeIcon icon={faUserPlus} />}
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
       
        {sampleDetails[mainIndex]["RegistrationSampleDetailsClaimedFabricWeight"].map((item, itemIndex) =>
             <tr key={itemIndex}>
                 <td className="border border-gray-500 text-xs ">
                     <input name="" type="text" className="p-0.5   text-center" value={item.subHeading} onChange={(e) => handleOnChange(e.target.value,"subHeading",itemIndex,"RegistrationSampleDetailsClaimedFabricWeight")
                     } />
                 </td>
                 <td className="border border-gray-500 text-xs ">
                     <input name="" type="text" className="p-0.5   text-center " value={item.value}  onChange={(e) => handleOnChange(e.target.value,"value",itemIndex,"RegistrationSampleDetailsClaimedFabricWeight")} />
                 </td>
                 <td className="border border-gray-500 text-xs ">
                     <select name="" id="" value={item.unitId} className=" p-0.5  text-xs "  onChange={(e) => handleOnChange(e.target.value,"unitId",itemIndex,"RegistrationSampleDetailsClaimedFabricWeight")}   >
                         <option value="">Select</option>
                         {(unitList?.data ? unitList?.data : []).map(f =>
                             <option value={f.id}> {f.name} </option>
                         )}
                     </select>
                 </td>
                 <td className="border border-gray-500 text-xs text-center">
                     <button
                     
                         type='button'
                         onClick={() => {
                            setSampleDetails(prev => {
                                 let newPrev = structuredClone(prev)
                                 newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricWeight"] = newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricWeight"] .filter((_, i) => i !== itemIndex)
                                 return newPrev
                             })
                         }}
                         className='text-xs text-red-600 '>{<FontAwesomeIcon icon={faTrashCan} />}
                     </button>

                 </td>
             </tr>
           
            
            )}
           
        </tbody>
    </table>
</div>


<div className=''>
    <div className="text-start text-xs">Claimed Yarn Count   </div>
    <table className="border border-gray-500 ">
        <thead className="border border-gray-500">
            <tr>
                <th className="border border-gray-500 w-24 text-xs p-0.5">Sub Heading </th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">Value</th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">Unit </th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">

                    <button type='button' className="text-green-700 text-xs " onClick={() => {
                        setSampleDetails(prev=>{
                            let newPrev =structuredClone(prev)
                            newPrev[mainIndex]["RegistrationSampleDetailsClaimedYarnCount"]=[...newPrev[mainIndex]["RegistrationSampleDetailsClaimedYarnCount"],{  subHeading :"",
                                            value :"",
                                            unitId:"",}]
                            return newPrev
                          })
    

                    }}> {<FontAwesomeIcon icon={faUserPlus} />}
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
       
              {sampleDetails[mainIndex]["RegistrationSampleDetailsClaimedYarnCount"]?.map((item, itemIndex) =>
             <tr key={itemIndex}>
                 <td className="border border-gray-500 text-xs ">
                     <input name="" type="text" className="p-0.5   text-center" value={item.subHeading} onChange={(e) => handleOnChange(e.target.value,"subHeading",itemIndex,"RegistrationSampleDetailsClaimedYarnCount")
                     } />
                 </td>
                 <td className="border border-gray-500 text-xs ">
                     <input name="" type="text" className="p-0.5   text-center " value={item.value}  onChange={(e) => handleOnChange(e.target.value,"value",itemIndex,"RegistrationSampleDetailsClaimedYarnCount")} />
                 </td>
                 <td className="border border-gray-500 text-xs ">
                     <select name="" id="" value={item.unitId} className=" p-0.5  text-xs "  onChange={(e) => handleOnChange(e.target.value,"unitId",itemIndex,"RegistrationSampleDetailsClaimedYarnCount")}   >
                         <option value="">Select</option>
                         {(unitList?.data ? unitList?.data : []).map(f =>
                             <option value={f.id}> {f.name} </option>
                         )}
                     </select>
                 </td>
                 <td className="border border-gray-500 text-xs text-center">
                     <button
                     
                         type='button'
                         onClick={() => {
                            setSampleDetails(prev => {
                                 let newPrev = structuredClone(prev)
                                 newPrev[mainIndex]["RegistrationSampleDetailsClaimedYarnCount"] = newPrev[mainIndex]["RegistrationSampleDetailsClaimedYarnCount"] .filter((_, i) => i !== itemIndex)
                                 return newPrev
                             })
                         }}
                         className='text-xs text-red-600 '>{<FontAwesomeIcon icon={faTrashCan} />}
                     </button>

                 </td>
             </tr>
             
            
            )}
           
        </tbody>
    </table>
</div>


<div className=''>
    <div className="text-start text-xs">Claimed Fabric Count   </div>
    <table className="border border-gray-500 table-fixed">
        <thead className="border border-gray-500">
            <tr>
                <th className="border border-gray-500 w-24 text-xs p-0.5">Sub Heading </th>
                <th className="border border-gray-500 w-24 text-xs p-0.5">W/C </th>

                <th className="border border-gray-500 w-16 text-xs p-0.5">Value</th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">Unit </th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">

                    <button type='button' className="text-green-700 text-xs " onClick={() => {
                         setSampleDetails(prev=>{
                            let newPrev =structuredClone(prev)
                            newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricCount"]=[...newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricCount"],{  subHeading :"",
                                            value :"",
                                            unitId:"",}]
                            return newPrev
                          })

                    }}> {<FontAwesomeIcon icon={faUserPlus} />}
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>{console.log(sampleDetails,"sampleDetails")}
      
        {sampleDetails[mainIndex]["RegistrationSampleDetailsClaimedFabricCount"]?.map((item, itemIndex) =>
             <tr key={itemIndex}>
                 <td className="border border-gray-500 text-xs ">
                     <input name="" type="text" className="p-0.5   text-center" value={item.subHeading} onChange={(e) => handleOnChange(e.target.value,"subHeading",itemIndex,"RegistrationSampleDetailsClaimedFabricCount")
                     } />
                 </td>
                 <td className="border border-gray-500 text-xs w-32">
               
                <select name="" id="" value={item.walesCourse} className=" p-0.5 w-full text-xs "  onChange={(e) => handleOnChange(e.target.value,"walesCourse",itemIndex,"RegistrationSampleDetailsClaimedFabricCount")}   >
                         <option value="">Select</option>
                         {(walesCourseList ? walesCourseList : []).map(f =>
                             <option value={f.value}> {f.show} </option>
                         )}
                     </select>
                
                 </td>
                 <td className="border border-gray-500 text-xs ">
                     <input name="" type="text" className="p-0.5   text-center " value={item.value}  onChange={(e) => handleOnChange(e.target.value,"value",itemIndex,"RegistrationSampleDetailsClaimedFabricCount")} />
                 </td>
                 <td className="border border-gray-500 text-xs ">
                     <select name="" id="" value={item.unitId} className=" p-0.5  text-xs "  onChange={(e) => handleOnChange(e.target.value,"unitId",itemIndex,"RegistrationSampleDetailsClaimedFabricCount")}   >
                         <option value="">Select</option>
                         {(unitList?.data ? unitList?.data : []).map(f =>
                             <option value={f.id}> {f.name} </option>
                         )}
                     </select>
                 </td>
                 <td className="border border-gray-500 text-xs text-center">
                     <button
                     
                         type='button'
                         onClick={() => {
                            setSampleDetails(prev => {
                                 let newPrev = structuredClone(prev)
                                 newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricCount"] = newPrev[mainIndex]["RegistrationSampleDetailsClaimedFabricCount"] .filter((_, i) => i !== itemIndex)
                                 return newPrev
                             })
                         }}
                         className='text-xs text-red-600 '>{<FontAwesomeIcon icon={faTrashCan} />}
                     </button>

                 </td>
             </tr>
             
            
            )}
           
        </tbody>
    </table>
</div>


<div>

<div className=''>
    <div className="text-start text-xs">Claimed Fiber Content   </div>
    <table className="border border-gray-500 ">
        <thead className="border border-gray-500">
            <tr>
                <th className="border border-gray-500 w-24 text-xs p-0.5">Sub Heading </th>
              
                <th className="border border-gray-500 w-16 text-xs p-0.5">Unit </th>
                <th className="border border-gray-500 w-16 text-xs p-0.5">

                    <button type='button' className="text-green-700 text-xs " onClick={() => {
                        setSampleDetails(prev=>{
                            let newPrev =structuredClone(prev)
                            newPrev[mainIndex]["RegistrationSampleDetailsClaimedFiberContent"]=[...newPrev[mainIndex]["RegistrationSampleDetailsClaimedFiberContent"],{  subHeading :"",
                                           
                            fiberContentId:"",}]
                            return newPrev
                          })


                    }}> {<FontAwesomeIcon icon={faUserPlus} />}
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
        
        {sampleDetails[mainIndex]["RegistrationSampleDetailsClaimedFiberContent"]?.map((item, itemIndex) =>
           <tr key={itemIndex}>
               <td className="border border-gray-500 text-xs ">
                   <input name="" type="text" className="p-0.5   text-center" value={item.subHeading} onChange={(e) => handleOnChange(e.target.value,"subHeading",itemIndex,"RegistrationSampleDetailsClaimedFiberContent")
                   } />
               </td>
             
               <td className="border border-gray-500 text-xs ">
                   <select name="" id="" value={item.fiberContentId} className=" p-0.5  text-xs "  onChange={(e) => handleOnChange(e.target.value,"fiberContentId",itemIndex,"RegistrationSampleDetailsClaimedFiberContent")}   >
                       <option value="">Select</option>
                       {(fiberContentList.data ? fiberContentList.data : []).map(f =>
                           <option value={f.id}> {f.aliasName} </option>
                       )}
                   </select>
               </td>
               <td className="border border-gray-500 text-xs text-center">
                   <button
                   
                       type='button'
                       onClick={() => {
                          setSampleDetails(prev => {
                               let newPrev = structuredClone(prev)
                               newPrev[mainIndex]["RegistrationSampleDetailsClaimedFiberContent"] = newPrev[mainIndex]["RegistrationSampleDetailsClaimedFiberContent"] .filter((_, i) => i !== itemIndex)
                               return newPrev
                           })
                       }}
                       className='text-xs text-red-600 '>{<FontAwesomeIcon icon={faTrashCan} />}
                   </button>

               </td>
           </tr>
       
        )}
            
            
        
           
        </tbody>
    </table>
</div>


</div>





</div>

<div className=" grid justify-end">
          <button
            onKeyDown={e => {
             if (e.code === "Enter") {
               setIsClaimedDetailsOpen(false)
          }

         }}
         className='bg-green-400 rounded-lg p-1 w-32' onClick={() => setIsClaimedDetailsOpen(false)}>Done
         </button>
         </div>





            {/* <div className='grid grid-cols-2  gap-y-12 gap-x-3 mt-9 mb-5 ml-4'>
          
            <div  className="flex text-xs w-96">
           <label className="grid justify-center items-center"> Claimed Fabric Weight</label>
            <input name="" type="text" className="h-6  grid border border-gray-500 rounded-l-md text-center w-24 ml-1" value={sampleDetails[index]?.claimedFabricWeight ? sampleDetails[index]?.claimedFabricWeight : ""} onChange={(e) =>handleOnChange(e.target.value,"claimedFabricWeight")}  />

            <select name="" id=""  value={sampleDetails[index]?.claimedFabricWeightUnitId ? sampleDetails[index]?.claimedFabricWeightUnitId : ""} className="border border-gray-500 rounded-r-md text-xs h-6 w-28" onChange={(e) => handleOnChange(e.target.value,"claimedFabricWeightUnitId")} >
                <option value="">Select</option>
                 {(unitList?.data ? unitList?.data : []).map(f =>
                  <option value={f.id}> {f.name} </option>
                   )}
              </select>
           
            </div>

            <div  className="flex text-xs w-96">
            <label className="grid justify-center items-center">  Claimed Yarn Count</label> 
            <input type="text" className={`h-6 border border-gray-500 rounded-l-md text-center w-24 ml-1 `}  value={sampleDetails[index]?.claimedYarnCount ? sampleDetails[index]?.claimedYarnCount : ""}  onChange={(e) => handleOnChange(e.target.value,"claimedYarnCount")}  />

            <select name="" id="" value={sampleDetails[index]?.claimedYarnCountUnitId ? sampleDetails[index]?.claimedYarnCountUnitId : ""}  className=" h-6 w-28 border border-gray-500 rounded-r-md text-xs" onChange={(e) => handleOnChange(e.target.value,"claimedYarnCountUnitId")} >
                <option value="">Select</option>
                 {(unitList?.data ? unitList?.data : []).map(f =>
                  <option value={f.id}> {f.name} </option>
                   )}
              </select>
           
            </div>



            <div  className="flex text-xs w-96">
            <label className="grid justify-center items-center">Claimed Fabric Count </label>
            <input type="text" className="h-6 border border-gray-500 rounded-l-md text-center w-24 ml-1" value={sampleDetails[index]?.claimedFabricCount ? sampleDetails[index]?.claimedFabricCount : ""}   onChange={(e) => handleOnChange(e.target.value,"claimedFabricCount")} />

            <select name="" id="" value={sampleDetails[index]?.claimedFabricCountUnitId ? sampleDetails[index]?.claimedFabricCountUnitId : ""}  className="border border-gray-500 rounded-r-md text-xs h-6 w-28" onChange={(e) => handleOnChange(e.target.value,"claimedFabricCountUnitId")} >
                <option value="">Select</option>
                 {(unitList?.data ? unitList?.data : []).map(f =>
                  <option value={f.id}> {f.name} </option>
                   )}
              </select>
           
            </div>


            <div  className="flex text-xs w-96 gap-1">
            <label className="grid justify-center items-center">Claimed Fiber Content </label>

            <select name="" id="" value={sampleDetails[index]?.claimedFiberContentId ? sampleDetails[index]?.claimedFiberContentId : ""}   className="border border-gray-500 rounded text-xs h-6 w-48" onChange={(e) => handleOnChange(e.target.value,"claimedFiberContentId")} >
                <option value="">Select</option>
                 {(fiberContentList?.data ?fiberContentList.data : []).map(f =>
                  <option value={f.id}> {f.aliasName} </option>
                   )}
              </select>
              </div>
        
            </div>

            <div className=" grid justify-end">
          <button
            onKeyDown={e => {
             if (e.code === "Enter") {
               setIsClaimedDetailsOpen(false)
          }

         }}
         className='bg-green-400 rounded-lg p-1 w-32' onClick={() => setIsClaimedDetailsOpen(false)}>Done
         </button>
         </div> */}
        </div>
           
    )
}