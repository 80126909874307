import React from 'react'
import tw from '../../../../Utils/tailwind-react-pdf'

import { getImageUrlPath } from '../../../../Utils/helper'

import { Text, View, StyleSheet,Image } from '@react-pdf/renderer';

import WashCareDataPrint from './WashCareDataPrint';

const WashCareInstructionPrint = ({washCareData,washCareDetails,sampleItem}) => {
   
  return (
    < >
    
      {washCareDetails.map((value,index)=> <WashCareDataPrint washCareData={washCareData} value={value}  key={value.washCareId} index={index}  sampleItem={sampleItem}/> )} 
    
           
             
           

</>
  )
}

export default WashCareInstructionPrint