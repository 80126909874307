import React from 'react'
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import TestParametersLists from './TestParametersLists';
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';

const TestStatusDate = ({ val, valIndex, dataObj }) => {

    return (
        <>
            <tr className='w-full'>
                <td className=' py-1.5 text-left gridAlignLeft capitalize'>

                    {val?.finishedDate ? getDateFromDateTimeToDisplay(val?.finishedDate) : ""}
                </td>
            </tr>


        </>
    )
}

export default TestStatusDate