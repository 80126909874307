import React, { useContext, useRef, } from "react";
import { TextInput, DropdownInput } from "../../../../Inputs";
import secureLocalStorage from "react-secure-storage";
import { dropDownListObject } from "../../../../Utils/contructObject";
import { FieldsContext } from '..';
import { useGetPartyQuery } from "../../../../redux/ErpServices/PartyMasterServices";
import { Loader } from "../../../../Basic/components";


export default function Form() {
    const fields = useContext(FieldsContext);

    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
        useGetPartyQuery({ params });


    return (
        <div>
            {isSupplierLoading || isSupplierFetching
                ?
                <Loader />
                :
                <div>
                    <div className='grid grid-cols-3 my-2'>
                        <DropdownInput autoFocus name="Supplier Name" options={dropDownListObject(fields.id ? supplierList.data : supplierList.data.filter(item => item.active), "name", "id")} value={fields.supplierName} setValue={fields.setSupplierName} required={true} readOnly={fields.readOnly} />
                        <TextInput name="Submitted by" type="text" value={fields.submittedBy} setValue={fields.setSubmittedBy} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Submitted Size" type="text" value={fields.submittedSize} setValue={fields.setSubmittedSize} required={true} readOnly={fields.readOnly} />
                    </div>
                </div>
            }
        </div>
    )
}