import React, { useEffect, useState } from 'react'
import { TextInput } from '../../../Inputs';
import { useGetBranchByIdQuery, useUpdateBranchMutation } from '../../../redux/services/BranchMasterService';
import { getCommonParams } from '../../../Utils/helper';
import { toast } from 'react-toastify';

const AmendmentPrice = () => {
    const [amendmentPrice, setAmendmentPrice] = useState("");



    const { branchId } = getCommonParams()
    const { data, isLoading, isFetching } = useGetBranchByIdQuery(branchId);



    useEffect(() => {
        if (!data?.data) return
        let currentData = data?.data;
        setAmendmentPrice(currentData?.amendmentPrice ? currentData?.amendmentPrice : "");

    }, [data, isLoading, isFetching])

    const [updateBranch] = useUpdateBranchMutation()

    const handleSubmit = async (text = "uploaded") => {
        try {
            await updateBranch({ id: branchId, amendmentPrice, isAmendmentPrice: true });
            toast.success(text + " Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    return (
        <fieldset className='w-1/2'>
            <legend className='sub-heading'>Amendment Price</legend>
            <TextInput name="Amendment.Price" type="text" value={amendmentPrice} setValue={setAmendmentPrice} required={true} />

            <div className='flex justify-end'>
                <button className='bg-green-600 hover:bg-green-700 text-gray-100 rounded-md p-1' onClick={() => handleSubmit()}>Save</button>
            </div>
        </fieldset>
    )
}

export default AmendmentPrice
