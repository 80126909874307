import React from 'react'

const TestLists = ({ val, valIndex }) => {
    return (
        <>

            <tr className='text-left'>
                <td className=' py-1 gridAlignLeft text-left capitalize'>
                    *{val?.LabTest?.name.toLowerCase() || ""}
                </td>

            </tr>

        </>
    )
}

export default TestLists