import React from 'react'
import ReqValue from './ReqValue'
import { Text, View } from '@react-pdf/renderer'


const ResultReq = ({ req, reqIndex }) => {
  return (
    <>
      <View className='p-1'>

        ({
          req?.result?.map((test, testIndex) => {
            return <ReqValue key={testIndex} test={test} testIndex={testIndex} />
          }
          )
        })
      </View>
    </>
  )
}

export default ResultReq