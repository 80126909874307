import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SAMPLE_DISCRIPTION_TWO_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const SampleDescriptionTwoMasterApi = createApi({
  reducerPath: "sampleDescriptionTwoMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["SampleDescriptionTwoMaster"],
  endpoints: (builder) => ({
    getSampleDescriptionTwoMaster: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: SAMPLE_DISCRIPTION_TWO_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: SAMPLE_DISCRIPTION_TWO_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["SampleDescriptionTwoMaster"],
    }),
    getSampleDescriptionTwoMasterById: builder.query({
      query: (id) => {
        return {
          url: `${SAMPLE_DISCRIPTION_TWO_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["SampleDescriptionTwoMaster"],
    }),
    addSampleDescriptionTwoMaster: builder.mutation({
      query: (payload) => ({
        url: SAMPLE_DISCRIPTION_TWO_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["SampleDescriptionTwoMaster"],
    }),
    updateSampleDescriptionTwoMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${SAMPLE_DISCRIPTION_TWO_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["SampleDescriptionTwoMaster"],
    }),
    deleteSampleDescriptionTwoMaster: builder.mutation({
      query: (id) => ({
        url: `${SAMPLE_DISCRIPTION_TWO_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SampleDescriptionTwoMaster"],
    }),
  }),
});

export const {
  useGetSampleDescriptionTwoMasterQuery,
  useGetSampleDescriptionTwoMasterByIdQuery,
  useAddSampleDescriptionTwoMasterMutation,
  useUpdateSampleDescriptionTwoMasterMutation,
  useDeleteSampleDescriptionTwoMasterMutation,
} = SampleDescriptionTwoMasterApi;

export default SampleDescriptionTwoMasterApi;
