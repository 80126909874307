import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PACKAGE_TEMPLATE_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const PackageTemplateApi = createApi({
  reducerPath: "packageTemplate",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["Party"],
  endpoints: (builder) => ({
    getPackageTemplate: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: PACKAGE_TEMPLATE_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PACKAGE_TEMPLATE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Party"],
    }),
    getPackageTemplateById: builder.query({
      query: (id) => {
        return {
          url: `${PACKAGE_TEMPLATE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Party"],
    }),
    addPackageTemplate: builder.mutation({
      query: (payload) => ({
        url: PACKAGE_TEMPLATE_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Party"],
    }),
    updatePackageTemplate: builder.mutation({
      query: ({id, body}) => {
        return {
          url: `${PACKAGE_TEMPLATE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Party"],
    }),
    deletePackageTemplate: builder.mutation({
      query: (id) => ({
        url: `${PACKAGE_TEMPLATE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Party"],
    }),
  }),
});

export const {
  useGetPackageTemplateQuery,
  useGetPackageTemplateByIdQuery,
  useAddPackageTemplateMutation,
  useUpdatePackageTemplateMutation,
  useDeletePackageTemplateMutation,
} = PackageTemplateApi;

export default PackageTemplateApi;
