import React from 'react'
import SampleTest from './SampleTest'
import { Text, View } from '@react-pdf/renderer'
import tw from '../../../../Utils/tailwind-react-pdf'

const TestHeading = ({ item, heading, headingIndex, reportData }) => {
  return (
    <>
      {

        heading?.requirementResult?.flatMap(item => item.result.flatMap(val => val.result)).length > 0 ?




          <View style={tw("w-full mt-5")}>


            <View style={tw("flex flex-row gap-x-5 w-full mt-1")}>
              <View style={tw("w-[40%] ")}>
                <Text style={tw('')}>

                </Text>
              </View>

              <View style={tw('text-xs flex flex-row overflow-auto gap-x-3 justify-left items-left  w-[40%] ')}>
                <Text style={[tw('pl-1 upperCase font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
                  {heading?.heading}
                </Text>
              </View>
              <View style={tw("w-[20%] ")}>
                <Text style={tw('')}>

                </Text>
              </View>


            </View>







            {

              reportData?.samples
                ?.filter((val, valIndex) =>
                  reportData?.samples.length > 1
                    ?
                    heading?.requirementResult.some(test => {
                      return (test?.result.find(i => (i.sampleId.split("-")[1]) === val)?.result || []).length > 0
                    })
                    : true
                )
                .map((val, valIndex) =>
                  <>

                    <View style={tw("flex flex-row gap-x-5 w-full mt-1")}>
                      <View style={tw("w-[40%] ")}>
                        <Text style={tw('')}>

                        </Text>
                      </View>

                      <View style={tw('text-xs flex flex-row overflow-auto gap-x-3 justify-center items-center  w-[40%] ')}>
                        {reportData?.samples?.length > 1 ?
                          <Text >
                            {val}
                          </Text>


                          : ""}
                      </View>
                      <View style={tw("w-[20%] ")}>
                        <Text style={tw('')}>

                        </Text>
                      </View>


                    </View>


                    <View style={tw('flex flex-row w-full')}>
                      <View style={tw('capitalize text-xs text-left w-[20%] ')}>

                      </View>
                      <View style={tw('capitalize text-xs  text-left w-[60%]')}>
                        {
                          item?.isRemovalForce ? (
                            <>
                              <View style={tw('flex flex-row gap-x-3 overflow-auto text-center w-full')}>
                                <Text style={tw('font-bold w-[25%]')}>
                                  Type of Component
                                </Text>
                                <Text style={tw('font-bold w-[25%]')}>
                                  Area of Component
                                </Text>
                                <Text style={tw('font-bold w-[25%]')}>
                                  Result
                                </Text>
                                <Text style={tw('font-bold w-[25%]')}>
                                  Mode Of failure
                                </Text>
                              </View>
                            </>
                          ) : <></>
                        }
                      </View>
                      <View style={tw(' capitalize text-xs text-center  w-[20%]')}>

                      </View>
                    </View>





                    <View style={tw('w-full')}>
                      {(heading?.requirementResult?.filter(val => val.result?.flatMap(j => j.result)?.length > 0))?.map((test, testIndex) =>
                        // {heading?.requirementResult?.map((test, testIndex) =>
                        <SampleTest key={testIndex} val={val} item={item} test={test} testIndex={testIndex} reportData={reportData} valIndex={valIndex} />
                      )}
                    </View>


                  </>
                )

            }



















            {/* <View style={tw("flex flex-row gap-x-5 w-full")}>
              <View style={tw("w-[40%] ")}>
                <Text style={tw('')}>

                </Text>
              </View>

              <View style={tw('text-xs flex flex-row overflow-auto gap-x-3 justify-center items-center  w-[40%] ')}>
                {reportData?.samples?.length > 1 ? reportData?.samples?.map(val => (
                  <Text >
                    {val}
                  </Text>
                ))

                  : "A "}
              </View>
              <View style={tw("w-[20%] ")}>
                <Text style={tw('')}>

                </Text>
              </View>


            </View>
            <View style={tw('w-full')}>
              {heading?.requirementResult?.map((test, testIndex) =>
                <SampleTest key={testIndex} item={item} test={test} testIndex={testIndex} reportData={reportData} />
              )}
            </View> */}
          </View>
          : []
      }
    </>

  )
}

export default TestHeading