import React from 'react'
import { useGetWashCareGroupMasterQuery } from '../../../redux/LabServices/WashCareGroupMasterServices'
import { findFromListWithMatchKey, getImageUrlPath, params } from '../../../Utils/helper';
import { Loader } from '../../../Basic/components';

const WashCareSelectionSampleWise = ({ id, setIsSampleWashCareOpen, readOnly, sampleDetails, setSampleDetails, currentActiveTab, specialInstruction }) => {
    const { data: washCareGroupMaster, isLoading: isWashCareGroupLoading, isFetching: isWashCareGroupFetching } = useGetWashCareGroupMasterQuery({ params: { ...params, includeWashCare: true } })

    const addWashCare = (washCareGroupId, washCareId) => {
        setSampleDetails(prev => {
            let newWashCareStatus = structuredClone(prev)
            let currentIndex = -1;
            let index = prev.findIndex(item => item.sampleId === currentActiveTab)
            if (index === -1) return prev
            if (newWashCareStatus[index]?.SampleWashCareDetails) {
                currentIndex = newWashCareStatus[index]["SampleWashCareDetails"].findIndex(item => parseInt(item.washCareGroupId) === parseInt(washCareGroupId))
            }
            if (currentIndex === -1) {
                newWashCareStatus[index]["SampleWashCareDetails"] = [...(newWashCareStatus[index]["SampleWashCareDetails"] ? newWashCareStatus[index]["SampleWashCareDetails"] : []), { washCareGroupId, washCareId }]
            } else {
                let sample = structuredClone(newWashCareStatus[index]["SampleWashCareDetails"])
                sample[currentIndex]["washCareId"] = washCareId;
                newWashCareStatus[index]["SampleWashCareDetails"] = sample
            }
            return newWashCareStatus
        })
    }

    const removeWashCare = (washCareGroupId) => {
        setSampleDetails(prev => {
            let index = prev.findIndex(item => item.sampleId === currentActiveTab)
            if (index === -1) return prev
            let newWashCareStatus = structuredClone(prev)
            if (!newWashCareStatus[index]?.SampleWashCareDetails) return
            newWashCareStatus[index]["SampleWashCareDetails"] = newWashCareStatus[index]["SampleWashCareDetails"].filter(item => parseInt(item.washCareGroupId) !== parseInt(washCareGroupId))
            return newWashCareStatus
        })
    }

    const handleWashCareSelection = (isChecked, washCareGroupId, washCareId) => {
        if (isChecked) removeWashCare(washCareGroupId)
        else addWashCare(washCareGroupId, washCareId)
    }

    let index = sampleDetails.findIndex(item => item.sampleId === currentActiveTab)

    const isWashCareSelected = (washCareGroupId, washCareId) => {
        let index = sampleDetails.findIndex(item => item.sampleId === currentActiveTab)
        if (index === -1) return false
        let selectedWashCareId = findFromListWithMatchKey(washCareGroupId, sampleDetails[index]["SampleWashCareDetails"], "washCareId", "washCareGroupId")
        if (!selectedWashCareId) return false
        return parseInt(selectedWashCareId) === parseInt(washCareId)
    }

    if (isWashCareGroupFetching || isWashCareGroupLoading || !washCareGroupMaster?.data) return <Loader />
    return (
        <div>

            <div className='grid grid-cols-5 gap-3 px-1 bg-white'>
                {washCareGroupMaster.data.filter(item => item.active).map(washCareGroup =>
                    <fieldset key={washCareGroup.id} className="border border-gray-400 rounded px-3 h-[350px] overflow-auto">
                        <legend className="sub-heading">{washCareGroup.name}</legend>
                        <div>
                            {washCareGroup.WashCare.map(washCare =>
                                <div key={washCare.id} className="border"
                                    onClick={() => { handleWashCareSelection(isWashCareSelected(washCareGroup.id, washCare.id), washCareGroup.id, washCare.id) }}
                                >
                                    <div className='flex items-center gap-5 mt-3 w-12'>
                                        <input type="radio"
                                            checked={isWashCareSelected(washCareGroup.id, washCare.id)}
                                            readOnly={readOnly} />
                                        <img src={getImageUrlPath(washCare.image)} alt="" />
                                    </div>
                                    <div className="mt-2">
                                        <label className="text-xs">
                                            {washCare.name}
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </fieldset>

                )}
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-3 md:items-center md:my-1 md:px-1 data'>
                    <label className='md:text-start flex'> Special Instructions </label>
                    <textarea className={`h-10 w-full focus:outline-none col-span-2 border border-gray-500 rounded`}
                        cols="30" rows={3} value={sampleDetails[index]?.specialInstruction ? sampleDetails[index]?.specialInstruction : ""}
                        onChange={(e) => {
                            setSampleDetails(sampleDetails => {
                                let newSampleDetails = structuredClone(sampleDetails)
                                newSampleDetails[index]["specialInstruction"] = e.target.value
                                return newSampleDetails
                            })
                        }
                        } readOnly={readOnly}></textarea>
                </div>
                <div className=" grid justify-end">
                    <button
                        onKeyDown={e => {
                            if (e.code === "Enter") {
                                setIsSampleWashCareOpen(false)
                            }

                        }}
                        className='bg-green-400 rounded-lg p-1 w-32' onClick={() => setIsSampleWashCareOpen(false)}>Done
                    </button>
                </div>
            </div>
        </div>
    )
}

export default WashCareSelectionSampleWise
