import React from 'react'
import GenerateInvoiceNumber from './GenerateInvoiceNumber'
import moment from 'moment'

const PrintSheet = ({data,testDetailsData}) => {


    
  return (
    <div className='font-times'>
 <div>
                <h2 className="text-xl font-semibold text-center mt-2 mb-2">{data?.data?.invoiceNo?.includes("OV") ? "TAX INVOICE" :"INVOICE"} </h2>
            </div>

            <div className='grid grid-cols-2 gap-y-1 text-xs'>
                {data?.data?.invoiceNo
                    ?
                    <p><strong>Invoice No:</strong> {data?.data.invoiceNo}</p>
                    :
                    <GenerateInvoiceNumber registrationId={data?.data.registrationId} />
                }
                <p><strong>Invoice.Date:</strong> {moment.utc(data?.data?.invoiceDate).format("DD-MM-YYYY")}</p>
                <p><strong>STATE CODE:</strong> </p>
                <p><strong>HSN CODE:</strong> </p>
            </div>
            <div className='grid grid-cols-1 mt-1.5 text-xs'>
                <h1 >To : {data?.data?.toCompany?.name} ---{data?.data?.toCompany?.address}</h1>
                <h1 className='mt-1'  >ContactName : {data?.data?.toCompany?.contactDetails?.map(val => val.contactPersonName).join("/")}</h1>
                <h1>Email.Id : {data?.data?.toCompany?.contactDetails?.map(val => val.email).join("/")}</h1>
            </div>

            <div className='grid grid-cols-2 mt-1.5 text-xs'>
                <p>BuyerName: {data?.data?.buyerName ? data?.data?.buyerName : ""} </p>
                <p>Test Report No: {data?.data?.testReportNo ? data?.data?.testReportNo : ""} </p>
                <p>Ref.Date: </p>
                <p>Reg.Date: {moment.utc(data?.data?.regDate ? data?.data?.regDate : "").format("DD-MM-YYYY")}</p>
                <p>Service:{data?.data?.service ? data?.data?.service : ""}</p>
                <p>Description: {data?.data?.description ? data?.data?.description : ""}</p>
                <p>Style. No:{data?.data?.styleNo ? data?.data?.styleNo : ""} </p>
                <p>Batch. No: </p>
            </div>

            {
                data?.data?.typeOfInvoice == "PACKAGE" ?

                    <table className="table-fixed w-full mt-1.5">
                        <thead>
                            <tr>
                                <th className="border border-gray-400 text-xs p-1 w-9">S.No</th>

                                <th className="border border-gray-400 text-xs p-1  w-60">Package Name</th>
                                <th className="border border-gray-400 text-xs p-1 w-32">Quantity</th>
                                <th className="border border-gray-400 text-xs p-1 w-32">Price</th>
                                <th className="border border-gray-400 text-xs p-1 w-32">Discount</th>
                                <th className="border border-gray-400 text-xs p-1 w-32">Amount</th>


                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.packageDetails.map((item, index) =>
                                <tr key={index}>
                                    <td className="border border-gray-400 text-xs p-1 ">{index + 1}</td>

                                    <td className="border border-gray-400 text-xs p-1">{item.packageName}</td>
                                    <td className="border border-gray-400 text-xs p-1 text-right ">
                                        {item?.qty}
                                    </td>
                                    <td className="border border-gray-400 text-xs p-1 text-right ">{item.price}</td>
                                    <td className="border border-gray-400 text-xs p-1 text-right ">{item.discount}</td>
                                    <td className="border border-gray-400 text-xs p-1 text-right ">{(!item.qty || !item.price) ? 0 : ((parseFloat(item.qty) * parseFloat(item.price))-parseFloat(item.discount)).toFixed(2)}</td>

                                </tr>
                            )}
                        </tbody>
                    </table>
                    : ""

            }

            {
                testDetailsData.length > 0 ? (

                    <>
                        <div className='mt-5'>
                            <table className="table-fixed w-full">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400 text-xs p-1 w-9">S.No</th>

                                        <th className="border border-gray-400 text-xs p-1 w-60">Test</th>
                                        <th className="border border-gray-400 text-xs p-1 w-32">Quantity</th>
                                        <th className="border border-gray-400 text-xs p-1 w-32">Rate</th>
                                <th className="border border-gray-400 text-xs p-1 w-32">Discount</th>

                                        <th className="border border-gray-400 text-xs p-1 w-32">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {testDetailsData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="border border-gray-400 text-xs p-1 ">{index + 1}</td>

                                            <td className="border border-gray-400 text-xs p-1">{item.testName}</td>
                                            <td className="border border-gray-400 text-xs p-1 text-right ">{item?.qty ? item?.qty : ""}
                                                {/* <input type="number" className='p-1' value={item?.qty ? item?.qty : []} onChange={(e) => {
                                                    handleInputChange(e.target.value, index, 'qty')
                                                }} /> */}
                                            </td>
                                            <td className="border border-gray-400 text-xs p-1 text-right ">{item.price}</td>
                                            <td className="border border-gray-400 text-xs p-1 text-right ">{item.discount}</td>
                                            
                                             
                                            <td className="border border-gray-400 text-xs p-1 text-right ">{(!item.qty || !item.price) ? 0 : ((parseFloat(item.qty) * parseFloat(item.price))-parseFloat(item.discount)).toFixed(2)}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                        </div>

                       

                    </>
                )


                    : ""


            }

            <div className='flex align-items-end justify-end mr-32 mt-5 text-xs'>

                <div className='grid grid-cols-1'>
                    <div className='mb-1'>
                        Analysis Charge:
                    </div>
                    {data?.data?.taxDetails?.map(val =>
                        <h1>
                            {`${val?.taxName} @ ${val.taxPercent} % : ${parseFloat(val?.taxAmount).toFixed(2)}`}
                        </h1>

                    )}

                    <div className='mb-2'>
                        RoundOff:  {data?.data?.totalTaxAmount ? parseFloat(data?.data?.totalTaxAmount).toFixed(2) : 0.00}

                    </div>

                    <h1>
                        NetCharge : {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}
                    </h1>
                    <h1>
                        Grand Total : {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}
                    </h1>


                </div>

            </div>


            <div className='mt-2.5'>
                <h1 className='text-sm font-bold'>
                    TERMS AND CONDITIONS
                </h1>
                <ol className='text-xs'>

                    <li>
                        1. 2% interest per month will be charged for any invoice remained unpaid for more than a month from the date of issue of the Invoice
                    </li>
                    <li>
                        2. No claim against this invoice will be entertained unless brought to our notice in writing within 7 days of receipt of this invoice
                    </li>
                    <li>
                        3. Our report for which this invoice is issued includes all the tests requested by you and the results thereof. You shall have thirty days from receipt of report to request
                        additional testing of the samples or to notify us of any errors or omissions relating to our report provided, however, such notice shall be in writing and shall
                        specifically address the issue you wish to raise. A failure to raise such issue within the prescribed time shall constitute your unqualified acceptance of the
                        completeness of report, the tests conducted and the correctness of the report contents
                    </li>
                    <li>
                        4. Prepayment may be enforced when the account is overdue.
                    </li>
                    <li>
                        5. Any dispute arising out of this transaction / Contract will be referred to institutional arbitration council of Tirupur as per the rules and regulations of Arbitration
                        Council of Tirupur and the award passed will be binding on us.
                    </li>
                    <li>
                        6. The test report is as per the sample provided by you / your representation.
                    </li>


                </ol>

            </div>

            <div className='mt-1 text-xs'>
                <h1 className='font-bold'>
                    OUR BANK DETAILS
                </h1>
                <h1 className='mt-0.5'>
                    <strong> ACCOUNT NAME:</strong> {data?.data?.bankDetails?.accountName ? data?.data?.bankDetails?.accountName : ""}    </h1>
                <h1><strong> ACCOUNT NO:</strong>  {data?.data?.bankDetails?.accountNo ? data?.data?.bankDetails?.accountNo : ""}   </h1>
                <h1><strong> IFSC.CODE:</strong>  {data?.data?.bankDetails?.ifsc ? data?.data?.bankDetails?.ifsc : ""}    </h1>
                <h1><strong> BRANCH:</strong>  {data?.data?.bankDetails?.branch ? data?.data?.bankDetails?.branch : ""}
                </h1>
            </div>


            <div className="flex justify-between mt-9 mr-20 ml-20 text-xs">
                <h1>
                    Verified By
                </h1>
                <h1>
                    Authorized By
                </h1>
            </div>

    </div>
  )
}

export default PrintSheet