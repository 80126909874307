import React from 'react'

const RemovalForceComponent = ({resultDetails,isResult,labTestRequirementDetailsId,setResultDetails,readOnly, childRecord,parameter}) => {
    const datas = resultDetails ? resultDetails.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
       
    

    function handleOnChange(newValue, labTestRequirementDetailsId,  field,isResult) {
        const index = resultDetails.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));
    
        setResultDetails(prev => {
            let newReq = structuredClone(prev);
            newReq[index][field] = newValue;
           
            return newReq
        })
    }

  return (
    <>
     <td className='border border-gray-500 p-0.5' >
              <input type="text" className=" text-center p-1 w-full focus:outline-none border border-gray-500"
               value={datas?.typeOfComponent}             
                onChange={(e) => handleOnChange(e.target.value, labTestRequirementDetailsId, "typeOfComponent",isResult)}
               readOnly={readOnly}   disabled={(childRecord.current > 0)} />
            </td>
            <td className='border border-gray-500 p-0.5' >
              <input type="text" className=" text-center w-full p-1 focus:outline-none border border-gray-500"
               value={datas?.areaOfComponent}            
                onChange={(e) => handleOnChange(e.target.value, labTestRequirementDetailsId,"areaOfComponent",isResult)}
                readOnly={readOnly}   disabled={(childRecord.current > 0)} />
            </td>
    </>
  )
}

export default RemovalForceComponent