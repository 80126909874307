import React from 'react'

const TestPackagePrice = ({ val, valIndex }) => {
    return (
        <>
            <tr className='text-center'>
                <td className='py-1 gridExcel text-center'>
                    {val?.packagePrice ? val?.packagePrice : ""}
                </td>
            </tr>
        </>
    )
}

export default TestPackagePrice