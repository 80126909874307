import React from 'react'
import { useGetLabTestNameMasterQuery } from '../../../../redux/LabServices/LabTestNameMasterServices'
import { findFromList, params } from "../../../../Utils/helper"
import { useGetLabTestMethodMasterQuery } from '../../../../redux/LabServices/LabTestMethodMasterServices'
import { Loader } from '../../../../Basic/components'
import { Text, View } from "@react-pdf/renderer";
import tw from '../../../../Utils/tailwind-react-pdf'
const SampleTestParametersPrint = ({testList, methodList, testDetails, packageName }) => {

 
  return (
   
     
      <View style={tw(' border border-gray-500 w-full')} >
     
          <View style={tw('flex flex-row ')}>
            <Text style={[tw('border-r border-b border-gray-500 p-2 w-16'),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>
              S.NO
            </Text>
           
            <Text style={[tw('border-r border-b border-gray-500 p-2 w-2/4'),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>

             List Of Test Parameter
            </Text>
            <Text style={[tw('border-r border-b border-gray-500 p-2 w-2/4'),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>

             Method
            </Text>
           
            </View>
     
      
          
          {testDetails.map((item, index) =>
            <View style={tw('flex flex-row ')}>
            <Text style={tw('border-r border-b border-gray-500 p-2 w-16')}>
                {index + 1}
              </Text>
              <Text style={tw('border-r border-b  border-gray-500 p-2 w-2/4')}>
                {findFromList(item.testId, testList.data, "name")}
              </Text>
              <Text style={tw('border-r border-b border-gray-500 p-2 w-2/4')}>
              {findFromList(item.methodId, methodList.data, "name")}
  
              </Text>
            </View>
          )}
          
       
      </View>
    
 
  )
}

export default SampleTestParametersPrint