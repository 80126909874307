import React, { useEffect, useState } from 'react'
import { TextInput } from '../../../Inputs';
import { useGetBranchByIdQuery, useUpdateBranchMutation } from '../../../redux/services/BranchMasterService';
import { getCommonParams } from '../../../Utils/helper';
import { toast } from 'react-toastify';

const BankDetails = () => {
    const [accountName, setAccountName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [branch, setBranch] = useState("");


    const { branchId } = getCommonParams()
    const { data, isLoading, isFetching } = useGetBranchByIdQuery(branchId);

    useEffect(() => {
        if (!data?.data) return
        let currentData = data?.data;
        setAccountName(currentData?.accountName ? currentData?.accountName : "");
        setAccountNo(currentData?.accountNo ? currentData?.accountNo : "");
        setIfsc(currentData?.ifsc ? currentData?.ifsc : "");
        setBranch(currentData?.branch ? currentData?.branch : "");
    }, [data, isLoading, isFetching])

    const [updateBranch] = useUpdateBranchMutation()

    const handleSubmit = async (text = "uploaded") => {
        try {
            await updateBranch({ id: branchId, accountName, accountNo, ifsc, branch });
            toast.success(text + " Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    return (
        <fieldset className='w-1/2'>
            <legend className='sub-heading'>Bank Details</legend>
            <TextInput name="Account Name" type="text" value={accountName} setValue={setAccountName} required={true} />
            <TextInput name="Account No" type="text" value={accountNo} setValue={setAccountNo} required={true} />
            <TextInput name="IFSC" type="text" value={ifsc} setValue={setIfsc} required={true} />
            <TextInput name="Branch" type="text" value={branch} setValue={setBranch} required={true} />
            <div className='flex justify-end'>
                <button className='bg-green-600 hover:bg-green-700 text-gray-100 rounded-md p-1' onClick={() => handleSubmit()}>Save</button>
            </div>
        </fieldset>
    )
}

export default BankDetails
