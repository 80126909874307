import React from 'react'

const FactoryAddress = () => {
  return (
    <div>
      <div className='w-full'>
          <div className="grid justify-center  mt-5 ">
            <h1 className='text-sm  text-lg'><strong>Laboratory Address:</strong> SRG Apparels Limited-Textile Testing Laboratory, 224/1, Ponnan Thottam, Murugampalayam, Iduvampalayam (Po), Tirupur-641687.</h1>
          </div>
          <div className='text-blue-500 text-xs'>
          The Laboratory has been accredited as per ISO/IEC 17025:2017 in chemical &amp; mechanical fields of Textile Testing, to know the Scope of accreditation visit
              www.nabl-india.org certificate no. TC10916.
          </div>
          {/* <div className="flex justify-center ">
          Mob. No: 9952785239, E-mail: <a className='text-blue-500'    href='testing.pro@srgapparels.com'>testing.pro@srgapparels.com</a>    , GST.NO: 33AADCS8162K1Z4
          </div> */}
          <div>
       <div className='grid justify-center text-xs'> <p> The Content of the test report is for your guidance and should not use for advertisement, evidence and litigation. The Sample is not drawn by the laboratory. Test Results related only to samples received and tested by the laboratory subjected to its general conditions of services are available on request and accessible{' '}
        <a className='text-blue-500' href="http://www.srgapparels.com/" >http://www.srgapparels.com/</a>. Test report shall not be reproduced except in full, without written approval of the laboratory. Test items will be retained by the laboratory for not more than 90 days.</p>
          </div>
          </div>
          </div>
    </div>
  )
}

export default FactoryAddress