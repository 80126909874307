import React from 'react'

const TestGroups = ({ val, valIndex }) => {
    return (
        <>
            <tr className='text-left'>
                <td className=' py-1 gridAlignLeft text-left capitalize'>
                    *{val?.LabTestGroup?.name.toLowerCase() || ""}
                </td>
            </tr>
        </>
    )
}

export default TestGroups