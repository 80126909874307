import React from 'react'
import GreyFabric from './GreyFabric'

const Program = ({ finishedGoodsType, programDetails, setProgramDetails,  setRawMaterial }) => {
    return (
        <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 px-3 overflow-auto'>
            <legend className='sub-heading'>Program Details</legend>
            {finishedGoodsType === "GF"
                ?
                <GreyFabric programDetails={programDetails} setProgramDetails={setProgramDetails} setRawMaterial={setRawMaterial} />
                :
                <></>
            }
        </fieldset>
    )
}

export default Program