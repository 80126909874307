import React from 'react'
import tw from '../../../../Utils/tailwind-react-pdf'



import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const SampleDetailsPrint = ({ data, sampleItem }) => {







  return (
    <View style={tw('text-xs')}>
      <View style={tw('w-full mt-1 border border-gray-500 ')}>


        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >
          <Text style={tw('border-r border-b border-gray-600 p-1 w-1/4 ')}>Supplier Name : </Text>
          <Text style={tw('border-r border-b border-gray-600  p-1 w-1/4')}>{data?.Supplier ? data?.Supplier.name : ""}</Text>
          <Text style={tw('border-r  border-b border-gray-600  p-1 w-1/4')}>Sample Description :</Text>
          <Text style={tw('border-b border-gray-600 w-1/4 p-1 ')}>{data?.sampleDescription ? data?.sampleDescription : ""}</Text>
        </View>


        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full")} >

          <Text style={tw('border-r border-b border-gray-600  p-1 w-1/4')}>Color Name : </Text>
          <Text style={tw('border-r border-b border-gray-600  p-1 w-1/4')}>{data?.color ? data.color : ""}</Text>
          <Text style={tw('border-r border-b border-gray-600 p-1 w-1/4')}>Style No :</Text>
          <Text style={tw('border-b border-gray-600 w-1/4 p-1 ')}>{data?.styleNo ? data.styleNo : ""}</Text>
        </View>
        <View style={tw(" flex flex-row flex-wrap justify-start w-full ")} >

          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Batch/Lot No :</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 flex-wrap p-1')}>{data?.batchNo ? data.batchNo : ""}</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Order No :</Text>
          <Text style={tw('border-b border-gray-600  w-1/4 p-1')}>{data?.orderNo ? data.orderNo : ""}</Text>
        </View>

        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >

          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Claimed Fabric Weight:</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>{`${(sampleItem?.RegistrationSampleDetailsClaimedFabricWeight ? sampleItem?.RegistrationSampleDetailsClaimedFabricWeight : []).map(item => `   ${item.subHeading} - ${item.value}  ${item.UnitOfMeasurement.name}  `).join("&")}`}</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>End Use:</Text>
          <Text style={tw('border-b border-gray-600  w-1/4 p-1')}>{data?.RegistrationEndUseDetails ? data?.RegistrationEndUseDetails.map(item => item.EndUse.name).toString() : ""}</Text>
        </View>

        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >

          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Claimed Fiber Content:</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>{`${(sampleItem?.RegistrationSampleDetailsClaimedFiberContent ? sampleItem?.RegistrationSampleDetailsClaimedFiberContent : []).map(item => `   ${item.subHeading} - ${item?.FiberContent?.aliasName}  `).join("&")}`}</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Season:</Text>
          <Text style={tw('border-b border-gray-600 w-1/4  p-1')}>{data?.season ? data.season : ""}</Text>
        </View>

        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >

          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Claimed Yarn Count:</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>{`${(sampleItem?.RegistrationSampleDetailsClaimedYarnCount ? sampleItem?.RegistrationSampleDetailsClaimedYarnCount : []).map(item => `    ${item.subHeading} - ${item.value}  ${item.UnitOfMeasurement.name}  `).join("&")}`}</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4  p-1')}>Phase:</Text>
          <Text style={tw('border-b border-gray-600 w-1/4  p-1')}>{data?.phase ? data.phase : ""}</Text>
        </View>

        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >

          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Claimed Fabric Count:</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>{`${(sampleItem?.RegistrationSampleDetailsClaimedFabricCount ? sampleItem?.RegistrationSampleDetailsClaimedFabricCount : []).map(item => `   ${item.subHeading} - ${item.value}  ${item.UnitOfMeasurement.name}  `).join("&")}`}</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Age Range:</Text>
          <Text style={tw('border-b border-gray-600 w-1/4  p-1')}>{data?.sizerange ? data.sizerange : ""}</Text>
        </View>

        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >

          <Text style={tw('border-r border-b border-gray-600  w-1/4 p-1')}>Country Of Origin:</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>{data?.CountryOfOrigin ? data.CountryOfOrigin.name : ""}</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Submitted Size:</Text>
          <Text style={tw('border-b border-gray-600 w-1/4 p-1')}>{data?.submittedSize ? data.submittedSize : ""}</Text>
        </View>

        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >

          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>Country Of Destination:</Text>
          <Text style={tw('border-r border-b border-gray-600 w-1/4 p-1')}>{`${(data?.CountryOfDestinationIds ? data?.CountryOfDestinationIds : []).map(item => `${item.Country?.name}`).join("/")}`}</Text>
          <Text style={tw('border-r border-b border-gray-600  w-1/4 p-1')}>Test Stage (Package):</Text>
          <Text style={tw('border-b border-gray-600 w-1/4 p-1')}>{data?.testStage ? data.testStage : ""}</Text>
        </View>

        <View style={tw(" flex flex-row flex-wrap  justify-start  w-full ")} >

          <Text style={tw('border-r  border-gray-600 w-1/4 p-1')}>Retest:</Text>
          <Text style={tw('border-r  border-gray-600 w-1/4 p-1')}>{data?.retest ? "YES" : "NO"}</Text>
          <Text style={tw('border-r  border-gray-600 w-1/4 p-1')}>Previous Report NO:</Text>
          <Text style={tw('w-1/4 p-1')}>{data?.prevReportNo ? data.prevReportNo : ""}</Text>
        </View>



      </View>
    </View>

  )
}

export default SampleDetailsPrint