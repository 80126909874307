import React from 'react'
import { Image, View } from '@react-pdf/renderer'
import { getImageUrlPath } from '../../../../Utils/helper';
import tw from '../../../../Utils/tailwind-react-pdf';

const SampleImage = ({ sampleItem }) => {
  const imageWidth = "240px"
  const imageHeight = "180px";



  return (
    <View style={tw("w-full flex flex-col justify-center items-center")}>{console.log(sampleItem, "sampleItemviewwww")}
      <View style={tw('border-2 border-gray-900   w-[265px] h-[215px] m-2')}>
        <View style={tw('flex flex-col justify-center items-center m-auto')}>
          <Image alt='sample' style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
            src={getImageUrlPath(sampleItem?.image)} />

        </View>
      </View>
    </View>

  )
}

export default SampleImage