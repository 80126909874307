import React from 'react'
import TestHeading from './TestHeading'

const TestRequirement = ({ item, req, reqIndex, requirementLength, reportData }) => {
  return (
    <div className='w-full '>
      <tr className='ml-16 font-bold'>
        <h1 className='upperCase'>{requirementLength > 1 ? req?.requirementGroup : ""}</h1>
      </tr>
      <div className=''>
        {req?.headingsResult?.map((heading, headingIndex) => {
          return <TestHeading key={headingIndex} item={item} heading={heading} headingIndex={headingIndex} reportData={reportData} />
        })}
      </div>
    </div>
  )
}

export default TestRequirement