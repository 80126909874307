import React, { useContext, useRef, } from "react";
import { TextInput, DropdownInput } from "../../../../Inputs";
import { FieldsContext } from '..';
import { ReceivedBy } from "../../../../Utils/DropdownData";

export default function Form() {
    const fields = useContext(FieldsContext);

    const childRecord = useRef(0);

    return (
        <div>
            <div className='grid grid-cols-3 my-2'>
                <DropdownInput autoFocus
                    name="Pack Received By"
                    options={ReceivedBy}
                    value={fields.receivedBy}
                    setValue={fields.setReceivedBy}
                    required={true}
                    readOnly={fields.readOnly}
                    disabled={(childRecord.current > 0)}
                />
                <TextInput name="Courier Name" type="text" value={fields.courierName} setValue={fields.setCourierName} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                <TextInput name="AWB#" type="text" value={fields.challanNo} setValue={fields.setChallanNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
            </div>
        </div>
    )
}