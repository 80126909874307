import React from 'react'
import TestReport from '../Document-TestReport'
import { PDFViewer } from '@react-pdf/renderer'
import tw from '../../../Utils/tailwind-react-pdf'


const TestReportDocument = ({ headingOptions, splitTest, setSplitTest, activeRegistrationId, passFail, setPassFail, reportData, washCareData, testComments }) => {
    return (
        <div className='bg-white p-6 font-times'>
            {/* <div className='mt-5'>
                <BlobProvider document={<TestReport washCareData={washCareData} testComments={testComments} setTestComments={setTestComments} componentRef={componentRef} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId} passFail={passFail} setPassFail={setPassFail} reportData={reportData} />} fileName="my_pdf3.pdf">
                    {({ blob }) => {
                        return <button className='bg-green-400 p-1 rounded-md mb-4' onClick={() => handleMailSend(blob, companyEmail, "Test Report", reportData?.data?.testReportNo, logo)}>Send Mail</button>;
                    }}
                </BlobProvider>
            </div> */}
            <PDFViewer style={tw('h-screen w-full')}>
                <TestReport headingOptions={headingOptions} washCareData={washCareData} testComments={testComments} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId} passFail={passFail} setPassFail={setPassFail} reportData={reportData} />
            </PDFViewer>
        </div>
    )
}

export default TestReportDocument
