import React from 'react'

import { Loader } from '../../../Basic/components'
import { findFromList, params } from "../../../Utils/helper"

const SampleDetailsPrint = ({data,sampleItem}) => {


  return (
    <div className='text-xs'>
        <table className='w-full mt-1'>
           <tbody className=''>
            <tr className='text-xs'>
           <td className='border border-gray-600 p-1 w-1/6 '>Supplier Name : </td> 
           <td className='border border-gray-600  p-1 w-2/6'>{data?.Supplier ? data?.Supplier.name : ""}</td> 
           <td className='border border-gray-600  p-1'>Sample Description :</td> 
           <td className='border border-gray-600 p-1'>{data?.SampleDescriptionOne ? data?.SampleDescriptionOne.name : ""}</td> 
           </tr>
           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Color Name : :</td>
           <td className='border border-gray-600  p-1'>{data?.color?data.color:""}</td>
           <td className='border border-gray-600 p-1'>Style No :</td> 
           <td className='border border-gray-600 p-1'>{data?.styleNo?data.styleNo:""}</td> 
           </tr>
           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Batch/Lot No :</td> 
           <td className='border border-gray-600  p-1'>{data?.batchNo?data.batchNo:""}</td> 
           <td className='border border-gray-600 p-1'>Order No :</td> 
           <td className='border border-gray-600 p-1'>{data?.orderNo?data.orderNo:""}</td> 
           </tr>

           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Claimed Fabric Weight:</td> 
           <td className='border border-gray-600  p-1'>{`${(sampleItem?.RegistrationSampleDetailsClaimedFabricWeight ? sampleItem?.RegistrationSampleDetailsClaimedFabricWeight : []).map(item => `${item.value}  ${item.UnitOfMeasurement.name}`).join("/")}`}</td> 
           <td className='border border-gray-600 p-1'>End Use:</td> 
           <td className='border border-gray-600 p-1'>{data?.RegistrationEndUseDetails ? data.RegistrationEndUseDetails.map(item=>item.EndUse.name).toString() : ""}</td> 
           </tr>

           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Claimed Fiber Content:</td> 
           <td className='border border-gray-600  p-1'>{`${(sampleItem?.RegistrationSampleDetailsClaimedFiberContent ? sampleItem?.RegistrationSampleDetailsClaimedFiberContent : []).map(item => `${item?.FiberContent?.aliasName} `).join("/") }`}</td> 
           <td className='border border-gray-600 p-1'>Season:</td> 
           <td className='border border-gray-600 p-1'>{data?.season?data.season:""}</td> 
           </tr>

           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Claimed Yarn Count:</td> 
           <td className='border border-gray-600  p-1'>{`${(sampleItem?.RegistrationSampleDetailsClaimedYarnCount ? sampleItem?.RegistrationSampleDetailsClaimedYarnCount : []).map(item => `${item.value}  ${item.UnitOfMeasurement.name}`).join("/")}`}</td> 
           <td className='border border-gray-600 p-1'>Phase:</td> 
           <td className='border border-gray-600 p-1'>{data?.phase?data.phase:""}</td> 
           </tr>

           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Claimed Fabric Count:</td> 
           <td className='border border-gray-600  p-1'>{`${(sampleItem?.RegistrationSampleDetailsClaimedFabricCount? sampleItem?.RegistrationSampleDetailsClaimedFabricCount : []).map(item => `${item.value}  ${item.UnitOfMeasurement.name}`).join("/")}`}</td> 
           <td className='border border-gray-600 p-1'>Age Range:</td> 
           <td className='border border-gray-600 p-1'>{data?.sizerange?data.sizerange:""}</td> 
           </tr>

           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Country Of Origin:</td> 
           <td className='border border-gray-600  p-1'>{data?.CountryOfOrigin?data.CountryOfOrigin.name:""}</td> 
           <td className='border border-gray-600 p-1'>Submitted Size:</td> 
           <td className='border border-gray-600 p-1'>{data?.submittedSize?data.submittedSize:"" }</td> 
           </tr>

           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Country Of Destination:</td> 
           <td className='border border-gray-600  p-1'>{data?.CountryOfDestination?data.CountryOfDestination.name:""}</td> 
           <td className='border border-gray-600 p-1'>Test Stage (Package):</td> 
           <td className='border border-gray-600 p-1'>{data?.testStage?data.testStage:""}</td> 
           </tr>

           <tr className='text-xs'>
           <td className='border border-gray-600  p-1'>Retest:</td> 
           <td className='border border-gray-600  p-1'>{data?.retest?"YES":"NO"}</td> 
           <td className='border border-gray-600 p-1'>Previous Report NO:</td> 
           <td className='border border-gray-600 p-1'>{data?.prevReportNo?data.prevReportNo:""}</td> 
           </tr>
          
         
            </tbody>
        </table>
    </div>
   
  )
}

export default SampleDetailsPrint