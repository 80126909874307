import React from 'react'

const SampleDetails = ({data}) => {
  return (
    <div className=''>
        <table className='w-full mt-4'>{console.log(data,"datasample")}
           <tbody className=''>
           <tr className='text-xs'>
           <td className='border border-gray-600 p-2'>Fabric Supplier :</td> 
           <td className='border border-gray-600 p-2'>{data?.Supplier?data?.Supplier.name: ""}</td> 
           </tr>
           <tr className='text-xs'>
           <td className='border border-gray-600 p-2'>Contact Name :</td> 
           <td className='border border-gray-600 p-2'>{data?.Supplier?data?.Supplier.contactPersonName: ""}</td> 
           </tr>
           <tr className='text-xs'> 
           <td className='border border-gray-600  p-2'>Contact No :</td> 
           <td className='border border-gray-600  p-2'> {data?.Supplier?data?.Supplier.phone: ""} </td>
           </tr> 
           <tr className='text-xs'>
           <td className='border border-gray-600  p-2'>Sample Description :</td> 
           <td className='border border-gray-600 p-2'>{data?.sampleDescription?data.sampleDescription:""}</td> 
           </tr>
           <tr className='text-xs'>
          <td className='border border-gray-600 p-2'> Color :</td> 
          <td className='border border-gray-600 p-2'> {data.color}</td> 
          </tr>
          <tr className='text-xs'>
           <td className='border border-gray-600  p-2'>Batch/Lot No :</td> 
           <td className='border border-gray-600  p-2'>{data.batchNo}</td> 
           </tr>
           <tr className='text-xs'>
           <td className='border border-gray-600 p-2'>Style Code :</td> 
           <td className='border border-gray-600 p-2'>{data.styleNo}</td> 
           </tr>
           <tr className='text-xs'>
           <td className='border border-gray-600 p-2'>Order No:</td> 
           <td className='border border-gray-600 p-2'>{data?.orderNo?data.orderNo:""}</td> 
           </tr>
           <tr className='text-xs'>
         <td className='border border-gray-600 p-2'>Season :</td>
         <td className='border border-gray-600 p-2'>{data.season}</td>
          </tr> 

           <tr className='text-xs'>
         <td className='border border-gray-600 p-2'>End Use :</td>
         <td className='border border-gray-600 p-2'>{data?.RegistrationEndUseDetails ? data.RegistrationEndUseDetails.map(item=>item.EndUse.name).toString() : ""}</td>
          </tr>
          <tr className='text-xs'>
         <td className='border border-gray-600 p-2'>Claimed Fabric Weight (GSM) :</td>
         <td className='border border-gray-600 p-2'>{data?.claimedFabricWeight?data?.claimedFabricWeight:""}</td>
          </tr>

          <tr className='text-xs'>
         <td className='border border-gray-600 p-2'>Claimed Fiber Content :</td>
         <td className='border border-gray-600 p-2'>{data?.claimedFiberContent?data?.claimedFiberContent:""}</td>
          </tr>
          <tr className='text-xs'>
         <td className='border border-gray-600 p-2'>Test Stage (Package):</td>
         <td className='border border-gray-600 p-2'>{data.TestStage?data.TestStage.name:""}</td>
          </tr>
          <tr className='text-xs'>
         <td className='border border-gray-600 p-2'>Retest:</td>
         <td className='border border-gray-600 p-2'>{data?.retest?"YES":"NO"}</td>
          </tr>


            </tbody>
        </table>
    </div>
  )
}

export default SampleDetails