import React, { useState } from "react";
import RegistrationFormReport from "./RegistrationFormReport";
import Form from "./Form";

export default function ResultEntry() {
    const [registrationSampleDetailsId, setRegistrationSampleDetailsId] = useState("");
    const [registrationId, setRegistrationId] = useState("")



    if (registrationSampleDetailsId) {
        return <Form key={new String(registrationSampleDetailsId)} registrationId={registrationId} setRegistrationId={setRegistrationId} registrationSampleDetailsId={registrationSampleDetailsId} setRegistrationSampleDetailsId={setRegistrationSampleDetailsId} />
    }
    return <RegistrationFormReport
        dataClick={(sampleId, registrationId) => {
            setRegistrationSampleDetailsId(sampleId);
            setRegistrationId(registrationId)
        }}
    />
}