export const EMPLOYEE_API = "employees";
export const CITY_API = "cities";
export const STATE_API = "states";
export const COUNTRY_API = "countries";
export const DEPARTMENT_API = "departments";
export const LOGIN_API = "users/login";
export const COMPANY_API = "companies";
export const BRANCHES_API = "branches";
export const USERS_API = "users";
export const PAGES_API = "pages";
export const PAGES_GROUP_API = "pageGroup";
export const ROLES_API = "roles";
export const SUBSCRIPTION_API = "subscriptions";
export const FIN_YEAR_API = "finYear";
export const EMPLOYEE_CATEGORY_API = "employeeCategories";

export const PARTY_CATEGORY_API = "partyCategories"
export const CURRENCY_API = "currency"
export const PARTY_API = "party"
export const CONTENT_API = "content"
export const YARN_TYPE_API = "yarnType"
export const COUNTS_API = "counts"
export const HSN_API = "hsm"
export const BLEND_API = 'blend'
export const YARN_API = 'yarn'
export const FABRIC_TYPE_API = 'fabricType'
export const FABRIC_API = 'fabric'
export const ACCESSORY_GROUP_API = 'accessoryGroup'
export const ACCESSORY_ITEM_API = 'accessoryItem'
export const ACCESSORY_API = 'accessory'
export const COLOR_API = 'color'
export const PO_API = 'po'
export const UNIT_OF_MEASUREMENT_API = 'unitOfMeasurement'
export const PAY_TERM_API = 'payTerm'
export const TAX_TERM_API = 'taxTerm'
export const TAX_TEMPLATE_API = 'taxTemplate'
export const LOCATION_API = 'location'
export const DESIGN_API = "design"
export const GUAGE_API = "gauge"
export const GSM_API = "gsm"
export const LOOPLENGTH_API = "looplength"
export const DIA_API = "dia"
export const SIZE_API = "size"
export const PURCHASE_INWARD_OR_RETURN_API = "purchaseInwardOrReturn"
export const STOCK_API = "stock"
export const STYLE_API = "currency"
export const PROCESS_API = "process"


// Lab API
export const LAB_TEST_GROUP_API = "labTestGroup"
export const LAB_TEST_NAME_API = "labTest"
export const LAB_TEST_SUB_GROUP_API = "labTestSubGroup"
export const LAB_TEST_STANDARD_API = "labTestStandard"
export const LAB_TEST_CONDITION_API = "labTestCondition"
export const WASH_CARE_API = "washCare"
export const WASH_CARE_GROUP_API = "washCareGroup"
export const SAMPLE_DISCRIPTION_ONE_API = "sampleDescriptionOne"
export const SAMPLE_DISCRIPTION_TWO_API = "sampleDescriptionTwo"
export const SAMPLE_DISCRIPTION_THREE_API = "sampleDescriptionThree"
export const PRODUCT_CATEGORY_API = "productCategory"
export const BRAND_API = "brand"
export const WASH_API = "wash"
export const FINISH_API = "finish"
export const REGISTRATION_API = "registration"
export const SERVICE_LEVEL_API = "serviceLevel"
export const SUBMISSION_TYPE_API = "submissionType"
export const ON_HOLD_REASON_API = "onHoldReason"
export const END_USE_API = "endUse"
export const MILL_API = "millOrFactory"
export const PACKAGE_API = "packages"
export const PACKAGE_TEMPLATE_API = "packages"
export const LAB_TEST_METHOD_API = "labTestMethod"
export const LAB_CODE_API = "labCode"
export const HOLIDAYS_CALENDER_API = "holidays"
export const LAB_TEST_ANALYTE_API ="labTestAnalyte"
export const LAB_TEST_REQUIREMENT_API ="labTestRequirement"
export const FORMULA_API ="formula"
export const FIBER_CONTENT_API ="fiberContent"
export const SAMPLE_DISCRIPTION_FOUR_API = "sampleDescriptionFour"
export const LAB_TEST_RESULT_ENTRY_API = "labTestResultEntry"

