import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { findFromList, getClaimedRangeValue, getClaimedValue, getNotClaimedRangeValue, groupBy, isBetweenRange } from '../../../Utils/helper'
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices';
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices';
import secureLocalStorage from 'react-secure-storage';
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices';
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import { useGetLabTestAnalyteMasterByIdQuery, useGetLabTestAnalyteMasterQuery } from '../../../redux/LabServices/LabTestAnalyteMasterServices';
import { Fragment } from 'react';

import { useGetFormulaMasterQuery } from '../../../redux/LabServices/FormulaMasterServices';
import ResultInput from './ResultInput';
import PopupSelect from '../../../UiComponents/PopupSelect';
import ModeOfFailure from '../ResultEntry/ModeOfFailure';
import RemovalForceComponent from '../ResultEntry/RemovalForceComponent';
import { Modal } from '../../../Inputs';
import FailureImageUpload from './FailureImageUpload';
import { useDeleteLabTestResultEntryMutation } from '../../../redux/LabServices/LabTestResultEntryServices';


const SampleTestDetails = ({ isFiberContent, paraItem, isLastResultExist, resultDetailsWithResult, setResultDetailsWithResult, setIsFiberContent, fiberContentId, sampleTwoId, setId, setResultDetails, resultDetails, itemIndex, singleRegistrationData, matchingSampleDetails, registerId, registrationSampleDetailsId, readOnly, childRecord, results,
    resultDatas
}) => {

    const [isRemovalForce, setIsRemovalForce] = useState(false);
    const [failureImage, setFailureImage] = useState([])
    const [isFailureImageOpen, setIsFailureImageOpen] = useState(false);
    const [subHeading, setSubHeading] = useState([])


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),

    };

    const { data: labTestRequirementList, isLoading: isLabRequirementLoading, isFetching: isLabRequirementFetching } =
        useGetLabTestRequirementMasterQuery({
            params: {
                ...params,
                testId: paraItem?.testId ? paraItem.testId : undefined,
                partyId: singleRegistrationData?.data?.buyerId ? singleRegistrationData.data.buyerId : undefined,
                sampleTypeOneId: matchingSampleDetails?.sampleOneId ? matchingSampleDetails.sampleOneId : undefined,
                sampleTypeThreeId: matchingSampleDetails?.sampleThreeId ? matchingSampleDetails.sampleThreeId : undefined,
                sampleTypeFourId: matchingSampleDetails?.sampleFourId ? matchingSampleDetails.sampleFourId : undefined,
            }
        });

    const [deleteResultEntry] = useDeleteLabTestResultEntryMutation();
    const getRegex = useCallback((formula, subHeading) => {
        function getFormula(parameter, subHeading) {
            let resultTest = resultDetails.filter(res => parseInt(res.testId) === parseInt(paraItem.testId));
            resultTest = resultTest.find(i => i.parameterName.toLowerCase() === parameter.toLowerCase())
            if (!resultTest) return parameter
            resultTest = resultTest?.resultValue?.find(i => i.subHeading?.toLowerCase() === subHeading?.toLowerCase())
            if (!resultTest) return parameter
            return resultTest?.value
        }
        if (!formula) return formula
        let input = formula;
        const words = formula.match(/\{(.*?)\}/g)
        if (!words) return formula
        words.forEach(element => {
            input = input.replace(element, getFormula(element.slice(1, -1), subHeading))
        });
        return getRegex(input, subHeading)
    }, [resultDetails, paraItem])
    const { data: formulaList } = useGetFormulaMasterQuery({ params });

    const getResult = useCallback((selectedClaimedValue, matchingSampleDetails, parameter, result, loopsubHeading, walesCourse) => {
        function testResultCalculation(parameter, loopsubHeading, labTestRequirementDetailsId, reqSubHeadingId) {
            let formulaName = parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)
            if (!formulaName) return false
            formulaName = formulaName ? formulaName?.formula?.map(i => i?.formulaId) : []
            let formulaValue = parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)?.formula?.map(i => i?.value)
            if (!formulaValue.every(i => i)) return false
            if (formulaName?.length === 0) return 0
            if (formulaName.length === 1) {
                let functionality = findFromList(formulaName[0], formulaList.data, "functionality")
                let functionArray = ['+', '-']
                if ((!functionArray.includes(functionality)) && (!parameter?.labTestAnalyteDetailsParameters?.isTextBased)) {
                    let result = resultDetails ? resultDetails?.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
                    if (!result) return 0
                    let resultData = result?.resultValue ? result?.resultValue : []

                    if (!resultData?.length === 0) return false
                    let resultValue = resultData?.find(result => parseInt(result.id) === parseInt(reqSubHeadingId))?.value
                    let subHeading = resultData.find(item => parseInt(item.id) === parseInt(reqSubHeadingId))

                    try {
                        resultValue = resultValue ? resultValue : "0";
                        if ((resultValue.length === 3) && (resultValue.includes("-"))) {
                            resultValue = resultValue?.replace("-", ".")
                        }
                        resultValue = (resultValue ? eval(getRegex(resultValue, subHeading?.subHeading)) : "0").toString()
                        let evaluate = `${parseFloat(resultValue?.replace("-", "."))}  ${functionality}   ${parseFloat(formulaValue[0].replace("-", "."))}`
                        return eval(evaluate)
                    }
                    catch (error) {
                        return false
                    }

                }
                else {
                    return getTextBasedResult(parameter, loopsubHeading)
                }
            }
            if (formulaName.length >= 1) {
                let startValue = 0;
                let endValue = 0;
                if (formulaName[0]) {
                    let functionality0 = findFromList(formulaName[0], formulaList?.data, "functionality")
                    startValue = getNotClaimedRangeValue(formulaValue[0], functionality0)
                }
                if (formulaName[1]) {
                    let functionality1 = findFromList(formulaName[1], formulaList?.data, "functionality")
                    endValue = getNotClaimedRangeValue(formulaValue[1], functionality1)
                }
                let result = resultDetails ? resultDetails?.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
                let resultData = result?.resultValue ? result?.resultValue : []
                let subHeading = resultData.find(item => parseInt(item.id) === parseInt(reqSubHeadingId))
                let resultValue = resultData?.find(result => parseInt(result.id) === parseInt(reqSubHeadingId))?.value
                try {
                    resultValue = resultValue ? eval(getRegex(resultValue, subHeading?.subHeading)) : 0
                    return isBetweenRange(startValue, endValue, resultValue)
                }
                catch (error) {
                    return false
                }
            }
        }
        function testResultCalculationForClaimedTest(selectedClaimedValue, matchingResultDetails, labTestRequirementDetailsId, reqSubHeadingId, parameter, loopsubHeading, walesCourse) {
            if (selectedClaimedValue === "CLAIMEDFIBERCONTENT") {
                let formulaName = parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)
                if (!formulaName) return false
                formulaName = formulaName ? formulaName?.formula?.map(i => i?.formulaId) : []
                let formulaValue = parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)?.formula?.map(i => i?.value)
                if (formulaName.length !== 0) {
                    let result = resultDetails ? resultDetails?.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
                    let resultData = result?.resultValue ? result?.resultValue : []



                    try {
                        let resultValue = resultData?.find(result => parseInt(result.id) === parseInt(reqSubHeadingId))?.claimedValue
                        return resultValue?.every(i => {
                            let startValue = i.claimedPercentage;
                            let endValue = i.claimedPercentage;
                            if (formulaValue[0]) {
                                let functionality0 = findFromList(formulaName[0], formulaList.data, "functionality")
                                startValue = getClaimedRangeValue(i.claimedPercentage, formulaValue[0], functionality0);
                            }
                            if (formulaValue[1]) {
                                let functionality1 = findFromList(formulaName[1], formulaList.data, "functionality")
                                endValue = getClaimedRangeValue(i.claimedPercentage, formulaValue[1], functionality1)
                            }
                            return isBetweenRange(startValue, endValue, parseFloat(i.percentage))
                        })
                    }
                    catch (error) {
                        return false
                    }

                }
            } else {
                let formulaName = parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)
                if (!formulaName) return false
                formulaName = formulaName ? formulaName?.formula?.map(i => i?.formulaId) : []
                let formulaValue = parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)?.formula?.map(i => i?.value)
                if (formulaName.length !== 0) {
                    const claimedValue = getClaimedValue(selectedClaimedValue, matchingResultDetails, loopsubHeading, walesCourse);
                    let startValue = claimedValue;
                    let endValue = claimedValue;
                    if (formulaValue[0]) {
                        let functionality0 = findFromList(formulaName[0], formulaList.data, "functionality")
                        startValue = getClaimedRangeValue(claimedValue, formulaValue[0], functionality0);
                    }
                    if (formulaValue[1]) {
                        let functionality1 = findFromList(formulaName[1], formulaList.data, "functionality")
                        endValue = getClaimedRangeValue(claimedValue, formulaValue[1], functionality1)
                    }
                    let result = resultDetails ? resultDetails?.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
                    let resultData = result?.resultValue ? result?.resultValue : []
                    let subHeading = resultData.find(item => parseInt(item.id) === parseInt(reqSubHeadingId))
                    let resultValue = resultData?.find(result => parseInt(result.id) === parseInt(reqSubHeadingId))?.value
                    try {
                        resultValue = resultValue ? eval(getRegex(resultValue, subHeading?.subHeading)) : 0
                        return isBetweenRange(startValue, endValue, resultValue)
                    }
                    catch (error) {
                        return false
                    }

                }
                return false
            }
        }
        function getTextBasedResult(parameter, loopsubHeading) {
            const datas = resultDetails ? resultDetails.find(item => parseInt(item?.labTestRequirementDetailsId) === parseInt(parameter.id)) : []
            const resultData = datas?.resultValue ? datas?.resultValue : []
            return resultData.filter(result => result.requirementGroup == loopsubHeading).every(result =>
                getResultOfText(parameter, result, loopsubHeading)
            )
        }
        function getResultOfText(parameter, result, loopsubHeading) {
            let formulaValue = parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)?.formula?.map(i => i?.value)
            if (!formulaValue.every(i => i)) return false
            return formulaValue[0]?.toLowerCase().trim() == result?.value?.toLowerCase().trim()
        }
        return selectedClaimedValue
            ?
            testResultCalculationForClaimedTest(selectedClaimedValue, matchingSampleDetails, parameter?.id, result.id, parameter, loopsubHeading, walesCourse)
            :
            testResultCalculation(parameter, loopsubHeading, parameter?.id, result?.id)
    }, [getRegex, formulaList, resultDetails])





    function getIsRequirementDeleted(id) {

        return resultDatas.find(i => parseInt(i.labTestRequirementDetailsId) === parseInt(id))?.isDeleted;
    }


    function getAllResultTestLevel() {

        return resultDetailsWithResult.filter(i => !(getIsRequirementDeleted(i.labTestRequirementDetailsId))).filter(i => parseInt(i.testId) === parseInt(paraItem.testId)).filter(i => i?.parameter?.labTestAnalyteDetailsParameters?.isResult).every((datas) => {
            const resultData = datas?.resultValue ? datas?.resultValue : []

            return resultData.every((result) => {
                return result?.result
            })
        })
    }

    const { data: labTestParameterList } =
        useGetLabTestAnalyteMasterQuery({ params });

    const parameterList = labTestParameterList?.data.find((items) => ((parseInt(items.testId) === parseInt(paraItem?.testId)) && (parseInt(items.SampleDescriptionOne.id) === parseInt(matchingSampleDetails?.sampleOneId)) && (items?.active)))

    const parameterListId = parameterList?.id ? parameterList.id : ""
    const { data: singleLabTestParameterList, isLoading: isLabParameterLoading, isFetching: isLabParameterFetching } =
        useGetLabTestAnalyteMasterByIdQuery(parameterListId, { skip: !parameterListId });

    const { data: labTestNameList, isLoading: isLabTestLoading, isFetching: isLabTestFetching } =
        useGetLabTestNameMasterQuery({ params });

    const { data: labTestMethodList } =
        useGetLabTestMethodMasterQuery({ params });


    const { data: labTestConditionList } =
        useGetLabTestConditionMasterQuery({ params });


    function getAllResultRequirementLevel(parameter, loopsubHeading) {


        const reqIndex = resultDetailsWithResult.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(parameter.id));
        const datas = resultDetailsWithResult[reqIndex]
        if (!datas) return false

        const resultData = datas?.resultValue ? datas?.resultValue : []
        if (!resultData) return false

        const resultIndex = resultDetailsWithResult[reqIndex]["resultValue"]?.findIndex(i => i.subHeading?.toLowerCase() == loopsubHeading?.toLowerCase())

        return resultData.filter(result => result?.requirementGroup?.toLowerCase() == loopsubHeading?.toLowerCase()).every((result, subHeadingIndex) => {



            return resultDetailsWithResult[reqIndex]["resultValue"][resultIndex]["result"]
        }
        )
    }

    useEffect(() => {
        const selectedOption = singleLabTestParameterList?.data?.selectedOption ? singleLabTestParameterList?.data?.selectedOption : "";
        setIsFiberContent(selectedOption === "CLAIMEDFIBERCONTENT");
    }, [singleLabTestParameterList, isLabParameterLoading, isLabParameterFetching, setIsFiberContent])



    const requirementId = useMemo(() => (resultDatas || [])
        .filter(i => parseInt(i.testId) === parseInt(paraItem.testId))[0]?.LabTestRequirementDetails?.labTestRequirementId, [resultDatas, paraItem])



    useEffect(() => {
        if (!isFiberContent) return
        if (isLabRequirementLoading || isLabRequirementFetching || !labTestRequirementList?.data) return

        setResultDetails(prev => {
            let labTestRequirementDetailsTest;
            if (requirementId) {
                labTestRequirementDetailsTest = (labTestRequirementList?.data || []).find(i => parseInt(i.id) === parseInt(requirementId));
            } else {
                labTestRequirementDetailsTest = labTestRequirementList.data.filter(i => i.active)[0]
            }
            const newResults = structuredClone(prev)
            labTestRequirementDetailsTest?.LabTestRequirementDetails?.filter(item => parseInt(item.labTestAnalyteDetailsParameters?.sampleTypeTwoId) === parseInt(sampleTwoId))
                // .filter(i => isLastResultExist(i.id, paraItem.testId))
                .forEach(parameter => {
                    let prevIndex = prev.findIndex(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id))
                    let isResult = results.find(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id));
                    let resultValue = isResult ? isResult.resultValue : [{ subHeading: "base", value: parameter?.labTestAnalyteDetailsParameters?.value }]
                    let typeOfComponent = isResult && isResult?.typeOfComponent ? isResult.typeOfComponent : "";
                    let modeOfFailure = isResult && isResult?.modeOfFailure ? isResult?.modeOfFailure : "";

                    let areaOfComponent = isResult && isResult?.areaOfComponent ? isResult?.areaOfComponent : "";






                    if (prevIndex != -1) {
                        newResults[prevIndex]["resultValue"] = resultValue;
                        newResults[prevIndex]["typeOfComponent"] = typeOfComponent;
                        newResults[prevIndex]["modeOfFailure"] = modeOfFailure;
                        newResults[prevIndex]["areaOfComponent"] = areaOfComponent;
                        newResults[prevIndex]["isDeleted"] = isResult?.isDeleted;
                    } else {
                        newResults.push({
                            labTestRequirementDetailsId: parameter.id,
                            isDeleted: isResult?.isDeleted,
                            parameter: parameter,
                            parameterName: parameter?.labTestAnalyteDetailsParameters?.name ? parameter?.labTestAnalyteDetailsParameters?.name : "",
                            testId: paraItem.testId,
                            headingId: parameter.headingId,
                            typeOfComponent,
                            modeOfFailure,
                            areaOfComponent,
                            resultValue
                        })
                    }
                })

            return newResults
        })
    }, [labTestRequirementList, isLabRequirementLoading, isLabRequirementFetching, setResultDetails, results, paraItem, sampleTwoId, isLastResultExist, requirementId])




    useEffect(() => {
        if (isFiberContent) return
        if (isLabRequirementLoading || isLabRequirementFetching || !labTestRequirementList?.data) return

        setResultDetails(prev => {
            let labTestRequirementDetailsTest;
            if (requirementId) {

                labTestRequirementDetailsTest = (labTestRequirementList?.data || []).find(i => parseInt(i.id) === parseInt(requirementId));

            } else {
                labTestRequirementDetailsTest = labTestRequirementList.data.filter(i => i.active)[0]
            }



            const newResults = structuredClone(prev)
            labTestRequirementDetailsTest?.LabTestRequirementDetails?.filter(item => item.labTestAnalyteDetailsParameters?.sampleTypeTwoId == sampleTwoId)
                .forEach((parameter, index) => {
                    let prevIndex = prev.findIndex(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id))
                    let isResult = results.find(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id));
                    let resultValue;
                    resultValue = isResult ? isResult?.resultValue
                        : [{ subHeading: "Base", value: parameter?.labTestAnalyteDetailsParameters?.value ? parameter?.labTestAnalyteDetailsParameters.value : "", claimedValue: [] }]
                    let typeOfComponent = isResult && isResult?.typeOfComponent ? isResult.typeOfComponent : "";
                    let modeOfFailure = isResult && isResult?.modeOfFailure ? isResult?.modeOfFailure : "";
                    let areaOfComponent = isResult && isResult?.areaOfComponent ? isResult?.areaOfComponent : "";
                    if (prevIndex !== -1) {
                        newResults[prevIndex]["resultValue"] = resultValue;
                        newResults[prevIndex]["typeOfComponent"] = typeOfComponent;
                        newResults[prevIndex]["modeOfFailure"] = modeOfFailure;
                        newResults[prevIndex]["areaOfComponent"] = areaOfComponent;
                        newResults[prevIndex]["isDeleted"] = isResult?.isDeleted;
                    } else {
                        newResults.push({
                            labTestRequirementDetailsId: parameter.id,
                            isDeleted: isResult?.isDeleted,
                            labTestRequirementId: parameter.labTestRequirementId,
                            parameter: parameter,
                            parameterName: parameter?.labTestAnalyteDetailsParameters.name,
                            testId: paraItem.testId,
                            headingId: parameter.headingId,
                            typeOfComponent,
                            modeOfFailure,
                            areaOfComponent,
                            resultValue
                        })
                    }
                })
            return newResults
        })
    }, [labTestRequirementList, isLabRequirementLoading, isLabRequirementFetching, setResultDetails, results, paraItem, isFiberContent, isLastResultExist, sampleTwoId, requirementId])


    useEffect(() => {
        // let req = (labTestRequirementList?.data[0])
        // if (isFiberContent) return
        let req;
        if (requirementId) {

            req = (labTestRequirementList?.data || []).find(i => parseInt(i.id) === parseInt(requirementId));
        } else {

            req = (labTestRequirementList?.data || []).filter(i => i.active)[0]
        }
        let reqList = req?.LabTestRequirementDetails

        if (!reqList) return


        setResultDetails(prev => {

            let newPrev = structuredClone(prev)

            // if (newPrev.length === 0) return prev

            return newPrev.map(item => {
                let newItem = structuredClone(item)
                let reqAdmin = reqList.find(reqItem => parseInt(reqItem.id) === parseInt(item.labTestRequirementDetailsId))

                if (!reqAdmin) return item


                let defaultrequirement = reqAdmin.requirement.find(reqItemSubHeading => reqItemSubHeading.subHeading.toLowerCase() == "base")
                newItem.resultValue.forEach(resultItem => {
                    let requirement = reqAdmin.requirement.find(reqItemSubHeading => reqItemSubHeading.subHeading.toLowerCase() == resultItem.subHeading.toLowerCase())

                    if (requirement) {

                        resultItem["requirementGroup"] = requirement?.subHeading
                    } else {
                        resultItem["requirementGroup"] = defaultrequirement?.subHeading
                    }
                })
                return newItem
            })
        })
    }, [setResultDetails, labTestRequirementList, isLabRequirementLoading, isLabRequirementFetching, requirementId])




    function handleChangeBlend(newValue, subHeading, labTestRequirementDetailsId, resultIndex, itemIndex, field) {
        const index = resultDetails.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));

        setResultDetails(prev => {
            let newReq = structuredClone(prev);
            const subHeadingIndex = newReq[index]["resultValue"].findIndex(i => i.subHeading.toLowerCase() == subHeading.toLowerCase())
            newReq[index]["resultValue"][subHeadingIndex]["claimedValue"][itemIndex][field] = newValue;
            return newReq
        })
    }

    useEffect(() => {
        const isRemovalForce = findFromList(paraItem.testId, labTestNameList?.data, "isRemovalForce")
        if (isRemovalForce) {
            return setIsRemovalForce(isRemovalForce)
        }
        else {
            return
        }
    }, [setIsRemovalForce, paraItem.testId, labTestNameList, isLabTestLoading, isLabTestFetching])


    let matchingResultDetails = resultDetails?.find(value => value?.testId === paraItem?.testId);
    let testParametersHeadings = labTestRequirementList?.data.flatMap(item => item.LabTestRequirementDetails)

    function findHeading(headingId) {
        return testParametersHeadings?.find(item => item.headingId == headingId).labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.heading
    }



    const labTestRequirements = labTestRequirementList?.data ? labTestRequirementList?.data?.filter(item => item?.active) : []



    const labTestRequirementsData = labTestRequirements[0]?.LabTestRequirementDetails && (labTestRequirements[0]?.LabTestRequirementDetails[0]?.["requirement"]?.map(item => item.subHeading) || []);

    if (!singleLabTestParameterList || isLabParameterLoading || isLabParameterFetching || !labTestNameList || !labTestMethodList || !labTestConditionList || !labTestRequirementList || !labTestParameterList
        || isLabRequirementFetching || isLabRequirementLoading || !formulaList
    ) return null
    return (
        <>

            <Modal isOpen={isFailureImageOpen} onClose={() => setIsFailureImageOpen(false)} widthClass={"px-2 h-[95%] w-[50%]"}>
                <FailureImageUpload matchingSampleDetails={matchingSampleDetails} item={paraItem} sampleTestId={paraItem?.id} failureImage={failureImage} setFailureImage={setFailureImage} setIsFailureImageOpen={setIsFailureImageOpen} />
            </Modal>


            <div className=" p-4 w-full">
                <div key={itemIndex} className='text-xs table-input'>
                    <div className=' p-3 flex w-full justify-between font-bold'>
                        <div className='grid grid-rows-2'>
                            <div className='text-start font-bold'>
                                {itemIndex + 1} . {findFromList(paraItem.testId, labTestNameList.data, "name")}
                            </div>
                            <div className='flex gap-x-3'>{findFromList(paraItem.methodId, labTestMethodList.data, "name")}
                                <span>
                                    {findFromList(paraItem.conditionId, labTestConditionList.data, "name")}
                                </span>
                            </div>
                        </div>
                        <div className='flex gap-x-5 text-xs'>
                            <button className='bg-blue-400 rounded-lg p-1' onClick={() => { setIsFailureImageOpen(true) }}>Failure Image</button>
                            <button className='bg-red-400 rounded-lg p-1' onClick={() => { deleteResultEntry({ registrationSampleId: registrationSampleDetailsId, testId: paraItem.testId }) }}>
                                Delete All Result Entries</button>
                        </div>
                        <div className='text-xl uppercase pl-2'>
                            {

                                paraItem?.isData ?
                                    <span className='text-white bg-orange-600 p-1 rounded-md tracking-wider'>Data</span>

                                    :
                                    getAllResultTestLevel()
                                        ?
                                        <span className='text-white bg-green-700 p-1 rounded-md tracking-wider'>Pass</span>
                                        :
                                        <span className='text-white bg-red-500 p-1 rounded-md tracking-wider'>Fail</span>
                            }
                        </div>
                    </div>
                </div>
                <table className='w-full table-fixed'>
                    <thead>
                        <tr className="border border-gray-500 table-input text-xs">
                            <th className='w-9 border border-gray-500 p-2 capitalize '>
                                s.no
                            </th>
                            <th className="w-64  border border-gray-500">Parameter</th>

                            {
                                isRemovalForce ?
                                    <>
                                        <th className="w-40  border border-gray-500">Type Of Component</th>
                                        <th className="w-40  border border-gray-500">Area Of Component</th>
                                    </>
                                    : ""

                            }

                            <th className="border border-gray-500">
                                <div className='flex justify-between items-center '>
                                    <div className='text-center w-full'>
                                        Test Entry
                                    </div>
                                </div>
                            </th>
                            {
                                isRemovalForce ?

                                    <th className="w-40  border border-gray-500">Mode Of Failure</th>

                                    : ""
                            }
                            <th className='w-44 border border-gray-500'>
                                Requirement
                            </th>
                            <th className='w-32 border border-gray-500'>
                                Test Result
                            </th>

                        </tr>
                    </thead>
                    <tbody>

                        {labTestRequirementsData
                            ?.filter(sub =>
                                resultDetails.some(i =>
                                    i.resultValue.some(j => j.requirementGroup?.toLowerCase() == sub?.toLowerCase())
                                ))
                            .map(loopsubHeading =>
                                <Fragment key={loopsubHeading} >
                                    <tr className='border border-gray-500 '>
                                        <td className='border border-gray-500'></td>
                                        <td className='border border-gray-500'></td>
                                        {
                                            isRemovalForce ?
                                                <td className='border border-gray-500' colSpan={2}></td>
                                                : ""
                                        }
                                        <td className='border border-gray-500 flex'>
                                            {matchingResultDetails?.resultValue.filter(item => item["requirementGroup"] &&
                                                item["requirementGroup"].toLowerCase() === loopsubHeading.toLowerCase()).map((sub, i) =>
                                                    <div className='flex gap-2 w-42' key={i}>
                                                        <input colSpan={sub} value={sub?.subHeading}
                                                            className='border border-gray-500 pt-1 pb-1 w-32 ml-0.5 pl-4 text-xs capitalize text-center'
                                                            onChange={(e) => {
                                                                setResultDetails(prev => {
                                                                    let newPrev = structuredClone(prev);
                                                                    const heading = e.target.value;
                                                                    return newPrev.map((item) => {
                                                                        if (!item["resultValue"]) return item
                                                                        if (!item["resultValue"][i]) return item
                                                                        if (matchingResultDetails?.testId === item?.testId) {
                                                                            item["resultValue"][i]["subHeading"] = heading;
                                                                        }
                                                                        return item
                                                                    })
                                                                })
                                                            }}
                                                        >
                                                        </input>
                                                        <PopupSelect options={labTestRequirementsData} value={loopsubHeading}
                                                            onChange={(e) => {
                                                                setResultDetails(prev => {
                                                                    let newPrev = structuredClone(prev);
                                                                    let onlyTestRequirementsNotMatching = newPrev.filter(prevItem => prevItem.testId !== paraItem.testId)
                                                                    let onlyTestRequirementsMatching = newPrev.filter(prevItem => prevItem.testId === paraItem.testId)
                                                                    onlyTestRequirementsMatching = onlyTestRequirementsMatching.map(item => {
                                                                        let newItem = structuredClone(item)
                                                                        let resultIndex = newItem["resultValue"].findIndex(item => item.subHeading
                                                                            === sub.subHeading)

                                                                        newItem["resultValue"][resultIndex]["requirementGroup"] = e.target.value;
                                                                        return newItem
                                                                    })
                                                                    return [...onlyTestRequirementsMatching, ...onlyTestRequirementsNotMatching]
                                                                })
                                                            }} />
                                                        {/* <button
                                                            type='button'
                                                            onClick={() => {
                                                                setResultDetails(prev => {
                                                                    let newPrev = structuredClone(prev);
                                                                    let onlyTestRequirementsNotMatching = newPrev.filter(prevItem => prevItem.testId !== item.testId)
                                                                    let onlyTestRequirementsMatching = newPrev.filter(prevItem => prevItem.testId === item.testId)
                                                                    onlyTestRequirementsMatching = onlyTestRequirementsMatching.map((item) => {
                                                                        return {
                                                                            ...item,
                                                                            resultValue: [...item.resultValue.filter((item, itemIndex) => i !== itemIndex)

                                                                            ]
                                                                        }
                                                                    })
                                                                    return [...onlyTestRequirementsMatching, ...onlyTestRequirementsNotMatching]
                                                                })

                                                            }}
                                                            className='ml-0.5 w-4 text-xs text-red-600'>{<FontAwesomeIcon icon={faTrashCan} />}
                                                        </button> */}
                                                    </div>
                                                )}

                                        </td>
                                        {
                                            isRemovalForce ?
                                                <td className='border border-gray-500' ></td>
                                                : ""
                                        }
                                        <td className='text-center font-bold border border-gray-500 capitalize text-sm'>{loopsubHeading}</td>
                                    </tr>


                                    {(labTestRequirementList?.data || []).filter(i => requirementId ? (parseInt(i.id) === parseInt(requirementId)) : i?.active)
                                        .map((item, itemIndex) =>
                                            <Fragment key={item.id}>

                                                {(item?.LabTestRequirementDetails ? Object.entries(groupBy(item?.LabTestRequirementDetails.filter(val => resultDetails.find(i => i.headingId == val.headingId)).filter(item => item.labTestAnalyteDetailsParameters?.sampleTypeTwoId == sampleTwoId), "headingId")) : [])
                                                    .filter(([headingId, parameters]) =>
                                                        parameters?.filter(item => resultDetails.find(val => (val.labTestRequirementDetailsId == item.id) && !val.isDeleted)).length > 0
                                                    )
                                                    .map(([headingId, parameters], valueIndex) =>

                                                        <Fragment key={headingId}>
                                                            <tr className='border border-gray-500'>
                                                                <td className=' border border-gray-500'></td>
                                                                <td className=' border border-gray-500'>  </td>
                                                                <td className='h-7  text-center font-bold p-1 text-xs capitalize '> {findHeading(headingId)}</td>
                                                            </tr>

                                                            {parameters?.filter(i => !getIsRequirementDeleted(i.id)).map((parameter, index) =>
                                                                <tr key={index} className='text-xs'>
                                                                    <td className='border border-gray-500 p-1'>{index + 1}</td>
                                                                    <td className='border border-gray-500 p-1 capitalize text-xs'>  {parameter?.labTestAnalyteDetailsParameters?.name}</td>
                                                                    {
                                                                        isRemovalForce &&
                                                                        <RemovalForceComponent resultDetails={resultDetails} isResult={parameter?.labTestAnalyteDetailsParameters?.isResult} labTestRequirementDetailsId={parameter.id} setResultDetails={setResultDetails} readOnly={readOnly} childRecord={childRecord} parameter={parameter} />

                                                                    }
                                                                    <td className='border border-gray-500 capitalize text-xs'>
                                                                        <ResultInput
                                                                            item={item} paraItem={paraItem}
                                                                            resultDetailsWithResult={resultDetailsWithResult}
                                                                            getAllResultTestLevel={getAllResultTestLevel}
                                                                            isSelectedOption={parameter?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.LabTestAnalyte?.selectedOption}
                                                                            isTextBased={parameter?.labTestAnalyteDetailsParameters?.isTextBased}
                                                                            setResultDetailsWithResult={setResultDetailsWithResult}
                                                                            handleChangeBlend={handleChangeBlend} fiberContentId={fiberContentId}
                                                                            singleParameter={singleLabTestParameterList.data}
                                                                            parameters={singleLabTestParameterList.data.LabTestAnalyteDetails}
                                                                            getRegex={getRegex} isEditable={parameter?.labTestAnalyteDetailsParameters?.isEditable}
                                                                            isResult={parameter?.labTestAnalyteDetailsParameters?.isResult}
                                                                            requirementList={labTestRequirementList?.data[0]} testItem={item}
                                                                            parameter={parameter}
                                                                            setResultDetails={setResultDetails} loopsubHeading={loopsubHeading}
                                                                            results={results} setSubHeading={setSubHeading} subHeading={subHeading}
                                                                            setId={setId} readOnly={readOnly} childRecord={childRecord}
                                                                            registrationSampleDetailsId={registrationSampleDetailsId}
                                                                            index={index} labTestRequirementDetailsId={parameter.id}
                                                                            resultDetails={resultDetails} registerId={registerId}
                                                                            matchingSampleDetails={matchingSampleDetails} getClaimedValue={getClaimedValue}
                                                                            getResult={getResult}
                                                                        />
                                                                    </td>
                                                                    {
                                                                        isRemovalForce &&

                                                                        <ModeOfFailure resultDetails={resultDetails} isResult={parameter?.labTestAnalyteDetailsParameters?.isResult} labTestRequirementDetailsId={parameter.id} setResultDetails={setResultDetails} readOnly={readOnly} childRecord={childRecord} parameter={parameter} />

                                                                    }
                                                                    <td className='border border-gray-500 text-center'>
                                                                        {
                                                                            (parameter?.labTestAnalyteDetailsParameters?.isResult && !parameter?.labTestAnalyteDetailsParameters?.isTextBased)
                                                                                ?
                                                                                <div className='flex gap-1'>
                                                                                    <span>
                                                                                        {(
                                                                                            (parameter?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.LabTestAnalyte?.selectedOption != "")
                                                                                                ?
                                                                                                getClaimedValue(parameter?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.LabTestAnalyte?.selectedOption, matchingSampleDetails, loopsubHeading, parameter?.labTestAnalyteDetailsParameters?.walesCourse)
                                                                                                :
                                                                                                ""
                                                                                        )}
                                                                                    </span>
                                                                                    (<span className='flex gap-1 text-start capitalize'>
                                                                                        {
                                                                                            // (parameter?.requirement?.find(item => item.subHeading == loopsubHeading)?.formula?.map((i, index) =>
                                                                                            //     <span className='' key={index}>
                                                                                            //         {`${i?.Formula?.name} ${i.value} `}
                                                                                            //     </span>
                                                                                            // ))
                                                                                            (parameter?.requirement ? (parameter?.requirement?.find(item => item?.subHeading?.trim()?.toLowerCase() === loopsubHeading?.toLowerCase())?.formulaAliasName) : "")

                                                                                        }
                                                                                    </span>)
                                                                                </div>
                                                                                :
                                                                                <span className='flex gap-1 text-start capitalize'>
                                                                                    {
                                                                                        // (parameter?.requirement?.find(item => item?.subHeading == loopsubHeading)?.formula?.map((i, index) =>
                                                                                        //     <span className='' key={index}>
                                                                                        //         {` ${i.value} `}
                                                                                        //     </span>
                                                                                        // ))
                                                                                        (parameter?.requirement ? (parameter?.requirement?.find(item => item?.subHeading?.trim()?.toLowerCase() === loopsubHeading?.toLowerCase())?.formulaAliasName) : "")

                                                                                    }
                                                                                </span>
                                                                        }
                                                                        { }
                                                                    </td>
                                                                    <td className=' border border-gray-500 text-center font-bold'>
                                                                        {
                                                                            ((parameter?.labTestAnalyteDetailsParameters?.isResult && !paraItem?.isData))
                                                                                ?
                                                                                (getAllResultRequirementLevel(parameter, loopsubHeading)
                                                                                    ?
                                                                                    <span className='text-green-700'>Pass</span>
                                                                                    :
                                                                                    <span className='text-red-500'>Fail</span>)
                                                                                :
                                                                                (paraItem?.isData && parameter?.labTestAnalyteDetailsParameters?.isResult)
                                                                                    ?
                                                                                    <span className='text-orange-500'>Data</span>
                                                                                    : "---"

                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Fragment>
                                                    )}
                                            </Fragment>
                                        )}
                                </Fragment>
                            )}
                    </tbody>
                </table>

            </div>











        </>
    )
}

export default SampleTestDetails