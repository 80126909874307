import React from 'react'
import logo from "../../../assets/pin.png"
const Header = () => {
  return (
    <>

      <div className='border-b-1 border-gray-900 flex '>
        <div className="w-1/4">
          <img className="bg-gray-400 rounded-xl p-2 m-4 w-28" src={logo} />
        </div>
        <div className="py-4 text-center w-2/4">
          <h1 className="font-bold text-xl underline underline-offset-4"> TEXTILE TESTING LABORATORY</h1>

          <h1 className="font-bold text-lg underline underline-offset-4 mt-2">Test Scanning Sheet</h1>
        </div>
      </div>


    </>
  )
}

export default Header