

const SampleTab = ({ label, isActive, onClick }) => {
    return (
        <div>
            <button className={`${isActive ? 'nav-bar-bg text-white' : 'text-black'} px-4 py-1.5 mx-2 font-bold  mt-1 border rounded-md`} onClick={() => onClick(label)}>
                <span className="text-[11px]">{label}</span>
            </button>
        </div>
    );
};
export default SampleTab