import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import tw from '../../../../Utils/tailwind-react-pdf'

const ColourFastnessLegend = ({ testResults }) => {
    return (
        <View style={tw("ml-9 mt-9")} wrap={false}>
            {

                testResults?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults).length > 0 ||
                    testResults?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults).length > 0 ||
                    testResults?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults).length > 0 ||
                    testResults?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults).length > 0 ?
                    <View style={tw('border border-gray-600 w-3/4')}>
                        <View>
                            <View style={tw(' text-xs text-center w-full')}>
                                <Text style={[tw(' border-b border-gray-500 text-xs p-1'),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>
                                    COLOUR CHANGE / STAINING RATING
                                </Text>
                            </View>
                            <View style={tw("flex flex-row  border-b border-gray-500")}>
                                <Text style={tw('border-r border-gray-500 text-xs p-1 w-1/3')}>
                                    Grade 5
                                </Text>
                                <Text style={tw(' text-xs p-1 w-2/3')}>
                                    NO CHANGE
                                </Text>
                            </View>
                            <View style={tw("flex flex-row  border-b border-gray-500")}>
                                <Text style={tw('border-r border-gray-500 text-xs p-1 w-1/3')}>
                                    Grade 4
                                </Text>
                                <Text style={tw(' text-xs p-1 w-2/3')}>
                                    SLIGHT COLOUR CHANGE / STAINING
                                </Text>
                            </View>
                            <View style={tw("flex flex-row  border-b border-gray-500")}>
                                <Text style={tw('border-r border-gray-500 text-xs p-1 w-1/3')}>
                                    Grade 3
                                </Text>
                                <Text style={tw(' text-xs p-1 w-2/3')}>
                                    NOTICEABLE COLOUR CHANGE / STAINING
                                </Text>
                            </View>
                            <View style={tw("flex flex-row border-b border-gray-500")}>
                                <Text style={tw('border-r border-gray-500 text-xs p-1 w-1/3')}>
                                    Grade 2
                                </Text>
                                <Text style={tw(' text-xs  p-1 w-2/3')}>
                                    CONSIDERABLE COLOUR CHANGE / STAINING
                                </Text>
                            </View>
                            <View style={tw("flex flex-row ")}>
                                <Text style={tw('border-r border-gray-500 text-xs p-1 w-1/3')}>
                                    Grade 1
                                </Text>
                                <Text style={tw(' text-xs p-1 w-2/3')}>
                                    SEVERE COLOUR CHANGE / STAINING
                                </Text>
                            </View>
                        </View>
                    </View> : ""
            }
        </View>
    )
}

export default ColourFastnessLegend