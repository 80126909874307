import { Document } from '@react-pdf/renderer'
import React from 'react'
import Content from './Content'
import tw from '../../../Utils/tailwind-react-pdf'



const TestReport = ({ headingOptions, washCareData, testComments, splitTest, passFail, reportData }) => {
    return (
        <Document style={[tw("text-sm"), { fontFamily: "Times-Roman" }]} key={[headingOptions, washCareData, testComments, splitTest, passFail, reportData]} >
            <Content headingOptions={headingOptions} washCareData={washCareData} testComments={testComments} splitTest={splitTest} reportData={reportData} passFail={passFail} />
        </Document>
    )
}

export default TestReport