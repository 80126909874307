import React, { useEffect, useState } from 'react'
import { TextInput } from '../../../Inputs';
import { useGetBranchByIdQuery, useUpdateBranchMutation } from '../../../redux/services/BranchMasterService';
import { getCommonParams } from '../../../Utils/helper';
import { toast } from 'react-toastify';

const TaxDetails = () => {
    const [scgst, setScgst] = useState(0);
    const [igst, setIgst] = useState(0);
    const [tds, setTds] = useState(0);

    const { branchId } = getCommonParams()
    const { data, isLoading, isFetching } = useGetBranchByIdQuery(branchId);

    useEffect(() => {
        if (!data?.data) return
        let currentData = data?.data;
        setScgst(currentData?.scgst ? currentData?.scgst : 0);
        setIgst(currentData?.igst ? currentData?.igst : 0);
        setTds(currentData?.tds ? currentData?.tds : 0);
    }, [data, isLoading, isFetching])

    const [updateBranch] = useUpdateBranchMutation()

    const handleSubmit = async (text = "uploaded") => {
        try {
            await updateBranch({ id: branchId, scgst, igst,tds });
            toast.success(text + " Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    return (
        <fieldset className='w-1/2'>
            <legend className='sub-heading'>Tax Details</legend>
            <TextInput name="SCGST" type="number" min={0} value={scgst} setValue={setScgst} required={true} />
            <TextInput name="IGST" type="number" min={0} value={igst} setValue={setIgst} required={true} />
            <TextInput name="TDS" type="number" min={0} value={tds} setValue={setTds} required={true} />
            <div className='flex justify-end'>
                <button className='bg-green-600 hover:bg-green-700 text-gray-100 rounded-md p-1' onClick={() => handleSubmit()}>Save</button>
            </div>
        </fieldset>
    )
}

export default TaxDetails
