import React from 'react'
import PageWrapper from "../PageWrapper";
import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";
import tw from "../../../../Utils/tailwind-react-pdf";
import SampleDetailsPrint from './SampleDetailsPrint';
import WashCareInstruction from './WashCareInstruction'
import SampleTestParametersPrint from './SampleTestParametersPrint'
import { formatAMPM, getDateFromDateTimeForDisplay } from '../../../../Utils/helper';
import moment from 'moment';
import WashCareData from './WashCareData';
import { TICK_ICON_PDF } from '../../../../icons';




const Content = ({ reportData, testList, methodList, data, washCareData }) => {


  // Font.register({
  //   family: 'Roboto',
  //   fontWeight:"bold",


  // src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxK.woff2',

  // })

  // Font.register({ family: 'Roboto', fonts: [ { src: roboto , fontWeight: 'bold' } ] })


  return (
    <>
      <PageWrapper >
        <View style={tw("p-5 w-full")}>

          <View style={tw("flex gap-y-5 w-full")}>

            <View style={tw("flex flex-row justify-start  w-full ")} >
              <View style={tw("flex flex-row w-1/2 gap-x-2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}> Lab Report No :</Text>
                <Text style={tw("text-xs")}>  {data?.docId} </Text>
              </View>
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Log-In-Date And Time : </Text>
                <Text style={tw("text-xs")}>{data?.createdAt ? moment(data.createdAt).format("DD-MM-YYYY") : ""} </Text>
              </View>
            </View>


            <View style={tw("flex flex-row justify-start   w-full  ")} >

              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Due Date : </Text><Text style={tw("text-xs")}>{data?.dueDate ? moment(data.dueDate).format("DD-MM-YYYY") : ""}</Text>
              </View>
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold "), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Service :</Text>
                <Text style={tw("text-xs")}>{data?.ServiceLevel?.name}</Text>
              </View>
            </View>

            <View style={tw("flex flex-row  justify-start w-full ")} >

              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Buyer Name :</Text>
                <Text style={tw("text-xs")}>{data?.Buyer?.name} </Text>
              </View>
              <View style={tw("flex flex-row gap-x-2 w-1/2")}>
                <Text style={[tw("text-xs font-bold"), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Log In By:</Text>
                <Text style={tw("text-xs")}>{data?.logIn} </Text>
              </View>
            </View>


          </View>

          <View style={tw('mt-5 ')}>
            <SampleDetailsPrint data={data} reportData={reportData} />
          </View>
          <View style={tw("mt-2")}>
            <View style={tw("w-full")}>
              <View style={tw("border border-gray-500 p-1")}>
                <Text style={tw("text-xs underline")}>Submitted Washcare Instruction :</Text>
                <View style={tw("flex flex-row gap-x-3 ")} >
                  {(data?.washCareDetails ? data.washCareDetails : []).map((value, index) =>
                    <WashCareData key={index} value={value} />)}
                </View>
              </View>
            </View>
          </View>
        </View>
      </PageWrapper>
      <PageWrapper>
        <View style={tw("p-12 w-full")}>
          <SampleTestParametersPrint testList={testList} methodList={methodList} testDetails={data?.testDetails ? data?.testDetails : []} packageName={data?.Package ? data?.Package.name : ""} />
        </View>
        <View style={tw('p-3')}>
          <Text style={[tw('underline'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>(Note) </Text>
          <View style={tw('mt-6 ')}>
            <View style={tw('mt-2 flex flex-row gap-x-2')}>
              <View>{TICK_ICON_PDF}</View> <Text>You are requested to update your GSTN details in our records. Please send your GST number(s) and Address to account.pro@srgapparels.com</Text>
            </View>
            <View style={tw('mt-2 flex flex-row gap-x-2')}>
              <View>{TICK_ICON_PDF}</View>   <Text>Please quote Lab Report number for any correspondence.</Text>
            </View>
            <View style={tw('mt-2 flex flex-row gap-x-2')}>
              <View>{TICK_ICON_PDF}</View>  <Text>Delay in receiving any requested mandatory information or additional sample may affect in report due date.
              </Text>
            </View>
            <View style={tw('mt-2 flex flex-row gap-x-2')}>
              <View>{TICK_ICON_PDF}</View>   <Text>Test assignment are made based on the specification in the submitted TRF by customer.</Text>
            </View>
            <View style={tw('mt-2 flex flex-row gap-x-2')}>
              <View>{TICK_ICON_PDF}</View> <Text>Hard copy of test report will be issued based on the request.</Text>
            </View>
            <View style={tw('mt-2 flex flex-row gap-x-2')}>
              <View>{TICK_ICON_PDF}</View>  <Text>Please check with accounts for outstanding payment and clear the payment to avoid the delay for further testing / reports process.</Text>
            </View>
            <View style={tw('mt-2 flex flex-row gap-x-2')}>
              <View>{TICK_ICON_PDF}</View>   <Text> Please feel free Contact For further enquiry :  Technical & Customer Service: N. Sethuraman – 9952785239 / 9677706626.</Text>
            </View>

          </View>
        </View>
        {
          data?.OnHoldReason?.name &&
          <View style={tw('mt-2 flex flex-row gap-x-2 items-center justify-center mt-5 bg-red-500 p-1')}>
            <Text style={[tw('underline'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>OnHold Reason : </Text>
            <Text style={[tw(''), { fontWeight: 900, fontFamily: "Times-Bold" }]}>{data?.OnHoldReason?.name ? data?.OnHoldReason?.name : "---"} </Text>
          </View>
        }


      </PageWrapper>
    </>
  )
}

export default Content