import React, { useContext, useRef } from "react";
import { FieldsContext } from '..';
import secureLocalStorage from "react-secure-storage";
import { DropdownInput } from "../../../../Inputs";
import { dropDownListObject } from "../../../../Utils/contructObject";
import { useGetSampleDescriptionOneMasterQuery } from "../../../../redux/LabServices/SampleDescriptionOneMasterServices";
import { useGetSampleDescriptionTwoMasterQuery } from "../../../../redux/LabServices/SampleDescriptionTwoMasterServices";
import { useGetSampleDescriptionThreeMasterQuery } from "../../../../redux/LabServices/SampleDescriptionThreeMasterServices";
import { useGetSampleDescriptionFourMasterQuery } from "../../../../redux/LabServices/SampleDescriptionFourMasterServices";
import { Loader } from "../../../../Basic/components";

export default function Form() {
    const fields = useContext(FieldsContext);

    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: sampleOneList, isLoading: isSampleOneLoading, isFetching: isSampleOneFetching } =
        useGetSampleDescriptionOneMasterQuery({ params });

    const { data: sampleTwoList, isLoading: isSampleTwoLoading, isFetching: isSampleTwoFetching } =
        useGetSampleDescriptionTwoMasterQuery({ params });

    const { data: sampleThreeList, isLoading: isSampleThreeLoading, isFetching: isSampleThreeFetching } =
        useGetSampleDescriptionThreeMasterQuery({ params });

    const { data: sampleFourList, isLoading: isSampleFourLoading, isFetching: isSampleFourFetching } =
        useGetSampleDescriptionFourMasterQuery({ params });

    return (
        <div>
            {isSampleOneLoading || isSampleOneFetching || isSampleTwoLoading || isSampleTwoFetching ||
                isSampleThreeLoading || isSampleThreeFetching || isSampleFourLoading || isSampleFourFetching
                ?
                <Loader />
                :
                <div>
                    <div className='grid grid-cols-4 my-2'>
                        <DropdownInput autoFocus name="Sample Type 1" options={dropDownListObject(fields.id ? sampleOneList.data : sampleOneList.data.filter(item => item.active), "name", "id")} value={fields.sampleOneId} setValue={fields.setSampleOneId} required={true} readOnly={fields.readOnly || fields.isResultEntryStarted} disabled={(childRecord.current > 0)} />
                        <DropdownInput name="Sample Type 2" options={dropDownListObject(fields.id ? sampleTwoList.data : sampleTwoList.data.filter(item => item.active), "name", "id")} value={fields.sampleTwoId} setValue={fields.setSampleTwoId} required={true} readOnly={fields.readOnly || fields.isResultEntryStarted} disabled={(childRecord.current > 0)} />
                        <DropdownInput name="Sample Type 3" options={dropDownListObject(fields.id ? sampleThreeList.data : sampleThreeList.data.filter(item => item.active), "name", "id")} value={fields.sampleThreeId} setValue={fields.setSampleThreeId} required={true} readOnly={fields.readOnly || fields.isResultEntryStarted} disabled={(childRecord.current > 0)} />
                        <DropdownInput name="Sample Type 4" options={dropDownListObject(fields.id ? sampleFourList.data : sampleFourList.data.filter(item => item.active), "name", "id")} value={fields.sampleFourId} setValue={fields.setSampleFourId} required={true} readOnly={fields.readOnly || fields.isResultEntryStarted} disabled={(childRecord.current > 0)} />

                    </div>
                </div>
            }
        </div>
    )
}