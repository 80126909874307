import React from 'react'
import { findFromList, params } from "../../../../Utils/helper"
import { Loader } from '../../../../Basic/components'

import { Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import tw from '../../../../Utils/tailwind-react-pdf';
const SampleTestParametersPrint = ({testList,methodList,conditionList, testDetails, packageName, groupName }) => {

 
  if (!testList?.data || !methodList?.data || !conditionList?.data) return <Loader />
  return (

<>

         <View style={"page"}>

      <View style={tw("text-center p-1")}>
    
        <Text style={[tw("text-md font-bold"),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>Group : {groupName}</Text>
      </View>



      <View style={tw('w-full mt-1  border border-gray-500')}>

    
      <View style={tw(" flex flex-row text-xs w-full")} >

         
            {/* <Text style={tw('border-r border-b border-gray-600 p-1 w-1/9 ')}>
              S.NO
            </Text> */}
            <Text style={[tw('border-r border-b border-gray-600 p-1 w-1/4'),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>
              Test Parameter
            </Text>
            <Text style={[tw('border-r border-b border-gray-600 p-1 w-32'),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>
              Methods
            </Text>
            <Text style={[tw('border-r border-b border-gray-600 p-1 w-2/4 '),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>
              Condition
            </Text>
            <Text style={[tw(' border-b border-gray-600 p-1 w-1/4'),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>
              Result
            </Text>
        
        </View>
       


          {testDetails.map((item, index) =>
             <View style={tw(" flex flex-row text-xs w-full")} >

              {/* <Text style={tw('w-1/9 border-r border-b border-gray-600')}>
                {index + 1}
              </Text> */}
              <Text style={tw('border-r border-b border-gray-600 p-1  w-1/4 ')}>
                {findFromList(item.testId, testList.data, "name")}
              </Text>
              <Text style={tw('border-r border-b border-gray-600 p-1  w-32  ')}>
                {findFromList(item.methodId, methodList.data, "name")}
              </Text>
              <Text style={tw(' border-r border-b border-gray-600 p-1 w-2/4')}>
                {findFromList(item.conditionId, conditionList.data, "name")}
              </Text>
              <Text style={tw(' border-b border-gray-600 p-1  w-1/4')}>
               
              </Text>
              </View>
          )}
           
      
          
     
       
         </View>






            <View style={tw("mt-4 w-full ml-5")}>


              <View style={tw("flex flex-row justify-start w-full mt-3")} >


            
                <Text style={[tw("text-xs font-bold w-1/4"),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>Sample Preparation By:</Text>
                <Text style={tw("text-xs w-1/4")}> </Text>
              
             
                <Text style={[tw("text-xs font-bold  w-1/4"),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>Sign & Date :</Text> 
                <Text style={tw("text-xs w-1/4")}></Text>
            


            </View>


            <View style={tw("flex flex-row justify-start  w-full mt-3")} >

           
                <Text style={[tw("text-xs font-bold w-1/4 "),{ fontWeight: 900, fontFamily: "Times-Bold" }]}> Test Performed by:  </Text>
                <Text style={tw("text-xs w-1/4")}></Text>
          

        
              <Text style={[tw("text-xs font-bold w-1/4 "),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>Sign & Date :</Text>
              <Text style={tw("text-xs w-1/4")}> </Text>
            

            </View>



            <View style={tw("flex flex-row justify-start  w-full mt-3")} >

           
                <Text style={[tw("text-xs font-bold w-1/4 "),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>   Verification By (First Review):    </Text>
                <Text style={tw("text-xs w-1/4")}></Text>
           
        
          
              <Text style={[tw("text-xs font-bold w-1/4 "),{ fontWeight: 900, fontFamily: "Times-Bold" }]}>Verification By (Final Review) :</Text>
              <Text style={tw("text-xs w-1/4")}> </Text>
            </View>

     
</View>

        

      </View> 
     
</>
  )
}

export default SampleTestParametersPrint