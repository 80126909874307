import React from 'react'
import { useGetWashCareMasterByIdQuery } from '../../../redux/LabServices/WashCareMasterServices'
import { Loader } from '../../../Basic/components'
import { findFromList, getImageUrlPath, viewBase64String } from "../../../Utils/helper";

const WashCareDataPrint = ({washCareData,value,sampleItem }) => {

  let washCareImageArray=washCareData?.data.flatMap(val=>val.WashCare)
 
 
  return (
    <div>

      <div className=' '>
        <h1 className=' p-2 text-center'>
           {/* <img src={viewBase64String(data?.data?.imageBase64)} alt="" />  */}
           <img className='w-20' src={getImageUrlPath(findFromList(value.washCareId,washCareImageArray,"image"))}/>
            </h1>

       
      </div>






    </div>
  )
}

export default WashCareDataPrint