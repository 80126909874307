import React from 'react'
import { Image, Text, View } from '@react-pdf/renderer'
import { getImageUrlPath } from '../../../../Utils/helper'
import tw from '../../../../Utils/tailwind-react-pdf'

const SamplewiseTestedImages = ({ testImg, passFail, splitTest }) => {
  let TestImages
  if (splitTest == "ACCREDITATION") {
    TestImages = testImg?.images?.filter(val => val.isAccreditationTestedImage)
  }
  else if (splitTest == "NONACCREDITATION") {
    TestImages = testImg?.images?.filter(val => !val.isAccreditationTestedImage)
  }
  else {
    TestImages = testImg?.images
  }
  return (
    <View style={tw("text-center")}>
      {
        TestImages.length > 0 &&
        <>
          <Text style={[tw(' mt-5 uppercase font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
            {testImg?.sampleId.slice(-1) >= 0 ? "" : testImg?.sampleId.slice(-1)}
          </Text>
          <View style={tw('flex flex-row flex-wrap justify-center mt-1 gap-x-2')}>

            {TestImages?.map((img, imgIndex) =>

            (

              <>
                <View style={tw("flex flex-col  max-w-md")}>
                  <Text style={tw("w-48 h-12 capitalize text-xs")}>
                    {img?.comment ? img?.comment.toLowerCase() : ""}
                  </Text>
                  <View style={tw(' mb-2 mt-1')}>

                    <View style={tw("border border-gray-700  p-1")}>

                      <Image key={imgIndex} alt='Tested' style={[{ height: '150', width: '150', objectFit: 'fit' }, tw("mt-2 max-w-md")]}
                        src={getImageUrlPath(img?.picture)}
                      />

                    </View>

                  </View>
                </View>


              </>

            )
            )}
          </View>

        </>
      }



    </View>
  )
}

export default SamplewiseTestedImages