import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { END_USE_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const EndUseMasterApi = createApi({
  reducerPath: "endUseMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["EndUseMaster"],
  endpoints: (builder) => ({
    getEndUseMaster: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: END_USE_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: END_USE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["EndUseMaster"],
    }),
    getEndUseMasterById: builder.query({
      query: (id) => {
        return {
          url: `${END_USE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["EndUseMaster"],
    }),
    addEndUseMaster: builder.mutation({
      query: (payload) => ({
        url: END_USE_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["EndUseMaster"],
    }),
    updateEndUseMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${END_USE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["EndUseMaster"],
    }),
    deleteEndUseMaster: builder.mutation({
      query: (id) => ({
        url: `${END_USE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EndUseMaster"],
    }),
  }),
});

export const {
  useGetEndUseMasterQuery,
  useGetEndUseMasterByIdQuery,
  useAddEndUseMasterMutation,
  useUpdateEndUseMasterMutation,
  useDeleteEndUseMasterMutation,
} = EndUseMasterApi;

export default EndUseMasterApi;
