import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetLabTestMethodMasterQuery,
    useGetLabTestMethodMasterByIdQuery,
    useAddLabTestMethodMasterMutation,
    useUpdateLabTestMethodMasterMutation,
    useDeleteLabTestMethodMasterMutation,
} from "../../../redux//LabServices/LabTestMethodMasterServices";
import { useGetLabTestStandardMasterByIdQuery, useGetLabTestStandardMasterQuery } from "../../../redux/LabServices/LabTestStandardMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox, DropdownInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject } from "../../../Utils/contructObject";
import { useGetLabTestNameMasterQuery } from "../../../redux/LabServices/LabTestNameMasterServices";
import { Loader } from "../../../Basic/components";
import { useGetLabCodeMasterQuery } from "../../../redux/LabServices/LabCodeMasterServices"
import { findFromList } from "../../../Utils/helper";

const MODEL = "Lab Test Method Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [standardId, setStandardId] = useState("")
    const [labTestId, setLabTestId] = useState("")
    const [labCodeId, setLabCodeId] = useState("")

    const [name, setName] = useState("");
    const [active, setActive] = useState(true);
    const [isAccredited, setIsAccredited] = useState(false);
    const [year, setYear] = useState("");

    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: labTestStandardList } =
        useGetLabTestStandardMasterQuery({ params });

    const { data: labCodeList } =
        useGetLabCodeMasterQuery({ params });

    const { data: allData, isLoading, isFetching } = useGetLabTestMethodMasterQuery({ params, searchParams: searchValue });
    const { data: labTestList } = useGetLabTestNameMasterQuery({ params });
    // const { data: singleLabTestStandardData } = useGetLabTestStandardMasterByIdQuery(standardId, {skip: !standardId}) ;
    // const { data: singleLabCodeData } = useGetLabCodeMasterByIdQuery(labCodeId, {skip: !labCodeId}) ;


    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetLabTestMethodMasterByIdQuery(id, { skip: !id });


    const [addData] = useAddLabTestMethodMasterMutation();
    const [updateData] = useUpdateLabTestMethodMasterMutation();
    const [removeData] = useDeleteLabTestMethodMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) {
                setReadOnly(true);
            } else {
                setReadOnly(false);
            }
            setName(data?.name ? data.name : "");
            setStandardId(data?.standardId ? data.standardId : "");
            setLabTestId(data?.labTestId ? data.labTestId : "");
            setLabCodeId(data?.labCodeId ? data.labCodeId : "");
            setYear(data?.year ? data.year : "");
            setActive(id ? (data?.active ? data.active : false) : true);
            setIsAccredited(data?.isAccredited ? data.isAccredited : false)
            childRecord.current = data?.childRecord || 0;
        },
        [id]
    );

    useEffect(() => {
        let standardName = findFromList(standardId, labTestStandardList?.data ? labTestStandardList?.data : [], "name")
        let code = findFromList(labCodeId, labCodeList?.data ? labCodeList.data : [], "name")
        setName(`${standardName} ${code}:${year}`)

    }, [standardId, labCodeId, year, labTestId]);

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, name, standardId, active, companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"), labTestId,
        isAccredited, labCodeId, year,
    }

    const validateData = (data) => {
        if (data.labCodeId && data.standardId && data.labTestId && data.year) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined);
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "Name", "Status"
    ]
    const tableDataNames = ["dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!labTestList?.data || !labCodeList?.data || !labTestStandardList?.data) return <Loader />

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-1 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Test Method Info</legend>
                                    <div className='grid grid-cols-4 my-2'>
                                        <DropdownInput name="Test" options={dropDownListObject(id ? labTestList.data : labTestList.data.filter(item => item.active), "name", "id")} value={labTestId} setValue={setLabTestId} required={true} readOnly={readOnly || childRecord.current > 0} disabled={(childRecord.current > 0)} />
                                        <DropdownInput name="Standard" options={dropDownListObject(id ? labTestStandardList.data : labTestStandardList.data.filter(item => item.active), "name", "id")} value={standardId} setValue={(value) => { setStandardId(value); }} required={true} readOnly={readOnly || childRecord.current > 0} disabled={(childRecord.current > 0)} />
                                        {/* <TextInput name="Test Method" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} /> */}
                                        <DropdownInput name="Code" options={dropDownListObject(id ? labCodeList.data : labCodeList.data.filter(item => item.active), "name", "id")} value={labCodeId} setValue={(value) => { setLabCodeId(value); }} required={true} readOnly={readOnly || childRecord.current > 0} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Year" type="text" value={year} setValue={setYear} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Name" type="text" value={name} setValue={setName} readOnly={readOnly} disabled />
                                        <CheckBox name="Accreditation" readOnly={readOnly || childRecord.current > 0} value={isAccredited} setValue={setIsAccredited} />
                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
