import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetPurchaseInwardOrReturnQuery,
  useGetPurchaseInwardOrReturnByIdQuery,
  useAddPurchaseInwardOrReturnMutation,
  useUpdatePurchaseInwardOrReturnMutation,
  useDeletePurchaseInwardOrReturnMutation,
} from "../../../redux/ErpServices/PurchaseInwardOrReturnServices";

import {
  useGetPoQuery
} from "../../../redux/ErpServices/PoServices"
import { useGetPartyQuery, useGetPartyByIdQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { LongDropdownInput, LongDisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { poTypes } from '../../../Utils/DropdownData';
import YarnInwardItems from "./YarnInwardItems";
import { useDispatch } from "react-redux";
import Modal from "../../../UiComponents/Modal";
import PoItemsSelection from "../PurchaseInwardReturn/PoItemsSelection";
import { getDateFromDateTime, substract } from "../../../Utils/helper";
import AccessoryInwardItems from "./AccessoryInwardItems";
import FabricInwardItems from "./FabricInwardItems";

const MODEL = "Purchase Order Cancel";

export default function Form() {

  const dispatch = useDispatch()
  const today = new Date()
  const [inwardItemSelection, setInwardItemSelection] = useState(false);
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [poNo, setPoNo] = useState("");
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [poType, setPoType] = useState("GreyYarn");
  const [supplierId, setSupplierId] = useState("");
  const [inwardItems, setInwardItems] = useState([]);

  const [finalInwardItems, setFinalInwardItems] = useState([]);

  const [active, setActive] = useState(true)

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )

  const { data: supplierList } =
    useGetPartyQuery({ params: { companyId, active: true } });

  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  const { data: allData, isLoading, isFetching } = useGetPurchaseInwardOrReturnQuery({ branchId, searchParams: searchValue });

  const { data: poList, isLoading: poListLoading, isFetching: poListFetching } = useGetPoQuery({ branchId, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching, 
    
    isLoading: isSingleLoading,
  } = useGetPurchaseInwardOrReturnByIdQuery(id, { skip: !id });

  const [addData] = useAddPurchaseInwardOrReturnMutation();
  const [updateData] = useUpdatePurchaseInwardOrReturnMutation();
  const [removeData] = useDeletePurchaseInwardOrReturnMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setPoType(data?.poType ? data.poType : "GreyYarn");
    setInwardItems(data?.poInwardReturnItems ? structuredClone(data.poInwardReturnItems) : []);
    setPoNo(data?.id ? `00${data?.id}` : "");
    if (data?.date) setDate(data?.date);
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setActive(id ? (data?.active ? data.active : false) : true);
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  
  const data = {
    inwardOrReturn:"PurchaseCancel",
    poType, supplierId,
    branchId, id, userId,
    poInwardReturnItems: finalInwardItems.map(item => {
      return { poNo: item.poNo, poItemId: item.poItemId, cancelQty: item.cancelQty }
    })
  }

  function isSupplierOutside() {
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMILNADU"
    }
    return false
  }

  const validateData = (data) => {
    return data.poType && data.supplierId
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback({ id, body: data }).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      toast.success(text + "Successfully");
      setId(returnData.data.id)
      dispatch({
        type: `po/invalidateTags`,
        payload: ['po'],
      });
    } catch (error) {
      console.log("handle");
    }
  };

  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
  };

  function onDataClick(id) {
    setId(id);
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  function removeItem(poNo, poItemId) {
    let newItems = structuredClone(inwardItems);
    newItems = newItems.filter(item => !((parseInt(item.poNo) === parseInt(poNo)) && (item.poItemId === poItemId)))
    setInwardItems(newItems);
  }

  function findPoItem(poNo, poItemId) {
    if (!poList) return {}
    let po = poList.data.find(p => parseInt(p.id) === parseInt(poNo));
    if (!po) return {}
    let poItem = po.poItems.find(i => i.poItemId === poItemId)
    if (!poItem) return {}
    return poItem
  }

  function isItemInPoAdded(poNo, poItemId, list) {
    let item = list.find(item => ((parseInt(item.poNo) === parseInt(poNo)) && (item.poItemId === poItemId)))
    if (!item) return false
    return true
  }

  function changeOnlyNeededPo(finalInwardItems) {
    let prevFinalItems = structuredClone(finalInwardItems);
    let toKeepItems = prevFinalItems.filter(item => isItemInPoAdded(item.poNo, item.poItemId, inwardItems))
    let newItems = inwardItems.filter(item => !isItemInPoAdded(item.poNo, item.poItemId, finalInwardItems))

    newItems = newItems.map((item) => {
      let poItem = findPoItem(item.poNo, item.poItemId)
      return { ...poItem, cancelQty: item?.cancelQty ? item.cancelQty : substract(substract(poItem.qty, poItem.alreadyCancelQty), (parseFloat(poItem.alreadyInwardedQty) +  parseFloat(poItem.alreadyReturnedQty))).toFixed(3), poNo: item.poNo, poItemId: item.poItemId }
    })
    return [...toKeepItems, ...newItems]
  }

  useEffect(() => {
    if(!id) setInwardItems([])
  }, [poType, supplierId])

  useEffect(() => {
    setFinalInwardItems(prev => changeOnlyNeededPo(prev));
  }, [inwardItems, setFinalInwardItems])

  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          poListLoading || poListFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  const allSuppliers = supplierList ? supplierList.data : []

  function filterSupplier() {
    let finalSupplier = []
    if (poType.toLowerCase().includes("yarn")) {
      finalSupplier = allSuppliers.filter(s => s.yarn)
    } else if (poType.toLowerCase().includes("fabric")) {
      finalSupplier = allSuppliers.filter(s => s.fabric)
    } else {
      finalSupplier = allSuppliers.filter(s => s.PartyOnAccessoryItems.length > 0)
    }
    return finalSupplier
  }
  let supplierListBasedOnSupply = filterSupplier()

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
    >
      <Modal isOpen={inwardItemSelection} onClose={() => setInwardItemSelection(false)} widthClass={"p-10"}>
        <PoItemsSelection inwardOrReturn={"PurchaseCancel"} setInwardItemSelection={setInwardItemSelection} transtype={poType} po={poList ? poList.data : []} supplierId={supplierId} inwardItems={inwardItems}
          setInwardItems={setInwardItems} />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          onClose={() => {
            setForm(false);
            setSearchValue("");
          }}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                  <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 h-[140px] px-3 overflow-auto'>
                      <legend className='sub-heading'>Purchase Order Cancel Info</legend>
                      <div className="flex justify-end relative top-0 right-0">
                        <button className="text-xs bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1" onClick={() => setFormReport(prev => !prev)}>
                          {formReport ? "Close Report >" : "Open Report <"}
                        </button>
                      </div>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="flex flex-wrap">
                          <div className={`flex items-center gap-[72px] mx-1 ${id ? "block" : "hidden"}`}>
                            <label className="text-xs">Po No</label>
                            <input className="border border-gray-400 h-6 w-14 rounded text-xs" value={poNo} required={true} readOnly={readOnly} disabled={true} />
                          </div>
                          <div className='flex gap-10 items-center justify-start md:my-1 px-1 data'>
                            <label>Po Type</label>
                            <select id='dd' autoFocus name="name" className='h-6 w-40 border border-gray-500 rounded' value={poType} onChange={(e) => setPoType(e.target.value)}>
                              {poTypes.map((option, index) => <option key={index} value={option.value} >
                                {option.show}
                              </option>)}
                            </select>
                          </div>
                          <LongDisabledInput name="Po Date" value={date} type={"date"} required={true} readOnly={readOnly} />
                          <LongDropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={readOnly} />
                        </div>
                        <button className="p-1.5 text-xs bg-lime-400 rounded hover:bg-lime-600 font-semibold transition hover:text-white"
                          onClick={() => {
                            if (!supplierId || !poType) {
                              toast.info("Please Select Inward/Return , Po type and Suppplier", { position: "top-center" })
                              return
                            }
                            setInwardItemSelection(true)
                          }
                          }
                        >Select Items</button>
                      </div>
                    </fieldset>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex h-[360px] px-3 overflow-auto overflow-x-hidden'>
                      <legend className='sub-heading'>Purchase Details</legend>
                      <>
                        {
                          poType.toLowerCase().includes("yarn")
                            ?
                            <YarnInwardItems removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                            :
                            <>
                              {
                                poType.toLowerCase().includes("fabric")
                                  ?
                                  <FabricInwardItems removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                  :
                                  <AccessoryInwardItems removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                              }
                            </>
                        }
                      </>
                    </fieldset>
                  </div>
                  <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                    <FormReport
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      setId={setId}
                      tableHeaders={tableHeaders}
                      tableDataNames={tableDataNames}
                      data={allData?.data ? allData?.data : []}
                      loading={
                        poListLoading || poListFetching
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}