import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LAB_CODE_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const LabCodeMasterApi = createApi({
  reducerPath: "labCodeMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["LabCodeMaster"],
  endpoints: (builder) => ({
    getLabCodeMaster: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: LAB_CODE_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LAB_CODE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["LabCodeMaster"],
    }),
    getLabCodeMasterById: builder.query({
      query: (id) => {
        return {
          url: `${LAB_CODE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["LabCodeMaster"],
    }),
    addLabCodeMaster: builder.mutation({
      query: (payload) => ({
        url: LAB_CODE_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["LabCodeMaster"],
    }),
    updateLabCodeMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${LAB_CODE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["LabCodeMaster"],
    }),
    deleteLabCodeMaster: builder.mutation({
      query: (id) => ({
        url: `${LAB_CODE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LabCodeMaster"],
    }),
  }),
});

export const {
  useGetLabCodeMasterQuery,
  useGetLabCodeMasterByIdQuery,
  useAddLabCodeMasterMutation,
  useUpdateLabCodeMasterMutation,
  useDeleteLabCodeMasterMutation,
} = LabCodeMasterApi;

export default LabCodeMasterApi;
