import React, { useEffect, useState } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { toast } from "react-toastify"
import { Loader } from '../../../Basic/components';
import { VIEW } from '../../../icons';
import Modal from '../../../UiComponents/Modal';
import TaxDetailsFullTemplate from '../TaxDetailsCompleteTemplate';
import { priceWithTax } from '../../../Utils/helper';


const FabricPoItems = ({ id, transType, poItems, setPoItems, readOnly, params, isSupplierOutside, taxTypeId }) => {

    const [currentSelectedIndex, setCurrentSelectedIndex] = useState("")
    const [greyObj, setGreyObj] = useState({})

    const handleInputChange = (value, index, field) => {
        console.log(field, index, value);
        const newBlend = structuredClone(poItems);
        newBlend[index][field] = value;
        if (field === "fabricId") {
            newBlend[index]["taxPercent"] = findYarnTax(value)
        }
        setPoItems(newBlend);
    };

    useEffect(() => {
        if (id || poItems.length !== 0) return
        setPoItems(Array.from({ length: 8 }, i => {
            return { yarnId: "", qty: "0.000", inwardQty: "0.000", colorId: greyObj ? greyObj.id : "", uomId: "", price: "0.00", discountType: "Percentage", discountValue: "0.00" }
        }))
    }, [greyObj, transType, setPoItems, poItems])

    useEffect(() => {
        setPoItems(prev => prev.map(item => { item["colorId"] = greyObj ? greyObj.id : ""; return item }))
    }, [greyObj, setPoItems])

    const addRow = () => {
        if (poItems.length >= fabricList.data.length) {
            toast.info("No More Items", { position: 'top-center' })
            return
        }
        const newRow = { fabricId: "", qty: "", colorId: greyObj ? greyObj.id : "", uomId: "", gaugeId: "", designId: "", gsmId: "", loopLengthId: "", kDiaId: "", fDiaId: "", qty: "", price: "" };
        setPoItems([...poItems, newRow]);
    };
    const handleDeleteRow = id => {
        setPoItems(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
    };

    const { data: fabricList } =
        useGetFabricMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    const { data: gaugeList } =
        useGetGaugeQuery({ params });

    const { data: designList } =
        useGetdesignQuery({ params });

    const { data: gsmList } =
        useGetgsmQuery({ params });

    const { data: loopLengthList } =
        useGetgsmQuery({ params});

    const { data: diaList } =
        useGetDiaQuery({ params});

    function findIdInYarnBlend(id) {
        return poItems ? poItems.find(blend => parseInt(blend.fabricId) === parseInt(id)) : false
    }
    function findYarnTax(id) {
        if (!fabricList) return 0
        let yarnItem = fabricList.data.find(item => parseInt(item.id) === parseInt(id))
        return yarnItem ? yarnItem.taxPercent : 0
    }
    useEffect(() => {
        if (transType.toLowerCase().includes("grey")) {
            if (colorList) {
                let colors = colorList.data
                let greyObj = colors.find(c => c.name.toUpperCase() === "GREY")
                setGreyObj(greyObj ? greyObj : "")
            }
        } else {
            setGreyObj("")
        }
    }, [colorList, isColorFetching, isColorLoading, transType])

    useEffect(() => {
        if (transType.toLowerCase().includes("grey") && !greyObj) {
            window.alert("Grey Color Not Added in Master")
        }
    }, [poItems, greyObj])

    if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList) return <Loader />

    return (
        <>
            {
                poItems.length !== 0 ?
                    <>
                        <Modal isOpen={Number.isInteger(currentSelectedIndex)} onClose={() => setCurrentSelectedIndex("")}>
                            <TaxDetailsFullTemplate setCurrentSelectedIndex={setCurrentSelectedIndex} taxTypeId={taxTypeId} currentIndex={currentSelectedIndex} poItems={poItems} handleInputChange={handleInputChange} isSupplierOutside={isSupplierOutside} />
                        </Modal>
                        <div className={`w-full`}>
                            <table className="border border-gray-600 text-xs table-fixed w-full">
                                <thead className='bg-blue-200 top-0'>
                                    <tr>
                                        <th className="table-data border border-gray-600 text-center">S.no</th>
                                        <th className="table-data border border-gray-600">Items<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600">Colors</th>
                                        <th className="table-data border border-gray-600">Design<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">Gauge<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">LL<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">GSM<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">K Dia<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">F Dia<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-20">UOM<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-16">Quantity<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-16">Price<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-16">Price(with Tax)<span className="text-red-500">*</span></th>
                                        <th className="table-data border border-gray-600 w-16">Gross</th>
                                        <th className="table-data border border-gray-600 w-16">View Tax</th>
                                        {readOnly &&
                                            <th>
                                                <div onClick={addRow}
                                                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                                    {PLUS}
                                                </div>
                                            </th>
                                        }
                                    </tr>
                                </thead>
                                <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
                                    {poItems.map((row, index) => (
                                        <tr key={index} className="w-full">
                                            <td className="table-data border border-gray-600 w-2 text-center">
                                                {index + 1}
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.fabricId} onChange={(e) => handleInputChange(e.target.value, index, "fabricId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?fabricList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id} >
                                                            {blend.aliasName}
                                                        </option>
                                                    ):fabricList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-full rounded py-1' value={row.colorId} onChange={(e) => handleInputChange(e.target.value, index, "colorId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?colorList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    ):colorList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-32 rounded py-1' value={row.designId} onChange={(e) => handleInputChange(e.target.value, index, "designId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?designList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    ):designList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.gaugeId} onChange={(e) => handleInputChange(e.target.value, index, "gaugeId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?gaugeList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    ):gaugeList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.loopLengthId} onChange={(e) => handleInputChange(e.target.value, index, "loopLengthId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?loopLengthList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                           {blend.name}
                                                        </option>
                                                    ):loopLengthList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.gsmId} onChange={(e) => handleInputChange(e.target.value, index, "gsmId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?gsmList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                             {blend.name}
                                                        </option>
                                                    ):gsmList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.kDiaId} onChange={(e) => handleInputChange(e.target.value, index, "kDiaId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?diaList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                           {blend.name}
                                                        </option>
                                                    ):diaList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.fDiaId} onChange={(e) => handleInputChange(e.target.value, index, "fDiaId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?diaList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    ):diaList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <select disabled={readOnly} className='text-left w-20 rounded py-1' value={row.uomId} onChange={(e) => handleInputChange(e.target.value, index, "uomId")}>
                                                    <option hidden>
                                                        Select
                                                    </option>
                                                    {id?uomList.data.map((blend) =>
                                                        <option value={blend.id} key={blend.id}>
                                                            {blend.name}
                                                        </option>
                                                    ):uomList.data.filter(item=>item.active)}
                                                </select>
                                            </td>
                                            <td className='border border-black'>
                                                <input
                                                    type="text"
                                                    className="text-right rounded py-1 px-1 w-16"
                                                    value={(!row.qty) ? 0 : row.qty}
                                                    disabled={readOnly}
                                                    onChange={(e) =>
                                                        handleInputChange(e.target.value, index, "qty")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                                                    }
                                                />
                                            </td>
                                            <td className='border border-black'>
                                                <input
                                                    type="number"
                                                    className="text-right rounded py-1 px-1 w-16"
                                                    value={(!row.price) ? 0 : row.price}
                                                    disabled={readOnly}
                                                    onChange={(e) =>
                                                        handleInputChange(e.target.value, index, "price")
                                                    }
                                                    onBlur={(e) =>
                                                        handleInputChange(parseFloat(e.target.value).toFixed(2), index, "price")
                                                    }
                                                />
                                            </td>
                                            <td className='border border-black text-right'>
                                                {priceWithTax(row.price, row.taxPercent).toFixed(2)}
                                            </td>
                                            <td className='border border-black'>
                                                <input
                                                    className="text-right rounded py-1 px-1 w-16"
                                                    value={(!row.qty || !row.price) ? 0 : (parseFloat(row.qty) * parseFloat(row.price))}
                                                    disabled={true}
                                                />
                                            </td>
                                            <td className='border border-black w-16'>
                                                <button
                                                    className="text-center rounded py-1 w-full"
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            setCurrentSelectedIndex(index);
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        if (!taxTypeId) return toast.info("Please select Tax Type", { position: "top-center" });
                                                        setCurrentSelectedIndex(index)
                                                    }
                                                    }
                                                >
                                                    {VIEW}
                                                </button>
                                            </td>
                                            {readOnly
                                                &&
                                                <td className='border border-gray-600'>
                                                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                                        {DELETE}
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                    :
                    <div></div>
            }
        </>
    )
}

export default FabricPoItems