import React from 'react'
import FailureImages from './FailureImages'

const TestwiseFailureImages = ({ item, index, reportData }) => {
  return (
    <div className='ml-2 mt-5 w-full'>
      <div className=' mt-5 underline uppercase font-bold'>
        Failure Images
      </div>
      {
        item?.failureImages?.length > 0 ?
          <>

            <div className=' mt-5 w-full '>
              <div className='font-bold underline text-left'>
                {item?.testName}

              </div>
              {/* <div className='flex mr-9'>
                <h1>{item?.methodName}</h1>
                <h1 className='ml-2'>({item?.conditionName})</h1>

              </div> */}
            </div>


            <div className=' mt-1 uppercase font-bold flex flex-row justify-center flex-wrap gap-x-2'>
              {item?.failureImages?.map((img, imgIndex) => {
                return <FailureImages key={imgIndex} img={img} item={item} imgIndex={imgIndex} reportData={reportData} />
              })}
            </div>
          </>
          : ""

      }
    </div>
  )
}

export default TestwiseFailureImages