import React, { useContext, useRef, } from "react";
import { TextInput, CheckBox, DropdownInput, MultiSelectDropdown, } from "../../../../Inputs";
import { FieldsContext } from '..';
import { dropDownListObject, multiSelectOption } from "../../../../Utils/contructObject";
import secureLocalStorage from "react-secure-storage";
import { useGetProductCategoryMasterQuery } from "../../../../redux/LabServices/ProductCategoryMasterServices";
import { useGetWashMasterQuery } from "../../../../redux/LabServices/WashMasterServies";
import { useGetFinishMasterQuery } from "../../../../redux/LabServices/FinishMasterServices";
import { Loader } from "../../../../Basic/components";
import { useGetCountriesQuery } from "../../../../redux/services/CountryMasterService";

export default function Form() {
    const fields = useContext(FieldsContext);
    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: productCategoryList, isLoading: isProductCategoryLoading, isFetching: isProductCategoryFetching } =
        useGetProductCategoryMasterQuery({ params });

    const { data: countryList, isLoading: isCountryListLoading, isFetching: isCountryListFetching } =
        useGetCountriesQuery({ params });


    const { data: washList, isLoading: isWashLoading, isFetching: isWashFetching } =
        useGetWashMasterQuery({ params });

    const { data: finishList, isLoading: isFinishLoading, isFetching: isFinishFetching } =
        useGetFinishMasterQuery({ params });

    return (
        <div>
            {isProductCategoryLoading || isProductCategoryFetching ||
                isWashLoading || isWashFetching || isFinishLoading || isFinishFetching || isCountryListLoading || isCountryListFetching
                ?
                <Loader />
                :
                <div>
                    <div>
                        <div className='grid grid-cols-5 my-2'>
                            <TextInput autoFocus name="Actual Fabric Dia (In Inch)" type="text" value={fields.actualFabricDia} setValue={fields.setActualFabricDia} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            <DropdownInput name="Wash Type" options={dropDownListObject(fields.id ? washList.data : washList.data.filter(item => item.active), "name", "id")} value={fields.washId} setValue={(value) => { fields.setWashId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            <DropdownInput name="Finish Type" options={dropDownListObject(fields.id ? finishList.data : finishList.data.filter(item => item.active), "name", "id")} value={fields.finishId} setValue={(value) => { fields.setFinishId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            <DropdownInput name="Country of Origin" options={dropDownListObject(fields.id ? countryList.data : countryList.data.filter(item => item.active), "name", "id")} value={fields.countryOfOriginId} setValue={(value) => { fields.setCountryOfOriginId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            {/* <TextInput name="Country of Origin" type="text" value={fields.countryOfOrigin} setValue={fields.setCountryOfOrigin} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} /> */}

                            {/* <DropdownInput name="Country of Destination" options={dropDownListObject(fields.id ? countryList.data : countryList.data.filter(item => item.active), "name", "id")} value={fields.countryOfDestinationId} setValue={(value) => { fields.setCountryOfDestinationId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} /> */}

                            {/* <TextInput name="Country of Destination" type="text" value={fields.countryOfDestination} setValue={fields.setCountryOfDestination} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} /> */}
                            <TextInput name="Prev Report No." type="text" value={fields.prevReportNo} setValue={fields.setPrevReportNo} required={true} readOnly={fields.readOnly} />
                            {/* <TextInput name="Product Category" type="text" value={fields.productCategory} setValue={fields.setProductCategory} required={true} readOnly={fields.readOnly} /> */}
                            <DropdownInput name="Product Category" options={dropDownListObject(fields.id ? productCategoryList?.data : productCategoryList.data.filter(item => item.active), "name", "id")} value={fields.productCategoryId} setValue={(value) => { fields.setProductCategoryId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            <TextInput name="TRF No." type="text" value={fields.trfNo} setValue={fields.setTrfNo} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            <TextInput name="Log-in By." type="text" value={fields.logIn} setValue={fields.setLogIn} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            {/* <DropdownInput name="Test Stage" autoFocus options={dropDownListObject(fields.id ? productCategoryList.data : productCategoryList.data.filter(item => item.active), "name", "id")} value={fields.testStageId} setValue={(value) => { fields.setTestStageId(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} /> */}
                            <TextInput name="Test Stage" type="text" value={fields.testStage} setValue={(value) => { fields.setTestStage(value); }} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                            <CheckBox name="Retest" value={fields.retest} setValue={fields.setRetest} readOnly={fields.readOnly} />

                        </div>
                        <div className='grid grid-cols-4 my-2'>
                            <MultiSelectDropdown name="Country of Destination"
                                selected={fields.countryOfDestinationIds}
                                setSelected={fields.setCountryOfDestinationIds}
                                options={countryList.data ? multiSelectOption(fields.id ? countryList.data : countryList?.data?.filter(item => item.active), "name", "id") : []}
                                required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}