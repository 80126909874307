import React, { useEffect, useState, useRef, useCallback, createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetRegistrationQuery,
    useGetRegistrationByIdQuery,
    useAddRegistrationMutation,
    useUpdateRegistrationMutation,
    useDeleteRegistrationMutation,
} from "../../../redux/LabServices/RegistrationServices";
import { useGetOnHoldReasonMasterQuery } from "../../../redux/LabServices/OnHoldReasonMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { TextInput, CheckBox, DropdownInput, DisabledInput, DateInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { findFromList } from "../../../Utils/helper";
import moment from "moment";
import { Supplier, SampleType, Tests, Service, Claim, GroupOfNumbers, Received, SampleDetailsOne, SampleDetailsTwo, WashCare } from "./Tabs"
import { useGetPackageMasterQuery } from "../../../redux/LabServices/PackageMasterServices";
import { dropDownListObject } from "../../../Utils/contructObject";
import { useGetPartyByIdQuery, useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import { Loader } from "../../../Basic/components";
import TestAllocation from "./TestAllocation";
import { GenerateButton } from "../../../Buttons";
import Modal from "../../../UiComponents/Modal";
import RegistrationFormReport from "./RegistrationFormReport";
import { invoiceTypeOptions } from "../../../Utils/DropdownData";
import ProfomaInvoice from "./ProfomaInvoice";
import DocumentProfomaInvoice from "./DocumentProfomaInvoice";
import SampleReceiptDocument from "../DashBoard/SampleReceiptDocument";
import ScanningSheetContainer from "../DashBoard/ScanningSheetContainer";



let subMenus = ["Sample Type", "Sample Details 1", "Sample Details 2", "Service", "Courier Details", "Submitted Wash Care",]

const MODEL = "Registration";

export const FieldsContext = createContext()

export default function Form() {
    const today = new Date()
    const [profomaInvoice, setProfomaInvoice] = useState(false);
    const [form, setForm] = useState(true);
    const [docId, setDocId] = useState("")
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [reportToId, setReportToId] = useState("");
    const [ulrNo, setUlrNo] = useState()
    const [submittedSize, setSubmittedSize] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [sampleOneId, setSampleOneId] = useState("");
    const [sampleTwoId, setSampleTwoId] = useState("");
    const [sampleThreeId, setSampleThreeId] = useState("");
    const [sampleFourId, setSampleFourId] = useState("");
    const [testStage, setTestStage] = useState("");
    const [brandId, setBrandId] = useState("");
    const [washId, setWashId] = useState("");
    const [finishId, setFinishId] = useState("");
    const [actualFabricDia, setActualFabricDia] = useState("");
    const [sizerange, setSizerange] = useState("");
    const [serviceLevelId, setServiceLevelId] = useState("")
    const [submissionTypeId, setSubmissionTypeId] = useState("");
    const [onHoldId, setOnHoldId] = useState("");
    const [totalWeight, setTotalWeight] = useState("");
    const [partyDcNumber, setPartyDcNumber] = useState("");
    const [active, setActive] = useState(false);
    const [buyerId, setBuyerId] = useState("");
    const [packageId, setPackageId] = useState("");
    const [noOfSample, setNoOfSample] = useState(0);
    const [sampleReturn, setSampleReturn] = useState(false);
    const [dueDate, setDueDate] = useState("");
    const [dueTime, setDueTime] = useState()
    const [date, setDate] = useState(moment.utc(today).format('YYYY-MM-DD'));
    const [retest, setRetest] = useState(false);
    const [prevReportNo, setPrevReportNo] = useState("");
    const [buyingHouse, setBuyingHouse] = useState("");
    const [orderNo, setOrderNo] = useState("");
    const [styleNo, setStyleNo] = useState("");
    const [trfNo, setTrfNo] = useState("");
    const [logIn, setLogIn] = useState("");

    const [batchNo, setBatchNo] = useState("");
    const [season, setSeason] = useState("");
    const [color, setColor] = useState("");
    const [fiberContent, setFiberContent] = useState("");
    const [actualFiberContent, setActualFiberContent] = useState("");
    const [sampleDescription, setSampleDescription] = useState("");
    const [claimedFabricWeight, setClaimedFabricWeight] = useState([]);

    const [claimedYarnCount, setClaimedYarnCount] = useState([]);
    const [claimedFabricCount, setClaimedFabricCount] = useState([]);
    const [claimedFiberContent, setClaimedFiberContent] = useState([]);
    const [countryOfOriginId, setCountryOfOriginId] = useState("");
    const [countryOfDestinationId, setCountryOfDestinationId] = useState("");
    const [countryOfDestinationIds, setCountryOfDestinationIds] = useState([]);
    const [millOrFactoryId, setMillOrFactoryId] = useState("")
    const [endUseDetails, setEndUseDetails] = useState([]);
    const [receivedBy, setReceivedBy] = useState("");
    const [courierName, setCourierName] = useState("");
    const [assignTo, setAssignTo] = useState("")
    const [challanNo, setChallanNo] = useState("");
    const [departmentNo, setDepartmentNo] = useState("")
    const [submittedBy, setSubmittedBy] = useState("");
    const [pcsPerPack, setPcsPerPack] = useState(0);
    const [sampleDetails, setSampleDetails] = useState([]);
    const [testScanning, setTestScanning] = useState(false)
    const [formReport, setFormReport] = useState(false);
    const [specialInstruction, setSpecialInstruction] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [activeNavBar, setActiveNavBar] = useState("Sample Type");
    const [phase, setPhase] = useState("");
    const [productCategoryId, setProductCategoryId] = useState("");
    const [supplierId, setSupplierId] = useState("");
    const [invoiceToId, setInvoiceToId] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [typeOfInvoice, setTypeOfInvoice] = useState("INDIVIDUAL");
    const [isResultEntryStarted, setIsResultEntryStarted] = useState(false);
    const [nextUlrNo, setNextUlrNo] = useState()
    const [cancelReason, setCancelReason] = useState("");
    const [cancel, setCancel] = useState(false);

    const [washCareStatus, setWashCareStatus] = useState([]);
    const [sampleAcknowledgement, setSampleAcknowledgement] = useState(false)

    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        )
    };

    const { data: onHoldList } =
        useGetOnHoldReasonMasterQuery({ params });

    const { data: buyerList, isLoading: buyerLoading, isFetching: buyerFetching } =
        useGetPartyQuery({ params });

    const { data: packageList, isLoading: packageLoading, isFetching: packageFetching } =
        useGetPackageMasterQuery({ params });

    const { data: allData, isLoading, isFetching } = useGetRegistrationQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetRegistrationByIdQuery({ id }, { skip: !id });


    const [addData] = useAddRegistrationMutation();
    const [updateData] = useUpdateRegistrationMutation();
    const [removeData] = useDeleteRegistrationMutation();

    const { data: supplierList } =
        useGetPartyQuery({ params });
    const { data: singleSupplier, isLoading: isSingleSupplierLoading, isFetching: isSingleSupplierFetching } =
        useGetPartyByIdQuery(supplierId, { skip: !supplierId });



    useEffect(() => {

        if (id) return
        if (!singleSupplier?.data || isSingleSupplierLoading || isSingleSupplierFetching) return

        let currentSupplier = singleSupplier.data.PartyBranch.find(branch => parseInt(branch.id) === parseInt(reportToId))

        if (!currentSupplier) return

        let currentContactPerson = currentSupplier?.contactDetails.find(contact => contact.contactPersonName.toLowerCase() === submittedBy.toLowerCase());


        setPhone(currentContactPerson?.mobileNo ? currentContactPerson.mobileNo : "")

        setEmail(currentContactPerson?.email ? currentContactPerson.email : "")
    }, [submittedBy, reportToId, singleSupplier, isSingleSupplierFetching, isSingleSupplierLoading, setPhone, setEmail])



    function getGroupId(onHoldId) {
        return findFromList(onHoldId, onHoldList.data, "submissionTypeId");
    }

    function getBuyersId(packageId) {
        return findFromList(packageId, packageList.data, "buyerNameId");
    }

    const getNextDocId = useCallback(() => {
        if (id || isLoading || isFetching) return
        if (allData?.nextDocId) {
            setDocId(allData.nextDocId)
        }
        if (allData?.nextUlrNo) {
            setNextUlrNo(allData?.nextUlrNo)
        }
        setUlrNo(allData?.nextUlrNo?.slice(0, 15))

    }, [allData, isLoading, isFetching, id])

    useEffect(getNextDocId, [getNextDocId])



    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            else setReadOnly(false);
            setIsResultEntryStarted(data?.isResultEntryStarted || false);
            // setActive(data?.active ? data.active : false);
            setCancel(data?.cancel ? data.cancel : false);
            if (data?.docId) {
                setDocId(data?.docId)
            }
            setCancelReason(data?.cancelReason ? data?.cancelReason : "")
            setUlrNo(data?.ulrNo ? data?.ulrNo : allData?.nextUlrNo?.slice(0, 15))
            setSampleReturn(id ? (data?.sampleReturn) : false);
            setSupplierId(data?.supplierId ? data?.supplierId : "")
            setReportToId(data?.reportToId ? data.reportToId : "");
            setInvoiceToId(data?.invoiceToId ? data.invoiceToId : "");
            setSubmittedBy(data?.submittedBy ? data.submittedBy : "");
            setSubmittedSize(data?.submittedSize ? data.submittedSize : "");
            setPhone(data?.phone ? data.phone : "");
            setEmail(data?.email ? data.email : "");
            setNoOfSample(data?.noOfSample ? data.noOfSample : "");
            setSampleOneId(data?.sampleOneId ? data.sampleOneId : "");
            setSampleTwoId(data?.sampleTwoId ? data.sampleTwoId : "");
            setSampleThreeId(data?.sampleThreeId ? data.sampleThreeId : "");
            setSampleFourId(data?.sampleFourId ? data.sampleFourId : "");
            setTestStage(data?.testStage ? data.testStage : "");
            setBrandId(data?.brandId ? data.brandId : "");
            setWashId(data?.washId ? data.washId : "");
            setFinishId(data?.finishId ? data.finishId : "");
            setActualFabricDia(data?.actualFabricDia ? data.actualFabricDia : "");
            setSizerange(data?.sizerange ? data.sizerange : "");
            setServiceLevelId(data?.serviceLevelId ? data.serviceLevelId : "");
            setSubmissionTypeId(data?.onHoldId ? getGroupId(data.onHoldId) : data?.submissionTypeId ? data?.submissionTypeId : "");
            setOnHoldId(data?.onHoldId ? data.onHoldId : "");
            setPackageId(data?.packageId ? data.packageId : "");
            setBuyerId(data?.packageId ? getBuyersId(data.packageId) : "");
            setTotalWeight(data?.totalWeight ? data.totalWeight : "");
            setPartyDcNumber(data?.partyDcNumber ? data.partyDcNumber : "");
            setOrderNo(data?.orderNo ? data.orderNo : "");
            setStyleNo(data?.styleNo ? data.styleNo : "");
            setTrfNo(data?.trfNo ? data.trfNo : "");
            setLogIn(data?.logIn ? data.logIn : "")
            setBatchNo(data?.batchNo ? data.batchNo : "");
            setSeason(data?.season ? data.season : "");
            setColor(data?.color ? data.color : "");
            setRetest(data?.retest);
            setPhase(data?.phase ? data.phase : "")
            setMillOrFactoryId(data?.millOrFactoryId ? data.millOrFactoryId : "");
            setPrevReportNo(data?.prevReportNo ? data.prevReportNo : "");
            setBuyingHouse(data?.buyingHouse ? data.buyingHouse : "");
            setClaimedFabricWeight(data?.ClaimedFabricWeight ? data.ClaimedFabricWeight : []);
            setClaimedYarnCount(data?.ClaimedYarnCount ? data.ClaimedYarnCount : []);
            setClaimedFabricCount(data?.ClaimedFabricCount ? data.ClaimedFabricCount : []);
            setClaimedFiberContent(data?.ClaimedFiberContent ? data.ClaimedFiberContent : []);
            setCountryOfOriginId(data?.countryOfOriginId ? data.countryOfOriginId : "");
            setCountryOfDestinationId(data?.countryOfDestinationId ? data.countryOfDestinationId : "");
            // setCountryOfDestinationIds(data?.CountryOfDestinationIds ? data.CountryOfDestinationIds : []);
            setFiberContent(data?.fiberContent ? data.fiberContent : "");
            setActualFiberContent(data?.actualFiberContent ? data.actualFiberContent : "");
            setSampleDescription(data?.sampleDescription ? data.sampleDescription : "");
            setEndUseDetails(data?.RegistrationEndUseDetails ? data.RegistrationEndUseDetails.map(item => ({ value: item.endUseId, label: item.EndUse.name })) : []);
            setCountryOfDestinationIds(data?.CountryOfDestinationIds ? data.CountryOfDestinationIds.map(item => ({ value: item?.countryOfDestinationId, label: item?.Country?.name })) : []);
            setReceivedBy(data?.receivedBy ? data.receivedBy : "");
            setCourierName(data?.courierName ? data.courierName : "");
            setAssignTo(data?.assignTo ? data.assignTo : "");
            setChallanNo(data?.challanNo ? data.challanNo : "");
            setProductCategoryId(data?.productCategoryId ? data.productCategoryId : "");
            setDepartmentNo(data?.departmentNo ? data.departmentNo : "");
            setDueDate(data?.dueDate ? moment.utc(data?.dueDate).format("YYYY-MM-DD") : "");
            // setDueTime(data?.dueTime ? moment.utc(data?.dueTime).format("hh-mm-ss a") : "");
            setDueTime(data?.dueTime ? data?.dueTime : "");

            // setDueTime(data?.serviceDueTime ? data?.serviceDueTime : "");
            setDate(data?.createdAt ? moment.utc(data.createdAt).format("YYYY-MM-DD") : moment.utc(today).format("YYYY-MM-DD"));
            setSampleDetails(data?.RegistrationSampleDetails ? data?.RegistrationSampleDetails : [])
            setPcsPerPack(data?.RegistrationSampleDetails ? data?.RegistrationSampleDetails?.length : [])
            setTypeOfInvoice(data?.typeOfInvoice ? data?.typeOfInvoice : "INDIVIDUAL");
            secureLocalStorage.setItem(sessionStorage.getItem("sessionId") + "sampleOneId", data?.sampleOneId);
            if (data?.date) setDate(data?.date);
        },
        [id]
    );



    useEffect(() => {
        setSampleDetails(sampleDetails => {
            let newSampleDetails = structuredClone(sampleDetails)
            newSampleDetails = newSampleDetails.map(sample => {
                let newItem = structuredClone(sample)
                newItem["specialInstruction"] = specialInstruction
                newItem["sampleOneId"] = newItem["sampleOneId"] ? newItem["sampleOneId"] : sampleOneId
                newItem["sampleTwoId"] = newItem["sampleTwoId"] ? newItem["sampleTwoId"] : sampleTwoId
                newItem["sampleThreeId"] = newItem["sampleThreeId"] ? newItem["sampleThreeId"] : sampleThreeId
                newItem["sampleFourId"] = newItem["sampleFourId"] ? newItem["sampleFourId"] : sampleFourId

                return newItem
            })
            return newSampleDetails
        }
        )
    }, [specialInstruction, sampleOneId, sampleTwoId, sampleThreeId, sampleFourId, setSampleDetails,

    ])

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    function validUlrNo() {
        let validUrl = ulrNo?.split("")
        validUrl = validUrl?.length === 22 ? ulrNo : null

        return validUrl
    }


    const data = {
        id, ulrNo: validUlrNo(), supplierId, reportToId, invoiceToId, submittedBy, submittedSize, phone, email, sampleOneId, sampleTwoId, sampleThreeId, sampleFourId,
        testStage, brandId, washId, finishId, actualFabricDia, sizerange, serviceLevelId, submissionTypeId,
        onHoldId, totalWeight, partyDcNumber, active, cancelReason, cancel,
        claimedFabricCount,
        claimedFabricWeight,
        claimedFiberContent,
        claimedYarnCount,
        countryOfOriginId, countryOfDestinationId, countryOfDestinationIds, fiberContent, sampleDescription,
        orderNo, styleNo, trfNo, batchNo, season, color, courierName, assignTo, challanNo, receivedBy, noOfSample,
        millOrFactoryId, retest, prevReportNo, buyerId, packageId, buyingHouse, dueDate, dueTime, sampleReturn, pcsPerPack,
        sampleDetails: sampleDetails.map(item => {
            let newItem = structuredClone(item);
            return newItem
        })
        , departmentNo, phase, productCategoryId, actualFiberContent, specialInstruction, logIn,
        endUseDetails,
        typeOfInvoice,
        isResultEntryStarted,
        ...params,
    }



    const setData = {
        setActive, setCancelReason, setCancel, setSubmittedBy, setSubmittedSize, setSampleOneId, setSampleTwoId, setSampleThreeId, setSampleFourId, setUlrNo,
        setTestStage, setBrandId, setWashId, setFinishId, setActualFabricDia, setSizerange, setServiceLevelId,
        setSubmissionTypeId, setOnHoldId, setTotalWeight, setPartyDcNumber, setClaimedFabricCount,
        setClaimedFabricWeight,
        setClaimedFiberContent, setClaimedYarnCount, setDueTime, setDueDate,
        setCountryOfOriginId, setCountryOfDestinationId, setCountryOfDestinationIds, setFiberContent,
        setSampleDescription, setOrderNo, setStyleNo, setTrfNo, setBatchNo, setSeason, setColor, setEndUseDetails, setCourierName,
        setAssignTo, setChallanNo, setReceivedBy, setMillOrFactoryId, setRetest, setPrevReportNo, setBuyerId, setPackageId, setBuyingHouse,
        setNoOfSample, setDueDate, setSampleReturn, setDepartmentNo, setPhase, setProductCategoryId, setActualFiberContent, setSpecialInstruction, setLogIn,
    }

    const getShowSubMenu = () => {
        switch (activeNavBar) {
            case "Supplier":
                return <Supplier />
            case "Sample Type":
                return <SampleType />
            case "Submitted Wash Care":
                return (
                    <Modal isOpen={true} onClose={() => setActiveNavBar("Claimed Details")} widthClass={"p-8 h-[80%] w-[80%] overflow-auto"}>
                        <WashCare id={id} singleData={singleData?.data} specialInstruction={specialInstruction} setSpecialInstruction={setSpecialInstruction} setWashCareStatus={setWashCareStatus} washCareStatus={washCareStatus} setActiveNavBar={setActiveNavBar} />
                    </Modal>
                )
            case "Tests":
                return <Tests />
            case "Service":
                return <Service date={date} serviceLevelIdFromApi={singleData?.data?.serviceLevelId} id={id} />
            case "Group of Numbers":
                return <GroupOfNumbers />
            // case "Claimed Details":
            //     return <Claim />
            case "Courier Details":
                return <Received />
            case "Sample Details 1":
                return <SampleDetailsOne />
            case "Sample Details 2":
                return <SampleDetailsTwo />
            default:
                break;
        }
    }
    const nextSubMenu = () => {
        let index = (subMenus.findIndex(value => value === activeNavBar) + 1)
        setActiveNavBar((subMenus[index]))
    }

    const validateData = (data) => {


        if (data.pcsPerPack && data.reportToId && data.invoiceToId && data.noOfSample && data.buyerId && data.packageId && data.supplierId && data.submittedBy) {
            return true;
        }
        return false;
    }

    const validateUlr = () => {
        let ulr = ulrNo?.split("")

        if (ulr?.length > 15 && ulr?.length < 22) {
            return false
        }
        return true
    }

    const handleSubmitCustom = async (callback, data, text, isDataClear = false) => {
        try {
            let returnData = await callback(data).unwrap();
            // if (isDataClear) {
            //     setId();
            //     syncFormWithDb(undefined);
            // }
            // else setId(returnData.data.id)
            setId(returnData.data.id)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = (isDataClear = false) => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!validateUlr(data)) {
            toast.info("ULRNO field Length Is Mismatch...!", {
                position: "top-center",
            });
            return;
        }

        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {

            handleSubmitCustom(updateData, data, "Updated", isDataClear);
        } else {
            handleSubmitCustom(addData, data, "Added", isDataClear);
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }
    const tableHeaders = [
        "Phone", "Status"
    ]
    const tableDataNames = ["dataObj.phone", 'dataObj.active ? ACTIVE : INACTIVE']


    function refreshRequirementDetails() {

        let invoiceId = singleSupplier?.data ? singleSupplier?.data?.PartyBranch.filter(i => i.isHo || i.isInvoice) : ""

        setInvoiceToId(invoiceId[0]?.id);
        let reportId = singleSupplier?.data ? singleSupplier?.data?.PartyBranch.filter(i => i.isHo || i.isReport) : ""

        setReportToId(reportId[0]?.id)

        let submittedPerson = singleSupplier?.data ? singleSupplier?.data?.PartyBranch.find(i => i.isHo)?.contactDetails.find(val => val.mobileNo == phone)?.contactPersonName : []


        setSubmittedBy(submittedPerson)
        setIsEditable(true);

    }


    useEffect(() => {
        if (!singleSupplier?.data || isSingleSupplierLoading || isSingleSupplierFetching) return

        if (!isEditable) return

        let currentSupplier = singleSupplier.data.PartyBranch.find(branch => parseInt(branch.id) === parseInt(reportToId))

        if (!currentSupplier) return

        let currentContactPerson = currentSupplier?.contactDetails?.find(contact => contact?.contactPersonName?.toLowerCase() === submittedBy?.toLowerCase());


        setPhone(currentContactPerson?.mobileNo ? currentContactPerson.mobileNo : "")

        setEmail(currentContactPerson?.email ? currentContactPerson.email : "")
    }, [submittedBy, reportToId, setPhone, setEmail])


    useEffect(() => {
        setSampleDetails(prev => {

            let newSampleDetails = structuredClone(prev);
            newSampleDetails.forEach(item => { item["SampleWashCareDetails"] = washCareStatus })
            return newSampleDetails


        })
    }, [washCareStatus])


    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    if (buyerLoading || buyerFetching || packageLoading || packageFetching || isSingleFetching || isSingleLoading) return <Loader />

    let currentSupplierList = singleSupplier?.data ? singleSupplier.data.PartyBranch.find(item => parseInt(item.id) === parseInt(reportToId))?.contactDetails : []

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid bg-theme overflow-auto"
        >
            <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]  bg-white p-1"}>
                <RegistrationFormReport onClick={(id) => { setId(id); setFormReport(false) }} />
            </Modal>
            <Modal isOpen={profomaInvoice} onClose={() => setProfomaInvoice(false)} widthClass={"px-2 h-[90%] w-[90%] bg-white p-1"}>
                <ProfomaInvoice id={id} />
                {/* <DocumentProfomaInvoice id={id} /> */}

            </Modal>
            <Modal isOpen={sampleAcknowledgement} onClose={() => setSampleAcknowledgement(false)} widthClass={"px-2 h-[90%] w-[90%] bg-white p-1"}>
                <SampleReceiptDocument activeRegistrationId={id} />


            </Modal>
            <Modal isOpen={testScanning} onClose={() => setTestScanning(false)} widthClass={"px-2 h-[90%] w-[90%] bg-white p-1"}>

                <ScanningSheetContainer activeRegistrationId={id} />


            </Modal>
            <div className="flex flex-col frame w-full">
                <FormHeader
                    onNew={onNew}

                    model={MODEL}
                    saveData={id ? () => saveData(true) : null}
                    setReadOnly={setReadOnly}
                    openReport={() => setFormReport(true)}
                    deleteData={deleteData}
                />
                <FieldsContext.Provider value={{ ...data, ...setData }}>
                    <div className="flex-1 grid gap-x-2">
                        <div className="col-span-3 grid h-[550px] overflow-auto">
                            <div>
                                <fieldset className="border-2 border-black rounded px-3">
                                    <legend className='sub-heading'>Registration Details</legend>
                                    <div className='grid grid-cols-6 items-center'>
                                        <DisabledInput name={"Lab Report No."} value={`${docId}`} />
                                        <DropdownInput name="Supplier"
                                            options={dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active && item.isSupplier), "name", "id")}
                                            value={supplierId} setValue={(value) => {
                                                setInvoiceToId("");
                                                setReportToId("");
                                                setSubmittedBy("");
                                                setPhone("");
                                                setEmail("");
                                                setSupplierId(value)
                                            }} required={true} readOnly={readOnly} />
                                        <DropdownInput name="Invoice To"
                                            options={dropDownListObject(singleSupplier?.data ? singleSupplier.data.PartyBranch.filter(i => i.isInvoice).map(item => {
                                                let newItem = structuredClone(item)
                                                if (item.isHo) {
                                                    newItem["name"] = `${newItem["name"]} (HO)`
                                                }
                                                return newItem
                                            }) : [], "name", "id")}
                                            value={invoiceToId} setValue={setInvoiceToId} required={true} readOnly={readOnly} />
                                        <DropdownInput name="Report To"
                                            options={dropDownListObject(singleSupplier?.data ? singleSupplier.data.PartyBranch.filter(i => i.isReport).map(item => {
                                                let newItem = structuredClone(item)
                                                if (item.isHo) {
                                                    newItem["name"] = `${newItem["name"]} (HO)`
                                                }
                                                return newItem
                                            }) : [], "name", "id")}
                                            value={reportToId}
                                            setValue={(value) => {
                                                setSubmittedBy("");
                                                setPhone("");
                                                setEmail("");
                                                setReportToId(value);
                                            }}
                                            required={true} readOnly={readOnly} />
                                        <DropdownInput name="Submitted by"
                                            options={dropDownListObject(currentSupplierList ? currentSupplierList : [], "contactPersonName", "contactPersonName")}
                                            value={submittedBy} setValue={setSubmittedBy} required={true} readOnly={readOnly} />
                                        {/* <TextInput name="Submitted by" type="text" value={submittedBy} setValue={setSubmittedBy} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} /> */}
                                        <TextInput name="Phone No" type="text" value={phone} setValue={setPhone} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Email Id" type="text" value={email} setValue={setEmail} readOnly={readOnly} required={true} disabled={(childRecord.current > 0)} caseSensitive />
                                        <DateInput name="Received On" type={"date"} value={date} setValue={setDate} readOnly={readOnly} required={true} disabled />
                                        <TextInput name="No of Sample" type="number" value={noOfSample} setValue={setNoOfSample} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TextInput name="Sub Lab Report No" type="number" value={pcsPerPack} setValue={setPcsPerPack} readOnly={readOnly} disabled={(childRecord.current > 0) || isResultEntryStarted} />
                                        <DropdownInput name="Buyer Name" options={dropDownListObject(id ? buyerList.data : buyerList.data.filter(item => item.active && item.isBuyer), "name", "id")} value={buyerId} setValue={setBuyerId} required={true} disabled={(childRecord.current > 0)} readOnly={readOnly || isResultEntryStarted} />
                                        <DropdownInput name="Package" options={dropDownListObject(id ? packageList.data.filter(item => parseInt(buyerId) === parseInt(item.buyerNameId)) : packageList.data.filter(item => parseInt(buyerId) === parseInt(item.buyerNameId)).filter(item => item.active), "name", "id")} value={packageId} readOnly={readOnly || isResultEntryStarted} setValue={setPackageId} required={true}
                                            disabled={(childRecord.current > 0) || isResultEntryStarted} />
                                        <DropdownInput name="Type Of Invoice" options={invoiceTypeOptions} value={typeOfInvoice} readOnly={readOnly} setValue={setTypeOfInvoice} required={true} />


                                        <CheckBox name="Sample Return" readOnly={readOnly} value={sampleReturn} setValue={setSampleReturn} />
                                        {/* <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} /> */}


                                        {!id &&
                                            <div className="text-sm">
                                                <GenerateButton onClick={() => saveData()} />
                                            </div>
                                        }



                                        {/* <div className='flex gap-x-5 w-full'>
                                            <CheckBox name="Cancel" value={cancel} setValue={setCancel} readOnly={readOnly} />
                                            {cancel && (
                                                <div className='flex items-center justify-center text-xs'>
                                                    <label className=''>Reason</label>
                                                    <textarea
                                                        readOnly={readOnly}
                                                        className='border border-gray-400 w-[300px] ml-1 '
                                                        value={cancelReason}
                                                        onChange={(e) => setCancelReason(e.target.value)}
                                                    ></textarea>
                                                </div>

                                            )}
                                        </div> */}






                                    </div>
                                    <div className='grid grid-cols-4 items-center '>
                                        {/* <TextInput name={`${"UlrNo:Ref -- "} (${nextUlrNo})`} type="text" value={ulrNo} setValue={setUlrNo} readOnly={readOnly} disabled={(childRecord.current > 0)} /> */}
                                        <TextInput name="UlrNo :" type="text" value={ulrNo} setValue={setUlrNo} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                    </div>
                                    <div className="flex gap-x-10 mt-3">
                                        {id &&
                                            <button className="bg-blue-600 text-white rounded-md p-0.5 w-32 text-xs"
                                                onClick={() => {
                                                    setSampleAcknowledgement(true)
                                                }}
                                            >Sample Acknowledgement</button>
                                        }
                                        {id &&
                                            <button className="bg-blue-600 text-white rounded-md p-0.5 w-32 text-xs"
                                                onClick={() => {
                                                    setProfomaInvoice(true)
                                                }}
                                            >Profoma Invoice</button>
                                        }

                                        {id &&
                                            <button className="bg-blue-600 text-white rounded-md p-0.5 w-32 text-xs"
                                                onClick={() => {
                                                    setTestScanning(true)
                                                }}
                                            >Test Scanning</button>
                                        }
                                    </div>



                                    <div className="w-full grid justify-end ">
                                        <button
                                            type='button' className={`text-white bg-green-700 rounded-md p-1 w-24 mr-5 mb-2`}
                                            onClick={() => {
                                                refreshRequirementDetails()

                                            }}
                                        > Refresh
                                        </button>

                                        {/* <CheckBox name="Sample Return" readOnly={readOnly} value={sampleReturn} setValue={setSampleReturn} /> */}


                                    </div>

                                </fieldset>
                                {id &&
                                    <>
                                        <fieldset className="border-2 border-black rounded px-3 h-[300px]">
                                            <legend className='flex gap-2 items-center' style={{ height: "20%" }}>
                                                {subMenus.map((menu, index) =>
                                                    <div key={index} onClick={() => setActiveNavBar(menu)} className={`border border-gray-400 col-span-2 text-center cursor-pointer ${activeNavBar === menu ? "sub-navbar-active" : "sub-navbar"}`}>{menu}</div>
                                                )}
                                            </legend>
                                            <div className='w-full' style={{ height: "22%" }}>
                                                {getShowSubMenu()}
                                                <div className=" grid justify-end mt-2.5">
                                                    <button
                                                        onKeyDown={e => {
                                                            if (e.code === "Enter") {
                                                                saveData()
                                                                nextSubMenu()
                                                            }

                                                        }}
                                                        className='bg-green-400 rounded-lg p-1 w-32' onClick={() => { saveData(); nextSubMenu() }}>Save & Next
                                                    </button>
                                                </div>

                                            </div>
                                        </fieldset>
                                        <TestAllocation buyerId={buyerId} id={id} sampleDetails={sampleDetails} setSampleDetails={setSampleDetails} packageId={packageId} specialInstruction={specialInstruction}
                                            isResultEntryStarted={isResultEntryStarted}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </FieldsContext.Provider>
            </div>
        </div>
    );
}
