import React, { useEffect } from "react";
import { useGetWashCareGroupMasterQuery } from "../../../../redux/LabServices/WashCareGroupMasterServices";
import secureLocalStorage from "react-secure-storage";
import { findFromListWithMatchKey, getImageUrlPath } from "../../../../Utils/helper";
import { Loader } from "../../../../Basic/components";
import { TextArea } from "../../../../Inputs";

const WashCare = ({ id, singleData, readOnly, setWashCareStatus, washCareStatus, specialInstruction, setSpecialInstruction, setActiveNavBar }) => {

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        )
    }

    useEffect(() => {
        setSpecialInstruction(singleData?.specialInstruction)

    }, [id, setSpecialInstruction, singleData])

    const { data: washCareGroupMaster, isLoading: isWashCareGroupLoading, isFetching: isWashCareGroupFetching } =
        useGetWashCareGroupMasterQuery({ params: { ...params, includeWashCare: true } })



    const addWashCare = (washCareGroupId, washCareId) => {
        setWashCareStatus(prev => {
            let newWashCareStatus = structuredClone(prev)
            let currentIndex = newWashCareStatus.findIndex(item => parseInt(item.washCareGroupId) === parseInt(washCareGroupId))
            if (currentIndex === -1) {
                newWashCareStatus.push({ washCareGroupId, washCareId })
            } else {
                newWashCareStatus[currentIndex]["washCareId"] = washCareId
            }
            return newWashCareStatus
        })
    }

    const removeWashCare = (washCareGroupId) => {
        setWashCareStatus(prev => {
            return prev.filter(item => parseInt(item.washCareGroupId) !== parseInt(washCareGroupId))
        })
    }

    const handleWashCareSelection = (isChecked, washCareGroupId, washCareId) => {
        if (isChecked) removeWashCare(washCareGroupId)
        else addWashCare(washCareGroupId, washCareId)
    }

    const isWashCareSelected = (washCareGroupId, washCareId) => {
        let selectedWashCareId = findFromListWithMatchKey(washCareGroupId, washCareStatus, "washCareId", "washCareGroupId")
        if (!selectedWashCareId) return false
        return parseInt(selectedWashCareId) === parseInt(washCareId)
    }

    if (isWashCareGroupFetching || isWashCareGroupLoading || !washCareGroupMaster?.data) return <Loader />
    return (
        <div className='grid grid-cols-5 gap-3 px-1 bg-white'>
            {washCareGroupMaster.data.filter(item => item.active).map(washCareGroup =>
                <fieldset key={washCareGroup.id} className="border border-gray-400 rounded px-3 h-[350px] overflow-auto bg-white">
                    <legend className="sub-heading">{washCareGroup.name}</legend>
                    <div>
                        {washCareGroup.WashCare.map(washCare =>
                            <div key={washCare.id} className="border"
                                onClick={() => { handleWashCareSelection(isWashCareSelected(washCareGroup.id, washCare.id), washCareGroup.id, washCare.id) }}
                            >
                                <div className='flex items-center gap-5 mt-3 w-12'>
                                    <input type="radio" name="named"
                                        checked={isWashCareSelected(washCareGroup.id, washCare.id)}

                                        readOnly={readOnly} />
                                    <img src={getImageUrlPath(washCare.image)} alt="" />
                                </div>
                                <div className="mt-2">
                                    <label className="text-xs">
                                        {washCare.name}
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </fieldset>
            )}
            <fieldset className="border border-gray-400 w-full col-span-5 px-2 h-[125px] rounded overflow-auto">
                <legend className="sub-heading">INSTRUCTION (SPECIAL)</legend>
                <TextArea autoFocus name={"Wash Care Special Instruction"} value={specialInstruction} setValue={setSpecialInstruction} className={"w-full"} />
                <div className=" grid justify-end">
                    <button
                        onKeyDown={e => {
                            if (e.code === "Enter") {
                                setActiveNavBar("Claimed Details")
                            }

                        }}
                        className='bg-green-400 rounded-lg p-1 w-32' onClick={() => setActiveNavBar("Claimed Details")}>Done
                    </button>
                </div>
            </fieldset>
        </div>
    )
}

export default WashCare
