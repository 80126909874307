import React from 'react'

const ResultValue = ({ item, testIndex, test, reportData }) => {
  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }


  function checkIsData(testId, allResults) {
    let findData = allResults?.find(i => parseInt(i.testId) === parseInt(testId))?.data

    return findData
  }

  return (
    <>

      {
        item?.isRemovalForce ? (
          <div className='flex gap-x-1 overflow-auto w-full mt-1'>
            <div className='capitalize w-[25%]'>
              {test?.typeOfComponent}

            </div>
            <div className='capitalize w-[25%]'>
              {test?.areaOfComponent}

            </div>
            <div className='capitalize w-[25%]'>
              {test?.reportValue} {test?.result ? " " : "*"}


            </div>
            <div className={`${test?.result ? "" : "font-bold -ml-2"} w-[25%]`}>
              {test?.modeOfFailure}
            </div>

          </div>
        )
          :
          <div className={`${(!(test?.result) && (test?.isResult)) && (!checkIsData(item?.testId, reportData?.testDetailsSummary?.allResults)) ? "font-bold" : ""}`}>
            {(test?.decimalValue > 0 && isNumeric(test?.reportValue)) ? parseFloat(test?.reportValue).toFixed(test?.decimalValue) : test?.reportValue} {(!(test?.result) && (test?.isResult) && (!checkIsData(item?.testId, reportData?.testDetailsSummary?.allResults))) ? "*" : ""}
          </div>
      }
    </>
  )
}

export default ResultValue