import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetPurchaseInwardOrReturnQuery,
  useGetPurchaseInwardOrReturnByIdQuery,
  useAddPurchaseInwardOrReturnMutation,
  useUpdatePurchaseInwardOrReturnMutation,
  useDeletePurchaseInwardOrReturnMutation,
} from "../../../redux/ErpServices/PurchaseInwardOrReturnServices";
import { useGetTaxTemplateQuery } from '../../../redux/ErpServices/TaxTemplateServices';
import {
  useGetProcessQuery,
} from "../../../redux/ErpServices/processMasterServices";
import {
  useGetPoQuery
} from "../../../redux/ErpServices/PoServices"
import { useGetPartyQuery, useGetPartyByIdQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { LongDropdownInput, LongDisabledInput, DropdownInput, TextInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { poTypes, processDeliveryOrReturn, } from '../../../Utils/DropdownData';
import { useDispatch } from "react-redux";
import Modal from "../../../UiComponents/Modal";
import { findFromList, getDateFromDateTime } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";
import Program from "../Program"
import RawMaterial from "../RawMaterial";

const MODEL = "Yarn Delivery to Knitting / Yarn Return From Knitting";


export default function Form() {

  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [rawMaterial, setRawMaterial] = useState(false);
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [poType, setPoType] = useState("GreyYarn");
  const [deliveryOrReturn, setInwardOrReturn] = useState("ProcessDelivery");
  const [processId, setProcessId] = useState("");

  const [programDetails, setProgramDetails] = useState([])

  const [supplierId, setSupplierId] = useState("");

  const [delNo, setDelNo] = useState("")
  const [delDate, setDelDate] = useState("")

  const [inwardItems, setInwardItems] = useState([]);

  const [finalInwardItems, setFinalInwardItems] = useState([]);

  const [active, setActive] = useState(true)

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )

  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };
  const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params });

  const { data: supplierList } =
    useGetPartyQuery({ params });

  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  const { data: allData, isLoading, isFetching } = useGetPurchaseInwardOrReturnQuery({ branchId, searchParams: searchValue });

  const { data: poList, isLoading: poListLoading, isFetching: poListFetching } = useGetPoQuery({ branchId, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetPurchaseInwardOrReturnByIdQuery(id, { skip: !id });

  const [addData] = useAddPurchaseInwardOrReturnMutation();
  const [updateData] = useUpdatePurchaseInwardOrReturnMutation();
  const [removeData] = useDeletePurchaseInwardOrReturnMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setActive(id ? (data?.active ? data.active : false) : true);
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    dcDate: delDate, dcNo: delNo,
    poType, supplierId,
    branchId, id, userId,
  }

  const validateData = (data) => {
    return data.poType && data.supplierId && data.inwardOrReturn
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback({ id, body: data }).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      toast.success(text + "Successfully");
      setId(returnData.data.id)
      dispatch({
        type: `po/invalidateTags`,
        payload: ['po'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']


  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          poListLoading || poListFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  const allSuppliers = supplierList ? supplierList.data : []

  function filterSupplier() {
    let finalSupplier = []
    if (poType.toLowerCase().includes("yarn")) {
      finalSupplier = allSuppliers.filter(s => s.yarn)
    } else if (poType.toLowerCase().includes("fabric")) {
      finalSupplier = allSuppliers.filter(s => s.fabric)
    } else {
      finalSupplier = allSuppliers.filter(s => s.PartyOnAccessoryItems.length > 0)
    }
    return finalSupplier
  }
  let supplierListBasedOnSupply = filterSupplier()

  if (isProcessLoading || isProcessFetching) return <Loader />

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
    >
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          onClose={() => {
            setForm(false);
            setSearchValue("");
          }}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                  <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                      <legend className='sub-heading'>Yarn Delivery/Return Info</legend>
                      <div className="flex justify-end relative top-0 right-0">
                        <button className="text-xs bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1" onClick={() => setFormReport(prev => !prev)}>
                          {formReport ? "Close Report >" : "Open Report <"}
                        </button>
                      </div>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="flex flex-wrap">
                          <div className={`flex items-center gap-[72px] mx-1 ${id ? "block" : "hidden"}`}>
                            <label className="text-xs">Inward No</label>
                            <input className="border border-gray-400 h-6 w-14 rounded text-xs" value={id} required={true} readOnly={readOnly} disabled={true} />
                          </div>
                          <LongDisabledInput name="Delivery / Return Date" value={date} type={"date"} required={true} readOnly={readOnly} />
                          <DropdownInput
                            className={"w-[110px]"}
                            name="Delivery / Return"
                            options={processDeliveryOrReturn}
                            value={deliveryOrReturn}
                            setValue={setInwardOrReturn}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <DropdownInput
                            className={"w-[110px]"}
                            name="To Process"
                            options={dropDownListObject(id ? processList.data : processList.data.filter(item => item.active), "name", "id")}
                            value={processId}
                            setValue={setProcessId}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <DropdownInput
                            className={"w-[110px]"}
                            name="Po Type"
                            options={poTypes}
                            value={poType}
                            setValue={setPoType}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <LongDropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                          <TextInput name={"Del. No."} value={delNo} setValue={setDelNo} readOnly={readOnly} />
                          <TextInput name={"User Del. Date."} value={delDate} setValue={setDelDate} type={"date"} readOnly={readOnly} />
                        </div>
                      </div>
                    </fieldset>
                    {/* {(findFromList(processId,processList.data,"io")).toString().split("_").at(1)} */}
                    <Program setRawMaterial={setRawMaterial} finishedGoodsType={processId ? (findFromList(processId, processList.data, "io")).toString().split("_").at(1) : ""}
                      programDetails={programDetails} setProgramDetails={setProgramDetails} />
                    {rawMaterial &&
                      <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 px-3 overflow-auto'>
                        <legend className='sub-heading'>Yarn Delivery from stock</legend>
                        <RawMaterial rawMaterialType={processId ? (findFromList(processId, processList.data, "io")).toString().split("_").at(0) : "" }/>
                      </fieldset>
                    }
                  </div>
                  <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                    <FormReport
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      setId={setId}
                      tableHeaders={tableHeaders}
                      tableDataNames={tableDataNames}
                      data={allData?.data ? allData?.data.filter(item => item.inwardOrReturn !== "PurchaseCancel") : []}
                      loading={
                        poListLoading || poListFetching
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}