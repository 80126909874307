import React, { useEffect, useState } from 'react'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper'
import { useAddRegistrationMutation, useGetRegistrationByIdQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices'
import secureLocalStorage from 'react-secure-storage'
import { toast } from 'react-toastify'

const PartialRevert = ({ allData, activeRegistrationId, setPartialRevert }) => {

  const [addData] = useAddRegistrationMutation();
  const [updateData] = useUpdateRegistrationMutation();


  const { data: singleRegister, isLoading: isSingleRegisterLoading, isFetching: isSingleRegisterFetching } =
    useGetRegistrationByIdQuery({ id: activeRegistrationId }, { skip: !activeRegistrationId });

  const [testGroups, setTestGroups] = useState([])



  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )


  useEffect(() => {
    let data = singleRegister?.data?.RegistrationTestGroups.filter(val => val.isResultEntryFinished)
    if (!data) return
    setTestGroups(prev => {
      let newItem = [];

      data?.forEach((val, index) => {

        newItem.push({
          testGroupId: val.testGroupId,
          registrationId: val.registrationId,
          isResultEntryFinished: val.isResultEntryFinished,
          LabTestGroup: val?.LabTestGroup?.name,

          revertReasons: [
            {
              reason: "",

            }
          ]
        })

      })

      return newItem
    })
  }, [activeRegistrationId, setTestGroups, singleRegister, isSingleRegisterLoading, isSingleRegisterFetching])




  function addItem(testGroupId, index) {
    setTestGroups(testGroups => {
      let newItems = structuredClone(testGroups);
      newItems[index]["groupId"] = testGroupId

      return newItems
    });
  }

  function removeItem(groupId, index) {
    setTestGroups(testGroups => {
      let newItems = structuredClone(testGroups);
      newItems[index]["groupId"] = groupId
      return newItems
    });
  }

  function isTestAdded(groupId) {
    let item = testGroups.find(item => ((parseInt(item.groupId) === parseInt(groupId))))

    if (!item) return false
    return true
  }

  const handleUpdateRegistration = async (callback, registerdata, text, isDataClear = false) => {
    try {
      await callback(registerdata).unwrap();
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };



  const saveData = (registrationId, userId, filteredTestGroups, isDataClear = false) => {

    if (!window.confirm("Are you sure Revert the Results ...?")) {
      return;
    }

    handleUpdateRegistration(updateData, {

      registrationId: registrationId, testGroups: filteredTestGroups, isAdminRevert: true, currentUserId: userId, isResultEntryFinished: false,
    }, " Revert To User ", isDataClear);
  };

  function handleChange(value, index, valIndex, field) {
    setTestGroups(testGroups => {
      let newItems = structuredClone(testGroups);
      newItems[index]["revertReasons"][valIndex]["reason"] = value

      return newItems
    });
  }


  return (
    <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto">
      <div className="md:flex md:items-center md:justify-between page-heading mt-9">
        <div className="heading text-center  md:mx-10">Partial Revert</div>
      </div>
      <>
        <div
          className="h-[500px] overflow-auto"
        >
          <table className="table-auto text-center w-full">
            <thead className="border-2 table-header">
              <tr className='h-2'>
                <th
                  className="border-2  top-0 stick-bg w-10"
                >
                  S. no.
                </th>
                <th
                  className="border-2  top-0 stick-bg w-44"
                >TestGroup

                </th>
                <th
                  className="border-2  top-0 stick-bg"
                >Select

                </th>
                <th
                  className="border-2  top-0 stick-bg"
                >Reason

                </th>

              </tr>
            </thead>


            <tbody className="border-2">
              {testGroups?.map((dataObj, index) => (
                <tr
                  key={dataObj.id}
                  className="border-2 table-row cursor-pointer"
                >
                  <td className='py-1'> {(index + 1)}</td>
                  <td className='py-1 w-20'> {dataObj?.LabTestGroup}</td>
                  <td className='py-1 w-12'>
                    <input type='checkbox' checked={isTestAdded(dataObj.groupId, index)}
                      onChange={() => {

                        if (isTestAdded(dataObj.groupId, index)) {
                          removeItem(null, index)
                        } else {
                          addItem(dataObj.testGroupId, index)
                        }
                      }} />
                  </td>

                  {
                    dataObj?.revertReasons?.map((val, valIndex) =>
                      <td className='py-1'>
                        <textarea className=" w-3/4 focus:outline-none border border-gray-500 rounded p-2"
                          value={val.reason} onChange={(e) => handleChange(e.target.value, index, valIndex, "revertReasons")} >

                        </textarea>

                      </td>


                    )
                  }

                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </>
      <div className='grid w-full justify-end pr-9 pb-5'>{console.log(testGroups, "testGroups")}
        <button className="bg-green-600 text-white rounded-md p-2 w-20 text-xs"

          onClick={() => { saveData(activeRegistrationId, userId, testGroups?.filter(val => val.groupId)); setPartialRevert(false) }}

        >SUBMIT</button>
      </div>

    </div>
  )
}

export default PartialRevert