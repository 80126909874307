import React, { useEffect, useState, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetStyleMasterQuery,
    useGetStyleMasterByIdQuery,
    useAddStyleMasterMutation,
    useUpdateStyleMasterMutation,
    useDeleteStyleMasterMutation,
} from "../../../redux/ErpServices/StyleMasterServices";
import { useGetFabricMasterQuery } from "../../../redux/ErpServices/FabricMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox, MultiSelectDropdown, DropdownInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject, multiSelectOption } from "../../../Utils/contructObject";
import { diaMeasurementList } from "../../../Utils/DropdownData";


const MODEL = "Style Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [uploadImage, setUploadImage] = useState("")
    const [styleCode, setStyleCode] = useState("");
    const [productType, setProductType] = useState("");
    const [itemName, setItemName] = useState("");
    const [seoTitle, setSeoTitle] = useState("");
    const [size, setSize] = useState([]);
    const [sleeve, setSleeve] = useState("");
    const [pattern, setPattern] = useState("");
    const [occassion, setOccassion] = useState("");
    const [numberOfProducts, setNumberOfProducts] = useState("");
    const [material, setMaterial] = useState("");
    const [washCare, setWashCare] = useState("");
    const [active, setActive] = useState(true);
    const [fabricId, setFabricId] = useState("");


    const [searchValue, setSearchValue] = useState("");


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: fabricList } =
        useGetFabricMasterQuery({ params: { ...params, active: true } });

    const { data: allData, isLoading, isFetching } = useGetStyleMasterQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetStyleMasterByIdQuery(id, { skip: !id });


    const [addData] = useAddStyleMasterMutation();
    const [updateData] = useUpdateStyleMasterMutation();
    const [removeData] = useDeleteStyleMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setUploadImage(data?.uploadImage ? data.uploadImage : "");
            setStyleCode(data?.styleCode ? data.styleCode : "");
            setProductType(data?.productType ? data.productType : "");
            setItemName(data?.itemName ? data.itemName : "");
            setSeoTitle(data?.seoTitle ? data.seoTitle : "");
            setSize(data?.size ? data.size : []);
            setSleeve(data?.sleeve ? data.sleeve : "");
            setFabricId(data?.fabricId ? data.fabricId : "");
            setPattern(data?.pattern ? data.pattern : "");
            setOccassion(data?.occassion ? data.occassion : "");
            setNumberOfProducts(data?.numberOfProducts ? data.numberOfProducts : "");
            setMaterial(data?.material ? data.material : "");
            setWashCare(data?.washCare ? data.washCare : "");
            setActive(id ? (data?.active ? data.active : false) : true);
        },
        [id]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, uploadImage, styleCode, productType, itemName, seoTitle, size, sleeve, fabricId,
        pattern, occassion, numberOfProducts, material, active, washCare,
        companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId")
    }

    const validateData = (data) => {
        if (data.name && data.code) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId(returnData.data.id)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "Code", "Name", "Status"
    ]
    const tableDataNames = ["dataObj.code", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    childRecord={0}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip w-full">
                    <div className="col-span-3 border overflow-auto">
                        <div className='col-span-3 border overflow-auto'>
                            <div className='mr-1 md:ml-2 flex gap-5'>
                                <fieldset className='frame my-1 h-72 w-72'>
                                    <legend className='sub-heading'>Upload Image</legend>
                                    <div className='grid grid-cols-1 my-2'>
                                        <input className="block w-full mb-5 text-xs cursor-pointer rounded bg-gray-50 text-gray-400 focus:outline-none bg-gray-700 border-gray-600 placeholder-gray-400" type="file" value={uploadImage} setValue={setUploadImage} />
                                    </div>
                                </fieldset>
                                <fieldset className='frame my-1 h-72 w-full flex flex-col gap-10'>
                                    <legend className='sub-heading'>Style Info</legend>
                                    <div className="grid grid-cols-2">
                                        <TextInput name="SKU / Style code" type="text" value={styleCode} setValue={setStyleCode} required={true} readOnly={readOnly} />
                                        <TextInput name="Product Type" type="text" value={productType} setValue={setProductType} required={true} readOnly={readOnly} />
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <TextInput name="Item Name" type="text" value={itemName} setValue={setItemName} required={true} readOnly={readOnly} />
                                        <TextInput name="SEO Title" type="text" value={seoTitle} setValue={setSeoTitle} required={true} readOnly={readOnly} />
                                    </div>
                                    <div className="flex flex-col">
                                        <MultiSelectDropdown readOnly={readOnly} name="Size" selected={size} setSelected={setSize} options={multiSelectOption(diaMeasurementList)} />
                                        <CheckBox name="Active" value={active} setValue={setActive} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className='mr-1 md:ml-2 col-span-3 overflow-auto'>
                            <fieldset className='frame h-60 w-full flex flex-col gap-5'>
                                <legend className='sub-heading'>Description</legend>
                                <div className="grid grid-cols-2">
                                    <TextInput name="Sleeve" type="text" value={sleeve} setValue={setSleeve} required={true} readOnly={readOnly} />
                                    <DropdownInput name="Fabric" options={dropDownListObject(fabricList.data, "aliasName", "id")} value={fabricId} setValue={(value) => { setFabricId(value); }} readOnly={readOnly} required={true} />
                                </div>
                                <div className="grid grid-cols-2">
                                    <TextInput name="Pattern" type="text" value={pattern} setValue={setPattern} required={true} readOnly={readOnly} />
                                    <TextInput name="Occassion" type="text" value={occassion} setValue={setOccassion} required={true} readOnly={readOnly} />
                                </div>
                                <div className="grid grid-cols-2">
                                    <TextInput name="Number of Products" type="text" value={numberOfProducts} setValue={setNumberOfProducts} required={true} readOnly={readOnly} />
                                    <TextInput name="Material" type="text" value={material} setValue={setMaterial} required={true} readOnly={readOnly} />
                                </div>
                                <div className="grid grid-cols-2">
                                    <TextInput name="Wash Care" type="text" value={washCare} setValue={setWashCare} required={true} readOnly={readOnly} />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
