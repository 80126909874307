import React from 'react';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from "react-secure-storage";
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices';
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices';
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices';
import { findFromListWithMatchKey } from '../../../Utils/helper';
import { useGetPartyByIdQuery } from '../../../redux/ErpServices/PartyMasterServices';


const PackageGridTemplate = ({ packageTemplateDetails, setPackageTemplateDetails, id, readOnly, buyerId }) => {

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const handleInputChange = (event, testId, field) => {
        const value = event.target.value;
        const newBlend = structuredClone(packageTemplateDetails);
        let index = newBlend.findIndex(item => parseInt(item.testId) === parseInt(testId))
        if (index === -1) return
        if (field === "methodId") {
            if (value === "") {
                newBlend[index]["conditionId"] = ""
            }
        }
        newBlend[index][field] = value;
        setPackageTemplateDetails(newBlend);
    };



    const { data: lebTestNameList, isLoading: labTestNameLoading, isFetching: labTestNameFetching } =
        useGetLabTestNameMasterQuery({ params });

    const { data: conditionList, isLoading: conditionLoading, isFetching: conditionFetching } =
        useGetLabTestConditionMasterQuery({ params: { ...params, active: true } });

    const { data: methodList, isLoading: methodLoading, isFetching: methodFetching } =
        useGetLabTestMethodMasterQuery({ params: { ...params, active: true } });


    const { data: partyDetails, isLoading: isPartyDetailsLoading, isFetching: isPartyDetailsFetching } =
        useGetPartyByIdQuery(buyerId, { skip: !buyerId });

    if (labTestNameLoading || labTestNameFetching || conditionLoading || conditionFetching || methodLoading || methodFetching) return <Loader />

    function addItem(testId) {
        setPackageTemplateDetails(packageTemplateDetails => {
            let newItems = structuredClone(packageTemplateDetails);
            let newItem = { testId }
            newItems.push(newItem);
            return newItems
        });
    }

    function removeItem(testId) {
        setPackageTemplateDetails(packageTemplateDetails => {
            let newItems = structuredClone(packageTemplateDetails);
            newItems = newItems.filter(item => !((parseInt(item.testId) === parseInt(testId))))
            return newItems
        });
    }

    function isTestAdded(testId) {
        let item = packageTemplateDetails.find(item => ((parseInt(item.testId) === parseInt(testId))))

        if (!item) return false
        return true
    }




    function handleSelectAllChange(value) {
        if (value) {
            lebTestNameList.data.forEach(item => addItem(item.id))
        } else {
            lebTestNameList.data.forEach(item => removeItem(item.id))
        }
    }

    function getSelectAll() {
        return lebTestNameList.data.every(item => isTestAdded(item.id))
    }

    function isMethodInBuyerStandard(labTestMethod) {
        if (!partyDetails?.data) return false
        if (partyDetails?.data.PartyOnStandardItems) {
            let index = partyDetails?.data.PartyOnStandardItems.findIndex(item => parseInt(item.labTestStandardId) === parseInt(labTestMethod.standardId))
            if (index === -1) return false
            return true
        }
        return false
    }

    if (isPartyDetailsFetching || isPartyDetailsLoading) return <Loader />

    return (
        <div className='w-full py-3 px-1'>
            <div className='overflow-auto' >{console.log(packageTemplateDetails, "packagedetails")}
                <div>
                    <table className='border border-black w-full text-xs text-center'>
                        <thead className='border border-black'>
                            <th className='flex flex-col w-12'>
                                <label>Select all</label>
                                <input type='checkbox' onChange={(e) => handleSelectAllChange(!getSelectAll())}
                                    checked={getSelectAll()}
                                    disabled={readOnly}
                                />
                            </th>
                            <th className='border border-black w-8'>
                                S.No
                            </th>
                            <th className='border border-black w-64'>
                                Tests
                            </th>
                            <th className='border border-black w-32'>
                                Methods
                            </th>
                            <th className='border border-black'>
                                Conditions
                            </th>
                        </thead>
                        <tbody>
                            {lebTestNameList.data.map((test, index) =>
                                <tr>
                                    <td className='border border-black w-12'>
                                        <input type='checkbox' checked={isTestAdded(test.id)}
                                            onChange={() => {
                                                if (readOnly) return
                                                if (isTestAdded(test.id)) {
                                                    removeItem(test.id)
                                                } else {
                                                    addItem(test.id)
                                                }
                                            }} />
                                    </td>
                                    <td className='border border-black py-1 w-8'>
                                        {index + 1}
                                    </td>
                                    <td className='text-left border border-black px-1'>
                                        {test.name}
                                    </td>
                                    <td className='text-left border border-black'>
                                        <select
                                            disabled={readOnly || (!isTestAdded(test.id))}
                                            className='text-left w-full rounded h-8 py-2 focus:outline-none'
                                            value={findFromListWithMatchKey(test.id, packageTemplateDetails, "methodId", "testId")}
                                            onChange={(e) => (handleInputChange(e, test.id, "methodId"))}>
                                            <option hidden>
                                                Select
                                            </option>
                                            {methodList.data.filter(item => isMethodInBuyerStandard(item)).filter(item =>
                                                parseInt(item.labTestId) === parseInt(test.id)).map((methodId) =>
                                                    <option value={methodId.id} key={methodId.id}>
                                                        {methodId.name}
                                                    </option>
                                                )}
                                        </select>
                                    </td>
                                    <td className='text-left border border-black'>
                                        <select
                                            disabled={readOnly || (!isTestAdded(test.id))}
                                            className='text-left w-full rounded h-8 py-2 focus:outline-none'
                                            value={findFromListWithMatchKey(test.id, packageTemplateDetails, "conditionId", "testId")}
                                            onChange={(e) => (handleInputChange(e, test.id, "conditionId"))}>
                                            <option hidden>
                                                Select
                                            </option>
                                            {
                                                conditionList.data.filter(item => {
                                                    return (parseInt(item.methodId) === parseInt(findFromListWithMatchKey(test.id, packageTemplateDetails, "methodId", "testId")))
                                                }
                                                ).filter(item => id ? true : item.active).map((conditionId) =>
                                                    <option value={conditionId.id} key={conditionId.id}>
                                                        {conditionId.name}
                                                    </option>
                                                )}
                                        </select>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PackageGridTemplate