import React, { useRef, useState, useEffect } from 'react';
import Invoice from './Invoice';
import Header from '../PrintFormat-ProfomaInvoice/Header';
import FactoryAddress from '../PrintFormat-TestReport/FactoryAddress';
import { useReactToPrint } from 'react-to-print'
import { PrintButtonOnly } from '../../../Buttons'
import Layout from './Layout';
function SampleEmail() {
  const [isHeightReached, setIsHeightReached] = useState(false);
  const divRef = useRef(null);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
     
  });

 


  useEffect(() => {
    const checkHeight = () => {
      if (divRef.current) {
        const { height } = divRef.current.getBoundingClientRect();
        const divClassHeight = parseFloat(getComputedStyle(divRef.current).getPropertyValue('height'));
        console.log(height,"height",divClassHeight,"divClassHeight")
        if (height <= divClassHeight) {
          setIsHeightReached(true);
        } else {
          setIsHeightReached(false);
        }
      }
    };

    // Run checkHeight initially and add resize event listener to recheck height on window resize
    checkHeight();
    window.addEventListener('resize', checkHeight);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', checkHeight);
    };
  }, []); 

  

  return (
    // <div>
    //    <div className="flex w-full justify-end ">
    //             <PrintButtonOnly onClick={()=>{handleToPrint()}} />
    //         </div>
    //         <div ref={componentRef} className=''>
    //        <header> <Header/></header>
    //   <div ref={divRef}>
    //    <Invoice />
    //   </div>
    //   {isReached &&  <div> <footer><FactoryAddress/> </footer><header><Header/> </header></div>}

    //         </div>
      
    // </div>
<div>

    <div className="flex w-full justify-end ">
               <PrintButtonOnly onClick={()=>{handlePrint()}} />
           </div>


    
          
    <div className="a4sheet" ref={componentRef}>
      <div className='splitheight p-1.5'>
      <Header/>
      </div>
   <div className='contentheight  p-1.5' ref={divRef}>
   <Invoice/>
   </div>
    
    {isHeightReached && 
     <div className='footerheight p-1.5'> 
      <FactoryAddress/> 
      </div>}
    
   
    </div>


    </div>   

  );
}

export default SampleEmail;
