import React from "react";
import SampleDetails from "./SampleDetails";
import SampleImage from "./SampleImage";
import TestResultsList from "./TestResultsList";
import TestwiseFailureImages from "./TestwiseFailureImages";
import SamplewiseTestedImages from "./SamplewiseTestedImages";
import TestResultLists from "./TestResultLists";
import PageWrapper from "../PageWrapper";
import { Text, View } from "@react-pdf/renderer";
import tw from "../../../../Utils/tailwind-react-pdf";


export default function Content({ headingOptions, washCareData, testComments, splitTest, reportData, passFail }) {
  let testResults = reportData?.data?.testResultDetails;
  let testReportNo = reportData?.data?.testReportNo ? reportData?.data?.testReportNo : ""
  if (splitTest === "ACCREDITATION") {
    testReportNo = testReportNo.concat("A");
  } else if (splitTest === "NONACCREDITATION") {
    testReportNo = testReportNo.concat("B");
  }
  return (
    <>
      <PageWrapper headingOptions={headingOptions} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
        <View>

          <Text style={tw('text-center font-bold text-sm underline')}>
            Original Sample Image
          </Text>
          {(reportData?.data?.sampleImages ? reportData?.data.sampleImages : []).map((sampleItem, index) => {
            return <SampleImage sampleItem={sampleItem} key={index} reportData={reportData?.data} splitTest={splitTest} />
          })}
        </View>
      </PageWrapper>
      <PageWrapper headingOptions={headingOptions} reportData={reportData} sampleDetails={false} splitTest={splitTest} testReportNo={testReportNo}>
        <SampleDetails washCareData={washCareData} data={reportData?.data} splitTest={splitTest} testReportNo={testReportNo} />
      </PageWrapper>
      <PageWrapper headingOptions={headingOptions} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
        <TestResultsList reportData={reportData?.data} testComments={testComments} passFail={passFail} splitTest={splitTest} />
      </PageWrapper>


      <TestResultLists reportData={reportData} passFail={passFail} splitTest={splitTest} testResults={testResults} testReportNo={testReportNo} headingOptions={headingOptions} />


      {/* <ColourFastnessLegend testResults={testResults} /> */}



      {
        (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
          || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
          ?
          (
            <>


              <PageWrapper headingOptions={headingOptions} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>

                {
                  passFail === "FAIL" && splitTest === "ACCREDITATION" || passFail === "ALL" && splitTest === "ACCREDITATION" ? (
                    <View style={tw("text-center")}>
                      {reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />
                      })}



                    </View>
                  )
                    :
                    passFail === "FAIL" && splitTest === "NONACCREDITATION" || passFail === "ALL" && splitTest === "NONACCREDITATION" ? (
                      <View style={tw("text-center")}>
                        {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                          return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />

                        })}



                      </View>
                    )
                      :

                      passFail === "FAIL" && splitTest === "ALL" || passFail === "ALL" && splitTest === "ALL" ? (

                        <View style={tw("text-center")}>
                          {reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />
                          })}
                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />
                          })}
                        </View>
                      )
                        : ""
                }
                {


                  reportData?.data?.testedImages?.length > 0
                    ?
                    <></> :
                    <View>
                      <Text style={tw("text-center w-full mt-5 font-bold")}>
                        --- End of Test Report ---
                      </Text>
                    </View>
                }

              </PageWrapper>

            </>
          ) : ""
      }



      {
        reportData?.data?.testedImages?.length > 0
          ?
          (

            <PageWrapper headingOptions={headingOptions} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>


              <View style={tw(' w-full  pl-9 ml-2')}>
                <Text style={[tw(' mt-5 text-center w-full  uppercase font-bold underline'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
                  Tested Sample Images
                </Text>
                <View style={tw("w-5/6 text-center")}>
                  {
                    reportData?.data?.testedImages?.map((testImg, testImgIndex) => {
                      return <SamplewiseTestedImages passFail={passFail} key={testImgIndex} testImg={testImg} testImgIndex={testImgIndex} splitTest={splitTest} />
                    })
                  }
                </View>

              </View>
              <View>
                <Text style={tw("text-center w-full mt-5 font-bold")}>
                  --- End of Test Report ---
                </Text>
              </View>

            </PageWrapper>

          ) : ""
      }


    </>
  );
}
