import React from 'react'
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import secureLocalStorage from 'react-secure-storage';

const TestParametersLists = ({ item, val, dataObj }) => {

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),

    };


    return (
        <>

            <tr className='w-full'>
                <td className=' py-1 text-left gridAlignLeft capitalize'>
                    *{item?.LabTest?.name.toLowerCase() || ""}
                </td>
            </tr>



        </>
    )
}

export default TestParametersLists