import React, { useContext, useRef, } from "react";
import { TextInput, CheckBox, DropdownInput, MultiSelectDropdown, LongTextInput, } from "../../../../Inputs";
import { FieldsContext } from '..';
import { dropDownListObject, multiSelectOption } from "../../../../Utils/contructObject";
import secureLocalStorage from "react-secure-storage";
import { useGetMillOrFactoryMasterQuery } from "../../../../redux/LabServices/MillOrFactoryMasterServices";
import { useGetEndUseMasterQuery } from "../../../../redux/LabServices/EndUseMasterServices";
import { Loader } from "../../../../Basic/components";
import { MultiSelect } from "react-multi-select-component";
import { ready } from "jquery";

export default function Form() {

    const fields = useContext(FieldsContext);

    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: endUseList, isLoading: isEndUseLoading, isFetching: isEndUseFetching } =
        useGetEndUseMasterQuery({ params });

    const { data: factoryList, isLoading: isFactoryLoading, isFetching: isFactoryFetching } =
        useGetMillOrFactoryMasterQuery({ params });

    return (
        <div>
            {isEndUseLoading || isEndUseFetching || isFactoryLoading || isFactoryFetching
                ?
                <Loader />
                :
                <div>
                    <div className='grid grid-cols-5 my-2'>
                        {/* <CheckBox name={"Retest"} value={fields.retest} setValue={fields.setRetest} readOnly={fields.readOnly} /> */}
                        <TextInput autoFocus name="Sample Description" type="text" value={fields.sampleDescription} setValue={fields.setSampleDescription} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Style No." type="text" value={fields.styleNo} setValue={fields.setStyleNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Order No." type="text" value={fields.orderNo} setValue={fields.setOrderNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Batch No." type="text" value={fields.batchNo} setValue={fields.setBatchNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />

                        <TextInput name="Department No." type="text" value={fields.departmentNo} setValue={fields.setDepartmentNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Color Name" type="text" value={fields.color} setValue={fields.setColor} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Season" type="text" value={fields.season} setValue={fields.setSeason} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Phase" type="text" value={fields.phase} setValue={fields.setPhase} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />

                        <DropdownInput name="Fabric Supplier / Mill Name" options={dropDownListObject(fields.id ? factoryList.data : factoryList.data.filter(item => item.active), "name", "id")} value={fields.millOrFactoryId} setValue={fields.setMillOrFactoryId} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Age / Size range" type="text" value={fields.sizerange} setValue={fields.setSizerange} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Submitted Size" type="text" value={fields.submittedSize} setValue={fields.setSubmittedSize} required={true} readOnly={fields.readOnly} />
                        <TextInput name="Total Weight (In Kgs)" type="text" value={fields.totalWeight} setValue={fields.setTotalWeight} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />

                        <TextInput name="Party DC. Number" type="text" value={fields.partyDcNumber} setValue={fields.setPartyDcNumber} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                        <TextInput name="Buying House Name" type="text" value={fields.buyingHouse} setValue={fields.setBuyingHouse} required={true} readOnly={fields.readOnly} />
                    </div>


                    <div className="w-1/4">
                        <MultiSelectDropdown name="End Use" className={" grid-cols-1"}
                            selected={fields.endUseDetails}
                            setSelected={fields.setEndUseDetails}
                            options={endUseList ? multiSelectOption(fields.id ? endUseList.data : endUseList.data.filter(item => item.active), "name", "id") : []}
                            required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />


                    </div>
                </div>
            }
        </div>
    )
}