import { CLOSE_ICON, TICK_ICON } from "../../../icons";
import { useGetLabTestResultEntryQuery } from "../../../redux/LabServices/LabTestResultEntryServices";
import AccordionCustomUI from "../../../UiComponents/AccordianComponent";
import { getCommonParams } from "../../../Utils/helper";

export default function ResultStatusTracking({ registrationId }) {
    const params = getCommonParams();
    const { data: reportData } = useGetLabTestResultEntryQuery({
        params: {
            ...params,
            registrationId,
            resultEntryTrackingReport: true,
        }
    })
    return (
        <div className="text-sm">
            <div className="flex  text-xl">
                <span className="font-bold">
                    Lab Report No:
                </span>
                <span className="ml-5">
                    {reportData?.data?.labTestReportNo}
                </span>
            </div>
            <AccordionCustomUI stateData={reportData?.data?.samples ? reportData?.data?.samples : []}
                HeaderComponent={SampleComponent} BodyComponent={GroupComponent} />
        </div>
    );
}
function SampleComponent({ item }) {
    return (
        <div className="flex w-full justify-between items-center font-normal bg-gray-400 p-1 rounded">
            <span>
                {item.sampleId}
            </span>
            <span >
                {item.entryStatus === "Completed"
                    ?
                    <span className="text-green-800">
                        {TICK_ICON}
                    </span>
                    :
                    <span className="text-red-700">
                        {CLOSE_ICON}
                    </span>
                }
            </span>
        </div>
    )
}

const TestWiseDetails = ({ item }) => {
    return (
        <div className="ml-10">
            <ol>
                {item?.testDetails.map(i =>
                    <li key={i.id} className="flex justify-between">
                        <span className="text-blue-700">
                            {i.name}
                        </span>
                        <span >
                            {item.entryStatus === "Completed"
                                ?
                                <span className="text-green-800">
                                    {TICK_ICON}
                                </span>
                                :
                                <span className="text-red-700">
                                    {CLOSE_ICON}
                                </span>
                            }
                        </span>
                    </li>
                )}
            </ol>
        </div>
    )
}
function GroupComponent({ item }) {
    const GroupHead = ({ item }) =>
        <div key={item.id} className="flex justify-between w-full ml-5">
            <span className="text-blue-500">
                {item.name}
            </span>
            <span >
                {item.entryStatus === "Completed"
                    ?
                    <span className="text-green-800">
                        {TICK_ICON}
                    </span>
                    :
                    <span className="text-red-700">
                        {CLOSE_ICON}
                    </span>
                }
            </span>
        </div>
    return (
        <AccordionCustomUI stateData={item?.groupWiseDetails ? item?.groupWiseDetails : []}
            HeaderComponent={GroupHead} BodyComponent={TestWiseDetails} />
    )
}