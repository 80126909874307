import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { WASH_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const WashMasterApi = createApi({
  reducerPath: "washMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["WashMaster"],
  endpoints: (builder) => ({
    getWashMaster: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: WASH_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: WASH_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["WashMaster"],
    }),
    getWashMasterById: builder.query({
      query: (id) => {
        return {
          url: `${WASH_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["WashMaster"],
    }),
    addWashMaster: builder.mutation({
      query: (payload) => ({
        url: WASH_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["WashMaster"],
    }),
    updateWashMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${WASH_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["WashMaster"],
    }),
    deleteWashMaster: builder.mutation({
      query: (id) => ({
        url: `${WASH_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["WashMaster"],
    }),
  }),
});

export const {
  useGetWashMasterQuery,
  useGetWashMasterByIdQuery,
  useAddWashMasterMutation,
  useUpdateWashMasterMutation,
  useDeleteWashMasterMutation,
} = WashMasterApi;

export default WashMasterApi;
