import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetLabTestNameMasterQuery,

} from "../../../redux/LabServices/LabTestNameMasterServices";
import { useGetLabTestGroupMasterQuery } from "../../../redux/LabServices/LabTestGroupMasterServices"
import { useGetLabTestSubGroupMasterQuery } from "../../../redux/LabServices/LabTestSubGroupMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { CheckBox, DropdownInput, Modal, MultiSelectDropdown } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject, multiSelectOption } from "../../../Utils/contructObject";
import { findFromList } from "../../../Utils/helper";
import { Loader } from "../../../Basic/components";
import { useGetLabTestMethodMasterQuery } from "../../../redux/LabServices/LabTestMethodMasterServices";
import { CustomerClaimed } from "../../../Utils/DropdownData";
import {
    useAddLabTestAnalyteMasterMutation,
    useDeleteLabTestAnalyteMasterMutation,
    useGetLabTestAnalyteMasterByIdQuery,
    useGetLabTestAnalyteMasterQuery,
    useUpdateLabTestAnalyteMasterMutation
} from "../../../redux/LabServices/LabTestAnalyteMasterServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useGetSampleDescriptionOneMasterQuery } from "../../../redux/LabServices/SampleDescriptionOneMasterServices";
import { useGetSampleDescriptionTwoMasterQuery } from "../../../redux/LabServices/SampleDescriptionTwoMasterServices";
import ParameterHeading from "./ParameterHeading";
import TestParameterFormReport from "./TestParameterFormReport";



const MODEL = "Test Parameter Master";

export default function Form() {
    const [form, setForm] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [subGroupId, setSubGroupId] = useState("");
    const [name, setName] = useState("");
    const [active, setActive] = useState(true);
    const [isAccreditation, setIsAccreditation] = useState(false);
    const [testId, setTestId] = useState("");
    const [methodList, setMethodList] = useState([]);
    const [sampleTypeId, setSampleTypeId] = useState("");
    const [headings, setHeadings] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);
    const [formReport, setFormReport] = useState(false);
    const [isClone, setIsClone] = useState(false);
    const [reason, setReason] = useState("");
    const [isDimensional, setIsDimensional] = useState(false)

    const [labTestAnalyteDetails, setLabTestAnalyteDetails] = useState([]);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: labTestGroupList } =
        useGetLabTestGroupMasterQuery({ params });

    const { data: labTestNameList } =
        useGetLabTestNameMasterQuery({ params });

    const { data: labTestMethodList } =
        useGetLabTestMethodMasterQuery({ params });


    const { data: labTestSubGroupList } =
        useGetLabTestSubGroupMasterQuery({ params });

    const { data: sampleTypeList } =
        useGetSampleDescriptionOneMasterQuery({ params });


    const { data: sampleTypeTwoList } =
        useGetSampleDescriptionTwoMasterQuery({ params });

    const { data: allData, isLoading, isFetching } = useGetLabTestAnalyteMasterQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetLabTestAnalyteMasterByIdQuery(id, { skip: !id });


    const [addData] = useAddLabTestAnalyteMasterMutation();
    const [updateData] = useUpdateLabTestAnalyteMasterMutation();
    const [removeData] = useDeleteLabTestAnalyteMasterMutation();


    const getGroupId = useCallback((subGroupId) => {
        return findFromList(subGroupId, labTestSubGroupList.data, "groupId");
    }, [labTestSubGroupList]);

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            else setReadOnly(false)
            setName(data?.name ? data.name : "");
            setReason(data?.reason ? data?.reason : "")
            setSampleTypeId(data?.sampleTypeId ? data.sampleTypeId : "");

            setMethodList(data?.methodList ? data.methodList : [])
            setTestId(data?.testId ? data.testId : "")
            setIsDimensional(data?.isDimensional ? data?.isDimensional : false)
            setSubGroupId(data?.subGroupId ? data.subGroupId : "");
            setGroupId(data?.subGroupId ? getGroupId(data.subGroupId) : "");
            setActive(id ? (data?.active ? data.active : false) : true);
            setSelectedOption(data?.selectedOption ? data.selectedOption : "")
            setLabTestAnalyteDetails(data?.LabTestAnalyteDetails ? data.LabTestAnalyteDetails : [])
            setIsAccreditation(data?.isAccreditation ? data?.isAccreditation : false)
            childRecord.current = data?.childRecord || 0;
        },
        [id, getGroupId]
    );



    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    // const childRecordExists = childRecord.current > 0;
    const childRecordExists = false;




    const data = {
        id, reason, name, isAccreditation, isDimensional, sampleTypeId, selectedOption, labTestAnalyteDetails, subGroupId, groupId, testId, methodList, active, companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId")
    }

    const validateData = (data) => {
        if (data.subGroupId && data.groupId && data.sampleTypeId && data.labTestAnalyteDetails.length > 0) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            if (returnData?.statusCode === 1) {
                return toast.error(returnData?.message);
            } else {
                setId("")
                syncFormWithDb(undefined);
                onNew()
                toast.success(text + "Successfully");
            }
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (isClone) {
            handleSubmitCustom(addData, data, "Added");
        }
        else if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                let returnData = await removeData(id).unwrap();
                if (returnData?.statusCode === 1) {
                    return toast.error(returnData?.message);
                } else {
                    setId("")
                    syncFormWithDb(undefined);
                    onNew()
                    toast.success("Deleted Successfully");
                }
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
        setIsClone(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    function removeItem(index, field) {
        if (field === "headings")
            setHeadings(testDetails => {
                return testDetails.filter((_, i) => parseInt(i) !== parseInt(index))

            });
        else {
            setParameters(parameters => {
                return parameters.filter((_, i) => parseInt(i) !== parseInt(index))
            })
        }

    }

    function onKeyDown(e, index) {
        if (e.key === "Tab") {
            let lastIndexHeadings = headings.length - 1;
            let lastIndexParameters = parameters.length - 1;


            if (e.target.name === "headings" && lastIndexHeadings === index) {
                e.preventDefault();
                let newTestDetails = [...headings];
                newTestDetails.push({});
                setHeadings(newTestDetails);
            }

            else if (e.target.name === "parameters" && lastIndexParameters === index) {
                e.preventDefault();
                let newTestDetails = [...parameters];
                newTestDetails.push({});
                setParameters(newTestDetails);
            }
        }

    };

    const tableHeaders = [
        "Test Name", "SampleType", "Status"
    ]

    useEffect(() => {
        if (headings.length >= 0) return
        if (parameters.length >= 0) return
        setHeadings(prev => {
            let newArray = Array.from({ length: 5 - prev.length }, i => {
                return { heading: "" }
            })
            return [...prev, ...newArray]
        }
        )
        setParameters(prev => {
            let newArray = Array.from({ length: 5 - prev.length }, i => {
                return { name: "" }
            })
            return [...prev, ...newArray]
        }
        )

    }, [setHeadings, headings, parameters, setParameters])





    const handleInputChange = (value, index, field) => {

        const newBlend = structuredClone(labTestAnalyteDetails);
        newBlend[index][field] = value;

        setLabTestAnalyteDetails(newBlend);
    };




    const handleInputChangeParameter = (value, index, parameterIndex, field) => {


        const newBlend = structuredClone(labTestAnalyteDetails);

        newBlend[index]["labTestAnalyteDetailsParameters"][parameterIndex][field] = value;

        setLabTestAnalyteDetails(newBlend);
    };


    const handleOptionClick = (value) => {
        if (selectedOption === value) {

            setSelectedOption("");
        } else {

            setSelectedOption(value);
        }
    };

    const copyParameter = () => {
        setLabTestAnalyteDetails(prev => {
            const newBlend = structuredClone(labTestAnalyteDetails);
            return [...prev, ...newBlend.map(i => ({ ...i, id: null }))]
        })
    }



    const tableDataNames = ["dataObj.LabTest?.name", "dataObj.SampleDescriptionOne?.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!labTestGroupList || !labTestSubGroupList || !labTestNameList || !labTestMethodList || !sampleTypeTwoList || !sampleTypeList) return <Loader />

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[80%]"}>
                <TestParameterFormReport
                    onClick={(id) => { setId(id); setFormReport(false) }} />
            </Modal>
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onClone={() => { setIsClone(true); toast.success("Clone Successfull") }}
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    openReport={() => { setFormReport(true) }}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />

                <div className="flex-1 grid gap-x-2">
                    <div className="col-span-3 grid overflow-auto">
                        <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                            <div className={`${formReport ? "col-span-7" : "col-span-1"}`}>

                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Sub Test Info</legend>
                                    <div className='grid grid-cols-3'>
                                        {/* <DropdownInput autoFocus  name="Sample Category" options={Category} value={category} setValue={setCategory} required={true} readOnly={readOnly || childRecordExists} disabled={(childRecord.current > 0)} /> */}

                                        <DropdownInput name="SampleType 1" options={dropDownListObject(id ? sampleTypeList.data : sampleTypeList.data.filter(item => item.active), "name", "id")} value={sampleTypeId} setValue={setSampleTypeId} required={true} readOnly={(isClone ? false : id) || readOnly || childRecordExists} disabled={(childRecord.current > 0)} />

                                        <DropdownInput name="Test Group" options={dropDownListObject(id ? labTestGroupList.data : labTestGroupList.data.filter(item => item.active), "name", "id")} value={groupId} setValue={setGroupId} required={true} readOnly={(isClone ? false : id) || readOnly || childRecordExists} disabled={(childRecord.current > 0)} />

                                        <DropdownInput name="Test Sub Group"
                                            options={dropDownListObject((id ?
                                                labTestSubGroupList.data.filter(item => parseInt(groupId) === parseInt(item.groupId))
                                                : labTestSubGroupList.data.filter(item => parseInt(groupId) === parseInt(item.groupId)).filter(item => item.active)),
                                                "name", "id")} value={subGroupId} setValue={setSubGroupId} required={true} readOnly={(isClone ? false : id) || readOnly || childRecordExists} disabled={(childRecord.current > 0)} />
                                        <DropdownInput name="Test Name"
                                            options={dropDownListObject((id ?
                                                labTestNameList.data.filter(item => parseInt(subGroupId) === parseInt(item.subGroupId))
                                                : labTestNameList.data.filter(item => parseInt(subGroupId) === parseInt(item.subGroupId)).filter(item => item.active)),
                                                "name", "id")} value={testId} setValue={setTestId} required={true} readOnly={(isClone ? false : id) || readOnly || childRecordExists} disabled={(childRecord.current > 0)} />





                                        <CheckBox name="IsAccreditation" readOnly={readOnly || childRecordExists} value={isAccreditation} setValue={setIsAccreditation} />
                                        {/* <CheckBox name="IsDimensional" readOnly={readOnly || childRecordExists} value={isDimensional} setValue={setIsDimensional} /> */}


                                        {/* <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} /> */}


                                        <div className='flex gap-x-5 w-full'>
                                            <CheckBox name="Active" value={active} setValue={setActive} readOnly={readOnly} />
                                            {!active && (
                                                <div className='flex items-center justify-center text-xs'>
                                                    <label className=''>Reason</label>
                                                    <textarea
                                                        readOnly={readOnly}
                                                        className='border border-gray-400 w-[300px] ml-1 '
                                                        value={reason}
                                                        onChange={(e) => setReason(e.target.value)}
                                                    ></textarea>
                                                </div>

                                            )}
                                        </div>


                                    </div>
                                    <div className='grid grid-cols-2'>
                                        <MultiSelectDropdown name="Test Method"
                                            selected={methodList}
                                            setSelected={setMethodList}
                                            options={labTestMethodList.data ? isAccreditation ?
                                                multiSelectOption((id ? labTestMethodList?.data.filter(val => val.isAccredited).filter(item => item.labTestId == testId)
                                                    : labTestMethodList.data.filter(val => val.isAccredited).filter(item => item.labTestId == testId).filter(item => item.active)), "name", "id") : multiSelectOption((id ? labTestMethodList?.data.filter(val => !(val.isAccredited)).filter(item => item.labTestId == testId) : labTestMethodList.data.filter(val => !(val.isAccredited)).filter(item => item.labTestId == testId).filter(item => item.active)), "name", "id") : []}

                                            required={true} readOnly={readOnly || childRecordExists} disabled={(childRecord.current > 0)} />
                                    </div>
                                    <div className="flex justify-between text-xs mt-3">
                                        {CustomerClaimed.map((item, index) =>
                                            <div key={index}
                                                className=""
                                            >
                                                <input type="radio" name="claimed"
                                                    readOnly={readOnly || childRecordExists}
                                                    onClick={() => handleOptionClick(item.value)}
                                                    value={item.value}
                                                    checked={selectedOption === item.value}
                                                    className="text-xs" disabled={(isClone ? false : id) || childRecordExists || readOnly} />
                                                <label >{item.show}</label>
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className="flex justify-end relative top-0 right-0">
                                        <button className="text-xs bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1" onClick={() => setFormReport(prev => !prev)}>
                                            {formReport ? "Close Report >" : "Open Report <"}
                                        </button>
                                    </div> */}
                                </fieldset>


                                <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>

                                    <div className="flex justify-end relative top-2 right-5 gap-x-7">

                                        <button className="text-xs bg-green-500 hover:text-white font-semibold hover:bg-sky-800 transition rounded p-1"
                                            disabled={readOnly || childRecordExists}
                                            onClick={() => copyParameter()}>
                                            Copy Parameters
                                        </button>
                                    </div>

                                    <fieldset className={`frame my-1 grid grid-cols-1 ${(childRecordExists || readOnly) ? "pointer-events-none" : ""}`}>

                                        <legend className='sub-heading'>Sub Test Info</legend>

                                        <div className=' grid grid-cols-1'>

                                            <table className="border border-gray-500">
                                                <thead className="border border-gray-500">
                                                    <tr className="">
                                                        <th className="border border-gray-500 text-sm p-1">S.No </th>
                                                        <th className="border border-gray-500 text-sm p-1">Sample Material </th>
                                                        <th className="border border-gray-500 text-sm p-1">Edit</th>
                                                        <th className="border border-gray-500 text-sm p-1">Result</th>
                                                        <th className="border border-gray-500 text-sm p-1">Report</th>
                                                        <th className="border border-gray-500 text-sm p-1">Text Based</th>

                                                        <th className="border border-gray-500 text-sm p-1">Parameters </th>
                                                        {
                                                            selectedOption == "CLAIMEDFABRICCOUNT" &&

                                                            <th className="border border-gray-500 text-sm p-0.5">Wales/Course</th>


                                                        }

                                                        <th className="border border-gray-500 text-sm p-1">Dec.Val</th>
                                                        <th className="border border-gray-500 text-sm p-1">Value </th>
                                                        <th className="border border-gray-500 text-sm p-1" colSpan={2}>
                                                            <button type='button' className="text-green-700" onClick={() => {
                                                                setLabTestAnalyteDetails(prev => {
                                                                    let newPrev = structuredClone(prev);

                                                                    newPrev.push({

                                                                        heading: "",

                                                                        labTestAnalyteDetailsParameters: [
                                                                            {

                                                                                sampleTypeTwoId: "",
                                                                                isEditable: false,
                                                                                isResult: false,
                                                                                isTextBased: false,
                                                                                name: "",
                                                                                decimalValue: 0,
                                                                                value: ""

                                                                            }
                                                                        ]
                                                                    })
                                                                    return newPrev

                                                                })

                                                            }}
                                                                disabled={childRecordExists || readOnly}
                                                            > {<FontAwesomeIcon icon={faUserPlus} />}
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(labTestAnalyteDetails ? labTestAnalyteDetails : []).map((item, index) =>
                                                        <ParameterHeading childRecordExists={childRecordExists} index={index} selectedOption={selectedOption}
                                                            onKeyDown={onKeyDown} item={item} handleInputChange={handleInputChange} readOnly={readOnly || childRecordExists}
                                                            childRecord={childRecord} setLabTestAnalyteDetails={setLabTestAnalyteDetails} handleInputChangeParameter={handleInputChangeParameter} id={id} sampleTypeTwoList={sampleTypeTwoList}
                                                        />
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            {/* <div className={`frame h-full overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-5`}>

                                <FormReport
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    setId={setId}
                                    tableHeaders={tableHeaders}
                                    tableDataNames={tableDataNames}
                                    data={allData?.data}
                                    loading={
                                        isLoading || isFetching
                                    }
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
