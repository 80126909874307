import React, {useState} from 'react'
import GreyYarn from './GreyYarn'

const RawMaterial = ({ rawMaterialType,  }) => {
  const [rawMaterials, setRawMaterials] = useState([])
  return (
    <div>
      {rawMaterialType === "GY"
        ?
        <GreyYarn rawMaterials={rawMaterials} setRawMaterials={setRawMaterials} />
        :
        <></>
      }
    </div>
  )
}

export default RawMaterial