import React, { useEffect, useState } from 'react'
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';

import { toast } from 'react-toastify';
import InvoiceDocument from '../Document-InvoiceReport';
import { useGetRegistrationByIdQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { Loader } from '../../../Basic/components';
import { handleMailSend } from '../../../Utils/helper';
import logo from "../../../../src/assets/srgmaillogo.png"

const InvoiceReportDocument = ({ id }) => {





    const [testDetailsData, setTestDetailsData] = useState([])


    const { data } = useGetRegistrationByIdQuery({ id: parseInt(id), params: { invoice: true } }, { skip: !id })
    const [packageDiscount, setPackageDiscount] = useState()
    const [update] = useUpdateRegistrationMutation()


    useEffect(() => {
        setPackageDiscount(data?.data?.packageDetails[0]?.discount ? data?.data?.packageDetails[0]?.discount : 0)
    }, [data, setPackageDiscount])

    // const handleSave = async (text) => {
    //     try {
    //         let returnData = await update({ registrationId: id, executiveSummaryUpdate: true, invoiceTestDetails: testDetailsData ?testDetailsData:[] ,discount:packageDiscount}).unwrap();
    //         toast.success(text + "Successfully");
    //     } catch (error) {
    //         console.log("handle");
    //     }

    // }
    // function handleInputChange(value, index, field) {
    //     setTestDetailsData(prev => {
    //         let newData = structuredClone(prev);
    //         newData[index][field] = value;
    //         return newData
    //     })
    // }

    useEffect(() => {
        if (!data?.data) return
        let testData;
        if (data?.data?.invoiceTestDetails.length > 0) {
            testData = data?.data?.invoiceTestDetails
        }
        else {
            testData = data?.data?.testDetails
        }

        setTestDetailsData(testData)
    }, [data, setTestDetailsData])



    // const handleDownload = () => {
    //     // You can use the Blob constructor to create a new Blob object
    //     const blob = new Blob([pdfBlob], { type: 'application/pdf' });
    //     const file = new File([blob], 'document.pdf', { type: 'application/pdf' });
    //     const form = new FormData();
    //     form.append("file", file);
    //     fetch(`${process.env.REACT_APP_SERVER_URL}sendMail`, {
    //         method: "POST",
    //         body: form
    //     }).then(res => {
    //         toast.success("Mail Send SuccessFully");
    //     }).catch(err => {
    //         toast.error("Please Resend !")
    //     });
    // };

    // let companyEmail =data?.data?.toCompany?.contactDetails[0]?.email
    let companyEmail = data?.data?.email ? data?.data?.email : ""

    if (!data?.data) {
        return <Loader />
    }

    return (
        <div>{console.log(data, 'datainvoice', companyEmail)}
            <BlobProvider document={<InvoiceDocument data={data} testDetailsData={testDetailsData} />} fileName="my_pdf3.pdf">
                {({ blob }) => {


                    return <button className='bg-green-400 p-1 rounded-md mb-4' onClick={() => handleMailSend(blob, companyEmail, `${"Invoice.No:"}${data?.data?.invoiceNo || ""}/${data?.data?.testReportNo || ""}/${data?.data?.styleNo || ""}`, data?.data?.testReportNo, logo, "InvoiceDocument")}>Send Mail</button>;
                }}
            </BlobProvider>
            <PDFViewer className='w-full h-screen'>
                <InvoiceDocument data={data} testDetailsData={testDetailsData} />
            </PDFViewer>




        </div>
    )
}

export default InvoiceReportDocument