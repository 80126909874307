import React, { useEffect, useState } from 'react'
import { useGetYarnMasterQuery } from "../../../redux/ErpServices/YarnMasterServices";
import { useGetAccessoryMasterQuery } from "../../../redux/ErpServices/AccessoryMasterServices";
import { useGetAccessoryItemMasterQuery } from "../../../redux/ErpServices/AccessoryItemMasterServices";
import { useGetAccessoryGroupMasterQuery } from "../../../redux/ErpServices/AccessoryGroupMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import secureLocalStorage from 'react-secure-storage';
import { Loader } from '../../../Basic/components';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { findFromList, getDateFromDateTime } from '../../../Utils/helper';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';


const AccessoryPoItemSelection = ({ poItems, transtype }) => {
    const [localItems, setLocalItems] = useState([]);

    const [searchFilter, setSearchFilter] = useState([]);

    // useEffect(() => {
    //     let fullList = []
    //     poItems.forEach((poItem) => {
    //         poItem.poItems.forEach((item) => {
    //             console.log(item)
    //             fullList.push({ ...item, poNo: poItem.id, poDate: poItem.createdAt, dueDate: poItem.dueDate, poItemId: item.poItemId })
    //         })
    //     })
    //     setLocalItems(fullList)
    // }, [poItems, setLocalItems, searchFilter])

    function filterRecords(localItems) {
        let newLocalItems = [...localItems]
        newLocalItems = newLocalItems.filter(item => {
            for (let filter of searchFilter) {
                let fieldName = filter.fieldName
                let searchValue = filter.searchValue
                let objTransformFunc = filter.objTransformFunc
                let itemObj = objTransformFunc(item[fieldName])
                if (!(`${itemObj}`.toLowerCase().includes(searchValue.toLowerCase()))) return false
            }
            return true
        })
        return newLocalItems
    }

    // useEffect(() => {
    //     setLocalItems(prev => filterRecords(prev))
    // }, [setLocalItems, searchFilter, poItems])


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const { data: accessoryList, isLoading: accessoryListLoading, isFetching: accessoryListFetching } = useGetAccessoryMasterQuery({ companyId })
    const { data: uomList, isLoading: uomLoading, isFetching: uomFetching } = useGetUnitOfMeasurementMasterQuery({ companyId })
    const { data: colorList, isLoading: colorLoading, isFetching: colorFetching } = useGetColorMasterQuery({ companyId })
    const { data: sizeList, isLoading: sizeLoading, isFetching: sizeFetching } = useGetSizeMasterQuery({ companyId })
    const { data: accessoryGroupList, isLoading: accessoryGroupListLoading, isFetching: accessoryGroupListFetching } = useGetAccessoryGroupMasterQuery({ companyId })
    const { data: accessoryItemList, isLoading: accessoryItemListLoading, isFetching: accessoryItemListFetching } = useGetAccessoryItemMasterQuery({ companyId })



    function changeSearchFilter(field, value, objTransformFunc) {
        setSearchFilter(prev => {
            if (!value) {
                return prev.filter(item => item.fieldName !== field)
            }
            let newSearchFilter = [...prev]
            let index = newSearchFilter.findIndex(item => item.fieldName === field)
            if (index !== -1) {
                newSearchFilter[index]["searchValue"] = value
            } else {
                newSearchFilter.push({ fieldName: field, searchValue: value, objTransformFunc });
            }
            return newSearchFilter
        })
    }

    // function findAccessoryItemName(id) {
    //     if (!accessoryList) return 0
    //     let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
    //     return acc ? acc.accessoryItem.name : null
    // }


    // function findAccessoryGroupName(id) {
    //     if (!accessoryList) return 0
    //     let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
    //     return acc ? acc.accessoryItem.AccessoryGroup.name : null
    // }



    let count = 1;
    if (accessoryListLoading || accessoryListFetching || uomFetching || uomLoading || colorFetching || colorLoading || sizeLoading || sizeFetching || accessoryGroupListLoading || accessoryGroupListFetching || accessoryItemListLoading || accessoryItemListFetching) {
        return <tr>
            <td>
                <Loader />
            </td>
        </tr>
    }
    return (
        <div className='border border-r-black border-t-black border-l-black bg-gray-200'>
            <table className='table-fixed w-full text-xs'>{console.log(accessoryList, "accessory list")}
                <thead>
                    <tr className='bg-blue-200 border border-b-black py-2 sticky top-10'>
                        
                        <th className='text-center border border-r-black border-b-black border-l-black p-1'>
                            S.no
                        </th>
                        <th className='text-center border border-r-black border-b-black p-1'>
                            Po.no
                        </th>
                        <th className='text-center border border-r-black border-b-black p-1'>
                            Item Type
                        </th>
                        <th className='border border-r-black border-b-black p-1'>
                            Accessory Name
                        </th>
                        {/* <th className='border border-r-black border-b-black p-1'>
                            Accessory Items
                        </th>
                        <th className='border border-r-black border-b-black p-1'>
                            Accessory Group
                        </th> */}
                        <th className='border border-r-black border-b-black p-1'>
                            Color
                        </th>
                        <th className='border border-r-black border-b-black p-1'>
                            Size
                        </th>
                        <th className='border border-r-black border-b-black p-1'>
                            Uom
                        </th>
                        <th className='border border-r-black border-b-black p-1'>
                            Po qty
                        </th>
                      
                        <th className='border border-r-black border-b-black p-1'>
                            Price
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr className='py-2 h-5 sticky top-20 bg-gray-200'>{console.log(localItems, "localitems")}
                    
                      
                        <td className='text-center border border-r-black border-b-black shadow-xl'>
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("poNo", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                        </td>
                      
                       
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("accessoryId", e.target.value, (item) => findFromList(item, accessoryList.data, "aliasName"))
                                }} />
                        </td>

                        {/* <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("accessoryId", e.target.value, (item) => findAccessoryItemName(item))
                                }} />
                        </td>

                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("accessoryId", e.target.value, (item) => findAccessoryGroupName(item))
                                }} />
                        </td> */}
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("colorId", e.target.value, (item) => findFromList(item, colorList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("sizeId", e.target.value, (item) => findFromList(item, sizeList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("uomId", e.target.value, (item) => findFromList(item, uomList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                        </td>
                        <td className='text-center  border border-r-black border-b-black shadow-xl'>
                        </td>
                      
                    </tr>
                    {poItems.map((item, index) =>
                        <tr key={index} className='py-2'>
                           
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {count++}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {item.id}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {transtype}
                            </td>

                          
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.accessoryId, accessoryList.data, "aliasName")}
                            </td>
                            {/* <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findAccessoryItemName(item.accessoryId)}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findAccessoryGroupName(item.accessoryId)}
                            </td> */}
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.colorId, colorList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.sizeId, sizeList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {findFromList(item.itemDetails.uomId, uomList.data, "name")}
                            </td>
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {item.qty}
                            </td>
                           
                            <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                {item.price}
                            </td>
                          
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>

    )
}

export default AccessoryPoItemSelection;