import React, { useEffect, useState } from 'react'
import TestSelectionPanel from './TestSelectionPanel'
import SampleTab from "./SampleTab"
import PackageTestStatusTable from './PackageTestStatusTable';
import AdditionalTestStatusTable from './AdditionalTestStatusTable';
import WashCareSelectionSampleWise from './WashCareSelectionSampleWise';
import SampleImageUpload from './SampleImageUpload';
import Modal from '../../../UiComponents/Modal';
import SampleTypeSelectionSampleWise from './SampleTypeSelectionSampleWise';
import ClaimedDetailsSampleWise from './ClaimedDetailsSampleWise';

const TestAllocation = ({ sampleDetails, setSampleDetails, packageId, id, buyerId, specialInstruction, isResultEntryStarted }) => {
  const [isSampleWashCareOpen, setIsSampleWashCareOpen] = useState(false);
  const [isSampleTypeOpen, setIsSampleTypeOpen] = useState(false)
  const [isClaimedDetailsOpen, setIsClaimedDetailsOpen] = useState(false)
  const [isSampleImageOpen, setIsSampleImageOpen] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState(null);
  let index = sampleDetails.findIndex(item => item.sampleId === currentActiveTab)

  useEffect(() => {
    if (sampleDetails.length === 0) return
    const currentTabIndex = sampleDetails.findIndex(item => item.sampleId === currentActiveTab)
    if (currentTabIndex === -1) {
      setCurrentActiveTab(sampleDetails[0].sampleId)
    }
  }, [sampleDetails, currentActiveTab])

  function removeTest(testId) {
    setSampleDetails(prevSampleDetails => {
      const newSampleDetails = structuredClone(prevSampleDetails);
      newSampleDetails[index]["SampleTestDetails"] = newSampleDetails[index]["SampleTestDetails"].filter(test => !(parseInt(test.testId) === parseInt(testId)))
      return newSampleDetails

    })
  }

  return (
    <>
      <Modal isOpen={isSampleTypeOpen} onClose={() => setIsSampleTypeOpen(false)} widthClass={"p-9 h-[30%] w-[60%] overflow-auto bg-white"}>
        <SampleTypeSelectionSampleWise isResultEntryStarted={isResultEntryStarted} setIsSampleTypeOpen={setIsSampleTypeOpen} sampleDetails={sampleDetails} setSampleDetails={setSampleDetails} currentActiveTab={currentActiveTab} />
      </Modal>
      <Modal isOpen={isClaimedDetailsOpen} onClose={() => setIsClaimedDetailsOpen(false)} widthClass={"p-9 h-[60%] w-[95%] overflow-auto bg-white"}>
        <ClaimedDetailsSampleWise setIsClaimedDetailsOpen={setIsClaimedDetailsOpen} sampleDetails={sampleDetails} setSampleDetails={setSampleDetails} currentActiveTab={currentActiveTab} />
      </Modal>
      <Modal isOpen={isSampleWashCareOpen} onClose={() => setIsSampleWashCareOpen(false)} widthClass={"p-8 h-[80%] w-[80%] overflow-auto bg-white"}>
        <WashCareSelectionSampleWise setIsSampleWashCareOpen={setIsSampleWashCareOpen} specialInstruction={specialInstruction} sampleDetails={sampleDetails} setSampleDetails={setSampleDetails} currentActiveTab={currentActiveTab} />
      </Modal>
      <Modal isOpen={isSampleImageOpen} onClose={() => setIsSampleImageOpen(false)} widthClass={"p-8 overflow-auto bg-white"}>
        <SampleImageUpload currentActiveTab={currentActiveTab} sampleDetails={sampleDetails} />
      </Modal>
      <fieldset id='piecePerSample' className="border-2 border-black rounded px-3 mt-3 text-xs overflow-auto min-h-full">
        <legend className="sub-heading">Piece per Sample</legend>
        <div className="grid grid-cols-5 gap-3">
          <div className='flex flex-col mt-2'>
            <TestSelectionPanel removeTest={removeTest} packageId={packageId} sampleDetails={sampleDetails} setSampleDetails={setSampleDetails} currentActiveTab={currentActiveTab} />
          </div>
          <div className='col-span-4'>
            <div className='flex text-xs overflow-auto w-full '>
              {sampleDetails.map((sample, index) => (
                <SampleTab key={index} label={sample.sampleId} isActive={currentActiveTab === sample.sampleId} onClick={() => setCurrentActiveTab(sample.sampleId)} />
              ))}
            </div>
            <div className='flex w-full justify-end gap-5 mt-3'>
              <button className='bg-blue-400 rounded-lg p-1' onClick={() => { setIsClaimedDetailsOpen(true) }}>Claimed Details</button>

              <button className='bg-blue-400 rounded-lg p-1' onClick={() => { setIsSampleTypeOpen(true) }}>Sample Type</button>

              <button className='bg-blue-400 rounded-lg p-1' onClick={() => { setIsSampleWashCareOpen(true) }}>Wash Care</button>
              <button className='bg-blue-400 rounded-lg p-1' onClick={() => { setIsSampleImageOpen(true) }}>Sample Image</button>
            </div>
            <PackageTestStatusTable buyerId={buyerId} removeTest={removeTest} sampleDetails={sampleDetails} setSampleDetails={setSampleDetails} currentActiveTab={currentActiveTab} />
            <AdditionalTestStatusTable buyerId={buyerId} removeTest={removeTest} sampleDetails={sampleDetails} setSampleDetails={setSampleDetails} currentActiveTab={currentActiveTab} />
          </div>
        </div>
      </fieldset>
    </>
  )
}

export default TestAllocation
