import React from 'react'
import { Text, View } from '@react-pdf/renderer'


const ReqValue = ({ testIndex, test }) => {
  return (

    <Text>
      {test?.formulaAliasName ? test?.formulaAliasName.toLowerCase() : ""}
    </Text>
  )
}


export default ReqValue