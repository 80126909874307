import React from 'react'
import FailureImages from './FailureImages'
import { Text, View } from '@react-pdf/renderer'
import tw from '../../../../Utils/tailwind-react-pdf'

const TestwiseFailureImages = ({ item, index, reportData }) => {
  return (
    <View style={tw('ml-2 mt-5 w-full pl-9')}>
      {
        item?.failureImages?.length > 0 ?
          <>
            <Text style={[tw(' mt-4 uppercase underline font-bold'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>
              Failure Images :
            </Text>
            <View style={tw(' mt-3 w-full ')}>
              <Text style={tw('font-bold underline text-left')}>
                {item?.testName}
              </Text>
              {/* <View style={tw('flex flex-row  pr-3  mt-1')}>
                <View style={tw('text-xs w-[20%]')}>
                  <Text>{item?.methodName}</Text>

                </View>
                <View style={tw('text-xs  w-[80%]')}>
                  <Text style={tw('ml-2 capitalize')}>({item?.conditionName})</Text>

                </View>
              </View> */}
            </View>

            <View style={tw(' uppercase font-bold flex flex-row flex-wrap justify-center  mt-1 gap-x-2 ')}>
              {item?.failureImages?.map((img, imgIndex) => {
                return <FailureImages key={imgIndex} img={img} item={item} imgIndex={imgIndex} reportData={reportData} />
              })}
            </View>
          </>
          : ""

      }
    </View>
  )
}

export default TestwiseFailureImages