import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { WASH_CARE_API} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const WashCareMasterApi = createApi({
  reducerPath: "washCareMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["WashCareMaster"],
  endpoints: (builder) => ({
    getWashCareMaster: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: WASH_CARE_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: WASH_CARE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["WashCareMaster"],
    }),
    getWashCareMasterById: builder.query({
      query: (id) => {
        return {
          url: `${WASH_CARE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["WashCareMaster"],
    }),
    addWashCareMaster: builder.mutation({
      query: (payload) => ({
        url: WASH_CARE_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["WashCareMaster"],
    }),
    updateWashCareMaster: builder.mutation({
      query: ({id, body}) => {
        return {
          url: `${WASH_CARE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["WashCareMaster"],
    }),
    deleteWashCareMaster: builder.mutation({
      query: (id) => ({
        url: `${WASH_CARE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["WashCareMaster"],
    }),
  }),
});

export const {
  useGetWashCareMasterQuery,
  useGetWashCareMasterByIdQuery,
  useAddWashCareMasterMutation,
  useUpdateWashCareMasterMutation,
  useDeleteWashCareMasterMutation,
} = WashCareMasterApi;

export default WashCareMasterApi;
