import React from 'react'
import PrintSheet from './PrintSheet'

import FactoryAddress from '../PrintFormat-TestReport/FactoryAddress'
import Header from "./Header";

const Document = ({testList,methodList,data,componentRef,washCareData}) => {
  return (
    <div className='font-times' >
        return (
        <div id='print' className="w-full bg-white shadow-md p-5 rounded-md grid grid-cols-1 place-content-center" ref={componentRef}>
            <table>
            <thead>
                <tr>
                    <td>
                        <div class="page-header pl-5 pt-1.5">
                <Header />
           
            <br />

            </div>
            <div className="page-header-space">
            </div>
            </td>
            </tr>
            </thead>

            <tbody>
            <tr>
            <td>
      <PrintSheet data={data} washCareData={washCareData} testList={testList} methodList={methodList} />      
      </td>
                </tr>
            </tbody>

            {/* <tfoot>
                <tr>
                    <td>
                        <footer></footer>
                        <div className="page-footer p-3">
                            <FactoryAddress />
                        </div>
                        <div className="page-footer-space">
                        </div>
                    </td>
                </tr>
            </tfoot> */}

        </table>
        </div>
    )
    </div>
  )
}

export default Document