import React, { useEffect, useRef, useState } from 'react'
import { CheckBox, DisabledInput, TextArea, TextInput } from '../../../Inputs'
import secureLocalStorage from 'react-secure-storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faUserPlus } from '@fortawesome/free-solid-svg-icons';

const NewBranch = ({ setBranchForm, setBranchDetails, branchDetails, currentSelectedBranchIndex, setCurrentSelectedBranchIndex, childRecord = { current: 0 } }) => {
    const [readOnly, setReadOnly] = useState(false);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [contactDetails, setContactDetails] = useState([]);
    const [isInvoice, setIsInvoice] = useState(false);
    const [isReport, setIsReport] = useState(false);

    useEffect(() => {
        if (!Number.isInteger(currentSelectedBranchIndex)) return
        
        let currentData = branchDetails[currentSelectedBranchIndex];
        setName(currentData.name);
        setAddress(currentData.address);
        setContactDetails(currentData.contactDetails);
        setIsInvoice(currentData.isInvoice);
        setIsReport(currentData.isReport);
    }, [currentSelectedBranchIndex])

    function removeItem(currentIndex) {
        setContactDetails(contactDetails => {
            return contactDetails.filter((_, index) => index !== currentIndex)
        });
    }
    const submitData = (e) => {
        e.preventDefault();
        let branchObj = {
            name, address, contactDetails, isInvoice, isReport, isHo: false
        }
        if (Number.isInteger(currentSelectedBranchIndex)) {
            setBranchDetails(prevBranch => {
                let newBranch = structuredClone(prevBranch);
                newBranch[currentSelectedBranchIndex] = branchObj;
                return newBranch
            })
        } else {
            setBranchDetails(prevBranch => {
                let newBranch = structuredClone(prevBranch);
                newBranch?.push(branchObj);
                return newBranch
            })
        }
        setName("");
        setAddress("");
        setContactDetails("");
        setIsInvoice(false);
        setIsReport(false);
        setBranchForm(false);
        setCurrentSelectedBranchIndex("");
    }

    return (
        <div>{console.log(Number.isInteger(currentSelectedBranchIndex),"currentSelectedBranchIndex")}
            <h1 className='text-center text-blue-800 font-bold'>Branch Info</h1>
            <form onSubmit={submitData}>
                <div className='col-span-3 grid md:grid-cols-1 border overflow-auto'>
                    <div className='mr-1 md:ml-2'>
                        <div className='flex'>
                            <CheckBox name="Invoice To" readOnly={readOnly} value={isInvoice} setValue={setIsInvoice} />
                            <CheckBox name="Report To" readOnly={readOnly} value={isReport} setValue={setIsReport} />
                        </div>
                        <div className='grid grid-cols-2 my-2'>
                            <TextInput name="Branch Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                            <TextArea name="Address" type="text" value={address} setValue={setAddress} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                        </div>
                        <fieldset className='my-1 row-span-2 overflow-auto'>
                            <legend className='sub-heading'>Contact Person Details</legend>
                            <div className='grid grid-cols-1 gap-2 my-2'>
                                <table className="border border-gray-500">
                                    <thead className="border border-gray-500">
                                        <tr>
                                            <th className="border border-gray-500 text-sm">S.no</th>
                                            <th className="border border-gray-500 text-sm">Contact Person Name</th>
                                            <th className="border border-gray-500 text-sm">Mobile.no</th>
                                            <th className="border border-gray-500 text-sm">Tel.Phone</th>
                                            <th className="border border-gray-500 text-sm" >Email Id</th>
                                            <th>
                                                <button type='button' className="text-green-700" onClick={() => {
                                                    let newContactDetails = [...contactDetails];
                                                    newContactDetails.push({});
                                                    setContactDetails(newContactDetails);
                                                }}> {<FontAwesomeIcon icon={faUserPlus} />}
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contactDetails.map((item, index) =>
                                            <tr className="text-center" key={index}>

                                                <td className="border border-gray-500 text-xs">
                                                    {index + 1}
                                                </td>
                                                <td className="border border-gray-500 text-xs">
                                                    <input className="w-full p-1"
                                                        type="text" value={item.contactPersonName} onChange={(e) => {
                                                            const newContactDetails = structuredClone(contactDetails)
                                                            newContactDetails[index]["contactPersonName"] = e.target.value;
                                                            setContactDetails(newContactDetails);
                                                        }} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} caseSensitive />

                                                </td>
                                                <td className="border border-gray-500 text-xs">
                                                    <input className="w-full p-1" type="text" value={item.mobileNo} onChange={(e) => {
                                                        const newContactDetails = structuredClone(contactDetails);
                                                        newContactDetails[index]["mobileNo"] = e.target.value;
                                                        setContactDetails(newContactDetails);
                                                    }} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />

                                                </td>
                                                <td className="border border-gray-500 text-xs">
                                                    <input className="w-full p-1" type="text" value={item.phone} onChange={(e) => {
                                                        const newContactDetails = structuredClone(contactDetails);
                                                        newContactDetails[index]["phone"] = e.target.value;
                                                        setContactDetails(newContactDetails);
                                                    }} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />

                                                </td>
                                                <td className="border border-gray-500 text-xs">
                                                    <input className="w-full p-1" type="text" value={item.email} onChange={(e) => {
                                                        const newContactDetails = structuredClone(contactDetails);
                                                        newContactDetails[index]["email"] = e.target.value;
                                                        setContactDetails(newContactDetails);
                                                    }} readOnly={readOnly} />

                                                </td>
                                                <td className="border border-gray-500 p-1 text-xs">
                                                    <button
                                                        type='button'
                                                        onClick={() => removeItem(index)}
                                                        className='text-md text-red-600 ml-1'>{<FontAwesomeIcon icon={faTrashCan} />}</button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </fieldset>

                    </div>
                </div>
                <div className=" grid justify-center">
                    <button
                        onKeyDown={e => {
                            if (e.code === "Enter") {

                            }

                        }}
                        className='bg-green-400 rounded-lg p-1 w-32' >Done
                    </button>
                </div>
            </form>


        </div>
    )
}

export default NewBranch