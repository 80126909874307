import React from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices"
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetRegistrationQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { useGetPackageMasterQuery } from '../../../redux/LabServices/PackageMasterServices';
import { useGetLabTestResultEntryQuery } from '../../../redux/LabServices/LabTestResultEntryServices';
import { Modal } from '../../../Inputs';
import TestReportContainerPreview from '../DashBoard/TestReportContainerPreview';
import { BACKWARD_ICON_PDF, VIEW } from '../../../icons';
import { SIGNOUT_ICON_PDF } from '../../../icons';
import { toast } from 'react-toastify';
import TestReportDocument from '../DashBoard/TestReportContainerPreviewReactPdf';
import TestReportRevert from '../AdminResultEntry/TestReportRevert';


const RegistrationFormReport = ({
  heading = "Final Report Verification",
  dataClick
}) => {
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const [searchDocId, setSearchDocId] = useState("");
  const [searchRegDate, setSearchRegDate] = useState("");
  const [searchDueDate, setSearchDueDate] = useState("");
  const [searchSupplierName, setSearchSupplierName] = useState("");
  const [searchBuyerName, setSearchBuyerName] = useState("");
  const [searchPackageName, setSearchPackageName] = useState("");
  const [dataPerPage, setDataPerPage] = useState("10");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [sampleId, setSampleId] = useState("")
  const [testReport, setTestReport] = useState(false);
  const [activeRegistrationId, setActiveRegistrationId] = useState("")
  const [passFail, setPassFail] = useState("ALL");
  const [splitTest, setSplitTest] = useState("ALL");
  const [headingOptions, setHeadingOptions] = useState("")
  const [revert, setRevert] = useState(false)


  const handleOnclick = (e) => {
    setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
  }
  const searchFields = { searchDocId, searchRegDate, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName }

  useEffect(() => { setCurrentPageNumber(1) }, [dataPerPage, searchDocId, searchRegDate, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName])

  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = {
    branchId, companyId
  };

  const { data: allData, isLoading, isFetching } = useGetRegistrationQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, isFinal: true } });
  const { data: packageList, isLoading: isPackageLoading, isFetching: isPackageFetching } = useGetPackageMasterQuery({ params })
  const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
    useGetPartyQuery({ params: { ...params } });


  const [updateData] = useUpdateRegistrationMutation();

  useEffect(() => {
    if (allData?.totalCount) {
      setTotalCount(allData?.totalCount)
    }
  }, [allData, isLoading, isFetching])


  useEffect(() => {
    let registrationFormReportComponent = document.getElementById("registrationFormReport");
    registrationFormReportComponent.addEventListener('keydown', function (ev) {
      var focusableElementsString = '[tabindex="0"]';
      let ol = document.querySelectorAll(focusableElementsString);
      if (ev.key === "ArrowDown") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      } else if (ev.key === "ArrowUp") {
        for (let i = 0; i < ol.length; i++) {
          if (ol[i] === ev.target) {
            let o = ol[i - 1];
            o.focus(); break;
          }
        }
        ev.preventDefault();
      }
    });
    return () => {
      registrationFormReportComponent.removeEventListener('keydown', () => { });
    };
  }, []);


  const handleUpdateRegistration = async (callback, registerdata, text, isDataClear = false) => {
    try {
      await callback(registerdata).unwrap();
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };



  const saveData = (registrationId, field, isDataClear = false) => {

    if (field == "isRevert") {
      if (!window.confirm("Are you sure Revert the Results ...?")) {
        return;
      }

      handleUpdateRegistration(updateData, {

        registrationId: registrationId, isFinalReportVerification: false, isFinalReview: true,
      }, "  Revert To Admin  ", isDataClear);
    }
    else {
      if (!window.confirm("Are you sure Move the Dashboard ...?")) {
        return;
      }

      handleUpdateRegistration(updateData, {

        registrationId: registrationId, isRegistrationCompleted: true, isRegisterCompleted: true,
      }, " Move To DashBoard  ", isDataClear);
    }



  };

  const isLoadingIndicator = isLoading || isFetching || isSupplierFetching || isSupplierLoading || isPackageFetching || isPackageLoading


  return (
    <>
      <Modal isOpen={testReport} onClose={() => setTestReport(false)} widthClass={"w-[90%] h-[90%]  bg-white p-1"} >

        <TestReportContainerPreview onClose={() => setTestReport(false)} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId}
          passFail={passFail} setPassFail={setPassFail} allData={allData?.data} isPreview={true} />


        {/* <TestReportDocument headingOptions={headingOptions}
          splitTest={splitTest} setSplitTest={setSplitTest}
          activeRegistrationId={activeRegistrationId} passFail={passFail}
          setPassFail={setPassFail} /> */}



      </Modal>
      <Modal isOpen={revert} onClose={() => setRevert(false)} widthClass={"w-[50%] h-[40%]  bg-white p-1"} >



        <TestReportRevert onClose={() => setRevert(false)} activeRegistrationId={activeRegistrationId}
          allData={allData?.data} setRevert={setRevert}
        />
      </Modal>
      <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto">
        <div className="md:flex md:items-center md:justify-between page-heading p-1">
          <div className="heading text-center md:mx-10">{heading}</div>
          <div className=" sub-heading justify-center md:justify-start items-center">
            <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
            <select value={dataPerPage}
              onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
              {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
            </select>
          </div>
        </div>
        <>
          <div
            className="h-[500px] overflow-auto"
          >
            <table className="table-fixed text-center w-full">
              <thead className="border-2 table-header">
                <tr className='h-2'>
                  <th
                    className="border-2  top-0 stick-bg w-10"
                  >
                    S. no.
                  </th>
                  <th
                    className="border-2  top-0 stick-bg flex flex-col"
                  >
                    <div>Reg. No</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchDocId}
                      onChange={(e) => {
                        setSearchDocId(e.target.value);
                      }}
                    />
                  </th>

                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Reg. Date</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchRegDate}
                      onChange={(e) => {
                        setSearchRegDate(e.target.value);
                      }}
                    />
                  </th>
                  <th

                    className="border-2  top-0 stick-bg flex flex-col"
                  >
                    <div>Supplier</div><input
                      type="text"
                      className="text-black  h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchSupplierName}
                      onChange={(e) => {
                        setSearchSupplierName(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Due Date</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchDueDate}
                      onChange={(e) => {
                        setSearchDueDate(e.target.value);
                      }}
                    />
                  </th>
                  <th
                    className="border-2  top-0 stick-bg"
                  >
                    <div>Buyer</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchBuyerName}
                      onChange={(e) => {
                        setSearchBuyerName(e.target.value);
                      }}
                    />
                  </th>
                  <th className="border-2  top-0 stick-bg">
                    <div>Package</div><input
                      type="text"
                      className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                      placeholder="Search"
                      value={searchPackageName}
                      onChange={(e) => {
                        setSearchPackageName(e.target.value);
                      }}
                    />
                  </th>
                  <th className="border-2  top-0 stick-bg w-16">
                    Preview

                  </th>
                  <th className='w-12 border-2  top-0 stick-bg'>
                    Revert
                  </th>
                  <th className='w-12 border-2  top-0 stick-bg'>
                    Log Out
                  </th>
                </tr>
              </thead>
              {isLoadingIndicator ?
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
                :
                <tbody className="border-2">
                  {(allData?.data || []).map((dataObj, index) => (
                    <tr
                      key={dataObj.id}
                      className="border-2 table-row cursor-pointer"                    >
                      <td className='py-1'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                      <td className='py-1'> {dataObj.docId}</td>
                      {/* <td className='border text-xs text-center p-1'  >
                      {(dataObj.RegistrationSampleDetails ? dataObj.RegistrationSampleDetails : []).filter(val=>filteredList?.find(i=>parseInt(i.id)===parseInt(val.id))).map((item, itemIndex) =>
                        <div key={item.id} onClick={() => dataClick(item.id, dataObj.id)} className='p-1 bg-white hover:bg-green-300'>
                          {item.sampleId}
                        </div>
                      )}
                    </td> */}
                      <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj.createdAt)} </td>
                      <td className='py-1'>{findFromList(dataObj.supplierId, supplierList.data, "name")}</td>
                      <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj.dueDate)}</td>
                      <td className='py-1'>{findFromList(dataObj.buyerId, supplierList.data, "name")}
                      </td>
                      <td className='py-1'>{findFromList(dataObj.packageId, packageList.data, "name")} </td>
                      <td className='py-1 text-center' onClick={() => { setActiveRegistrationId(dataObj.id); setTestReport(true) }}>

                        {VIEW}


                      </td>
                      <td className='py-1 text-center' onClick={() => { setActiveRegistrationId(dataObj.id); setRevert(true); saveData(dataObj.id, "isRevert") }}>

                        {BACKWARD_ICON_PDF}


                      </td>
                      <td className='py-1 text-center' onClick={() => { saveData(dataObj.id, "isLogOut") }}>

                        {SIGNOUT_ICON_PDF}


                      </td>

                    </tr>
                  ))}
                </tbody>
              }
            </table>
          </div>
        </>
        <ReactPaginate
          previousdiv={"<"}
          nextdiv={">"}
          breakdiv={"..."}
          breakClassName={"break-me"}
          forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
          pageCount={Math.ceil(totalCount / dataPerPage)}
          marginPagesDisplayed={1}
          onPageChange={handleOnclick}
          containerClassName={"flex justify-center m-2 gap-5 items-center"}
          pageClassName={"border custom-circle text-center"}
          disabledClassName={"p-1 bg-gray-200"}
          previousLinkClassName={"border p-1 text-center"}
          nextLinkClassName={"border p-1"}
          activeClassName={"bg-blue-900 text-white px-2"} />


      </div>
    </>
  )
}

export default RegistrationFormReport