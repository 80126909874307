import React from 'react'
import { Loader } from '../../../Basic/components'
import { findFromList } from '../../../Utils/helper'
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices'
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices'
import { params } from '../../../Utils/helper'
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices'
import { useGetPartyByIdQuery } from '../../../redux/ErpServices/PartyMasterServices'

const AdditionalTestStatusTable = ({ sampleDetails, setSampleDetails, currentActiveTab, removeTest, buyerId }) => {
    let index = sampleDetails.findIndex(item => item.sampleId === currentActiveTab)

    const handleInputChange = (value, testId, field) => {
        const newBlend = structuredClone(sampleDetails);
        if (index === -1) return
        let testIndex = sampleDetails[index].SampleTestDetails.findIndex(sampleTest => parseInt(sampleTest.testId) === parseInt(testId))
        newBlend[index]["SampleTestDetails"][testIndex][field] = value;
        setSampleDetails(newBlend);
    };
    const { data: labTestList } = useGetLabTestNameMasterQuery({ params })
    const { data: conditionList } = useGetLabTestConditionMasterQuery({ params })
    const { data: methodList } = useGetLabTestMethodMasterQuery({ params })

    const { data: partyDetails } =
        useGetPartyByIdQuery(buyerId, { skip: !buyerId });

    function isMethodInBuyerStandard(labTestMethod) {
        if (!partyDetails?.data) return false
        if (partyDetails?.data.PartyOnStandardItems) {
            let index = partyDetails?.data.PartyOnStandardItems.findIndex(item => parseInt(item.labTestStandardId) === parseInt(labTestMethod.standardId))
            if (index === -1) return false
            return true
        }
        return false
    }

    if (!methodList || !conditionList || !labTestList || !sampleDetails[index]) return <Loader />
    if (sampleDetails[index] && sampleDetails[index]?.SampleTestDetails && sampleDetails[index].SampleTestDetails.filter(item => !item.isPackage).length === 0) return null
    return (
        <div className='w-full'>
            <div className='text-center font-bold text-lg'>Additional Test</div>
            <table className='w-full table-fixed border'>
                <thead className='table-header text-center w-full'>
                    <tr className='w-full'>
                        <th className='w-10'>S.no</th>
                        <th className='w-64'>Test</th>
                        <th className='w-32'>Method</th>
                        <th>Condition</th>
                        <th className='w-20'>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {sampleDetails[index].SampleTestDetails &&
                        <>
                            {sampleDetails[index].SampleTestDetails.filter(item => !item.isPackage).map((packageTest, index) =>
                                <tr className='text-xs' key={packageTest.testId}
                                    onContextMenu={(e) => {
                                        e.preventDefault()
                                        if (window.confirm(`Delete "${findFromList(packageTest.testId, labTestList?.data ? labTestList.data : [], "name")}"...?`)) {
                                            removeTest(packageTest.testId)
                                        }
                                    }}
                                >
                                    <td className='table-data text-center'>{index + 1}</td>
                                    <td className='table-data'>{findFromList(packageTest.testId, labTestList?.data ? labTestList.data : [], "name")}</td>
                                    <td className='table-data'>
                                        <select
                                            className='text-left w-full rounded h-8 py-2 focus:outline-none'
                                            value={packageTest.methodId}
                                            onChange={(e) => (handleInputChange(e.target.value, packageTest.testId, "methodId"))}>
                                            <option hidden>
                                                Select
                                            </option>
                                            {methodList.data.filter(item => isMethodInBuyerStandard(item)).filter(item =>
                                                parseInt(item.labTestId) === parseInt(packageTest.testId)).map((methodId) =>
                                                    <option value={methodId.id} key={methodId.id}>
                                                        {methodId.name}
                                                    </option>
                                                )}
                                        </select>
                                    </td>
                                    <td className='table-data'>
                                        <select
                                            className='text-left w-full rounded h-8 py-2 focus:outline-none'
                                            value={packageTest.conditionId}
                                            onChange={(e) => (handleInputChange(e.target.value, packageTest.testId, "conditionId"))}>
                                            <option hidden>
                                                Select
                                            </option>
                                            {
                                                conditionList.data.filter(item => {
                                                    return (parseInt(item.methodId) === parseInt(packageTest.methodId))
                                                }
                                                ).map((conditionId) =>
                                                    <option value={conditionId.id} key={conditionId.id}>
                                                        {conditionId.name}
                                                    </option>
                                                )}
                                        </select>
                                    </td>
                                    <td className='table-data'>
                                        <div className='flex justify-center items-center'>
                                            <input type="checkbox" checked={packageTest?.isData}
                                                onChange={(e) => (handleInputChange(!(packageTest?.isData), packageTest.testId, "isData"))}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    }
                </tbody>
            </table>

        </div>
    )
}

export default AdditionalTestStatusTable
