import React, { useEffect, useRef, useState } from 'react'
import { useGetRegistrationByIdQuery } from '../../../redux/LabServices/RegistrationServices'
import { Loader } from '../../../Basic/components'
import { useGetBranchQuery } from '../../../redux/services/BranchMasterService'
import { findFromList, groupBy, params } from "../../../Utils/helper"

import PrintTestScanningSheet from '../PrintFormat-TestScanningSheet'
import { useReactToPrint } from 'react-to-print'
import { PrintButtonOnly } from '../../../Buttons'
import PrintNewTestScanningSheet from '../PrintFormatNew-TestScanning'
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices'
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices'
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices'
import { useGetWashCareGroupMasterQuery } from '../../../redux/LabServices/WashCareGroupMasterServices'

import { PreviewButtonOnly } from '../../../Buttons'
import { Modal } from '../../../Inputs'
import ScanningSheetDoc from './ScanningSheetDoc'



const ScanningSheetContainer = ({ activeRegistrationId }) => {
    const [sendData, setSendData] = useState({})
    const [invoiceDocument, setInvoiceDocument] = useState(false)
    const { data: branchData, isLoading: isBranchLoading, isFetching: isBranchFetching } = useGetBranchQuery({ params })
    const { data: registrationData, isLoading, isFetching } = useGetRegistrationByIdQuery({ id: parseInt(activeRegistrationId) }, { skip: !activeRegistrationId })

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    useEffect(() => {
        if (registrationData?.data && branchData?.data) {
            const { branchId, endUseId } = registrationData.data
            let data = {
                ...registrationData.data,
                branchCode: findFromList(branchId, branchData.data, "branchCode"),
            }
            setSendData(data)
        }
    }, [registrationData, isLoading, isFetching, branchData, isBranchFetching, isBranchLoading])


    const { data: testList } = useGetLabTestNameMasterQuery({ params })
    const { data: methodList } = useGetLabTestMethodMasterQuery({ params })
    const { data: conditionList } = useGetLabTestConditionMasterQuery({ params })
    const { data: washCareData, isLoading: isWashCareGroupLoading, isFetching: isWashCareGroupFetching } =
        useGetWashCareGroupMasterQuery({ params: { ...params, includeWashCare: true } })


    if (!registrationData?.data && branchData?.data && isWashCareGroupLoading || isWashCareGroupFetching) return <Loader />
    return (
        <>
            <Modal isOpen={invoiceDocument} onClose={() => setInvoiceDocument(false)} widthClass={" h-[90%] w-[90%] bg-white p-2"}>

                <ScanningSheetDoc testList={testList} methodList={methodList} conditionList={conditionList} washCareData={washCareData} componentRef={componentRef} registrationData={registrationData} data={sendData} />

            </Modal>
            <div className="flex w-full justify-end ">
                <PreviewButtonOnly onClick={() => setInvoiceDocument(true)} />

            </div>
            <div className='overflow-auto flex flex-col'>
                {registrationData?.data.RegistrationSampleDetails.map(sampleItem => {
                    let sampleItemTests = sampleItem.SampleTestDetails.map(item => {
                        let newItem = structuredClone(item)
                        newItem["group"] = item.LabTest.LabTestSubGroup.LabTestGroup.name
                        return newItem
                    })

                    let sampleWashCareDetails = sampleItem.SampleWashCareDetails.map(item => { return { washCareId: item.washCareId } })
                    let groupByLabGroup = groupBy(sampleItemTests, "group")
                    return Object.keys(groupByLabGroup).map(item =>
                        <PrintTestScanningSheet data={sendData} sampleItem={sampleItem} sampleWashCareDetails={sampleWashCareDetails} groupName={item} groupTestDetails={groupByLabGroup[item]} />

                    )
                }
                )}
            </div>





            {/* <div className='hidden'   >
            <InvoiceContent testList={testList} methodList={methodList} conditionList={conditionList} washCareData={washCareData} componentRef={componentRef} registrationData={registrationData}  data={sendData}   />
             
            </div> */}
        </>
    )
}

export default ScanningSheetContainer
