import React, { useEffect, useState } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getAllowableReturnQty, getDateFromDateTime, isBetweenRange, substract } from '../../../Utils/helper';
import {
    useGetPoQuery,
} from "../../../redux/ErpServices/PoServices";
import { toast } from 'react-toastify';



const FabricReturnItems = ({ finalInwardItems, setFinalInwardItems, readOnly, removeItem }) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        setFinalInwardItems(newBlend);
    };


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )

    const { data: poList, isLoading: poListLoading, isFetching: poListFetching } = useGetPoQuery({ branchId });

    const { data: fabricList } =
        useGetFabricMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    const { data: designList } =
        useGetdesignQuery({ params: { companyId } });

    const { data: gaugeList } =
        useGetGaugeQuery({ params: { companyId } });

    const { data: loopLengthList } =
        useGetLoopLengthQuery({ params: { companyId } });

    const { data: gsmList } =
        useGetgsmQuery({ params: { companyId } });

    const { data: diaList } =
        useGetDiaQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });


    if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList || !poList) return <Loader />
    return (
        <>
            <div className={` relative w-full overflow-y-auto py-1`}>
                <table className="border border-gray-600 text-xs table-auto w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className='h-8'>
                            <th className='text-center w-8 border border-r-black border-b-black border-l-black'>
                                S.no
                            </th>
                            <th className='text-center w-24 border border-r-black border-b-black'>
                                Po.no
                            </th>
                            <th className='text-center w-24 border border-r-black border-b-black'>
                                Po.Date
                            </th>
                            <th className='text-center w-24 border border-r-black border-b-black'>
                                Due Date
                            </th>
                            <th className='w-24 border border-r-black border-b-black'>
                                Fabric Name
                            </th>
                            <th className='w-16 border border-r-black border-b-black'>
                                Color
                            </th>
                            <th className='w-16 border border-r-black border-b-black'>
                                Design
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Gauge
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                LL
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Gsm
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                K-Dia
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                F-Dia
                            </th>
                            <th className='w-12 border border-r-black border-b-black'>
                                Uom
                            </th>
                            <th className="table-data border border-gray-600 w-24">Inward Qty</th>
                            <th className="table-data border border-gray-600 w-24">Stock Qty</th>
                            <th className="table-data border border-gray-600 w-24">Allowed Return Qty</th>
                            <th className="table-data border border-gray-600 w-24">Return Qty</th>
                            <th className="table-data border border-gray-600 w-24">Price</th>
                            <th className="table-data border border-gray-600 w-24">Gross</th>
                            <th className='table-data border border-gray-600 w-12'>Delete</th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
                        {finalInwardItems.map((item, index) => (
                            <tr key={index} className="w-full">
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {index + 1}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {item.poNo}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {getDateFromDateTime(item.poDate)}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {getDateFromDateTime(item.dueDate)}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.fabricId, fabricList.data, "aliasName")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.colorId, colorList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.designId, designList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.gaugeId, gaugeList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.loopLengthId, loopLengthList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.gsmId, gsmList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.kDiaId, diaList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.fDiaId, diaList.data, "name")}
                                </td>
                                <td className='text-center  border border-r-black border-b-black shadow-xl'>
                                    {findFromList(item.uomId, uomList.data, "name")}
                                </td>
                                <td className="table-data border border-gray-600 w-2 text-right py-1">
                                    {(parseFloat(item.alreadyInwardedQty) + parseFloat(item.alreadyReturnedQty)).toFixed(3)}
                                </td>
                                <td className="table-data border border-gray-600 w-2 text-right py-1">
                                    {parseFloat(item.stockQty).toFixed(3)}
                                </td>
                                <td className="table-data border border-gray-600 w-2 text-right py-1">
                                    {getAllowableReturnQty(item.alreadyInwardedQty, item.alreadyReturnedQty, item.stockQty).toFixed(3)}
                                </td>
                                <td className=' border-black border-2'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 w-24 px-1"
                                        value={(!item.returnQty) ? 0 : item.returnQty}
                                        disabled={readOnly}
                                        onChange={(event) => {
                                            if(!event.target.value){
                                                handleInputChange(0, index, "returnQty");
                                                return
                                            }
                                            if (isBetweenRange(0, getAllowableReturnQty(item.alreadyInwardedQty, item.alreadyReturnedQty, item.stockQty), event.target.value)) {
                                                handleInputChange(event.target.value.replace(/^0+/, ''), index, "returnQty")
                                            } else {
                                                toast.info("Return Qty Cannot be more than allowable Qty", { position: 'top-center' })
                                            }
                                        }}
                                        onBlur={(e) =>
                                            {
                                                   
                                                if(!e.target.value){
                                                    handleInputChange(0.000, index, "returnQty");
                                                    return
                                                }
                                                handleInputChange(parseFloat(e.target.value).toFixed(3), index, "returnQty")
                                            }
                                            
                                        }
                                    />
                                    <div className='text-center'>
                                    </div>
                                </td>
                                <td className='border border-black text-right' >
                                    {parseFloat(item.price).toFixed(2)}
                                </td>
                                <td className='border border-black'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1 w-24 px-1"
                                        value={(item.price * item.returnQty).toFixed(2)}
                                        disabled={true}
                                    />
                                </td>
                                <td className='border border-gray-600 w-12'>
                                    {readOnly
                                        ?
                                        ""
                                        :
                                        <div tabIndex={-1} onClick={() => removeItem(item.poNo, item.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FabricReturnItems