import React from 'react'
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import TestParametersLists from './TestParametersLists';

const TestParameters = ({ val, valIndex, dataObj }) => {

    return (
        <>
            {
                val?.SampleTestDetails?.map((item, itemIndex) =>
                    <TestParametersLists key={itemIndex} item={item} val={val} dataObj={dataObj} />
                )
            }

        </>
    )
}

export default TestParameters