import { ACTIVE, INACTIVE } from "../Strings"

export const bloodList = [
    { show: "A +ve", value: "AP" },
    { show: "A -ve", value: "AN" },
    { show: "B +ve", value: "BP" },
    { show: "B -ve", value: "BN" },
    { show: "AB +ve", value: "ABP" },
    { show: "AB -ve", value: "ABN" },
    { show: "O +ve", value: "OP" },
    { show: "O -ve", value: "ON" }
]

export const genderList = [
    { show: 'MALE', value: 'MALE' },
    { show: 'FEMALE', value: 'FEMALE' },
    { show: 'OTHER', value: 'OTHER' }
]

export const passFailOption = [
    { show: 'PASS', value: 'PASS' },
    { show: 'FAIL', value: 'FAIL' },
    { show: 'ALL', value: 'ALL' }
]

export const headingOption = [
    { show: 'BUYER', value: 'BUYER' },
    { show: 'SELF REFERENCE', value: 'SELF REFERENCE' },

]

export const splitTestOption = [
    { show: 'ACCREDITATION', value: 'ACCREDITATION' },
    { show: 'NONACCREDITATION', value: 'NONACCREDITATION' },
    { show: 'ALL', value: 'ALL' }
]

export const maritalStatusList = [
    { show: 'SINGLE', value: 'SINGLE' },
    { show: 'MARRIED', value: 'MARRIED' },
    { show: 'SEPARATED', value: 'SEPARATED' }
]

export const pageType = [
    { show: 'MASTER', value: 'Masters' },
    { show: 'TRANSACTION', value: 'Transactions' },
    { show: 'REPORTS', value: 'Reports' },
    { show: 'ADMIN CONTROLS', value: 'AdminAccess' },
]

export const accessoryCategoryList = [
    { show: 'STITCHING ACCESSORIES', value: 'STITCHING' },
    { show: 'PACKING ACCESSORIES', value: 'PACKING' }
]

export const prefixCategory = [
    { show: "DEFAULT", value: "Default" },
    { show: "SPECIFIC", value: "Specific" }
]

export const employeeType = [
    { show: "PERMANENT", value: true },
    { show: "TEMPORARY", value: false }
]

export const statusDropdown = [
    { show: "ACTIVE", value: true },
    { show: "INACTIVE", value: false }
]

export const poTypes = [
    { show: "Grey Yarn", value: "GreyYarn" },
    { show: "Dyed Yarn", value: "DyedYarn" },
    { show: "Grey Fabric", value: "GreyFabric" },
    { show: "Dyed Fabric", value: "DyedFabric" },
    { show: "Accessory", value: "Accessory" },
]

export const discountTypes = [
    { show: "Flat", value: "Flat" },
    { show: "Percentage", value: "Percentage" }
]

export const diaMeasurementList = [
    { show: "CMS", value: "CMS" },
    { show: "Inches", value: "INCHES" },
    { show: "Open Width", value: "OPENWIDTH" },
    { show: "Tubuler", value: "TUBULER" },
]

export const purchasePrPi = [
    { show: "Purchase Inward", value: "PurchaseInward" },
    { show: "Purchase Return", value: "PurchaseReturn" }
]

export const inwardTypeOptions = [
    { show: "Against Po", value: "AgainstPo" },
    { show: "Direct Inward", value: "DirectInward" }
]

export const processDeliveryOrReturn = [
    { show: "Process Delivery", value: "ProcessDelivery" },
    { show: "Process Return", value: "ProcessReturn" }
]

export const ProcessIOOptions = [
    { show: "GY-GY", value: "GY_GY" },
    { show: "GY-DY", value: "GY_DY" },
    { show: "GY-GF", value: "GY_GF" },
    { show: "DY-DY", value: "DY_DY" },
    { show: "DY-DF", value: "DY_DF" },
    { show: "GF-DF", value: "GF_DF" },
    { show: "DF-DF", value: "DF_DF" },
]

export const Retest = [
    { show: "Yes", value: "yes" },
    { show: "No", value: "no" },
]

export const ReceivedBy = [
    { show: "Courier", value: "courier" },
    { show: "Customer", value: "customer" },
]

export const showEntries = [
    { show: "10", value: "10" },
    { show: "25", value: "25" },
    { show: "50", value: "50" },
    { show: "100", value: "100" },
]

export const revertOptions = [
    { show: "PARTIAL", value: "PARTIAL" },
    { show: "COMPLETED", value: "COMPLETED" },

]

export const Category = [
    { show: "Fabric", value: "FABRIC" },
    { show: "Garment", value: "GARMENT" },
]

export const Group = [
    { show: "Heading", value: "HEADING" },
    { show: "Parameter", value: "PARAMETER" },
]

export const SampleMaterial = [
    { show: "Hosiery", value: "HOSIERY" },
    { show: "Woven", value: "WOVEN" },
]

export const Functionality = [
    { show: "<", value: "<" },
    { show: ">", value: ">" },
    { show: "=", value: "=" },
    { show: ">=", value: ">=" },
    { show: "<=", value: "<=" },
    { show: "±", value: "± " },
    { show: "+", value: "+" },
    { show: "-", value: "-" },
    { show: "*", value: "*" },
    { show: "%", value: "%" },
    { show: "/", value: "/" },
    { show: "Default", value: "Default" },

]


export const CustomerClaimed = [
    { show: "Claimed Fabric Weight", value: "CLAIMEDFABRICWEIGHT" },
    { show: "Claimed Fiber Content", value: "CLAIMEDFIBERCONTENT" },
    { show: "Claimed Yarn Count", value: "CLAIMEDYARNCOUNT" },
    { show: "Claimed Fabric Count", value: "CLAIMEDFABRICCOUNT" },
]

export const IsReadingOrRequirement = [
    { show: "IsReading", value: "ISREADING" },
    { show: "IsRequirement", value: "ISREQUIREMENT" },

]

export const walesCourseList = [
    { show: 'WALES', value: 'WALES' },
    { show: 'COURSE', value: 'COURSE' },

]

export const invoiceTypeOptions = [
    { show: 'PACKAGE', value: 'PACKAGE' },
    { show: 'INDIVIDUAL', value: 'INDIVIDUAL' },
]


export const filterData = [
    { show: "ALL", value: "ALL" },
    { show: "ACTIVE", value: "ACTIVE" },
    { show: "INACTIVE", value: "INACTIVE" },
]
export const reportTypeOptions = [
    { show: "DEFAULT", value: "CURRENT" },
    { show: "MOTHER REPORT", value: "MOTHER REPORT" },
]


export const registrationStatusItems = [
    { show: "ACTIVE", value: "ACTIVE" },
    { show: "CANCELLED", value: "CANCELLED" },
    { show: "ALL", value: "ALL" },

]



