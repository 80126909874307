import React from 'react'
import logo from "../../../assets/pin.png"
import tw from '../../../Utils/tailwind-react-pdf'


import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
const Header = ({ }) => {

  return (
    <View>
      <View style={tw("flex flex-row  gap-x-12  justify-start pl-12 items-center p-2   border border-gray-400 w-full h-20 mt-5")}>
        <View>
          <Image style={tw("bg-gray-400 rounded-xl w-28")} src={logo} />
        </View>
        <View style={tw("ml-16 mt-3 underline")}>
          <Text style={tw("text-xl")}>
            TEXTILE TESTING LABORATORY
          </Text>
          <Text style={tw("ml-16 underline text-lg")}>
            Test Scanning Sheet
          </Text>
        </View>



      </View>
    </View>








  )
}

export default Header