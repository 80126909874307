import React, { useContext, useRef, } from "react";
import { TextInput } from "../../../../Inputs";
import { FieldsContext } from '..';

export default function Form() {
    const fields = useContext(FieldsContext);

    const childRecord = useRef(0);

    return (
        <div>
            <div className='grid grid-cols-3 my-2'>
                <TextInput autoFocus name="Order No." type="text" value={fields.orderNo} setValue={fields.setOrderNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                <TextInput name="Style No." type="text" value={fields.styleNo} setValue={fields.setStyleNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                <TextInput name="TRF No." type="text" value={fields.trfNo} setValue={fields.setTrfNo} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
            </div>
            <div className='grid grid-cols-3 my-2'>
                <TextInput name="Batchno / Lotno" type="text" value={fields.batchNo} setValue={fields.setBatchNo} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                <TextInput name="Season / Phase" type="text" value={fields.season} setValue={fields.setSeason} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
                <TextInput name="Color" type="text" value={fields.color} setValue={fields.setColor} required={true} readOnly={fields.readOnly} disabled={(childRecord.current > 0)} />
            </div>
        </div>
    )
}