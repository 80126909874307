import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PURCHASE_INWARD_OR_RETURN_API} from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const purchaseInwardOrReturnApi = createApi({
  reducerPath: "PurchaseInwardOrReturn",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["PurchaseInwardOrReturn"],
  endpoints: (builder) => ({
    getPurchaseInwardOrReturn: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: PURCHASE_INWARD_OR_RETURN_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PURCHASE_INWARD_OR_RETURN_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["PurchaseInwardOrReturn"],
    }),
    getPurchaseInwardOrReturnById: builder.query({
      query: (id) => {
        return {
          url: `${PURCHASE_INWARD_OR_RETURN_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["PurchaseInwardOrReturn"],
    }),
    addPurchaseInwardOrReturn: builder.mutation({
      query: (payload) => ({
        url: PURCHASE_INWARD_OR_RETURN_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["PurchaseInwardOrReturn"],
    }),
    updatePurchaseInwardOrReturn: builder.mutation({
      query: ({id, body}) => {
        return {
          url: `${PURCHASE_INWARD_OR_RETURN_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["PurchaseInwardOrReturn"],
    }),
    deletePurchaseInwardOrReturn: builder.mutation({
      query: (id) => ({
        url: `${PURCHASE_INWARD_OR_RETURN_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PurchaseInwardOrReturn"],
    }),
  }),
});

export const {
  useGetPurchaseInwardOrReturnQuery,
  useGetPurchaseInwardOrReturnByIdQuery,
  useAddPurchaseInwardOrReturnMutation,
  useUpdatePurchaseInwardOrReturnMutation,
  useDeletePurchaseInwardOrReturnMutation,
} = purchaseInwardOrReturnApi;

export default purchaseInwardOrReturnApi;
