import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { IMAGE_UPLOAD_URL } from "../Constants";
import { toast } from "react-toastify";

export function generateSessionId(length = 8) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function findDateInRange(fromDate, toDate, checkDate) {
  if ((fromDate <= checkDate) && (checkDate <= toDate)) {
    return true
  }
  return false;
}

export function latestExpireDateWithinNDays(latesExpireDate = secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "latestActivePlanExpireDate"), n = 20) {
  if (differenceInTimeToInDays(differenceInTime(new Date(latesExpireDate), new Date())) <= n) {
    return true;
  }
  return false;
}

export function differenceInTime(dateOne, dateTwo) {
  return dateOne.getTime() - dateTwo.getTime();
}

export function differenceInTimeToInDays(differenceInTime) {
  return differenceInTime / (1000 * 3600 * 24);
}

// React paginate, page index starts with 0 ,
//  actual page number starts with 1 so, input to 
// react-paginate and valueFrom react-paginate converted

export function pageNumberToReactPaginateIndex(pageNumber) {
  return pageNumber - 1;
}

export function reactPaginateIndexToPageNumber(pageIndex) {
  return pageIndex + 1;
}

export function viewBase64String(base64String) {
  return "data:image/png;base64, " + base64String
}

export function hasPermission(permission) {
  if (Boolean(secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "superAdmin"))) {
    return true;
  }
  return JSON.parse(secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userRole")).role.RoleOnPage.find(item => item.pageId === parseInt(secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "currentPage")))[permission]
}

export function getYearShortCode(fromDate, toDate) {
  return `${new Date(fromDate).getFullYear().toString().slice(2)}-${new Date(toDate).getFullYear().toString().slice(2)}`
}

export const handleKeyDown = (event, callback) => {
  let charCode = String.fromCharCode(event.which).toLowerCase();
  if ((event.ctrlKey || event.metaKey) && charCode === 's') {
    event.preventDefault();
    callback();
  }
}

export function calculateAge(dob) {
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

// Convert number to words

const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0, n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f(g(x));
const not = x => !x;
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
export const numToWords = n => {

  let a = [
    '', 'one', 'two', 'three', 'four',
    'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen',
    'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
  ];

  let b = [
    '', '', 'twenty', 'thirty', 'forty',
    'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];

  let g = [
    '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion',
    'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion'
  ];

  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' hundred ',
      num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
      a[tens + ones] || a[ones]
    ].join('');
  };

  let thousand = (group, i) => group === '' ? group : `${group} ${g[i]}`;

  if (typeof n === 'number')
    return numToWords(String(n));
  else if (n === '0')
    return 'zero';
  else
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(' ');
};


export function titleCase(str) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export const getDateFromDateTime = (dateTime) => moment.utc(dateTime).format("YYYY-MM-DD")
export const getDateFromDateTimeForDisplay = (dateTime) => moment.utc(dateTime).format("DD-MM-YYYY")
export const getTimeFromDateTime = (dateTime) => moment.utc(dateTime).format("HH-mm")

export function formatAMPM(dataString) {
  let date = new Date(dataString)
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


export function findFromList(id, list, property) {
  if (!list) return ""
  let data = list.find(i => parseInt(i.id) === parseInt(id))
  if (!data) return ""
  return data[property]
}

export function findFromListWithMatchKey(id, list, property, matchKey) {
  if (!list) return ""
  let data = list.find(i => parseInt(i[matchKey]) === parseInt(id))
  if (!data) return ""
  return data[property]
}



export function isBetweenRange(startValue, endValue, value) {

  return ((parseFloat(startValue) <= parseFloat(value)) && (parseFloat(value) <= parseFloat(endValue))) || ((parseFloat(startValue) >= parseFloat(value)) && (parseFloat(value) >= parseFloat(endValue)))
}

export function substract(num1, num2) {
  let n1 = parseFloat(num1) * 1000;
  let n2 = parseFloat(num2) * 1000;
  let result = (n1 - n2) / 1000
  return result;
}

export function getAllowableReturnQty(inwardedQty, returnedQty, stockQty) {
  let balanceReturnQty = parseFloat(inwardedQty) + parseFloat(returnedQty);
  return (balanceReturnQty < parseFloat(stockQty)) ? balanceReturnQty : parseFloat(stockQty)
}

export function priceWithTax(price, tax) {
  if (!price) return 0
  if (!tax) return parseFloat(price)
  let taxAmount = (parseFloat(price) / 100) * parseFloat(tax)
  return parseFloat(price) + taxAmount
}

export const params = {
  companyId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  ),
  branchId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  ),
  userId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )
};

export const getDateFromDateTimeToDisplay = (dateTime) => moment.utc(dateTime).format("DD-MM-YYYY")

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getDateArray = function (start, end) {
  let arr = [];
  let dt = new Date(start);
  end = new Date(end);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
}

export const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function isSameDay(d1, d2) {
  return moment.utc(d1).format("YYYY-MM-DD") === moment.utc(d2).format("YYYY-MM-DD")
}

export function getClaimedValue(selectedOption, sampleDetails, loopsubHeading, walesCourse) {



  if (selectedOption == "CLAIMEDYARNCOUNT") {

    return sampleDetails["RegistrationSampleDetailsClaimedYarnCount"]?.find(item => item?.subHeading?.trim()?.toLowerCase() == loopsubHeading?.toLowerCase())?.value
  }
  else if (selectedOption == "CLAIMEDFABRICCOUNT") {
    return sampleDetails["RegistrationSampleDetailsClaimedFabricCount"]?.find(item => item?.subHeading?.trim()?.toLowerCase() == loopsubHeading?.toLowerCase() && item?.walesCourse == walesCourse)?.value

  }
  else if (selectedOption == "CLAIMEDFABRICWEIGHT") {

    return sampleDetails["RegistrationSampleDetailsClaimedFabricWeight"]?.find(item => item?.subHeading?.trim()?.toLowerCase() == loopsubHeading?.toLowerCase())?.value

  }

}

export function getClaimedRangeValue(claimedValue, formulaValue, functionality) {
  let value = parseFloat(formulaValue);
  if (formulaValue.includes("%")) {
    value = ((parseFloat(formulaValue) / 100) * parseFloat(claimedValue));
  }
  console.log(claimedValue, "claimedValue", functionality, "functionality", value, "value")
  value = eval(`${parseFloat(claimedValue)} ${functionality} ${value}`)
  return value
}

export function getNotClaimedRangeValue(formulaValue, functionality) {
  return parseFloat(`${functionality}${Math.abs(parseFloat(formulaValue))}`)
}


export function getImageUrlPath(fileName) {
  return `${IMAGE_UPLOAD_URL}${fileName}`
}


export const getCommonParams = () => ({
  companyId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  ),
  branchId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  ),
  userId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  ),
  finYearId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + 'currentFinYear'),
  token: `Bearer ${secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + 'token')} `
})




// export function handleMailSend(blob, mailAddress, subject, fileName, logo) {


//   let blobData = new Blob([blob], { type: 'application/pdf' });
//   let file = new File([blobData], 'document.pdf', { type: 'application/pdf' });
//   let form = new FormData();
//   form.append("file", file);
//   form.append("mailAddress", mailAddress);
//   form.append("subject", subject);
//   form.append("fileName", fileName);

//   form.append("logo", logo);

//   try {
//     let returnData = fetch(`${process.env.REACT_APP_SERVER_URL}sendMail`, {
//       method: "POST",
//       body: form
//     })

//     if (returnData?.statusCode === 1) {
//       return toast.error(returnData?.message);
//     } else {

//       toast.success("Successfully");
//     }
//   } catch (error) {
//     console.log("handle");
//   }
// };


export function handleMailSend(blob, mailAddress, subject, fileName, logo, heading) {



  let blobData = new Blob([blob], { type: 'application/pdf' });
  let file = new File([blobData], 'document.pdf', { type: 'application/pdf' });
  let form = new FormData();
  form.append("file", file);
  form.append("mailAddress", mailAddress);
  form.append("subject", subject);
  form.append("fileName", fileName);

  form.append("logo", logo);
  form.append("heading", heading);

  fetch(`${process.env.REACT_APP_SERVER_URL}sendMail`, {
    method: "POST",
    body: form
  }).then(res => {

    toast.success("Mail Send SuccessFully");
  }).catch(err => {
    toast.error("Please Resend !")
  });

}
export function handleMailSendWithMultipleAttachments(attachments, mailAddress, subject, fileName, logo, reportNo) {
  let form = new FormData();
  console.log(attachments, "attachments")
  attachments.forEach(attachment => {
    let blobData = new Blob([attachment.blob], { type: 'application/pdf' });
    let file = new File([blobData], `${attachment.reportNo}.pdf`, { type: 'application/pdf' });
    form.append("file", file);
  });
  form.append("mailAddress", mailAddress);
  form.append("subject", subject);
  form.append("fileName", fileName);
  form.append("reportNo", reportNo);

  form.append("logo", logo);

  fetch(`${process.env.REACT_APP_SERVER_URL}sendMail/multipleFiles`, {
    method: "POST",
    body: form
  }).then(res => {
    toast.success("Mail Send SuccessFully");
  }).catch(err => {
    toast.error("Please Resend !")
  });
}