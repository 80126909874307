import React from 'react'

import { getImageUrlPath, viewBase64String } from '../../../Utils/helper'
import empty from "../../../assets/empty.png"

const FailureImages = ({ item, img, imgIndex, reportData }) => {

  const imageWidth = "200px"
  const imageHeight = "200px";

  const image = img?.picture
  return (
    <>
      <div className='flex flex-col gap-y-2 max-w-md'>
        <div className='text-left'>
          {img?.comment}
        </div>


        <div className='mb-2'>
          <div>
            {img?.sampleid.slice(-1) >= 0 ? "" : img?.sampleid.slice(-1)}
          </div>
          <div className='border border-gray-600  p-2'>

            {Boolean(image) ?
              <img style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
                src={getImageUrlPath(image)}
              />
              :
              <img src={empty} className='w-36 text-center' />
            }
          </div>


        </div>



      </div>


    </>
  )
}

export default FailureImages