import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { CheckBox, DisabledInput, Modal } from "../../../Inputs";
import { findFromList } from "../../../Utils/helper";
import { Loader } from "../../../Basic/components";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import { useGetRegistrationByIdQuery } from "../../../redux/LabServices/RegistrationServices";
import SampleTestDetails from "./SampleTestDetails";
import {
    useAddLabTestResultEntryMutation,
    useDeleteLabTestResultEntryMutation,
    useGetLabTestResultEntryQuery,
} from "../../../redux/LabServices/LabTestResultEntryServices";
import { useGetSampleDescriptionTwoMasterQuery } from "../../../redux/LabServices/SampleDescriptionTwoMasterServices";
import TestedImageUpload from "./TestedImageUpload";
import { useGetUserByIdQuery } from "../../../redux/services/UsersMasterService";
import { useGetSampleDescriptionFourMasterQuery } from "../../../redux/LabServices/SampleDescriptionFourMasterServices";
import { useGetSampleDescriptionThreeMasterQuery } from "../../../redux/LabServices/SampleDescriptionThreeMasterServices";
import { useGetSampleDescriptionOneMasterQuery } from "../../../redux/LabServices/SampleDescriptionOneMasterServices";


const MODEL = "Admin Result Entry";

export default function Form({ registrationSampleDetailsId, setRegistrationSampleDetailsId, registrationId }) {
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [active, setActive] = useState(true);
    const [formReport, setFormReport] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);
    const [registerId, setRegisterId] = useState("")
    const [resultDetails, setResultDetails] = useState([]);
    const [resultDetailsWithResult, setResultDetailsWithResult] = useState([]);
    const [results, setResults] = useState([]);
    const [fiberHeading, setFiberHeading] = useState("");
    const [fiberIndex, setFiberIndex] = useState("");
    const [isFiberContent, setIsFiberContent] = useState(false)
    const [fiberContentId, setFiberContentId] = useState("");
    const [requirementList, setRequirementList] = useState([]);
    const [testedImages, setTestedImages] = useState([]);
    const [isTestedImageOpen, setIsTestedImageOpen] = useState(false);
    const [showResultValues, setShowResultValues] = useState(false);
    const [isAccreditationTestedImage, setIsAccreditationTestedImage] = useState(false)

    const adminEntryDate = new Date()

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),

    };
    const param = {
        registrationSampleDetailsId: registrationSampleDetailsId,
    }

    const {
        data: singleRegistrationData,
        isFetching: isSingleRegistrationDataFetching,
        isLoading: isSingleRegistrationDataLoading,
    } = useGetRegistrationByIdQuery({ id: registrationId }, { skip: !registrationId });

    const { data: sampleFourData } = useGetSampleDescriptionFourMasterQuery({ params });
    const { data: sampleThreeData } = useGetSampleDescriptionThreeMasterQuery({ params });
    const { data: sampleTwoData } = useGetSampleDescriptionTwoMasterQuery({ params });
    const { data: sampleOneData } = useGetSampleDescriptionOneMasterQuery({ params });

    const registrationSampleDetails = singleRegistrationData?.data?.RegistrationSampleDetails ? singleRegistrationData.data.RegistrationSampleDetails : []

    const matchingSampleDetails = registrationSampleDetails?.find(item => parseInt(item.id) === parseInt(registrationSampleDetailsId))


    const { data: sampleTwoList } = useGetSampleDescriptionTwoMasterQuery({ params });

    const sampleTwoId = findFromList(matchingSampleDetails?.sampleTwoId, sampleTwoList?.data, "id")

    const { data: buyerList } =
        useGetPartyQuery({ params });



    const { data: resultEntryDatas, isLoading: isResultEntryLoadingDatas, isFetching: isResultEntryFetchingDatas } = useGetLabTestResultEntryQuery({
        params: {
            ...params,
            // registrationSampleDetailsId: registrationSampleDetailsId
            registrationSampleDetailsId: registrationSampleDetailsId ? structuredClone(registrationSampleDetailsId) : undefined
        }
    }, { refetchOnMountOrArgChange: true });





    useEffect(() => {

        setResultDetailsWithResult(prev => {

            if (prev.length === resultDetails.length) return prev

            return resultDetails
        }
        );
    }, [resultDetails])



    const isLastResultExist = useCallback((labTestRequirementDetailsId, testId) => {

        let findOutTestId = resultEntryDatas?.data?.filter(item => item.testId == testId)

        if (findOutTestId.length !== 0) {
            const lastEnteredData = resultEntryDatas?.lastResultEntryData?.LabTestResultDetails ? resultEntryDatas?.lastResultEntryData?.LabTestResultDetails : [];
            if (lastEnteredData.length === 0) return true
            return lastEnteredData.findIndex(i => parseInt(i.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId)) !== -1
        }
        return true
    }, [resultEntryDatas])


    const [addData] = useAddLabTestResultEntryMutation();
    const [removeData] = useDeleteLabTestResultEntryMutation();

    const userId = {
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        ),
    };

    const {
        data: singleUserData,

    } = useGetUserByIdQuery(userId.userId, { skip: !userId.userId });


    useEffect(() => {
        setRegisterId(singleRegistrationData?.data?.docId ? singleRegistrationData.data.docId : "")
    }, [registrationId, singleRegistrationData, isSingleRegistrationDataFetching, isSingleRegistrationDataLoading])

    const data = {
        id, labTestResultDetails: resultDetailsWithResult.map(i => ({ ...i, isFiberContent })), registrationSampleDetailsId, isAdminEntryDate: true, active, companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId")
        , userId: userId.userId, adminEntryDate
    }

    const validateData = (data) => {
        if (data.testConditionId && data.testId && data.partyId && data.methodList) {
            return true;
        }
        return false;
    }

    async function handleSubmitCustom(callback, data, text) {
        try {
            let returnData = await callback(data).unwrap();
            setId("");
            onClose()
            toast.success(text + "Successfully");

        } catch (error) {
            console.log("handle");
        }
    }

    const saveData = () => {
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        handleSubmitCustom(addData, data, "Added");
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setSearchValue("");
        setReadOnly(false);
    };
    useEffect(() => {
        if (!resultEntryDatas?.data || isResultEntryLoadingDatas || isResultEntryFetchingDatas) return
        setResults(resultEntryDatas?.data)
        setShowResultValues(resultEntryDatas?.lastResultEntryData?.resultEntriesCompleted)
    }, [resultEntryDatas, isResultEntryLoadingDatas, isResultEntryFetchingDatas, setResults])

    function onClose() {
        setSearchValue("");
        setRegistrationSampleDetailsId("");
        setResults([]);
        setResultDetails([]);
    }
    if (!resultEntryDatas) return <Loader />

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme "
        >
            <Modal isOpen={isTestedImageOpen} onClose={() => setIsTestedImageOpen(false)} widthClass={"px-2 h-[95%] w-[50%]"}>
                <TestedImageUpload matchingSampleDetails={matchingSampleDetails} sampleId={matchingSampleDetails?.id} testedImages={testedImages} setTestedImages={setTestedImages} setIsTestedImageOpen={setIsTestedImageOpen} isAccreditationTestedImage={isAccreditationTestedImage} setIsAccreditationTestedImage={setIsAccreditationTestedImage} />
            </Modal>
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={onClose}
                    model={MODEL}
                    saveData={saveData}

                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                />

                <div className="flex-1 grid gap-x-2">
                    <div className="col-span-3 grid overflow-auto">
                        <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                            <div className={`${formReport ? "col-span-12" : "col-span-1"}`}>
                                <fieldset className='frame'>
                                    <legend className='sub-heading'>Test Requirement Info</legend>
                                    <div className="grid grid-cols-5">
                                        <DisabledInput name="Reg. ID" value={registerId} required={true} readOnly={readOnly} />

                                        <DisabledInput name="Samples" value={findFromList(registrationSampleDetailsId, singleRegistrationData?.data?.RegistrationSampleDetails, "sampleId")} required={true} readOnly={readOnly} />

                                        <DisabledInput name="Buyer Name" value={findFromList(singleRegistrationData?.data?.buyerId, buyerList.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 1" value={findFromList(matchingSampleDetails?.sampleOneId, sampleOneData?.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 2" value={findFromList(matchingSampleDetails?.sampleTwoId, sampleTwoData?.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 3" value={findFromList(matchingSampleDetails?.sampleThreeId, sampleThreeData?.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 4" value={findFromList(matchingSampleDetails?.sampleFourId, sampleFourData?.data, "name")} required={true} readOnly={readOnly} />
                                        <button className='bg-blue-400 w-28 text-xs rounded-lg p-1' onClick={() => { setIsTestedImageOpen(true) }}>Tested Image</button>
                                    </div>
                                </fieldset>
                                {
                                    showResultValues &&
                                    <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                                        <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-5 w-full flex min-h-[350px] overflow-auto border border-gray-600'>
                                            <legend className='sub-heading'>Test Info</legend>
                                            <div className='grid my-2 w-full'>
                                                {(matchingSampleDetails?.SampleTestDetails ? matchingSampleDetails.SampleTestDetails : [])
                                                    .map((item, itemIndex) =>
                                                        <SampleTestDetails
                                                            isFiberContent={isFiberContent} setIsFiberContent={setIsFiberContent}
                                                            paraItem={item}
                                                            showResultValues={showResultValues}
                                                            isLastResultExist={isLastResultExist}
                                                            resultDetailsWithResult={resultDetailsWithResult}
                                                            requirementList={requirementList} setRequirementList={setRequirementList}
                                                            setResultDetailsWithResult={setResultDetailsWithResult}

                                                            setFiberIndex={setFiberIndex}
                                                            fiberIndex={fiberIndex} setFiberHeading={setFiberHeading}
                                                            fiberHeading={fiberHeading} setFiberContentId={setFiberContentId}
                                                            fiberContentId={fiberContentId}
                                                            sampleTwoId={sampleTwoId}
                                                            key={item.id} results={results}
                                                            matchingSampleDetails={matchingSampleDetails}
                                                            id={id} setId={setId} resultDetails={resultDetails}
                                                            setResultDetails={setResultDetails}
                                                            itemIndex={itemIndex}
                                                            singleRegistrationData={singleRegistrationData}
                                                            registerId={registerId}
                                                            registrationSampleDetailsId={registrationSampleDetailsId}
                                                            readOnly={readOnly} childRecord={childRecord}
                                                            resultDatas={resultEntryDatas?.data}
                                                        />
                                                    )}
                                            </div>
                                        </fieldset>

                                    </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    );

}