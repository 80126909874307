import React from 'react'
import tw from '../../../../Utils/tailwind-react-pdf'
import { Text, View } from "@react-pdf/renderer";
const SampleDetailsPrint = ({data,reportData}) => {
  return (
    <View style={tw('w-full border border-gray-600')}>      
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
           <Text style={tw( 'w-1/2')}>Fabric Supplier :</Text> 
           <Text style={tw( 'w-1/2')}>{data?.Supplier?data?.Supplier.name: ""}</Text> 
           </View>
        <View style={tw(" border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
      
           <Text style={tw( 'w-1/2')}>Contact Name :</Text> 
           <Text style={tw('w-1/2')}>{data?.submittedBy?data?.submittedBy: ""}</Text> 
           </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
       
           <Text style={tw(' w-1/2')}>Contact No :</Text> 
           <Text style={tw(' w-1/2')}> {data?.phone?data?.phone: ""} </Text>
           </View> 
        <View style={tw(" border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
      
           <Text style={tw(' w-1/2')}>Sample Description :</Text> 
           <Text style={tw( 'w-1/2')}>{data?.sampleDescription?data.sampleDescription:""}</Text> 
           </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
      
          <Text style={tw( 'w-1/2')}> Color :</Text> 
          <Text style={tw( 'w-1/2')}> {data.color}</Text> 
          </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
     
           <Text style={tw('w-1/2')}>Batch/Lot No :</Text> 
           <Text style={tw('w-1/2')}>{data.batchNo}</Text> 
           </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
      
           <Text style={tw('w-1/2')}>Style No :</Text> 
           <Text style={tw('w-1/2')}>{data.styleNo}</Text> 
           </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
      
           <Text style={tw('w-1/2')}>Order No:</Text> 
           <Text style={tw('w-1/2')}>{data?.orderNo?data.orderNo:""}</Text> 
           </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
      
         <Text style={tw('w-1/2')}>Season :</Text>
         <Text style={tw('w-1/2')}>{data?.season}</Text>
          </View> 

        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
      
         <Text style={tw('w-1/2')}>End Use :</Text>
         <Text style={tw('w-1/2')}>{data?.RegistrationEndUseDetails ? data?.RegistrationEndUseDetails.map(item=>item.EndUse.name).toString() : ""}</Text>
          </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
     
         <Text style={tw('w-1/2')}>Claimed Fabric Weight (GSM) :</Text>
         <Text style={tw('w-1/2')}>{`${reportData?.data?.samples.length > 1 ? reportData?.data?.samples.map(val => `${val}`).join("&") : ""} ${(reportData?.data?.claimedFabricWeight ? reportData?.data?.claimedFabricWeight : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `   ${item.subHeading} - ${item.value}  ${item.UnitOfMeasurement.name} `) : "--").join("&")}`}</Text>
          </View>

        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
     
         <Text style={tw('w-1/2')}>Claimed Fiber Content :</Text>
         <Text style={tw('w-1/2')}>{`${reportData?.data?.samples.length > 1 ? reportData?.data?.samples.map(val => `${val}`).join("&") : ""} ${(reportData?.data?.claimedFiberContent ? reportData?.data?.claimedFiberContent : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `   ${item.subHeading} - ${item?.FiberContent?.aliasName}  `) : "--").join("&")}`}</Text>
          </View>
        <View style={tw("border-b border-gray-600 flex flex-row  justify-start  w-full p-2")} >
     
         <Text style={tw('w-1/2')}>Test Stage (Package):</Text>
         <Text style={tw('w-1/2')}>{data?.testStage?data?.testStage:""}</Text>
          </View>
        <View style={tw(" flex flex-row  justify-start  w-full p-2")} >
     
         <Text style={tw('w-1/2')}>Retest:</Text>
         <Text style={tw('w-1/2')}>{data?.retest?"YES":"NO"}</Text>
          </View>


           
    
    </View>
  )
}

export default SampleDetailsPrint