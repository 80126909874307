import { DELETE } from '../../../icons';
import { useGetAccessoryMasterQuery } from '../../../redux/ErpServices/AccessoryMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getAllowableReturnQty, isBetweenRange, substract } from '../../../Utils/helper';
import { toast } from 'react-toastify';

const AccessoryReturnItems = ({ transType, finalInwardItems, setFinalInwardItems, readOnly, removeItem, }) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        setFinalInwardItems(newBlend);
    };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const { data: accessoryList } =
        useGetAccessoryMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    function findAccessoryItemName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.name : null
    }

    function findAccessoryGroupName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.AccessoryGroup.name : null
    }

    const { data: sizeList } =
        useGetSizeMasterQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });
    if (!accessoryList || !colorList || !uomList || !sizeList) return <Loader />
    return (
        <div className={`relative w-full overflow-y-auto`}>
            <table className="border border-gray-600 text-xs table-fixed w-full">
                <thead className='bg-blue-200 top-0'>
                    <tr className='h-8'>
                        <th className="table-data border border-gray-600  text-center">S.no</th>
                        <th className="table-data border border-gray-600  text-center">Po.no</th>
                        <th className="table-data border border-gray-600">Accessory Name</th>
                        <th className="table-data border border-gray-600">Accessory Items</th>
                        <th className="table-data border border-gray-600">Accessory Group</th>
                        <th className="table-data border border-gray-600">Colors</th>
                        <th className="table-data border border-gray-600">Size</th>
                        <th className="table-data border border-gray-600 ">UOM</th>
                        <th className="table-data border border-gray-600 ">Inward Qty</th>
                        <th className="table-data border border-gray-600 ">Stock Qty</th>
                        <th className="table-data border border-gray-600 ">Allowed Return Qty</th>
                        <th className="table-data border border-gray-600 ">Return Qty</th>
                        <th className="table-data border border-gray-600 ">Price</th>
                        <th className="table-data border border-gray-600 ">Gross</th>
                        {!readOnly &&
                            <th className='table-data border border-gray-600'>Delete</th>
                        }
                    </tr>
                </thead>
                <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>

                    {finalInwardItems.map((row, index) => (
                        <tr key={index} className="w-full">
                            <td className=" border border-gray-600  text-center">
                                {index + 1}
                            </td>
                            <td className=" border border-gray-600  text-center">
                                {row.poNo}
                            </td>
                            <td className='border border-black'>
                                {findFromList(row.accessoryId, accessoryList.data, "aliasName")}
                            </td>
                            <td className='border border-gray-600'>

                                {findAccessoryItemName(row.accessoryId)}
                            </td>
                            <td className='border border-gray-600'>
                                {findAccessoryGroupName(row.accessoryId)}
                            </td>
                            <td className='border border-black'>
                                {findFromList(row.colorId, colorList.data, "name")}
                            </td>
                            <td className='border border-black'>
                                {findFromList(row.sizeId, sizeList.data, "name")}
                            </td>
                            <td className='border border-black'>
                                {findFromList(row.uomId, uomList.data, "name")}
                            </td>
                            <td className=" border border-gray-600  text-right">
                                {(parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty)).toFixed(3)}
                            </td>
                            <td className="border border-gray-600  text-right">
                                {parseFloat(row.stockQty).toFixed(3)}
                            </td>
                            <td className=" border border-gray-600  text-right">
                                {getAllowableReturnQty(row.alreadyInwardedQty, row.alreadyReturnedQty, row.stockQty).toFixed(3)}
                            </td>
                            <td className=' border-black border-2'>
                                <input
                                    type="number"
                                    className="text-right rounded w-full"
                                    value={(!row.returnQty) ? 0 : row.returnQty}
                                    disabled={readOnly}
                                    onChange={(event) => {
                                        if (!event.target.value) {
                                            handleInputChange(0, index, "returnQty");
                                            return
                                        }
                                        if (isBetweenRange(0, getAllowableReturnQty(row.alreadyInwardedQty, row.alreadyReturnedQty, row.stockQty), event.target.value)) {
                                            handleInputChange(event.target.value.replace(/^0+/, ''), index, "returnQty")
                                        } else {
                                            toast.info("Return Qty Cannot be more than allowable Qty", { position: 'top-center' })
                                        }
                                    }}
                                    onBlur={(e) => {

                                        if (!e.target.value) {
                                            handleInputChange(0.000, index, "returnQty");
                                            return
                                        }
                                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "returnQty")
                                    }

                                    }
                                />
                            </td>
                            <td className='border border-black text-right' >
                                {parseFloat(row.price).toFixed(2)}
                            </td>
                            <td className='border border-black'>
                                {(row.price * row.returnQty).toFixed(2)}
                            </td>
                            {!readOnly
                                &&
                                <td className='border border-gray-600'>
                                    <div tabIndex={-1} onClick={() => removeItem(row.poNo, row.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                        {DELETE}
                                    </div>
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AccessoryReturnItems