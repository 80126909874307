import React, { useState } from "react";
import InvoiceExcel from "./InvoiceExcel";



export default function InvoiceSummary() {
    return <InvoiceExcel
        dataClick={(sampleId, registrationId) => {

        }}
    />
}