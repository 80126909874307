import React from 'react'
import SampleTestParameters from './SampleTestParameters'
import PageWrapper from '../PageWrapper'
import tw from '../../../../Utils/tailwind-react-pdf'
import ColourFastnessLegend from './ColourFastnessLegend'
import { Text, View } from "@react-pdf/renderer";

const TestResultLists = ({ reportData, passFail, splitTest, testResults, testReportNo, headingOptions }) => {
  return (
    <>
      {
        passFail === "PASS" && splitTest === "ACCREDITATION" ? (
          <PageWrapper headingOptions={headingOptions} style={tw("page")} accredidationLogo={true} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo} >
            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
              return <SampleTestParameters item={item} reportData={reportData?.data} />
            })}
            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
              return <SampleTestParameters item={item} reportData={reportData?.data}
              />
            })}
            <ColourFastnessLegend testResults={testResults} />
            {


              reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                ?
                <></> :
                <View>
                  <Text style={tw("text-center w-full mt-1 font-bold")}>
                    --- End of Test Report ---
                  </Text>
                </View>
            }
          </PageWrapper>
        )
          :
          passFail === "PASS" && splitTest === "NONACCREDITATION" ? (
            <PageWrapper headingOptions={headingOptions} style={tw("page")} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                return <SampleTestParameters item={item} reportData={reportData?.data} />
              })}
              {/* <ColourFastnessLegend testResults={testResults} /> */}
              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                return <SampleTestParameters item={item} reportData={reportData?.data} />
              })}
              <ColourFastnessLegend testResults={testResults} />
              {


                reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                  || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                  ?
                  <></> :
                  <View>
                    <Text style={tw("text-center w-full mt-5 font-bold")}>
                      --- End of Test Report ---
                    </Text>
                  </View>
              }

            </PageWrapper>
          )
            :
            passFail === "PASS" && splitTest === "ALL" ? (
              <PageWrapper headingOptions={headingOptions} style={tw("page")} accredidationLogo={true} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
                {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters item={item} reportData={reportData?.data} />
                })}


                {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters item={item} reportData={reportData?.data} />
                })}

                {/* <ColourFastnessLegend testResults={testResults} /> */}

                {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters item={item} reportData={reportData?.data} />
                })}

                {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters item={item} reportData={reportData?.data} />
                })}
                <ColourFastnessLegend testResults={testResults} />
                {


                  reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                    || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                    ?
                    <></> :
                    <View>
                      <Text style={tw("text-center w-full mt-5 font-bold")}>
                        --- End of Test Report ---
                      </Text>
                    </View>
                }

              </PageWrapper>
            )
              :
              passFail === "FAIL" && splitTest === "ACCREDITATION" ? (
                <PageWrapper headingOptions={headingOptions} style={tw("page")} accredidationLogo={true} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
                  {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                    return <SampleTestParameters item={item} reportData={reportData?.data} />
                  })}
                  {/* <ColourFastnessLegend testResults={testResults} /> */}

                  {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                    return <SampleTestParameters item={item} reportData={reportData?.data} />
                  })}
                  <ColourFastnessLegend testResults={testResults} />
                  {


                    reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                      || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                      ?
                      <></> :
                      <View>
                        <Text style={tw("text-center w-full mt-5 font-bold")}>
                          --- End of Test Report ---
                        </Text>
                      </View>
                  }
                </PageWrapper>
              )
                :
                passFail === "FAIL" && splitTest === "NONACCREDITATION" ? (
                  <PageWrapper headingOptions={headingOptions} style={tw("page")} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
                    {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                      return <SampleTestParameters item={item} reportData={reportData?.data} />
                    })}

                    {/* <ColourFastnessLegend testResults={testResults} /> */}

                    {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                      return <SampleTestParameters item={item} reportData={reportData?.data} />
                    })}

                    <ColourFastnessLegend testResults={testResults} />
                    {


                      reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                        || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                        ?
                        <></> :
                        <View>
                          <Text style={tw("text-center w-full mt-5 font-bold")}>
                            --- End of Test Report ---
                          </Text>
                        </View>
                    }
                  </PageWrapper>
                )
                  :

                  passFail === "FAIL" && splitTest === "ALL" ? (

                    <PageWrapper headingOptions={headingOptions} style={tw("page")} accredidationLogo={true} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
                      {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters item={item} reportData={reportData?.data} />
                      })}

                      {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters item={item} reportData={reportData?.data} />
                      })}

                      {/* <ColourFastnessLegend testResults={testResults} /> */}

                      {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters item={item} reportData={reportData?.data} />
                      })}


                      {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters item={item} reportData={reportData?.data} />
                      })}

                      <ColourFastnessLegend testResults={testResults} />
                      {


                        reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                          || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                          ?
                          <></> :
                          <View>
                            <Text style={tw("text-center w-full mt-5 font-bold")}>
                              --- End of Test Report ---
                            </Text>
                          </View>
                      }

                    </PageWrapper>
                  )
                    :

                    passFail === "ALL" && splitTest === "ACCREDITATION" ? (
                      <PageWrapper headingOptions={headingOptions} style={tw("page")} accredidationLogo={true} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>

                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                          return <SampleTestParameters item={item} reportData={reportData?.data} />
                        })}



                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                          return <SampleTestParameters item={item} reportData={reportData?.data} />
                        })}
                        {/* <ColourFastnessLegend testResults={testResults} /> */}

                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                          return <SampleTestParameters item={item} reportData={reportData?.data} />
                        })}

                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                          return <SampleTestParameters item={item} reportData={reportData?.data} />
                        })}

                        <ColourFastnessLegend testResults={testResults} />
                        {


                          reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                            || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                            ?
                            <></> :
                            <View>
                              <Text style={tw("text-center w-full mt-5 font-bold")}>
                                --- End of Test Report ---
                              </Text>
                            </View>
                        }

                      </PageWrapper>
                    )
                      :
                      passFail === "ALL" && splitTest === "NONACCREDITATION" ? (
                        <PageWrapper headingOptions={headingOptions} style={tw("page")} reportData={reportData} testHeading={true} splitTest={splitTest} testReportNo={testReportNo}>
                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                            return <SampleTestParameters item={item} reportData={reportData?.data} />
                          })}

                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <SampleTestParameters item={item} reportData={reportData?.data} />
                          })}

                          {/* <ColourFastnessLegend testResults={testResults} /> */}


                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                            return <SampleTestParameters item={item} reportData={reportData?.data} />
                          })}



                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <SampleTestParameters item={item} reportData={reportData?.data} />
                          })}

                          <ColourFastnessLegend testResults={testResults} />
                          {


                            reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                              || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                              ?
                              <></> :
                              <View>
                                <Text style={tw("text-center w-full mt-5 font-bold")}>
                                  --- End of Test Report ---
                                </Text>
                              </View>
                          }
                        </PageWrapper>
                      )
                        :
                        passFail === "ALL" && splitTest === "ALL" ? (
                          <>
                            <PageWrapper headingOptions={headingOptions} style={tw("page")} accredidationLogo={true} testHeading={true} reportData={reportData} splitTest={splitTest} testReportNo={testReportNo}>
                              {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}
                              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}
                              {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(j => j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}
                              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(j => j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}
                              {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}
                              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}
                              {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(j => !j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}

                              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(j => !j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                                return <SampleTestParameters item={item} reportData={reportData?.data} />
                              })}
                              <ColourFastnessLegend testResults={testResults} />
                              {
                                reportData?.data?.testedImages?.length > 0 || (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                                  || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
                                  ?
                                  <></> :
                                  <View>
                                    <Text style={tw("text-center w-full mt-5 font-bold")}>
                                      --- End of Test Report ---
                                    </Text>
                                  </View>
                              }
                            </PageWrapper>
                          </>
                        )
                          : ""
      }
    </>
  )
}

export default TestResultLists