import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { CheckBox, DisabledInput } from "../../../Inputs";
import { findFromList } from "../../../Utils/helper";
import { Loader } from "../../../Basic/components";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import { useGetRegistrationByIdQuery, useUpdateRegistrationMutation } from "../../../redux/LabServices/RegistrationServices";
import SampleTestDetails from "./SampleTestDetails";
import {
    useAddLabTestResultEntryMutation,
    useDeleteLabTestResultEntryMutation,

    useGetLabTestResultEntryByIdQuery,
    useGetLabTestResultEntryQuery,
} from "../../../redux/LabServices/LabTestResultEntryServices";
import { useGetSampleDescriptionTwoMasterQuery } from "../../../redux/LabServices/SampleDescriptionTwoMasterServices";
import { useGetRolesQuery } from "../../../redux/services/RolesMasterService";
import { useGetUserByIdQuery } from "../../../redux/services/UsersMasterService";
import { useGetSampleDescriptionFourMasterQuery } from "../../../redux/LabServices/SampleDescriptionFourMasterServices";
import { useGetSampleDescriptionThreeMasterQuery } from "../../../redux/LabServices/SampleDescriptionThreeMasterServices";
import { useGetSampleDescriptionOneMasterQuery } from "../../../redux/LabServices/SampleDescriptionOneMasterServices";

const MODEL = "Result Entry";

export default function Form({ registrationSampleDetailsId, setRegistrationSampleDetailsId, registrationId, setRegistrationId }) {
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [fiberContentId, setFiberContentId] = useState("");
    const [active, setActive] = useState(true);
    const [docId, setDocId] = useState("")
    const [formReport, setFormReport] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);
    const [registerId, setRegisterId] = useState("")
    const [resultDetails, setResultDetails] = useState([]);
    const [results, setResults] = useState([]);
    const [fiberHeading, setFiberHeading] = useState("");
    const [fiberIndex, setFiberIndex] = useState("");
    const [isFiberContent, setIsFiberContent] = useState(false)
    const [requirementList, setRequirementList] = useState([]);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),

    };

    const userId = {
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        ),
    };



    const {
        data: singleUserData,

    } = useGetUserByIdQuery(userId.userId, { skip: !userId.userId });

    const { data: rolesData } = useGetRolesQuery({ params });

    const {
        data: singleRegistrationData,
        isFetching: isSingleRegistrationDataFetching,
        isLoading: isSingleRegistrationDataLoading,
    } = useGetRegistrationByIdQuery({ id: registrationId }, { skip: !registrationId });





    const registrationSampleDetails = singleRegistrationData?.data?.RegistrationSampleDetails ? singleRegistrationData.data.RegistrationSampleDetails : []

    const matchingSampleDetails = registrationSampleDetails?.find(item => parseInt(item.id) === parseInt(registrationSampleDetailsId))


    const matchingSampleTestDetails = matchingSampleDetails?.SampleTestDetails?.filter(item => singleUserData?.data?.role?.testGroupList?.find(value => value.label.toLowerCase() == item?.LabTest?.LabTestSubGroup?.LabTestGroup?.name.toLowerCase()) || singleUserData?.data?.role?.name?.toLowerCase() == "default admin")




    const { data: sampleTwoList } = useGetSampleDescriptionTwoMasterQuery({ params });

    const sampleTwoId = findFromList(matchingSampleDetails?.sampleTwoId, sampleTwoList?.data, "id")



    const { data: buyerList } =
        useGetPartyQuery({ params });


    const { data: resultEntryDatas, isLoading: isResultEntryLoadingDatas, isFetching: isResultEntryFetchingDatas } = useGetLabTestResultEntryQuery({
        params: {
            ...params,
            registrationSampleDetailsId: registrationSampleDetailsId ? structuredClone(registrationSampleDetailsId) : undefined
        }
    }, { refetchOnMountOrArgChange: true });

    const { data: allData } = useGetLabTestResultEntryQuery({ params, searchParams: searchValue });


    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetLabTestResultEntryByIdQuery(id, { skip: !id });


    const [addData] = useAddLabTestResultEntryMutation();
    const [removeData] = useDeleteLabTestResultEntryMutation();
    const { data: sampleFourData } = useGetSampleDescriptionFourMasterQuery({ params });
    const { data: sampleThreeData } = useGetSampleDescriptionThreeMasterQuery({ params });
    const { data: sampleTwoData } = useGetSampleDescriptionTwoMasterQuery({ params });
    const { data: sampleOneData } = useGetSampleDescriptionOneMasterQuery({ params });


    const getNextDocId = useCallback(() => {
        if (id) return
        if (allData?.nextDocId) {
            setDocId(allData.nextDocId)
        }
    }, [allData, id])

    useEffect(getNextDocId, [getNextDocId])

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            else setReadOnly(false)
            if (data?.docId) {
                setDocId(data?.docId)
            }
            setActive(id ? (data?.active ? data.active : false) : true);
        },
        [id]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, syncFormWithDb, singleData]);


    useEffect(() => {
        setRegisterId(singleRegistrationData?.data?.docId ? singleRegistrationData.data.docId : "")
    }, [registrationId, singleRegistrationData, isSingleRegistrationDataFetching, isSingleRegistrationDataLoading])

    const data = {
        id, labTestResultDetails: resultDetails?.map(i => ({ ...i, isFiberContent })),
        registrationSampleDetailsId, active,
        companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"),
        resultEntriesCompleted: true, userId: userId.userId
    }

    // const validateData = (data) => {
    //     if (data.testConditionId && data.testId && data.partyId && data.methodList) {
    //         return true;
    //     }
    //     return false;
    // }


    const validateResultValue = (resultDetails) => {

        if (isFiberContent) {
            return true
        }
        else {
            let resultValues = resultDetails?.filter(i => !i?.isDeleted).flatMap(val => val.resultValue)
            let filterResultValues = resultValues?.filter(val => val.value == "")
            if (filterResultValues.length == 0) {
                return true;
            }
            return false;
        }



    }

    async function handleSubmitCustom(callback, data, text) {
        try {
            let returnData = await callback(data).unwrap();
            setId("");
            setRegistrationId("")
            syncFormWithDb(undefined);
            toast.success(text + "Successfully");

        } catch (error) {
            console.log("handle");
        }
    }

    const handleUpdateRegistration = async (callback, registerdata, text, isDataClear = false) => {
        try {
            await callback(registerdata).unwrap();
            //    toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = (isDataClear = false) => {
        if (!validateResultValue(resultDetails)) {
            toast.info("Please fill all result Values ...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        handleSubmitCustom(addData, data, "Added");
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");

        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };
    useEffect(() => {
        if (!resultEntryDatas?.data || isResultEntryLoadingDatas || isResultEntryFetchingDatas) return
        setResults(resultEntryDatas?.data)
    }, [resultEntryDatas, isResultEntryLoadingDatas, isResultEntryFetchingDatas, setResults])


    const isLastResultExist = useCallback((labTestRequirementDetailsId, testId) => {
        let findOutTestId = resultEntryDatas?.data?.filter(item => item.testId == testId)
        if (findOutTestId.length !== 0) {
            if (findOutTestId.length === 0) return true
            let isEntry = findOutTestId.find(i => parseInt(i.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId))


            if (isEntry?.isDeleted) {

                return false
            }
            else {

                return true
            }

        }
        return true
    }, [resultEntryDatas])

    if (!resultEntryDatas || !rolesData) return <Loader />
    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setSearchValue("");
                        setRegistrationSampleDetailsId("");
                        setResults([]);
                        setResultDetails([]);
                    }}
                    model={MODEL}
                    saveData={saveData}

                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                />

                <div className="flex-1 grid gap-x-2">
                    <div className="col-span-3 grid overflow-auto">
                        <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"}`}>
                            <div className={`${formReport ? "col-span-12" : "col-span-1"}`}>
                                <fieldset className='frame p-1'>
                                    <legend className='sub-heading'>Test Requirement Info</legend>
                                    <div className="grid grid-cols-5 gap-y-2">
                                        <DisabledInput name="Reg. ID" value={registerId} required={true} readOnly={readOnly} />

                                        <DisabledInput name="Samples" value={findFromList(registrationSampleDetailsId, singleRegistrationData?.data?.RegistrationSampleDetails, "sampleId")} required={true} readOnly={readOnly} />

                                        <DisabledInput name="Buyer Name" value={findFromList(singleRegistrationData?.data?.buyerId, buyerList.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 1" value={findFromList(matchingSampleDetails?.sampleOneId, sampleOneData?.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 2" value={findFromList(matchingSampleDetails?.sampleTwoId, sampleTwoData?.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 3" value={findFromList(matchingSampleDetails?.sampleThreeId, sampleThreeData?.data, "name")} required={true} readOnly={readOnly} />
                                        <DisabledInput name="SampleType 4" value={findFromList(matchingSampleDetails?.sampleFourId, sampleFourData?.data, "name")} required={true} readOnly={readOnly} />
                                    </div>

                                </fieldset>

                                <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>

                                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-5 w-full flex min-h-[350px] overflow-auto border border-gray-600'>

                                        <legend className='sub-heading'>Test Info</legend>
                                        <div className='grid my-2 w-full'>

                                            {(matchingSampleTestDetails ? matchingSampleTestDetails : [])
                                                .map((item, itemIndex) =>
                                                    <SampleTestDetails
                                                        registrationId={registrationId}
                                                        isLastResultExist={isLastResultExist}
                                                        requirementList={requirementList}
                                                        setRequirementList={setRequirementList}
                                                        isFiberContent={isFiberContent} setIsFiberContent={setIsFiberContent}
                                                        setFiberIndex={setFiberIndex} fiberIndex={fiberIndex} setFiberHeading={setFiberHeading}
                                                        fiberHeading={fiberHeading} setFiberContentId={setFiberContentId}
                                                        fiberContentId={fiberContentId}
                                                        sampleTwoId={sampleTwoId}
                                                        key={item.testId} results={results} id={id} setId={setId}
                                                        resultDetails={resultDetails} setResultDetails={setResultDetails}
                                                        item={item} itemIndex={itemIndex} matchingSampleDetails={matchingSampleDetails}
                                                        singleRegistrationData={singleRegistrationData} registerId={registerId}
                                                        registrationSampleDetailsId={registrationSampleDetailsId} readOnly={readOnly}
                                                        childRecord={childRecord} resultDatas={resultEntryDatas?.data} />
                                                )}



                                        </div>
                                    </fieldset>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    );

}