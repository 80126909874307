import React, { useState } from "react";
import LogoutExcel from "./LogoutExcel";


export default function LogOutSummary() {
    return <LogoutExcel
        dataClick={(sampleId, registrationId) => {

        }}
    />
}