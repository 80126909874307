import React from 'react'
import moment from 'moment'
import { Image, Text, View } from '@react-pdf/renderer';
import tw from '../../../../Utils/tailwind-react-pdf';
import OverAllResult from './OverAllResult';
import RegistrationDetail from '../RegistrationDetail';
import { getImageUrlPath } from '../../../../Utils/helper';
import WaterMarkSymbol from '../../../../Utils/WaterMarkSymbol';

const SampleDetails = ({ washCareData, data, splitTest, testReportNo }) => {
  function findDays(workingDays, serviceDays) {
    if (parseInt(serviceDays) >= parseInt(workingDays)) {
      return workingDays
    }
    else {
      return "#"
    }
  }

  function readyMadeArray(motherArray) {
    let claimedArray = [];

    motherArray.map(val => {
      val.claimedDetails?.forEach(item => {
        claimedArray.push({
          sample: val?.sampleId?.slice(-1),
          sampleId: val?.sampleId,
          subHeading: item?.subHeading,
          fiberContentId: item?.fiberContentId,
          name: item?.FiberContent?.aliasName

        })
      })
    })

    return claimedArray
  }

  function claimedContentArray(motherArray) {
    let claimedArray = [];

    motherArray.map(val => {
      val.claimedDetails?.forEach(item => {
        claimedArray.push({
          sample: val?.sampleId?.slice(-1),
          sampleId: val?.sampleId,
          subHeading: item?.subHeading,
          value: item?.value,
          name: item?.UnitOfMeasurement?.name,
          unitId: item?.UnitOfMeasurement?.id,

        })
      })
    })

    return claimedArray
  }


  function getData(array) {



    const uniqueIds = new Set();
    return array.filter((item) => {
      if (!uniqueIds.has(item.fiberContentId)) {
        uniqueIds.add(item.fiberContentId);
        return true;
      }
      return false;
    });

  }

  function getArrayData(array) {



    const uniqueIds = new Set();
    return array.filter((item) => {
      if (!uniqueIds.has(item.value) && !uniqueIds.has(item.subHeading) && !uniqueIds.has(item.name)) {
        uniqueIds.add(item.value, item.subHeading, item.name);
        return true;
      }
      return false;
    });

  }



  function getSamples(fiberId, array) {

    let samples = array?.filter(item => parseInt(item?.fiberContentId) === parseInt(fiberId))
    return samples

  }


  function getSamplesClaimed(id, value, array) {


    let samples = array?.filter(item => parseInt(item?.unitId) === parseInt(id) && item?.value == value)
    return samples

  }


  return (
    <View style={tw('px-2 capitalize  ml-9 text-xs  break-keep w-full')} >

      <View style={tw("flex flex-row gap-x-16 w-full")}>
        <View style={tw('w-1/2 p-1 -mt-2')}>



          <View style={tw(' flex flex-row gap-x-12 justify-start w-full')}>
            <Text style={[tw('  pt-0.5 font-bold break-keep w-1/2'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Test Report No :  </Text>
            <Text style={tw(' pt-0.5 w-1/2 ml-7')}>{testReportNo}</Text>
          </View>




          <View style={tw('pt-0.5  w-full ')}>

            <Text style={tw('')}> {splitTest == "ACCREDITATION" && data?.testDetailsSummary?.accredidatedResults.length > 0 && data?.ulrNo ? `${data?.ulrNo}${"F"}` : splitTest == "NONACCREDITATION" ? "" : splitTest == "ALL" && data?.testDetailsSummary?.nonAccredidatedResults.length > 0 && data?.ulrNo ? `${data?.ulrNo}${"P"}` : data?.ulrNo ? `${data?.ulrNo}${"F"}` : "--"}</Text>
          </View>

          <View style={tw('flex flex-row gap-x-12 justify-start w-full mt-1')}>
            <Text style={[tw(' pt-1 font-bold break-keep  w-1/2'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Sample LogIn Date:  </Text>
            <Text style={tw(' pt-1 w-1/2 ml-7')}>{moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")}</Text>
          </View>




          <View style={tw('flex flex-row gap-x-12 justify-start w-full')}>
            <Text style={[tw(' pt-1 font-bold break-keep  w-1/2 -ml-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}> Date&nbsp;of&nbsp;Test&nbsp;Period:</Text>
            <Text style={tw(' pt-1 w-1/2 ml-6')}> {`${moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")} - ${data?.logoutDate ? moment.utc(data?.logoutDate).format("DD-MM-YYYY") : ""}`}</Text>
          </View>



          <View style={tw(' flex flex-row gap-x-12 justify-start w-full')}>
            <Text style={[tw(' pt-1 font-bold break-keep w-1/2 -ml-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}> Date Out :</Text>
            <Text style={tw(' pt-1 w-1/2 ml-6')}>  {data?.logoutDate ? moment.utc(data?.logoutDate).format("DD-MM-YYYY") : ""}</Text>
          </View>
          {data?.amendmentReason &&
            <>
              <View style={tw(' flex flex-row gap-x-12 justify-start w-full')}>
                <Text style={[tw(' pt-1 font-bold break-keep w-1/2 -ml-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}> Amendment <Text>{data?.testReportNo?.slice(-1)}</Text> Date:</Text>
                <Text style={tw(' pt-1 w-1/2 ml-6')}>  {moment.utc(data?.amendmentLogOutDate).format("DD-MM-YYYY")}</Text>
              </View>
              {/* <View style={tw(' flex flex-row gap-x-12 justify-start w-full')}>
                <Text style={[tw(' pt-1 font-bold break-keep w-1/2 -ml-1'), { fontWeight: 900, fontFamily: "Times-Bold" }]}> Amendment Reason :</Text>
                <Text style={tw(' pt-1 w-1/2 ml-6 capitalize')}>  {data?.amendmentReason.toLowerCase() || ""}</Text>
              </View> */}
            </>
          }
          <View style={tw(' flex flex-row gap-x-12 justify-start w-full')}>
            <Text style={[tw('pt-1 font-bold break-keep w-1/2 '), { fontWeight: 900, fontFamily: "Times-Bold" }]}>No Of Working Days:</Text>
            <Text style={tw('pt-1 w-1/2 ml-7')}>   {findDays(data?.noOfWorkingDays, data?.serviceDays)}</Text>
          </View>


        </View>

        <View style={tw("w-1/2")}>
          <OverAllResult splitTest={splitTest} data={data} />
        </View>
      </View>




      {/* <View style={tw(' flex justify-between flex-row w-full')}>
        <View style={tw('flex flex-row items-center justify-between w-1/2 py-1')}>
          <Text style={tw('  p-1 font-bold ')}>
            Sample LogIn Date:
          </Text>
          <Text>{moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")}</Text>
        </View>
        <View style={tw('flex flex-row items-center justify-end w-1/2 py-1')}>
          <Text style={tw('  p-1 font-bold ')}>Date&nbsp;of&nbsp;Test&nbsp;Period:</Text>
          <Text>
            {`${moment.utc(data?.sampleLoginDate).format("DD-MM-YYYY")} - ${moment.utc(data?.dateOut).format("DD-MM-YYYY")}`}
          </Text>
        </View>
      </View> */}




      <View style={tw('w-full')}>
        <View style={tw(' flex flex-row gap-x-1 pt-1')}>
          <Text style={[tw('pt-1 font-bold break-keep w-2/6'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Company Name & Address:</Text>
          <Text style={tw(' pt-1  w-4/6 -ml-10 capitalize')}>{`${data?.companyName.toLowerCase() || ""} & ${data?.companyAddress.toLowerCase() || ""}`}</Text>
        </View>

        <View style={tw(' flex flex-row gap-x-1 pt-1')}>
          <Text style={[tw('pt-1 font-bold break-keep w-2/6'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Contact Name : </Text>
          {/* <Text style={tw('pt-1 w-4/6 -ml-10')}>{(data?.companyContact ? data?.companyContact : []).map(name => name.contactPersonName).join("/")}</Text> */}
          <Text style={tw('pt-1 w-4/6 -ml-10')}>{data?.submittedBy.toLowerCase() || ""}</Text>
        </View>






        <RegistrationDetail heading={"Sample Description:"} value={data?.sampleDescription} />{console.log(data, "dataaaaa")}
        <RegistrationDetail heading={"Style No:"} value={data?.styleNo} />
        <RegistrationDetail heading={"Dept No:"} value={data?.departmentNo} />
        <RegistrationDetail heading={"Order No:"} value={data?.orderNo} />
        <RegistrationDetail heading={"Batch/Lot No:"} value={data?.batchNo} />
        <RegistrationDetail heading={"Color Name:"} value={data?.colorName} />
        <RegistrationDetail heading={"Season:"} value={data?.season} />
        <RegistrationDetail heading={"Phase:"} value={data?.phase} />
        <RegistrationDetail heading={"Fabric Supplier / Mill Name:"} value={data?.fabricSupplierMillName} />
        <RegistrationDetail heading={"Age Range:"} value={data?.ageSizeRange} />
        <RegistrationDetail heading={"Submitted Size:"} value={data?.submittedSize} />
        <RegistrationDetail heading={"Country Of Origin:"} value={data?.countryOfOrigin} />
        <RegistrationDetail heading={"Country Of Destination:"} value={data?.countryOfDestination?.map(val => `  ${val}   `).join("&")} />
        <RegistrationDetail heading={"End Use:"} value={data?.endUse?.map(val => `    ${val}     `).join("&")} />


        <View style={tw(' flex flex-row gap-x-1 pt-0.5 ')}>
          <Text style={tw(' font-bold break-keep pt-1 w-2/6 ')}>Claimed Fabric Weight:</Text>
          <Text style={tw(' w-4/6 pt-1 -ml-10 capitalize')}>{data?.samples.length > 1 ? (getArrayData(claimedContentArray(data?.claimedFabricWeight)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedFabricWeight))?.map(j => j.sample)?.join(" & ")}  ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `) : (getArrayData(claimedContentArray(data?.claimedFabricWeight)))?.map(val => `  ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"} ${val?.value} ${val?.name},   `)}</Text>
        </View>

        <View style={tw(' flex flex-row gap-x-1 pt-0.5 ')}>
          <Text style={tw(' font-bold break-keep pt-1 w-2/6 ')}>Claimed Yarn Count:</Text>
          <Text style={tw(' w-4/6 pt-1 -ml-10 capitalize')}>{data?.samples.length > 1 ? (getArrayData(claimedContentArray(data?.claimedYarnCount)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedYarnCount))?.map(j => j.sample)?.join(" & ")}  ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `) : (getArrayData(claimedContentArray(data?.claimedYarnCount)))?.map(val => `  ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"} ${val?.value} ${val?.name},   `)}</Text>
        </View>

        <View style={tw(' flex flex-row gap-x-1 pt-0.5 ')}>
          <Text style={tw(' font-bold break-keep pt-1 w-2/6 ')}>Claimed Fabric Count:</Text>
          <Text style={tw(' w-4/6 pt-1 -ml-10 capitalize')}>{data?.samples.length > 1 ? (getArrayData(claimedContentArray(data?.claimedFabricCount)))?.map(val => `${getSamplesClaimed(val?.unitId, val?.value, claimedContentArray(data?.claimedFabricCount))?.map(j => j.sample)?.join(" & ")}  ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.value} ${val?.name},   `) : (getArrayData(claimedContentArray(data?.claimedFabricCount)))?.map(val => `  ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"} ${val?.value} ${val?.name},   `)}</Text>
        </View>

        <View style={tw(' flex flex-row gap-x-1 pt-0.5 ')}>
          <Text style={tw(' font-bold break-keep pt-1 w-2/6 ')}>Claimed Fiber Content:</Text>
          <Text style={tw(' w-4/6 pt-1 -ml-10 capitalize')}>{data?.samples.length > 1 ? (getData(readyMadeArray(data?.claimedFiberContent)))?.map(val => `${getSamples(val?.fiberContentId, readyMadeArray(data?.claimedFiberContent))?.map(j => j.sample)?.join(" & ")} ${val?.subHeading == "BASE" ? "" : val?.subHeading}--${val?.name},   `) : (getData(readyMadeArray(data?.claimedFiberContent)))?.map(val => ` ${val?.subHeading == "BASE" ? "" : val?.subHeading} ${val?.subHeading == "BASE" ? "" : "--"} ${val?.name},   `)}</Text>
        </View>

        {/* <RegistrationDetail heading={"Claimed Fabric Weight:"} value={`${data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""} ${(data?.claimedFabricWeight ? data?.claimedFabricWeight : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `    ${item.subHeading} -  ${item.value}  ${item.UnitOfMeasurement.name}  `) : "--").join("&")}`} />
        <RegistrationDetail heading={"Claimed Yarn Count:"} value={`${data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""} ${(data?.claimedYarnCount ? data?.claimedYarnCount : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `    ${item.subHeading} -  ${item.value} ${item.UnitOfMeasurement.name}  `) : "--").join("&")}`} />
        <RegistrationDetail heading={"Claimed Fabric Count:"} value={`${data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""} ${(data?.claimedFabricCount ? data?.claimedFabricCount : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `    ${item.subHeading} -  ${item.value}  ${item.UnitOfMeasurement.name}  `) : "--").join("&")}`} />
        <RegistrationDetail heading={"Claimed Fiber Content:"} value={`${data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""} ${(data?.claimedFiberContent ? data?.claimedFiberContent : []).map(sampleItem => sampleItem.claimedDetails.length > 0 ? sampleItem.claimedDetails.map(item => `    ${item.subHeading} -  ${item?.FiberContent?.aliasName}  `) : "--").join("&")}`} /> */}
        <RegistrationDetail heading={"Actual Fiber Content:"} value={data?.actualFiberContent || ""} />
        <RegistrationDetail heading={"Suggested Fiber Content:"} value={data?.suggestedFiberContent || ""} />
        <RegistrationDetail heading={"Product Category:"} value={data?.productCategory} />
        <RegistrationDetail heading={"Test Stage (Package):"} value={data?.testStage} />
        <RegistrationDetail heading={"Previous Report No:"} value={data?.prevReportNo} />
        <View style={tw("  flex flex-row gap-x-1 mt-1 w-full p-1")}>
          <Text style={tw('font-bold  w-2/6')}>Submitted Washcare Instruction :</Text>
          <View style={tw("-ml-9 w-4/6 p-1")}>
            <Text style={tw('p-0.5')}>{data?.samples.length > 1 ? data?.samples.map(val => `${val}`).join("&") : ""}</Text>
            <View style={tw("flex flex-row flex-wrap w-full gap-x-5 gap-y-4")}>
              {(data?.groupedWashCareImages ? data?.groupedWashCareImages : []).map((val) =>
                <Image key={val.washCareId} src={getImageUrlPath(val?.image ? val?.image : "")} style={{ height: "30px", width: "30px", objectFit: 'fit' }} />
              )}

            </View>
          </View>
        </View>
        <View style={tw(" flex flex-row gap-x-1 m-0.5 w-full")}>
          <Text style={[tw('font-bold w-2/6'), { fontWeight: 900, fontFamily: "Times-Bold" }]}></Text>
          <Text style={tw('-ml-9 w-4/6')}> {data?.groupedSpecialInstructions.length > 1 ? (data?.groupedSpecialInstructions ? data?.groupedSpecialInstructions : []).flatMap(val => `${val.samples.map(i => i)} --  ${val.specialInstruction} `).join("/") : data?.groupedSpecialInstructions.map(val => val.specialInstruction)}  </Text>

        </View>






        <View style={tw(" flex flex-row  gap-x-1  w-full")}>
          <Text style={[tw('pt-1 font-bold break-keep w-2/6'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Conditioning :</Text>
          <Text style={tw('pt-1 w-4/6 -ml-10')}> {data?.conditioning ? data?.conditioning.toLowerCase() : ""} </Text>

        </View>
        <View style={tw(" flex flex-row  gap-x-1  w-full")}>
          <Text style={[tw('pt-1 font-bold break-keep w-2/6'), { fontWeight: 900, fontFamily: "Times-Bold" }]}>Remarks :</Text>
          <Text style={tw('pt-1 w-4/6 -ml-10')}> {data?.serviceRemarks ? data?.serviceRemarks.toLowerCase() : ""} </Text>

        </View>



        {data?.amendmentReason &&
          <>
            <View style={tw(" flex flex-row  gap-x-1 mt-2  w-full")}>
              <Text style={{ fontWeight: 900, fontFamily: "Times-Bold" }} >Amendment <Text>{data?.testReportNo?.slice(-1)}</Text> Reason :</Text>
              <Text  > {data?.amendmentReason} </Text>
            </View>
          </>
        }

      </View>
    </View>

  )
}

export default SampleDetails