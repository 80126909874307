import React from 'react'
import { getImageUrlPath } from '../../../Utils/helper'
import empty from "../../../assets/empty.png"

const TestedImages = ({ img, imgIndex, splitTest }) => {

  const imageWidth = "200px"
  const imageHeight = "200px";

  const image = img?.picture
  return (
    <>
      <div className='flex flex-col  gap-y-2 max-w-md'>



        <div className='text-left  capitalize w-48 h-20'>
          {img?.comment ? img?.comment.toLowerCase() : ""}
        </div>


        <div className='border border-gray-600 w-48  p-2'>

          {Boolean(image) ?
            <img style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
              src={getImageUrlPath(image)}
            />
            :
            <img src={empty} className='w-36 text-center' />
          }


        </div>


      </div>
    </>
  )
}

export default TestedImages