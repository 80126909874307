import React from 'react'
import { useState } from 'react'
import { HAMBURGER_ICON } from '../../icons'

const PopupSelect = ({ options, onChange, value }) => {
    const [show, setShow] = useState(false)
    return (
        <div className='flex relative gap'>
            <button onClick={() => setShow(prev => !prev)}>
                {HAMBURGER_ICON}
            </button>
            {show &&
                <select name="" id="" className='absolute top-7 p-1 bg-gray-300' value={value} onChange={onChange}>
                    {options.map(item =>
                        <option className='capitalize' key={item} value={item}>{item}</option>
                    )}
                </select>
            }
        </div>
    )
}

export default PopupSelect