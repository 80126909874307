import React, { Fragment } from 'react'
import SampleTest from './SampleTest'

const TestHeading = ({ item, heading, headingIndex, reportData }) => {
  return (
    <div>

      {
        heading?.requirementResult
          ?.flatMap(item => item.result.flatMap(val => val.result)).length > 0
          ?
          <>
            <div className='pl-1 capitalize font-bold  text-center'>
              {heading?.heading}
            </div>
            {
              reportData?.samples
                ?.filter((val, valIndex) =>
                  reportData?.samples.length > 1
                    ?
                    heading?.requirementResult.some(test => {
                      return (test?.result.find(i => (i.sampleId.split("-")[1]) === val)?.result || []).length > 0
                    })
                    : true
                )
                .map((val, valIndex) =>
                  <Fragment key={valIndex}>
                    <div className='flex  w-full place-items-center p-1 mt-5'>
                      <div className='capitalize text-xs text-left w-[30%] mt-2'>
                      </div>
                      <div className='capitalize text-xs  flex gap-x-16 justify-center items-center w-[50%]'>
                        {reportData?.samples?.length > 1 ? val : ""}
                      </div>
                      <div className='capitalize text-xs text-center w-[20%]'>
                      </div>
                    </div>
                    <div className='flex w-full '>
                      <div className='capitalize text-xs text-left w-[30%] '>
                      </div>
                      <div className='capitalize text-xs  text-center w-[50%]'>
                        {
                          item?.isRemovalForce ? (
                            <>
                              <div className='flex gap-x-6 overflow-auto w-full'>
                                <div className='font-bold w-[25%]'>
                                  Type of Component
                                </div>
                                <div className='font-bold w-[25%]'>
                                  Area of Component
                                </div>
                                <div className='font-bold w-[25%]'>
                                  Result
                                </div>
                                <div className='font-bold w-[25%]'>
                                  Mode Of failure
                                </div>
                              </div>
                            </>
                          ) : <></>
                        }
                      </div>
                      <div className=' capitalize text-xs text-center  w-[20%]'>

                      </div>
                    </div>
                    <div className='w-full p-1'>
                      {/* {heading?.requirementResult?.filter(val => val.result?.flatMap(j => j.result)?.length > 0)?.map((test, testIndex) => { */}
                      {heading?.requirementResult?.map((test, testIndex) => {

                        return <SampleTest key={testIndex} val={val} item={item} test={test} testIndex={testIndex} reportData={reportData} valIndex={valIndex} />
                      }
                      )}
                    </div>
                  </Fragment>
                )

            }

          </>
          :
          []

      }
    </div>

  )
}

export default TestHeading