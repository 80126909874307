import React from 'react';
import ResultValue from './ResultValue';
import { Text, View } from '@react-pdf/renderer';
import tw from '../../../../Utils/tailwind-react-pdf';


const Result = ({ item, result, resultIndex, reportData }) => {


  return (
    <>
      {
        item?.isRemovalForce ? (
          <>
            {
              result?.result?.filter((test, testIndex) => test.isReport).map((test, testIndex) => {
                return <ResultValue key={testIndex} item={item} test={test} testIndex={testIndex} reportData={reportData} />
              }
              )
            }
          </>) :
          (
            <View style={tw(" ")}>
              {
                result?.result?.filter((test, testIndex) => test.isReport).map((test, testIndex) => {
                  return <ResultValue key={testIndex} item={item} test={test} testIndex={testIndex} reportData={reportData} />
                }
                )
              }

            </View>

          )

      }
    </>
  );
}

export default Result;
