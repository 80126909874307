import React from 'react'
import PrintSheet from './PrintSheet'

import FactoryAddress from '../PrintFormat-TestReport/FactoryAddress'
import Header from './Header'
import Footer from './Footer'

const Document = ({washCareData,testList,methodList,conditionList,data,groupName,groupTestDetails,sampleItem,sampleWashCareDetails, componentRef}) => {
  return (
    <>
     
        <div  id='print' className="font-times w-full bg-white shadow-md p-4 rounded-md grid grid-cols-1 place-content-center"  >
            <table>
            <thead>
                <tr>
                    <td>
                        <div class="page-header pl-5 pt-1.5 font-times">
                <Header />
           
            <br />

            </div>
            <div className="page-header-space">
            </div>
            </td>
            </tr>
            </thead>

            <tbody>
            <tr>
            <td>
      <PrintSheet washCareData={washCareData} testList={testList} methodList={methodList} conditionList={conditionList} data={data} groupName={groupName} groupTestDetails={groupTestDetails} sampleItem={sampleItem} sampleWashCareDetails={sampleWashCareDetails} />      
      </td>
                </tr>
            </tbody>

            <tfoot>
                <tr>
                    <td>
                        <footer></footer>
                        <div className="page-footer p-2 font-times ">
                            <Footer/>
                        </div>
                        <div className="page-footer-space">
                        </div>
                    </td>
                </tr>
            </tfoot>

        </table>
        </div>
    
    </>
  )
}

export default Document