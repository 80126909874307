import React, { useState } from "react";
import RegistrationLoginExcel from "./RegistrationLoginExcel";




export default function RegistrationLoginSummary() {
    return <RegistrationLoginExcel
        dataClick={(sampleId, registrationId) => {

        }}
    />
}