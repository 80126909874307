import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LAB_TEST_ANALYTE_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const LabTestAnalyteMasterApi = createApi({
  reducerPath: "labTestAnalyteMaster",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["LabTestAnalyteMaster"],
  endpoints: (builder) => ({
    getLabTestAnalyteMaster: builder.query({
      query: ({params, searchParams}) => {
        if(searchParams){
          return {
            url: LAB_TEST_ANALYTE_API +"/search/"+searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LAB_TEST_ANALYTE_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["LabTestAnalyteMaster"],
    }),
    getLabTestAnalyteMasterById: builder.query({
      query: (id) => {
        return {
          url: `${LAB_TEST_ANALYTE_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["LabTestAnalyteMaster"],
    }),
    addLabTestAnalyteMaster: builder.mutation({
      query: (payload) => ({
        url: LAB_TEST_ANALYTE_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["LabTestAnalyteMaster"],
    }),
    updateLabTestAnalyteMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${LAB_TEST_ANALYTE_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["LabTestAnalyteMaster"],
    }),
    deleteLabTestAnalyteMaster: builder.mutation({
      query: (id) => ({
        url: `${LAB_TEST_ANALYTE_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LabTestAnalyteMaster"],
    }),
  }),
});

export const {
  useGetLabTestAnalyteMasterQuery,
  useGetLabTestAnalyteMasterByIdQuery,
  useAddLabTestAnalyteMasterMutation,
  useUpdateLabTestAnalyteMasterMutation,
  useDeleteLabTestAnalyteMasterMutation,
} = LabTestAnalyteMasterApi;

export default LabTestAnalyteMasterApi;
