import { DELETE } from '../../../icons';
import { useGetAccessoryMasterQuery } from '../../../redux/ErpServices/AccessoryMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, isBetweenRange, substract } from '../../../Utils/helper';
import { toast } from 'react-toastify';


const AccessoryInwardItems = ({ finalInwardItems, setFinalInwardItems, readOnly, removeItem }) => {
    const handleInputChange = (value, index, field) => {
        console.log("value", value, index, field)
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        setFinalInwardItems(newBlend);
    };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const { data: accessoryList } =
        useGetAccessoryMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    function findAccessoryItemName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(row => parseInt(row.id) === parseInt(id))
        return acc ? acc.accessoryItem.name : null
    }

    function findAccessoryGroupName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(row => parseInt(row.id) === parseInt(id))
        return acc ? acc.accessoryItem.AccessoryGroup.name : null
    }

    const { data: sizeList } =
        useGetSizeMasterQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });


    if (!accessoryList || !colorList || !uomList || !sizeList) return <Loader />
    return (
        <>
            <div className={`relative w-full overflow-auto py-1`}>
                <table className="border border-gray-600 text-xs table-fixed w-full  ">
                    <thead className='bg-blue-200 top-0'>
                        <tr className='h-8'>
                            <th className="table-data border border-gray-600  text-center">S.no</th>
                            <th className="table-data border border-gray-600  text-center">Po.no</th>
                            <th className="table-data border border-gray-600">Accessory Name</th>
                            <th className="table-data border border-gray-600">Accessory Items</th>
                            <th className="table-data border border-gray-600">Accessory Group</th>
                            <th className="table-data border border-gray-600">Colors</th>
                            <th className="table-data border border-gray-600">Size</th>
                            <th className="table-data border border-gray-600 ">UOM</th>
                            <th className="table-data border border-gray-600 ">Po Qty</th>
                            <th className="table-data border border-gray-600 ">Already Inwarded Qty</th>
                            <th className="table-data border border-gray-600 ">Balance Qty</th>
                            <th className="table-data border border-gray-600 ">Inward Qty</th>
                            <th className="table-data border border-gray-600 ">Price</th>
                            <th className="table-data border border-gray-600 ">Gross</th>
                            {!readOnly &&
                                <th className='table-data border border-gray-600 w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto border border-gray-600 h-full w-full text-right'>
                        {finalInwardItems.map((row, index) => (
                            <tr key={index} className="w-full">
                                <td className="border border-gray-600 text-center py-1">
                                    {index + 1}
                                </td>
                                <td className="border border-gray-600 text-center py-1">
                                    {row.poNo}
                                </td>
                                <td className='border border-black '>
                                    {findFromList(row.accessoryId, accessoryList.data, "aliasName")}
                                </td>
                                <td className='border border-gray-600'>
                                    {findAccessoryItemName(row.accessoryId)}
                                </td>
                                <td className='border border-gray-600'>
                                    {findAccessoryGroupName(row.accessoryId)}
                                </td>
                                <td className='border border-black'>
                                    {findFromList(row.colorId, colorList.data, "name")}
                                </td>
                                <td className='border border-black'>
                                    {findFromList(row.sizeId, sizeList.data, "name")}
                                </td>
                                <td className='border border-black'>
                                    {findFromList(row.uomId, uomList.data, "name")}
                                </td>
                                <td className='border text-right border-black'>
                                    {substract(row.qty, row.alreadyCancelQty).toFixed(3)}
                                </td>
                                <td className='border border-black text-right'> 
                                    {substract(parseFloat(row.alreadyInwardedQty), parseFloat(row.alreadyReturnedQty)).toFixed(3)}
                                </td>
                                <td className='border border-black text-right'>
                                    {substract(substract(row.qty,row.alreadyCancelQty),(parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))).toFixed(3)}
                                </td>
                                <td className='border-black border-2'>
                                    <input
                                        type="number"
                                        className="text-right rounded py-1  px-1 w-full"
                                        value={row.inwardQty}
                                        disabled={readOnly}
                                        onChange={(event) => {
                                            if(!event.target.value){
                                                handleInputChange(0, index, "inwardQty");
                                                return
                                            }
                                            if (isBetweenRange(0, substract(substract(row.qty, row.alreadyCancelQty), (parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))), event.target.value)) {
                                                handleInputChange(event.target.value.replace(/^0+/, ''), index, "inwardQty")
                                            }else {
                                                toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                                            }
                                        }}
                                        onBlur={(e) =>
                                            {
                                                
                                                if(!e.target.value){
                                                    handleInputChange(0.000, index, "inwardQty");
                                                    return
                                                }
                                                handleInputChange(parseFloat(e.target.value).toFixed(3), index, "inwardQty")
                                            }
                                           
                                        }
                                    />
                                </td>
                                <td className='border border-black'>
                                    {(row.price)}
                                </td>
                                <td className='border border-black'>
                                    {parseFloat((row.price) * (row.inwardQty)).toFixed(2)}
                                </td>
                                {!readOnly &&
                                    <td className='border border-gray-600 w-12'>
                                        <div tabIndex={-1} onClick={() => removeItem(row.poNo, row.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default AccessoryInwardItems
