import React from 'react'
import { useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices'
import { GenerateButton } from '../../../Buttons'

const GenerateProfomaInvoiceNumber = ({ registrationId }) => {
    const [update] = useUpdateRegistrationMutation()
    async function handleGenerate() {
        await update({ id: registrationId, isGenerateProfomaInvoiceNumber: true })
    }
    return (
        <GenerateButton onClick={handleGenerate} />
    )
}

export default GenerateProfomaInvoiceNumber