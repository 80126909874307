import React from 'react'
import { TICK_ICON_PDF, CLOSE_ICON_PDF, CLOSE_ICON } from '../../../../icons';
import { Text, View, Image } from '@react-pdf/renderer';
import tw from '../../../../Utils/tailwind-react-pdf';

const TestLists = ({ passFail, test, }) => {

  let pass, fail, data;
  if (passFail === "PASS") {
    pass = test?.pass ? CLOSE_ICON_PDF : "";
  } else if (passFail === "FAIL") {
    fail = test?.fail ? CLOSE_ICON_PDF : "";
  } else if (passFail === "ALL") {
    pass = test?.pass ? CLOSE_ICON_PDF : "";
    fail = test?.fail ? CLOSE_ICON_PDF : "";
    data = test?.data ? CLOSE_ICON_PDF : "";
  }

  return (
    <View style={tw('flex flex-row w-full')}>
      <Text style={tw('p-1 border-b border-x  border-gray-500 text-xs text-center w-[45%] capitalize')}>
        {`${test?.testName.toLowerCase() || ""} ${test?.testSamples?.length > 1 ? [test?.testSamples?.map(value => value).join("&")] : ""}`}
      </Text>
      <View style={tw('p-1 pl-4 border-b border-r border-gray-500 text-xs text-center w-[8%]')}>
        {test?.pass ? CLOSE_ICON_PDF : ""}
      </View>
      <View style={tw('p-1 pl-4 border-b border-r border-gray-500 text-xs text-center w-[8%]')}>
        {test?.fail ? CLOSE_ICON_PDF : ""}
      </View>
      <View style={tw('p-1 pl-4 border-b border-r border-gray-500 text-xs text-center w-[8%]')}>
        {test?.data ? CLOSE_ICON_PDF : ""}
      </View>
      <Text style={tw('p-1 border-b border-r border-gray-500 text-xs text-center w-[18%] capitalize')}>{test?.comments || ""}
      </Text>
    </View>
  )
}

export default TestLists