import React from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices"
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetRegistrationQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { useGetPackageMasterQuery } from '../../../redux/LabServices/PackageMasterServices';
import { useGetLabTestResultEntryQuery } from '../../../redux/LabServices/LabTestResultEntryServices';
import { Modal } from '../../../Inputs';
import TestReportContainerPreview from '../DashBoard/TestReportContainerPreview';
import { BACKWARD_ICON_PDF, VIEW } from '../../../icons';
import { SIGNOUT_ICON_PDF } from '../../../icons';
import { toast } from 'react-toastify';
import TestReportDocument from '../DashBoard/TestReportContainerPreviewReactPdf';
import TestReportRevert from '../AdminResultEntry/TestReportRevert';
import { useGetUserByIdQuery } from '../../../redux/services/UsersMasterService';
import TestListPrice from './TestListPrice';
import TestPackagePrice from './TestPackagePrice';
import TestLists from './TestLists';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const PartyExcel = ({
    heading = "Party Summary",
    dataClick
}) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocId, setSearchDocId] = useState("");

    const [searchDueDate, setSearchDueDate] = useState("");
    const [searchSupplierName, setSearchSupplierName] = useState("");
    const [searchBuyerName, setSearchBuyerName] = useState("");
    const [searchPackageName, setSearchPackageName] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [searchSampleLoginDate, setSearchSampleLoginDate] = useState()


    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = { searchSupplierName }

    useEffect(() => { setCurrentPageNumber(1) }, [dataPerPage, searchSupplierName,])

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const params = {
        branchId, companyId
    };

    const { data: packageList, isLoading: isPackageLoading, isFetching: isPackageFetching } = useGetPackageMasterQuery({ params })
    const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
        useGetPartyQuery({ params: { ...params, ...searchFields, excelReport: true, dataPerPage, pageNumber: currentPageNumber, } });

    useEffect(() => {
        if (supplierList?.totalCount) {
            setTotalCount(supplierList?.totalCount)
        }
    }, [supplierList, isSupplierLoading, isSupplierFetching])


    useEffect(() => {
        let registrationFormReportComponent = document.getElementById("registrationFormReport");
        registrationFormReportComponent.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = document.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            }
        });
        return () => {
            registrationFormReportComponent.removeEventListener('keydown', () => { });
        };
    }, []);


    const isLoadingIndicator = isSupplierFetching || isSupplierLoading || isPackageFetching || isPackageLoading


    return (
        <>

            <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto">
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10">{heading}</div>
                    <div className=" sub-heading justify-center md:justify-start items-center">
                        <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
                        <select value={dataPerPage}
                            onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                            {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
                        </select>
                    </div>
                </div>
                <>
                    <div
                        className="h-full overflow-auto"
                    >

                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button text-white bg-blue-400 p-2 text-xs rounded-xl mt-1 ml-3"
                            table="party-summary"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="GoToExcel"

                        >
                        </ReactHTMLTableToExcel>






                        <table id="party-summary" style={{ display: 'none' }} className="table-auto text-center w-full border border-gray-400 mt-3">
                            <thead className="table-Excel-header">
                                <tr className='h-2 textExcel'>
                                    <th
                                        className="border-2  top-0 stick-bg w-5 textExcel"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>PartyCode</div>

                                    </th>




                                    <th

                                        className="border-2  top-0 stick-bg flex flex-col textExcel"
                                    >
                                        <div>PartyName</div>
                                    </th>


                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Address</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg textExcel"
                                    >
                                        <div>Mobile.No</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>BuyerType</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>SupplierType</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>InvoiceType</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>ListOfTests</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>IndividualPrice</div>

                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>PackagePrice</div>

                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Remarks</div>

                                    </th>



                                </tr>
                            </thead>

                            <tbody className="border-2">
                                {(supplierList?.data || []).map((dataObj, index) => (
                                    <tr
                                        key={dataObj.id}
                                        className="border-2 table-Excel-row cursor-pointer textExcel"                    >
                                        <td className='py-1 textExcel'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className='py-1 textExcel'> {dataObj?.code || ""}</td>

                                        <td className='py-1 textExcel capitalize w-40'> {dataObj?.name.toLowerCase() || ""}</td>

                                        <td className='py-1 textExcel capitalize w-48 text-left px-1'> {dataObj?.address.toLowerCase() || ""}</td>
                                        <td className='py-1 textExcel'>{dataObj?.PartyBranch?.find(item => item.isHo)?.contactDetails[0]?.mobileNo}</td>
                                        <td className='py-1 textExcel'>{dataObj?.isBuyer ? "YES" : "NO"}     </td>
                                        <td className='py-1 textExcel'>{dataObj?.isSupplier ? "YES" : "NO"}     </td>
                                        <td className='py-1 textExcel'> {dataObj.isGstBill ? "Gst" : "Non-Gst"}</td>
                                        <td className='py-1 textExcel text-left'>
                                            <table className=' text-left w-full'>
                                                <tbody>
                                                    {
                                                        dataObj?.testDetails?.map((val, valIndex) =>
                                                            <TestLists key={valIndex} val={val} valIndex={valIndex} />
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </td>

                                        <td className='pb-2 textExcel'>
                                            <table className=' text-center w-full'>
                                                <tbody>
                                                    {
                                                        dataObj?.testDetails?.map((val, valIndex) =>
                                                            <TestListPrice key={valIndex} val={val} valIndex={valIndex} />
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </td>

                                        <td className='pb-1 textExcel'>
                                            <table className=' text-center w-full'>
                                                <tbody>
                                                    {
                                                        dataObj?.Package?.map((val, valIndex) =>
                                                            <TestPackagePrice key={valIndex} val={val} valIndex={valIndex} />
                                                        )
                                                    }
                                                </tbody>
                                            </table>

                                        </td>

                                        <td className='py-1 textExcel'>


                                        </td>




                                    </tr>
                                ))}
                            </tbody>

                        </table>









                        <table id="original-table" className="table-auto text-center w-full border border-gray-400 mt-3">
                            <thead className="table-Excel-header">
                                <tr className='h-2 textExcel'>
                                    <th
                                        className="border-2  top-0 stick-bg w-5 textExcel"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>PartyCode</div>

                                    </th>




                                    <th

                                        className="border-2  top-0 stick-bg flex flex-col textExcel"
                                    >
                                        <div>PartyName</div><input
                                            type="text"
                                            className="text-black  h-6 focus:outline-none border  border-gray-400 rounded-lg"
                                            placeholder="Search"
                                            value={searchSupplierName}
                                            onChange={(e) => {
                                                setSearchSupplierName(e.target.value);
                                            }}
                                        />
                                    </th>


                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Address</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg textExcel"
                                    >
                                        <div>Mobile.No</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>BuyerType</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>SupplierType</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>InvoiceType</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>ListOfTests</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>IndividualPrice</div>

                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>PackagePrice</div>

                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel"
                                    >
                                        <div>Remarks</div>

                                    </th>



                                </tr>
                            </thead>

                            <tbody className="border-2">
                                {(supplierList?.data || []).map((dataObj, index) => (
                                    <tr
                                        key={dataObj.id}
                                        className="border-2 table-Excel-row cursor-pointer textExcel"                    >
                                        <td className='py-1 textExcel'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className='py-1 textExcel'> {dataObj?.code || ""}</td>

                                        <td className='py-1 textExcel capitalize w-40'> {dataObj?.name.toLowerCase() || ""}</td>

                                        <td className='py-1 textExcel capitalize w-48 text-left px-1'> {dataObj?.address.toLowerCase() || ""}</td>
                                        <td className='py-1 textExcel'>{dataObj?.PartyBranch?.find(item => item.isHo)?.contactDetails[0]?.mobileNo}</td>
                                        <td className='py-1 textExcel'>{dataObj?.isBuyer ? "YES" : "NO"}     </td>
                                        <td className='py-1 textExcel'>{dataObj?.isSupplier ? "YES" : "NO"}     </td>
                                        <td className='py-1 textExcel'> {dataObj.isGstBill ? "Gst" : "Non-Gst"}</td>
                                        <td className='py-1 textExcel text-left'>
                                            <table className=' text-left w-full'>
                                                <tbody>
                                                    {
                                                        dataObj?.testDetails?.map((val, valIndex) =>
                                                            <TestLists key={valIndex} val={val} valIndex={valIndex} />
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </td>

                                        <td className='pb-2 textExcel'>
                                            <table className=' text-center w-full'>
                                                <tbody>
                                                    {
                                                        dataObj?.testDetails?.map((val, valIndex) =>
                                                            <TestListPrice key={valIndex} val={val} valIndex={valIndex} />
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </td>

                                        <td className='pb-1 textExcel'>
                                            <table className=' text-center w-full'>
                                                <tbody>
                                                    {
                                                        dataObj?.Package?.map((val, valIndex) =>
                                                            <TestPackagePrice key={valIndex} val={val} valIndex={valIndex} />
                                                        )
                                                    }
                                                </tbody>
                                            </table>

                                        </td>

                                        <td className='py-1 textExcel'>


                                        </td>




                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </>
                {/* <ReactPaginate
                    previousdiv={"<"}
                    nextdiv={">"}
                    breakdiv={"..."}
                    breakClassName={"break-me"}
                    forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                    pageCount={Math.ceil(totalCount / dataPerPage)}
                    marginPagesDisplayed={1}
                    onPageChange={handleOnclick}
                    containerClassName={"flex justify-center m-2 gap-5 items-center"}
                    pageClassName={"border custom-circle text-center"}
                    disabledClassName={"p-1 bg-gray-200"}
                    previousLinkClassName={"border p-1 text-center"}
                    nextLinkClassName={"border p-1"}
                    activeClassName={"bg-blue-900 text-white px-2"} /> */}


            </div>
        </>
    )
}

export default PartyExcel