import React, { useState } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import SampleDocument from './SampleDocument';
import { toast } from 'react-toastify';

const MyPDFViewerComponent = () => {
    const [pdfBlob, setPdfBlob] = useState(null); // State to hold the PDF blob

    const handleDownload = () => {
        // You can use the Blob constructor to create a new Blob object
        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        const file = new File([blob], 'document.pdf', { type: 'application/pdf' });
        const form = new FormData();
        form.append("file", file);
        fetch(`${process.env.REACT_APP_SERVER_URL}sendMail`, {
            method: "POST",
            body: form
        }).then(res => {
            toast.success("Mail Send SuccessFully");
        }).catch(err => {
            toast.error("Please Resend !")
        });
    };

    return (
        <div>
             <PDFDownloadLink document={<SampleDocument />} fileName="my_pdf3.pdf">
                {({ blob }) => {
                    // Store the blob in state when available
                    setPdfBlob(blob);
                    return <button onClick={handleDownload}>Download PDF</button>;
                }}
            </PDFDownloadLink>
            <PDFViewer className='w-full h-full'>
                <SampleDocument />
            </PDFViewer>
           
        </div>
    );
};

export default MyPDFViewerComponent;
