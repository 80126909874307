import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import ParameterItem from './ParameterItem';

const ParameterHeading = ({ index, selectedOption, onKeyDown, item, handleInputChange, readOnly, childRecord, setLabTestAnalyteDetails, handleInputChangeParameter, id, sampleTypeTwoList, childRecordExists }) => {
    const [activeCurrentIndex, setActiveCurrentIndex] = useState({})
    function handleCopyPaste(name) {

        if (!Number.isInteger(activeCurrentIndex?.parameterIndex)) return
        setLabTestAnalyteDetails(prev => {
            let newItems = structuredClone(prev)
            newItems[index]["labTestAnalyteDetailsParameters"][activeCurrentIndex.parameterIndex]["value"] = `${newItems[index]["labTestAnalyteDetailsParameters"][activeCurrentIndex.parameterIndex]["value"]} ({${name}})`;
            return newItems;
        })
    }
    return (
        <>
            <tr className="items-center" key={index}>
                <td className="border border-gray-500 text-xs text-center">{index + 1}</td>
                <td className=" text-xs bg-gray-400" colSpan={selectedOption == "CLAIMEDFABRICCOUNT" ? 10 : 8}>
                    <input className="w-full h-full p-1 uppercase" name="headings" type="text" onKeyDown={(e) => onKeyDown(e, index)} value={item.heading}

                        onChange={(e) =>
                            handleInputChange(e.target.value, index, "heading")
                        }
                        onBlur={(e) => {

                            handleInputChange(e.target.value, index, "heading");


                        }
                        }
                        readOnly={readOnly} disabled={readOnly || childRecordExists}
                    />

                </td>
                <td className="border border-gray-500 p-1 text-xs text-center" >
                    <button
                        type='button' tabIndex={-1}
                        disabled={readOnly || childRecordExists}
                        onClick={() => {
                            setLabTestAnalyteDetails(prev => {
                                let newPrev = structuredClone(prev);
                                newPrev = newPrev.filter((_, i) => i !== index)
                                return newPrev
                            })
                        }}
                        className='text-md text-red-600 ml-1'>{<FontAwesomeIcon icon={faTrashCan} />}</button>
                </td>
            </tr>

            {item?.labTestAnalyteDetailsParameters?.map((parameter, parameterIndex) =>

                <ParameterItem key={parameterIndex} selectedOption={selectedOption} childRecordExists={childRecordExists}
                    parameterIndex={parameterIndex} parameter={parameter} index={index} handleInputChangeParameter={handleInputChangeParameter} sampleTypeTwoList={sampleTypeTwoList} id={id} readOnly={readOnly} childRecord={childRecord} activeCurrentIndex={activeCurrentIndex} onKeyDown={onKeyDown} handleCopyPaste={handleCopyPaste} setActiveCurrentIndex={setActiveCurrentIndex} setLabTestAnalyteDetails={setLabTestAnalyteDetails}
                />
            )}

        </>
    )
}

export default ParameterHeading