import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";


import { useGetBlendMasterQuery } from "../../../redux/ErpServices/BlendMasterServices";



import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, LongTextInput, DropdownInput, LongDisabledInput, CheckBox, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { Loader } from "../../../Basic/components";
import { DELETE, PLUS } from "../../../icons";
import {
    useAddFiberContentMasterMutation,
    useDeleteFiberContentMasterMutation,
    useGetFiberContentMasterByIdQuery,
    useGetFiberContentMasterQuery,
    useUpdateFiberContentMasterMutation
} from "../../../redux/ErpServices/FiberContentMasterServices";
const MODEL = "Fiber Content Master";


export default function Form() {
    const [form, setForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const [id, setId] = useState("");
    const [aliasName, setAliasName] = useState("");
    const [blendDetails, setBlendDetails] = useState([]);



    const [active, setActive] = useState(true);


    const [searchValue, setSearchValue] = useState("");

    const childRecord = useRef(0);

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const userId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userId"
    )
    const params = {
        companyId
    };

    const { data: blendList, isLoading: isBlendListLoading, isFetching: isBlendListFetching } =
        useGetBlendMasterQuery({ params });



    const { data: allData, isLoading, isFetching } = useGetFiberContentMasterQuery({ params, searchParams: searchValue });


    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetFiberContentMasterByIdQuery(id, { skip: !id });

    const [addData] = useAddFiberContentMasterMutation();
    const [updateData] = useUpdateFiberContentMasterMutation();
    const [removeData] = useDeleteFiberContentMasterMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) setReadOnly(true);
        if (id) setAliasName(data?.aliasName ? data?.aliasName : "");
        setBlendDetails(data?.BlendDetails ? data?.BlendDetails : [{ blendId: "", percentage: "" }, { blendId: "", percentage: "" }, { blendId: "", percentage: "" }, { blendId: "", percentage: "" }]);
        setActive(id ? (data?.active ? data.active : false) : true);
    }, [id]);

    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        aliasName,
        blendDetails: blendDetails ? blendDetails.filter(item => item.blendId) : undefined,
        active, companyId, id, userId
    }

    const validatePercentage = () => {
        const yarnBlendPercentage = blendDetails.filter(blend => blend.blendId).reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue.percentage)
        }, 0);
        return yarnBlendPercentage === 100
    }

    function findName(arr, id) {
        if (!arr) return ""
        let data = arr.find(item => parseInt(item.id) === parseInt(id))
        return data ? data.name : ""
    }

    const calculateYarnName = () => {


        let yarnBlend = blendDetails ?
            blendDetails?.filter(blend => blend.blendId && blend.percentage).map(blend => `${parseInt(blend.percentage)}%${findName(blendList?.data, blend.blendId)}`).join(' ') : "";


        return `${yarnBlend}`
    }

    useEffect(() => {
        if (id) return
        setAliasName(calculateYarnName())
    }, [calculateYarnName()])



    const validateData = (data) => {
        return data.aliasName
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback({ id, body: data }).unwrap();
            } else {
                returnData = await callback(data).unwrap();
            }
            setId(returnData.data.id)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };


    const saveData = () => {
        if (!validatePercentage()) {
            toast.info("Yarn Blend equal to 100...!", { position: "top-center" })
            return
        }
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }

        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                onNew();
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        setReadOnly(false);
        // syncFormWithDb(undefined)
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }



    function findIdInYarnBlend(id) {
        return blendDetails ? blendDetails.find(blend => parseInt(blend.blendId) === parseInt(id)) : false
    }

    const handleInputChange = (event, index, field) => {
        const value = event.target.value;
        const newBlend = structuredClone(blendDetails);
        newBlend[index][field] = value;
        setBlendDetails(newBlend);
    };

    useEffect(() => {
        if (readOnly) return
        else {
            if (blendDetails.length === 0) {
                setBlendDetails([{ blendId: "", percentage: "" }, { blendId: "", percentage: "" }, { blendId: "", percentage: "" }, { blendId: "", percentage: "" }]);
            }
        }
    }, [blendDetails, readOnly])

    const addRow = () => {
        if (blendDetails.length >= blendList.data.length) {
            toast.info("No More Blends", { position: 'top-center' })
            return
        }
        const newRow = { blendId: "", percentage: "" };
        setBlendDetails([...blendDetails, newRow]);
    };
    const handleDeleteRow = (id) => {
        setBlendDetails(yarnBlendDetails => yarnBlendDetails.filter((row, index) => index !== parseInt(id)));
    };
    const onKeyDown = (e) => {
        if (e.key === "Tab") {
            addRow();
        }
    };


    if (!blendList || isBlendListLoading || isBlendListFetching) return <Loader />

    const tableHeaders = ["Alias Name", "Status"]
    const tableDataNames = ['dataObj.aliasName', 'dataObj.active ? ACTIVE : INACTIVE']

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}

                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >{console.log(singleData, "singleData")}
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                // childRecord={childRecord.current}
                />
                <div className="flex-1 grid md:grid-cols-2">
                    <div className="border-l w-[800px] h-[570px] overflow-auto">
                        <div className='col-span-3 mr-1 md:ml-5'>
                            <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-5 md:w-[650px] flex h-[300px] overflow-auto border border-gray-600'>
                                <legend className='sub-heading'>Blend Details</legend>
                                <div className=''>

                                    <div className='w-full overflow-y-auto p-3 '>
                                        <table className="w-full border border-gray-600 text-xs table-auto">
                                            <thead className='bg-gray-100 top-0'>
                                                <tr>
                                                    <th className="table-data border border-gray-600">Blend</th>
                                                    <th className="table-data border border-gray-600">Percentage</th>
                                                    <th>
                                                        {readOnly ?
                                                            "" :
                                                            <div onClick={addRow}
                                                                className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white rounded'>
                                                                {PLUS}
                                                            </div>
                                                        }
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
                                                {blendDetails.map((row, index) => (
                                                    <tr key={index} className="w-full">
                                                        <td className='flex justify-center items-center'>
                                                            <select disabled={readOnly} className='text-left w-full rounded border border-gray-600 h-8 py-2 focus:outline-none' value={row.blendId} onChange={(e) => handleInputChange(e, index, "blendId")}>
                                                                <option hidden>
                                                                    Select
                                                                </option>
                                                                {(id ? blendList.data : blendList.data.filter(item => item.active)).map((blend) =>
                                                                    <option value={blend.id} key={blend.id} hidden={findIdInYarnBlend(blend.id)}>
                                                                        {blend.name}
                                                                    </option>
                                                                )}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input
                                                                onKeyDown={(index === (blendDetails.length - 1)) ? onKeyDown : () => { }}
                                                                type="number"
                                                                className="border border-gray-600 rounded px-4 h-8 py-2 w-full"
                                                                value={parseInt(row.percentage, 10)}
                                                                disabled={readOnly || !row.blendId}
                                                                onChange={(event) =>
                                                                    handleInputChange(event, index, "percentage")
                                                                }
                                                            />
                                                        </td>
                                                        <td className='border border-gray-600'>
                                                            {readOnly
                                                                ?
                                                                ""
                                                                :
                                                                <div className='flex justify-center py-2 items-center rounded bg-gray-300'>
                                                                    <button tabIndex={-1} onClick={() => handleDeleteRow(index)}>{DELETE}</button>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <LongTextInput name="Alias Name" className={'focus:outline-none md:col-span-2 h-6 w-[450px] border border-gray-500 rounded'} type="text" value={aliasName} setValue={setAliasName} readOnly={readOnly} required={true} disabled={(childRecord.current > 0)} />
                                    <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />

                                </div>
                            </fieldset>


                        </div>
                    </div>
                    <div className="frame overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}
