import React from 'react'

const TestListPrice = ({ val, valIndex }) => {
    return (
        <>
            <tr className='text-center'>
                <td className='py-1 gridExcel text-center '>
                    {val?.price ? val?.price : ""}
                </td>
            </tr>
        </>
    )
}

export default TestListPrice